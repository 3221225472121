import {
    Card, CardText, CardBody, CardLink,
    CardHeader, CardFooter, CardTitle, CardSubtitle, Badge,
} from 'reactstrap';

import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux'
import { selectors as AuthSelectors } from '../store/slices/auth'

 const InteractiveSession = (props: any) => {

    const { content } = props;
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation('frontend', { useSuspense: false });
    const isLogged = useSelector(AuthSelectors.isLogged);

    const renderDescrizione = () =>
    {
        return content.descrizione
    }

    return (<Card className="mb-3">
        <CardBody>
            <CardTitle data-tip={t("Sessione interattiva")} tag="h6" className="clearfix">
                {`${content.titolo} (${content.durata} min.)`}
            </CardTitle>
            <CardText>{renderDescrizione()}</CardText>
        </CardBody>
    </Card>)
}

export default InteractiveSession;