import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const BOOKMARKS_STORAGE_KEY = "bookmarks";

const getExperimentSectionBookmarks = (experimentId:any, activeTab:any, userId:any) => {
  // getting stored value
  const saved = localStorage.getItem(BOOKMARKS_STORAGE_KEY);
  //console.log("BOOKMARK saved->" , saved)
  const initial = saved ? JSON.parse(saved) : {};
  //console.log("BOOKMARK initial->", initial)
  if (!initial || !initial[userId] || !initial[userId][experimentId] 
               || !initial[userId][experimentId][activeTab]) return [];
  else return initial[userId][experimentId][activeTab];
}

const updateLocalStorage = (bookmarks:any, experimentId:any,activeTab:any,userId:any) =>
{
  let bookmarksStorage = JSON.parse(localStorage.getItem(BOOKMARKS_STORAGE_KEY) as any) || {};
    if (!bookmarksStorage[userId]) bookmarksStorage[userId] = {};
    if (!bookmarksStorage[userId][experimentId]) bookmarksStorage[userId][experimentId] = {}
    bookmarksStorage[userId][experimentId][activeTab] = bookmarks;
    localStorage.setItem(BOOKMARKS_STORAGE_KEY, JSON.stringify(bookmarksStorage));
    return bookmarksStorage;
}


export const bookmarksSlice = createSlice({
    name: 'bookmarks',
    initialState: {
      bookmarks: [] as any,
      selectedBookmark: null as any,
      experimentId:"",
      activeTab:0,
      userId:"",
      bookmarksStorage:{},
    }
    ,
    reducers: {
      loadBookmarks: (state, action: PayloadAction<any>) => {
        
        const params = action.payload;
        state.experimentId = params["experimentId"];
        state.activeTab = params["activeTab"];
        state.userId = params["userId"];
        //console.log("BMR: In loadBookmarks", params)
        state.bookmarks = getExperimentSectionBookmarks(
          params["experimentId"], 
          params["activeTab"], 
          params["userId"])
          
        state.bookmarksStorage = JSON.parse(localStorage.getItem(BOOKMARKS_STORAGE_KEY) as any) || {};
      },
      setSelectedBookmark: (state, action: PayloadAction<any>) => {
        state.selectedBookmark = action.payload;
      },
      addBookmark: (state, action: PayloadAction<any>) => {
        const bookmark = action.payload;
        const newBookmarks = [...state.bookmarks, bookmark];
        state.bookmarks = newBookmarks;
        state.bookmarksStorage = updateLocalStorage(newBookmarks, state.experimentId,state.activeTab,state.userId);
      },
      updateBookmark: (state, action: PayloadAction<any>) => {
        const bookmarkToUpdate = action.payload;
        const newBookmarks = state.bookmarks.map((bookmark:any) =>{ if 
          (bookmark.id==bookmarkToUpdate.id) return bookmarkToUpdate; else return bookmark});
        state.bookmarks = newBookmarks;
        state.bookmarksStorage = updateLocalStorage(newBookmarks, state.experimentId,state.activeTab,state.userId);
      },
      removeBookmark: (state, action: PayloadAction<any>) => {
        const bookmarkIdToRemove = action.payload;
        //console.log("BOOKMARKid to remove:",bookmarkIdToRemove);
        const newBookmarks = state.bookmarks.filter((bookmark:any) => { return bookmark.id!=bookmarkIdToRemove})
        state.bookmarks = newBookmarks;
        state.bookmarksStorage = updateLocalStorage(newBookmarks, state.experimentId,state.activeTab,state.userId);
      }
     
    }
  });
  
  
  export const { actions, reducer }: any = bookmarksSlice
  

  export const selectors = {
    getBookmarks: (state: any) => {
      return state.bookmarks.bookmarks;
    },
    getSelectedBookmark: (state: any) => {
      return state.bookmarks.selectedBookmark;
    }

  }