import { FaPlay, FaPowerOff } from 'react-icons/fa';
import { IconButton } from '@material-ui/core';
import { useEffect, useState, useRef } from 'react';
import { VncScreen } from 'react-vnc';
import RFB from 'react-vnc/dist/types/noVNC/core/rfb';
import { useDispatch, useSelector } from 'react-redux';
import { selectors as CatalogSelector, actions as CatalogActions } from '../store/slices/catalog'
import { actions as ProfileActions, selectors as ProfileSelectors } from '../store/slices/profile'
import { actions as AppointmentActions, selectors as AppointmentsSelectors } from '../store/slices/appointments'
import { useParams } from 'react-router-dom'
import {
    Container, Row, Col, Card, CardText, CardBody, CardLink,
    Modal, ModalHeader, ModalBody, ModalFooter,
    CardHeader, CardFooter, CardTitle, CardSubtitle, Badge, Input, ButtonToolbar
  } from 'reactstrap';
  
import LanguageSelector from '../components/LanguageSelector';
import { ScreenshotCaptureComponent } from '../components/ScreenshotComponents';

export const DirectorInteractiveSessionPage = () => {

    const { experiment_id, interactive_device_id }: any = useParams();
    const interactiveDevices = useSelector(AppointmentsSelectors.getInteractiveDevices);
    const isDirector = useSelector(ProfileSelectors.isDirector);
    const ref = useRef(null) as any;
    const [connectionState, setConnectionState] = useState("idle")
    const dispatch = useDispatch()
    //const experienceInfo = useSelector(CatalogSelector.getCurrentExperimentWithIDevice) as any;
    const currentExperiment = useSelector(CatalogSelector.getCurrentExperiment);
    const [deviceInfo, setDeviceInfo] = useState(null) as any;
    const catalog = useSelector(CatalogSelector.getCatalog);
    const experimentsWithInteractiveDevices =
      useSelector(CatalogSelector.getExperimentsWithInteractiveDevices) as any;
    const [downloadScreenshot, setDownloadScreenshot] = useState(false) as any;
    const startEC2 = () => {
      const payload: any = {
        "deviceId": interactive_device_id,
        "action": "start"
      }
      dispatch(AppointmentActions.willStartStopEC2byInteractiveDeviceId(payload))
    }
  
    const stopEC2 = () => {
      const payload: any = {
        "deviceId": interactive_device_id,
        "action": "stop"
      }
      dispatch(AppointmentActions.willStartStopEC2byInteractiveDeviceId(payload))
    }
  
    const connect = () => {
      ref?.current?.connect();
    }
  
    const disconnect = () => {
      ref?.current?.disconnect();
    }
  
  
  
    useEffect(() => {
      dispatch(AppointmentActions.willLoadInteractiveDevices());
      dispatch(CatalogActions.willGetExperiment(experiment_id));
    }, []);
  
    useEffect(() => {
      dispatch(CatalogActions.willGetExperiment(experiment_id));
    }, [catalog]);
  
    useEffect(() => {
  
    }, [experimentsWithInteractiveDevices]);
  
    useEffect(() => {
      ////console.log("current experiment:", currentExperiment);
      const currentDevice = currentExperiment?.interactive_devices?.find((device: any) => device.id == interactive_device_id)
      setDeviceInfo(currentDevice);
      //console.log("current device:", currentDevice);
    }, [currentExperiment]);
  
  
    useEffect(() => {
      ////console.log("Interactive devices:", interactiveDevices);
      if (interactiveDevices != null) {
        ////console.log(`IS: (isDirector? ${isDirector} parametri di connessione device ${interactive_device_id}:`, interactiveDevices[interactive_device_id])
      }
  
    }, [interactiveDevices])
  
    if (!isDirector)
      return (<p>Forbidden</p>)
    else if (interactiveDevices == null || interactiveDevices[interactive_device_id] == null)
      return (<p>Remote device not found</p>)
    else return (
      <Container fluid>
        <LanguageSelector hidden />
        <Row>
          <Col xs="12">
            <Card className="mb-4 w-100" style={{
              borderColor: "#007bff", width: "100%"
            }}>
              <CardHeader style={{
                backgroundColor: "#007bff",
                borderColor: "#007bff",
                paddingBottom: 10,
                color: 'white'
  
              }}>
                <CardTitle tag="h5">{deviceInfo &&
                  `${deviceInfo.titolo}`}
                </CardTitle>
                <CardSubtitle style={{ marginLeft: "2px" }} tag="h6">
                  {`${currentExperiment?.titolo}`}</CardSubtitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{}} id="vncContent">
                      <VncScreen
                        debug
                        url={interactiveDevices[interactive_device_id]["wsUrl"]}
                        rfbOptions={{
                          "credentials":
                            { "password": interactiveDevices[interactive_device_id]["password"] }
                        } as any}
                        onCredentialsRequired={(rfb?: RFB) => {
                          ////console.log("IS: Credenziali richieste:", rfb);
                          setConnectionState("Credentials required")
                        }}
  
                        onConnect={(rfb?: RFB) => {
                          ////console.log("IS: Connesso:", rfb);
                          setConnectionState("Connected")
                        }}
  
                        onDisconnect={(rfb?: RFB) => {
                          ////console.log("IS: Disconnesso:", rfb);
                          setConnectionState("Disconnected")
                        }}
                        onClipboard={(e: any) => {
                          if (e.detail?.text != null)
                            navigator.clipboard.writeText(e.detail.text);
                        }}
                        onSecurityFailure={(e: any) => {
                          ////console.log("IS: Security Failure:", e.reason);
                          setConnectionState("Security Failure")
                        }}
  
                        scaleViewport
                        background="#000000"
                        style={{
                          width: '75vw',
                          height: '75vh',
                        }}
                        ref={ref}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                </Row>
              </CardBody>
              <CardFooter>
                <Row>
                  <Col sm="1"> <Badge color={connectionState == "Connected" ? "info" : "danger"}>{connectionState}</Badge> </Col>
                  <Col>
                    <div style={{ display: "flex", justifyContent: "center", marginRight: "10px" }}>
                      <ScreenshotCaptureComponent downloadScreenshot={true} elementId={"vncContent"} />
                      {(interactiveDevices != null && interactiveDevices[interactive_device_id] != null &&
                        interactiveDevices[interactive_device_id].isEC2 == true) &&
                        <>
                          <IconButton style={{ marginRight: "5px", marginLeft: "5px", borderRadius: "0", border: '2px solid blue' }}
                            color="secondary" onClick={(ev) => { startEC2() }}>
                            <span style={{ marginRight: "5px", marginLeft: "5px", fontSize: 16, color: "#007bff" }}>{"Start EC2"}
                            </span>
                            <FaPlay cursor="pointer"
                              style={{ fontSize: 24, border: '1px solid #007bff"', }} color="#007bff" />
                          </IconButton>
                          <IconButton style={{ marginLeft: "5px", borderRadius: "0", border: '2px solid blue' }}
                            color="secondary" onClick={(ev) => { stopEC2() }}>
                            <span style={{ marginRight: "5px", marginLeft: "5px", fontSize: 16 }}>{"Stop EC2"}
                            </span>
                            <FaPowerOff cursor="pointer"
                              style={{ fontSize: 24, marginRight: "0px", border: '1px solid #007bff"', }} color="#007bff" />
                          </IconButton>
                        </>}
                    </div>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Col>
        </Row>
  
      </Container>
    )
  
  }
  