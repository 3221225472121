export const theme = {
    "timeline": {
      "backgroundColor": "inherit",
      "fontSize": "2rem",
      "fontFamily": "-apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto, Helvetica,\n  Arial, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\""
    },
    "timelineTrack": {
      "left": "50%",
      "width": "2px",
      "height": "100%",
      "backgroundColor": "#007bff",
      "content": "''"
    },
    "marker": {
      "backgroundColor": "#007bff",
      "border": "2px solid #007bff",
      "borderRadius": "50%",
      "width": "15px",
      "height": "15px",
      "zIndex": 100
    },
    "card": {
      "borderRadius": "4px",
      "backgroundColor": "#fff",
      "color": "#333",
      "padding": "10px",
      "boxShadow": "0 4px 6px 0 hsla(0, 0%, 0%, 0.2)",
      "width": "100%",
      "maxWidth": "560px",
      "a": {
        "color": "#EC24B5"
      }
    },
    "button": {
      "fontSize": "14px",
      "backgroundColor": "rebeccapurple",
      "borderRadius": "4px",
      "padding": "6px",
      "color": "#fff",
      "margin": "10px 5px 5px 0",
      "border": "none",
      "cursor": "pointer"
    },
    "urlButton": {
      "fontSize": "14px",
      "backgroundColor": "rebeccapurple",
      "borderRadius": "4px",
      "padding": "6px",
      "margin": "10px 5px 5px 0",
      "border": "none",
      "color": "#fff"
    },
    "date": {
      "backgroundColor": "#ec24b5",
      "padding": "4px",
      "color": "#fff",
      "borderRadius": "4px",
      "fontWeight": 500,
      "fontSize": ".85rem"
    },
    "imageAtom": {
      "objectFit": "cover",
      "overflow": "hidden",
      "width": "100%",
      "maxHeight": "400px"
    },
    "imageCredit": {
      "marginTop": "10px",
      "fontSize": "0.85rem"
    },
    "imageText": {
      "marginBottom": "10px",
      "fontSize": "1rem"
    },
    "youTubeText": {
      "marginBottom": "10px",
      "fontSize": "1rem"
    },
    "events": {
      "padding": "0px"
    },
    "event": {
      "marginBottom": "20px"
    },
    "textAtom": { "fontSize":"1rem"}
  }