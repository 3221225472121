//https://codesandbox.io/s/generate-video-thumbnails-forked-0qsvp0?file=/src/App.js

import {
    generateVideoThumbnails
  } from "@rajesh896/video-thumbnails-generator";

import JSZip from 'jszip'

export const extractThumbnailsFromZipFileUrl = async (url) =>
{   //see https://stackoverflow.com/questions/62318128/reading-and-displaying-images-from-a-zip-file-in-react-using-jszip
    try {
        const file = await fetch(url)
        const fileBlob = await file.blob()
        let thumbs64 = {}
        //console.log("Zip Blob", fileBlob)
        const zipFile = await JSZip.loadAsync(fileBlob);
        //console.log("Zip File", zipFile)
        const mediaFiles = Object.entries(zipFile.files);

        //console.log("Media Files:", mediaFiles)
      if (!mediaFiles.length) {
        throw new Error('No media files found in archive');
      }
      //see https://stackoverflow.com/questions/37576685/using-async-await-with-a-foreach-loop
      const fileKeys = Object.keys(mediaFiles)
      for (const key of fileKeys)
      {
        ////console.log("TG key:", key)
        const imgContent = await mediaFiles[key][1].async('base64');
        const newKey = mediaFiles[key][0].slice(0, mediaFiles[key][0].indexOf("."))
        thumbs64[newKey] = `data:image/png;base64,${imgContent}`
      }
      /*
      await mediaFiles.forEach(async ([filename, image]) => {
          const imgContent = await image.async('base64');
          thumbs64[filename.slice(0, filename.indexOf("."))] = `data:image/png;base64,${imgContent}`
          //console.log("Extracted thumb64:", Object.keys(thumbs64));
      })
      */
      ////console.log("Extracted thumbnails in base64:", thumbs64, Object.keys(thumbs64))

      /*
      mediaFiles.forEach(([filename, image]) => {
        image.async('base64').then(imgContent => {
        thumbs64[filename.slice(0, filename.indexOf("."))] = `data:image/png;base64,${imgContent}`
        //console.log("Extracted thumb64:", Object.keys(thumbs64));
          ////console.log(`Thumbnail ${filename.slice(0, filename.indexOf("."))} Image content:`, imgContent)
        });
      });
      */
      
      
      return thumbs64;
    } catch(error) {
        //console.log("Error reading zip thumbnails", error);
        return null;
    }
    
}

export const generateThumbnailsZipFile = async (item,thumbnails) =>
{   
    // Per leggere lo zip delle immagini
    // https://stackoverflow.com/questions/62318128/reading-and-displaying-images-from-a-zip-file-in-react-using-jszip

    // Per salvare le thumbnails in un json (pesante)
    //var content = new Blob([JSON.stringify(thumbnails)], {type: "text/plain;charset=utf-8"});
    // saveAs(content, `thumbnails_json_${item?.id || "New"}.json`);
    
     const seconds = Object.keys(thumbnails)

    // see https://stuk.github.io/jszip/
    var zip = new JSZip();

    for(let i=0;i<seconds.length;i++)
    {   //console.log("TH: content:",thumbnails[seconds[i]].slice(22) )
        zip.file(`${seconds[i]}.png`, thumbnails[seconds[i]].slice(22), {base64: true});
    }
     try{
      let content = await  zip.generateAsync({type:"blob"});
        content.name = `thumbnails_${item?.id || "newvideo"}`
        //console.log("Thubnails Blob:", content) 
        // per salvare una copia in locale
        ////console.log("TH: saving file...")
        //saveAs(content, `thumbnails2_${item.id}.zip`);

      // Restituisco il file zip (sotto forma di Blob) in modo che possa essere
      // uploadato
      return content;
       
     }catch(error) {
         //console.log("TH: Error generating zip file:", error)
         return null;
     }
     
    }

export const generateThumbnails = async (videoItem) =>
{
    const numThumbs = 2
    const videoUrl = videoItem?.source

    try{
        //console.log("TG: (try)ing to build video from video url...", videoUrl)
        const r = await fetch(videoUrl);
        //console.log("TG: fetch:",r);
        const blobFile = await r.blob();
        //console.log("TG: blob:",blobFile);
        const video =  new File([blobFile], 
            "myVideo", { type: "video/mp4" })
            //console.log("TG: setting video file to:", video)

        let thumbs = await generateVideoThumbnails(video, numThumbs)
        //console.log("TG: Thumbnails generate:", thumbs)
        
    } catch(error) 
    {
        //console.log("TG: Error building video", error);
    }
}
