//https://developer.mozilla.org/it/docs/Web/JavaScript/Reference/Global_Objects/Array/map
//https://momentjscom.readthedocs.io/en/latest/moment/04-displaying/07-difference/

import moment from 'moment';
import {TrackType, DefItemColors} from './Constants';

import isNumber from 'lodash.isnumber'
import isString from 'lodash.isstring'
// promise needs to be imported since browser may not support it natively.
import Promise from 'bluebird'
import {  useEffect,useState,useLayoutEffect } from 'react';

export const useDeviceType = () => {
  const [deviceType, setDeviceType] = useState('unknown');

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    if (/mobi|android/i.test(userAgent)) {
      setDeviceType('mobile');
    } else if (/ipad|tablet|(android(?!.*mobile))/i.test(userAgent)) {
      setDeviceType('tablet');
    } else {
      setDeviceType('desktop');
    }
  }, []);

  return deviceType;
};

export const useDeviceOrientation = () => {
  const [orientation, setOrientation] = useState('portrait');

  const determineOrientation = () => {
    if (window.screen.orientation) {
      const { type } = window.screen.orientation;
      if (type.includes('portrait')) {
        setOrientation('portrait');
      } else if (type.includes('landscape')) {
        setOrientation('landscape');
      }
    } else {
      if (window.innerHeight > window.innerWidth) {
        setOrientation('portrait');
      } else {
        setOrientation('landscape');
      }
    }
  };

  useEffect(() => {
    determineOrientation(); // Initial check
    window.addEventListener('resize', determineOrientation); // Check on resize
    window.screen.orientation?.addEventListener('change', determineOrientation); // Check on orientation change

    return () => {
      window.removeEventListener('resize', determineOrientation); // Cleanup resize event
      window.screen.orientation?.removeEventListener('change', determineOrientation); // Cleanup orientation change event
    };
  }, []);

  return orientation;
};

export const getDurationInSeconds = (position, lastTime) =>
{
   return  moment.utc(moment.duration(
    moment(lastTime).diff(moment(position))).asSeconds()) 
}
export const getFormattedTime = (firstItemStartTime,position, myFormat) =>
{   
    //console.log(`FTP: first->${firstItemStartTime}, position -> ${position}`);
    let totalMilliseconds = moment.utc(moment.duration(
        moment(position).diff(moment(firstItemStartTime))).asMilliseconds())
    const days = Math.floor(moment.duration(totalMilliseconds).asDays())
    const hoursMinutesSeconds = moment.utc(totalMilliseconds).format(myFormat || "HH:mm:ss")
    if (myFormat==null)
    {
    const result =  `${(days>=0 && days<10) ? "0" :""}${days}.${hoursMinutesSeconds<10 ? "0" :""}${hoursMinutesSeconds}`;
    ////console.log(`FTP: (${totalMilliseconds}) -> currentPositionDate; ${result}`)
    return result
    }
    else return `${hoursMinutesSeconds}`
    
}



export const getFormattedTimeByPositionInSeconds = (positionInSec) =>
{   

    const days = Math.floor(moment.duration(positionInSec, "seconds").asDays())
    const hoursMinutesSeconds = moment.utc(positionInSec*1000).format("HH:mm:ss")
    const result =  `${(days>=0 && days<10) ? "0" :""}${days}.${hoursMinutesSeconds<10 ? "0" :""}${hoursMinutesSeconds}`;
    //console.log(`TP::: (${positionInSec}) -> currentPositionDate; ${result}`)
    return result
}


export const getTimelineItems = (items, lockedGroups, canEditPermission, editingEnabled) =>
{
   return items.map(item0 =>
    {
      let item = {...item0};

       item.start_time= moment(item.start_time)
       item.end_time= moment(item.end_time)
       //console.log(`experiment item utils item locked? ${lockedGroups[item.track]}`, item)

        //const track = getTrackById(tracks,item.track);
        // gli item possono essere ridimensionati sulla destra a meno che 
        // non si tratti di item di tipo Video e in tal caso
        // NON possono essere ridimensionati

        // sovrascrivo il canEdit tenendo conto della possibilità di 
        // disattivazione intenzionanale dell'editing da parte dell'utente
        // e dello stato di lock della traccia di appartenenza all'item
        ////console.log(`DEBUG: (prima) canEditPermission:${canEditPermission} editingEnabled:${editingEnabled}`);
        let canEdit = canEditPermission && editingEnabled && !lockedGroups[item.track];
        ////console.log(`DEBUG: canEdit di ${item.title} track: ${item.track} !lockedGroups:${!lockedGroups[item.track]} -> ${canEdit}`);
        item.canResize = canEdit ? (item.type===TrackType.VIDEO ? false : "right") : false;

        if (item.color==null) item.color = DefItemColors(item.type).color;
        if (item.bgColor==null) item.bgColor = DefItemColors(item.type).bgColor;
        
        item.canMove = canEdit;
        item.canChangeGroup = canEdit ? (item.type===TrackType.DOC ? true : false) : false;
        
        item.start_time = moment(item.start_time);
        
        if (item.start_offset==null)
        {
            item.start_offset = 0
        }

     if (item.duration!=null)
        {               
         if (item.type==TrackType.VIDEO)
            {  
                //console.log(`Trovato video ${item.title} con end_offset=${item.end_offset}`);
                item.end_time = moment(item.start_time).add(moment.duration(item.end_offset,'seconds')).add(moment.duration(-item.start_offset,'seconds'))
                
                //console.log(`End time impostata a ${item.end_time}`);
            }
            else
            {
                item.end_time = moment(item.start_time).add(moment.duration(item.duration*1000),'milliseconds')
            }   
        }  
      else 
      {
          item.duration =  moment(item.end_time).diff(moment(item.start_time), 'seconds', true);    
      }

    
    if (item.end_offset==null)
        {
            item.end_offset = item.duration
        }


      return item;
    }
    )
}

export const useIsOverflow = (ref, lineHeight, callback) => {
  const [isOverflow, setIsOverflow] = useState(undefined);
  const [numberOfLines, setNumberOfLines] = useState(1);
  useLayoutEffect(() => {
    const { current } = ref;

    const trigger = () => {
      const hasOverflow= (current.scrollHeight) > current.clientHeight;
      //const hasOverflow = current.clientHeight>=lineHeight*3; // MAXLines = 3
      const numberOfLines = Math.floor(current.clientHeight/(lineHeight||1))
      //console.log(`lh:${lineHeight} hasOverflow:${hasOverflow}  scrollHeight:${current.scrollHeight} clientHeight:${current.clientHeight} lines:${numberOfLines}`);
      setIsOverflow(hasOverflow);
      setNumberOfLines(numberOfLines)

      if (callback) callback(hasOverflow, numberOfLines);
    };

    if (current) {
      if ('ResizeObserver' in window) {
        new ResizeObserver(trigger).observe(current);
      }

      trigger();
    }
  }, [callback, ref]);

  return {isOverflow, numberOfLines};
};


export  const getTrackById = (tracks , trackId) =>
  { 
    ////console.log(`Ho trovato {groups.length} gruppi`);
    
    for (let trackIndex in tracks)
    {
      const track = tracks[trackIndex]
      ////console.log(`Individuato gruppo con id: ${track.id} contro ${trackId}`);
      if (track.id==trackId)
      return track;
    }
    console.warn(`Non ho trovato alcun gruppo con id:${trackId}`);
    return null;
  }





Promise.config({
  cancellation: true
})

export const ERROR_MESSAGE_LOAD_FAILED = 'Image load failed'
export const ERROR_MESSAGE_LOAD_TIMED_OUT = 'Image load timed out'

export const validator = ({ url, timeout = 5000 }) => {
  if (!url || !isString(url)) {
    throw new Error(`Invalid url parameter.  url: ${url}`)
  }
  if (!isNumber(timeout) || timeout < 1) {
    throw new Error(`Invalid timeout parameter.  Expected timeout >= 1.  timeout: ${timeout}`)
  }
  return new Promise((resolve, reject) => {
    const image = new Image()
    let timedOut = false

    const errorHandler = () => {
      if (timedOut) {
        return
      }
      clearTimeout(timer)
      reject(new Error(ERROR_MESSAGE_LOAD_FAILED))
    }

    const timer = setTimeout(() => {
      timedOut = true
      // Reset .src to invalid URL so it stops previous fetch and does not trigger a new load.
      image.src = '//!!!/image.jpg'
      reject(new Error(ERROR_MESSAGE_LOAD_TIMED_OUT))
    }, timeout)

    image.onerror = image.onabort = errorHandler

    image.onload = () => {
      // If fetch has timed out, the URL would be modified in order to cancel the fetch.
      // Therefore, "onload" would never be called when "timedOut" is true.

      /* istanbul ignore next */
      if (timedOut) {
        return
      }
      clearTimeout(timer)
      resolve({ image, url })
    }
    image.src = url
  })
}
