export const getLoadingStatus = (state: any) => {
    const status = (state.ui.loadingStatus)
    return status
  }

  export const getErrors= (state:any) => {
    const error = (state.ui.errorMessage.message)
    return error
  }

  export const getSaveButtonStatus= (state:any) => {
    const saveButton = (state.ui.saveButton)
    return saveButton
  }

  export const getUploadingState = (state: any) => {
    return state.ui.uploading
  }
  export const getUpdateProgress = (state: any) => {
    return state.ui.update_progress
  }
