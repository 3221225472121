import React, {Component } from 'react';
import RialePosixTracker from "./RialePosixTracker";

import moment from 'moment';
import _ from "underscore";
//https://software.es.net/react-timeseries-charts/#/example/baselines
//https://codesandbox.io/s/6x6lk3n
//https://stackoverflow.com/questions/39019094/reactjs-get-json-object-data-from-an-url
//https://github.com/esnet/react-timeseries-charts

//https://github.com/esnet/react-timeseries-charts/blob/master/src/website/packages/charts/examples/nyc/Index.js
//https://software.es.net/react-timeseries-charts/#/api/charts/LineChart

// TIME SERIES DOC -> usare timeseries.crop(timerange)
//http://software.es.net/pond/#/

// Baseline Demo
//https://github.com/esnet/react-timeseries-charts/blob/master/src/website/packages/charts/examples/baselines/Index.js

//Currency Demo (multigraph)
//https://github.com/esnet/react-timeseries-charts/blob/master/src/website/packages/charts/examples/currency/Index.js

// Example with multi row and CursorMarker
// https://software.es.net/react-timeseries-charts/#/example/weather

// Draggable components
//https://github.com/STRML/react-draggable

//https://github.com/esnet/react-timeseries-charts/blob/master/src/website/packages/charts/examples/currency/Index.js
import {
    Charts,
    ChartContainer,
    ChartRow,
    YAxis,
    LineChart,
    Baseline,
    Resizable,
    TimeMarker,
    EventMarker,
    ValueAxis,
    Legend,
    styler
} from "react-timeseries-charts";
import { TimeSeries, TimeEvent, TimeRange, TimeRangeEvent } from "pondjs";

import RialeModalPanel, {Portal} from "./RialeModalPanel";
import { willSignInAction } from '../../store/actions/auth';

export default class RialeIotViewer extends Component 
{

  constructor(props)
   {
     super(props);
     //console.log(`Istanziato RialeIotViewer`);
     this.state={series:null, jsonData:null, 
         //tracker:moment(props.currentPositionDate).toDate(),
        tracker: null,
        trackerValue: "",
        trackerEvent: null,
        x: null,
        y: null}
   }


   async componentDidMount()
   {
    
    const {iotItem} = this.props;
    //console.log(`CHIAMATA COMPONENT DID Mount DI RialeIotVIEWER su :`, iotItem);
    await this.setJsonDataByUrl(iotItem);
   }

  
   async componentDidUpdate(prevProps, prevState) {
       const {currentPositionDate,  iotItem } = this.props;
      ////console.log("CHIAMATA COMPONENT DID UPDATE DI RialeIotVIEWER");
      ////console.log(`RialeIotViewer ITEM PASSATO DA ${prevProps.iotItem} a ${iotItem}`);
      ////console.log(`prev:${prevProps.currentPositionDate} -> ${currentPositionDate}`);
       if(prevProps.iotItem!==iotItem)
       {
           //console.log(`RialeIotViewer ITEM PASSATO DA ${prevProps.iotItem} a ${iotItem}`);
           await this.setJsonDataByUrl(iotItem);
       }
    }

   setJsonDataByUrl = async (item) => 
    {
        if (item==null)
        {
            this.setState({series:null, jsonData:null});
            return;
        }
        
        try {

            //console.log(`Leggo il json dalla url::: ${item.source}`);
            
            let response = await fetch(item.source);
            let responseJson = await response.json();

            //gestione del POSIX (il parsing del Json è delegato al componente figlio)
            if (responseJson.type==="posix")
            {
                //console.log("IOT DI TIPO POSIX");
                const {posixPanelRect} = this.props;
                if (posixPanelRect!=null)
                {   // mi ricavo la dimensione iniziale del pannello sulla base di come era nel precedente item...
                    const initialPosixPanelWidth = posixPanelRect.witdh;
                    const initialPosixPanelHeight =  parseInt(responseJson.size[1] * responseJson.size[0]/initialPosixPanelWidth);
                    this.setState({initialPosixPanelWidth, initialPosixPanelHeight}); 
                }
                this.setState({jsonData: responseJson, series:null });
                // devo calcolare la dimensione iniziale del pannello sulla base della dimensione corrente
                return;
            }
            else{
                //console.log("IOT NON DI TIPO POSIX");
            }

            responseJson.points = responseJson.points.map((point) => 
            {
                return  point.map((p, index) =>{  
                if (index===0) return parseInt(p); 
                else
                  return parseFloat(p)
            })
             
            })
                ; //responseJson.points;
            
             
            
            ////console.log(`Colonne da Json: ${responseJson.columns}`);
            ////console.log(`Punti da Json: ${responseJson.points}`);

            // Data
     const series0 = new TimeSeries({
        name: responseJson.name,
        columns:responseJson.columns, // Es: ["time", "temperatura 1", "temperatura 2"]
        points: responseJson.points
    });
    
    // Traslo i campionamenti della serie in modo da far corrispondere la data del 
    // primo campione con quella dell'inizio dell'item CON EVENTUALE START_OFFSET
    let startTime = item.start_time;
    let startOffset = item.start_offset; //in secondi
    let t0 = moment(series0.at(0).key())
    let diff = startTime.diff(t0);
    const series = series0.map(e => {
        return new TimeEvent(moment(e.key()).add(moment.duration(-startOffset,'seconds')).add(diff),  e.data());
    });
    ////console.log("Serie caricata (traslata):");
    ////console.log(`Nome:${series.name}`);
    ////console.log(`Columns:${series.columns()}`);
    ////console.log(`Primo Event[${moment(series.at(0).key())}] data: ${series.at(0).data()}`);

    ////console.log(series);     
    ////console.log(`series:${series}`);
    if (series!=null)
    {   
        /*
        //console.log(`Data primo campione:${responseJson.points[0][0]}`);
        //console.log(`Primo campione della serie datato a ${moment(responseJson.points[0][0])}`);
        //console.log(`Primo valore della serie:${responseJson.points[0][1]}`);
        //console.log(`Ultimo campione della serie datato a ${moment(responseJson.points[responseJson.points.length-1][0])}`);
        */
    }

            
            this.setState({jsonData: responseJson, series });
        } catch(error) {
        
            console.error(`Errore nel parsing del JSON ${item.source} coi dati IOT: ${error}`);
            this.setState({series:null, jsonData:null});
        }
    }

    refCallback = element => {
        if (element) {
          this.elementRef = element;
          ////console.log("ELEMENT REF");
          ////console.log(this.elementRef);
          ////console.log(`CHART ROW WIDTH: ${this.elementRef.props.width}`);
          this.setState({rowWidth:this.elementRef.props.width});
          //this.props.getSize(element.getBoundingClientRect());
        }
      };

      handleMouseMove = (x, y) => {
        this.setState({ x, y });
        };

        handleTrackerChanged = tracker => {
            if (!tracker) {
                this.setState({ tracker, x: null, y: null });
            } else {
                this.setState({ tracker });
            }
        };

        handleTimeRangeChange = timeRange => {
            this.setState({ timeRange });
        };

   render() {
    const { jsonData, series, timeRange } = this.state;
   

    if (jsonData==null) return null;

    const {iotItem, currentPositionDate, timelineIsPlaying, 
        visibleTimeStart,visibleTimeEnd,bounds, timelineWindowDuration} = this.props;
       
    if (iotItem==null) return null;

    // Gestire qui il caso di un json di tipo POSIX
    if (jsonData.type=="posix")
    {

        const {posixPanelRect} = this.props;
        // se la dimensione fisica del Lab da rappresentare è troppo grande, 
        // si scala il pannello ad una dimensione massima ragionevole, mantenendo 
        // l'aspect ratio originaria del Lab
        const MAX_WIDTH = 400;
        let initialWidth = -1;
        let initialHeight = -1;

        if (posixPanelRect!= null)
        {
           initialWidth = posixPanelRect.width;
           initialHeight = posixPanelRect.height;
        }
        else
         {
             initialWidth = Math.min(jsonData.size[0],MAX_WIDTH)
             initialHeight = initialWidth * jsonData.size[1] / jsonData.size[0];
        
             //initialHeight = (posixPanelRect == null ? jsonData.size[1] : posixPanelRect.height)
         }
       
        const initialx = posixPanelRect == null ? 0 : posixPanelRect.x;
        const initialy = posixPanelRect == null ? 0 : posixPanelRect.y;
        ////console.log(`Creo Posix rect panel da stanza di SIZE [${jsonData.size}] ISIZE:[${initialWidth}, ${initialHeight}]  posixPanelRect pari a ${posixPanelRect}`);
        return (
            <Portal target="portal">
            <RialeModalPanel visible={jsonData.type=="posix"}
                            title={iotItem.title}
                            bounds={bounds}
                            initialWidth={initialWidth}
                            initialHeight={initialHeight}
                            initialX={initialx}
                            initialY={initialy}
                            lockAspectRatio = {true}
                            onContainerRectChanged={this.props.onContainerRectChanged}
                            >
        
           { (containerWidth, containerHeight, visible) =>( 
                <RialePosixTracker currentPositionDate={currentPositionDate} 
                item={iotItem}
                posixJson={jsonData} 
                containerWidth={containerWidth} containerHeight={containerHeight}/>)
           }
        </RialeModalPanel>
        </Portal>
        )

    }
    
    ////console.log(`in render di RialeIotViewer: iotItem Url:${iotItem.source} jsonData:${jsonData} series:${series}`);
    
    if (series==null) return null;
     /* vecchie scelte
    //const startTime =  moment(iotItem.start_time)  
    //const startTime = moment(currentPositionDate).add(-timelineWindowDuration/2);
    //const endTime = moment(startTime).add(timelineWindowDuration);
    //const endTime = moment(currentPositionDate);
   
    //const newCropStartTime = startTime.add(moment.duration(moment(currentPositionDate).diff(iotItem.end_time)));
    //const cropTimeRange = new TimeRange([newCropStartTime, currentPositionDate]);
      */
    //const cropTimeRange =  new TimeRange([moment.max(visibleTimeStart, moment(iotItem.start_time)  ), 
    //                        moment.min([visibleTimeEnd,  moment(iotItem.end_time) ])]);
    

    // taglio la serie in modo da mostrare solo i campioni presenti tra start_time e end_time
    const cropTimeRange =  new TimeRange([visibleTimeStart,visibleTimeEnd]); 
    const croppedSeries0 = series.crop(cropTimeRange);
    /*
    let series = series1.slice(
	series1.bisect(startTime.add(moment.duration(startOffset,'seconds')).toDate(),0),
	series1.bisect(endTime.add(moment.duration(-endOffset,'seconds')).toDate(),0)) 
    */
    const croppedSeries = croppedSeries0.slice(
        croppedSeries0.bisect(
            moment(iotItem.start_time).toDate(),0),
        croppedSeries0.bisect(moment(iotItem.end_time).toDate(),0));
    ////console.log(`Inizio: ${startTime} Fine: ${endTime}`);
   
    const seriesMinMax = () =>{ 
                    const cols = jsonData.columns.slice(1);
                    let minValue = croppedSeries.min(cols[0]);
                    let maxValue = croppedSeries.max(cols[0]);

                    for (let i=1; i<cols.length;i++)
                    {
                        minValue = Math.min(minValue,croppedSeries.min(cols[i]));
                        maxValue = Math.max(maxValue,croppedSeries.max(cols[i]));
                    }
                
                    ////console.log(`MIN VALUE: ${minValue}`);
                    ////console.log(`MAX VALUE: ${maxValue}`);
                             
                            return {minValue,maxValue};
                            };

    const  {minValue, maxValue} = seriesMinMax();
    
    const legendStyle = styler(
        jsonData.columns.slice(1).map((column, index ) => {
            ////console.log(`LEGEND STYLE: column:${column}  index:${index} color:${jsonData.chartColors[index]}` );

            return { key: column, 
                color: jsonData.chartColors==null || jsonData.chartColors[index]==null ? 'green' :
                     `${jsonData.chartColors[index]}`, 
                
                width:1}
        }));

    const legendCategories =  jsonData.columns.slice(1).map((column, index ) => {
        ////console.log(`LEGEND STYLE: column:${column}  index:${index} color:${jsonData.chartColors[index]}` );
        const value = (this.state.tracker != null && 
                        croppedSeries.at(croppedSeries.bisect(this.state.tracker))!=null ? 
            `${croppedSeries.at(croppedSeries.bisect(this.state.tracker)).get(column)} ${jsonData.ylabel}`
            : null);
        
        return { key: column, label: `${column}`, width:1, value}

    });
    
  
    const axisStyle = {
        labels: { labelColor: "Red", labelWeight: 100, labelSize: 11 , "text-align":"center" },
        axis: { axisColor: "Green" }
      };
      
   return iotItem!=null && series!=null &&
   (

<div className="p-3 m-4 border border-muted row">
   <div className="col-md-12" style={{marginLeft:50}}>
   <Resizable>
        <ChartContainer title={iotItem.title} 
        timeRange= { (timeRange == null || timelineIsPlaying) ? cropTimeRange : cropTimeRange} 
        timeAxisStyle={axisStyle}
        trackerPosition={this.state.tracker}
        trackerTimeFormat="%X"
        onTrackerChanged={this.handleTrackerChanged}
        onMouseMove={(x, y) => this.handleMouseMove(x, y)}
        onTimeRangeChanged={this.handleTimeRangeChange}
        enablePanZoom={!timelineIsPlaying}
        
        >
         
            <ChartRow height={200} ref={this.refCallback} >
                <YAxis style={axisStyle} id={`y`} label={`${jsonData.ylabel}`} 
                labelOffset={-5}
                min={minValue} max={maxValue} format=".2f" />
                <Charts >
                    <LineChart
                    axis={`y`}
                    breakLine={false}
                    series={croppedSeries}  
                    columns={jsonData.columns.slice(1)}
                    style={legendStyle}
                    />
                    
                    <EventMarker
                    type="flag"
                    offsetY={0}
                    //yScale={(v) => {return 0}}
                    axis={`y`}
                    event={new TimeRangeEvent(new TimeRange([currentPositionDate, currentPositionDate]),0)}
                    column={"y"}
                    info={[{ label: `${jsonData.columns[1]}`, value: this.state.trackerValue }]}
                    infoOffsetY={200}
                    infoHeight={200}
                    stemStyle={{ "stroke": "red" , "strokeWidth":"2"}}
                    markerRadius={0}
                />
                
                  {/*<CrossHairs x={this.state.x} y={this.state.y} /> */}
                </Charts>
                
                </ChartRow>
               
        </ChartContainer>
        </Resizable>
        
    </div>
    <div className="row">
    <div className="col-md-12" style={{marginLeft:90}}>
        <Legend
                type="line"
                align="right"
                style={legendStyle}
                stack={false}
                categories={legendCategories}
            />
        </div>
    </div>
  </div>
  )

   }
}

class CrossHairs extends React.Component {
    render() {
        const { x, y } = this.props;
        const style = { pointerEvents: "none", stroke: "#ccc" };
        if (!_.isNull(x) && !_.isNull(y)) {
            return (
                <g>
                    <line style={style} x1={0} y1={isNaN(y) ? 0: y} x2={this.props.width} y2={y} />
                    <line style={style} x1={x} y1={0} x2={x} y2={this.props.height} />
                </g>
            );
        } else {
            return <g />;
        }
    }
}