import { useState } from 'react';
import 'react-big-calendar/lib/sass/styles.scss'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss'
import { Header, Footer } from '../header'

import { Content } from '../components/Content';

import { useSelector } from 'react-redux';

import { selectors as AuthSelectors } from '../store/slices/auth'
import { useTranslation } from 'react-i18next';

import {
  TabContent, TabPane, Nav, NavItem, NavLink
} from 'reactstrap';

import { SyncSessionsDashboard } from '../components/Dashboards/SyncSessionsDashboard';
import { InteractiveSessionsDashboard } from '../components/Dashboards/InteractiveSessionsDashboard';
import TeacherClassroom from '../components/TeacherClassroom';
import { SYNCHRONOUS_SESSION_TYPE, INTERACTIVE_SESSION_TYPE } from '../store/sagas/remotesessions';

export const DashboardPage = () => {

  const isTeacher = useSelector(AuthSelectors.isTeacher);
  const isStudent = useSelector(AuthSelectors.isStudent);
  const { t } = useTranslation('frontend', { useSuspense: false });
  const [activeTab, setActiveTab] = useState(isStudent ? "1" : "0");

  return (
    <>
      <Header className="mb-0 text-white" section="Dashboard" showMenu={false} />
      <Content active="dashboard" section="dashboard">

        <Nav style={{ marginTop: "15px" }} tabs>
          {!isStudent &&
            <NavItem>
              <NavLink style={activeTab === '0' ?
                { cursor: "arrow", fontWeight: "bold", background: "#EEEEEE" } : { cursor: "pointer", fontWeight: "normal" }}

                onClick={() => { setActiveTab('0'); }}
              >
                {t("Sessioni sincrone")}
              </NavLink>
            </NavItem>
          }
          <NavItem>
            <NavLink
              style={activeTab === '1' ?
                { cursor: "arrow", fontWeight: "bold", background: "#EEEEEE" } : { cursor: "pointer", fontWeight: "normal" }}

              onClick={() => { setActiveTab("1"); }}
            >
              {t("Sessioni pratiche")}
            </NavLink>
          </NavItem>
          {isTeacher &&
            <NavItem>
              <NavLink
                style={activeTab === '2' ?
                  { cursor: "arrow", fontWeight: "bold", background: "#EEEEEE" } : { cursor: "pointer", fontWeight: "normal" }}

                onClick={() => { setActiveTab("2"); }}
              >
                {t("I miei studenti")}
              </NavLink>
            </NavItem>
          }
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="0">
            <SyncSessionsDashboard />
          </TabPane>
          <TabPane tabId="1">
            <InteractiveSessionsDashboard/>
          </TabPane>
          <TabPane tabId="2">
            <TeacherClassroom />
          </TabPane>
        </TabContent>
      </Content>
    </>
  )
}