import { API } from "aws-amplify";
import { loader } from "graphql.macro";

export const generateVideoTranscription = async (
    experimentId: any,
    videoItemId: String
  ) => {
    const query = loader("../graphql/generateVideoTranscription.gql");
    //console.log("generateVideoTranscription mutation: ", query);
    try {
      const result: any = await API.graphql({
        query: query,
        variables: {
          experimentId,
          videoItemId
        }
      });
      return result;
    } catch (error) {
      //console.log("Error in generateVideoTranscription Mutation", error);
      throw error;
    }
  };


  export const getVideoTranscription = async (transcriptionJobName: any) => {
    const query = loader("../graphql/getVideoTranscription.gql");
    //console.log("getVideoTranscription query: ", query);
    try {
      const result: any = await API.graphql({
        query: query,
        variables: {transcriptionJobName},
      });
      const res  = result["data"]["getVideoTranscription"];
      console.log("getVideoTranscription result:", res );
      return res;
    } catch (error) {
      console.log("Error in getVideoTranscription Query", error);
      throw error;
    }
  };
  

  export const listVideoTranscriptions = async (videoItemId: any) => {
    const query = loader("../graphql/listVideoTranscriptions.gql");
    //console.log("lilistVideoTranscriptions query: ", query);
    try {
      const result: any = await API.graphql({
        query: query,
        variables: { videoItemId },
      });
      const transcriptions = result["data"]["listVideoTranscriptions"];
       console.log("listVideoTranscriptions result:", transcriptions);
      return transcriptions;
    } catch (error) {
      console.log("Error in listVideoTranscriptions Query", error);
      throw error;
    }
  };