import {
    Appointmentstate, isNoLongerAvailable
} from '../../store/slices/appointments'
import {
    BUSY_COLOR, PARTIALLY_BOOKED_COLOR, BOOKED_COLOR, UNBOOKED_COLOR,
    RESERVED_COLOR, NO_LONGER_AVAILABLE_COLOR, NOT_FOUND_COLOR
} from '../../consts/colors'
import { isSardinianTeacher } from '../../store/slices/users'
import { belongsToOrganization } from '../../store/slices/profile'

export const appointmentstateLabel = {
    [Appointmentstate.NOT_FOUND]: ["error", "Non trovato"],
    [Appointmentstate.CREATE]: [UNBOOKED_COLOR, "Disponibile"],
    [Appointmentstate.UNBOOKED]: [UNBOOKED_COLOR, "Disponibile"],
    [Appointmentstate.PARTIALLY_BOOKED]: [PARTIALLY_BOOKED_COLOR, "Prenotata parzialmente"],
    [Appointmentstate.BOOKED]: [BOOKED_COLOR, "Prenotata"]
};

export const isAppointmentBookedByUser = (appointment, userAttributes) => {
    if (appointment?.attendees == null) return false;
    return appointment.attendees?.map((e) => e["user"]).includes(userAttributes.sub)
}

export const canChangeAppointmentLanguageSelections = (appointment, canEdit) => {
    console.log("CCA: savedAppointment:", appointment)
    return (canEdit && (appointment.state==Appointmentstate.CREATE || appointment.state==Appointmentstate.UNBOOKED)  )|| 
   (appointment.language=="Scelta libera" && (appointment.state == Appointmentstate.CREATE || appointment.state == Appointmentstate.UNBOOKED))
}

export const isForbidden = (appointment, registrationProfile, userAttributes) => {
    if (appointment == null) return true;
    //console.log("APB: dentro isReserved()", appointment);
    // l'appuntamento non è proibito a chi lo ha prenotato!
    if (isAppointmentBookedByUser(appointment, userAttributes)) return false;

    // se la lista revervedTo non risulta nulla, tale lista descrive la lista 
    // ESCLUSIVA degli utenti che possono partecipare, a prescindere dallo stato
    // del flag reservedToSardinianTeacher
    if (appointment.reservedTo != null) {
        return !appointment.reservedTo.map((e) => e["id"]).includes(userAttributes.sub);
    }
    // se l'appuntamento non risulta essere flaggato come reservedTo...
    else {

        // se l'appuntamento è riservato ai docenti sardi e l'utente
        // correntemente loggato non lo è (a meno di essere nella lista reservedTo), 
        // l'appuntamento non è accessibile per quell'utente
        if (appointment.reservedToSardinianTeachers == true) {
            const sardinianTeacher = isSardinianTeacher(registrationProfile)
            if (!sardinianTeacher) return true;
        }
        // se l'appuntamento non è specificatamente riservato ai sardi
        // applico il controllo oer verificare che almeno un sardo possa comunque prenotarsi
        // se l'appuntamento ha maxBookings>1, manca un solo slot disponibile e non
        // è stato ancora prenotato da alcun sardo, si considera l'ultimo slot
        // riservato ai soli docenti sardi
        if (appointment.maxBookings > 1 &&
            appointment.attendees?.length == (appointment.maxBookings - 1) &&
            !isSardinianTeacher(registrationProfile) &&
            !isBookedByAtLeastAsardidianTeacher(appointment)
        ) {
            console.log("APB: Caso limite maxBookings>1 && 1 slot rimasto senza nessun dovente sardo");
            return true
        }
        // se non è verificata la condizione precedente lo slot è libero
        else return false;
    }
}

const isBookedByAtLeastAsardidianTeacher = (appointment) => {
    if (appointment == null || appointment.attendees == null) return false;
    for (let i = 0; i < appointment.attendees.length; i++) {
        if (isSardinianTeacher(appointment.attendees[i])) return true;
    }
    return false;
}

export const getAttendeeInfo = (appointment, userAttributes) => {
    if (appointment?.attendees == null) return null;
    for (let i = 0; i < appointment.attendees.length; i++) {
        if (appointment.attendees[i]["user"] == userAttributes.sub) return appointment.attendees[i];
    }
    return null;
}

export const getEventColorByAppointment = (appointment, userAttributes, registrationProfile, canEdit) => {
    if (appointment == null) return NOT_FOUND_COLOR;
    if (isForbidden(appointment, registrationProfile, userAttributes))
        return RESERVED_COLOR;
    if (appointment.state == Appointmentstate.CREATE) {
        if (isNoLongerAvailable(appointment)) return NO_LONGER_AVAILABLE_COLOR;
        else
        return UNBOOKED_COLOR;
    }
    if (appointment.state == Appointmentstate.BOOKED) {
        if (isAppointmentBookedByUser(appointment, userAttributes))
            return BOOKED_COLOR;
        else return BUSY_COLOR;
    }
    if (appointment.state == Appointmentstate.PARTIALLY_BOOKED) {
        if (isAppointmentBookedByUser(appointment, userAttributes))
            return BOOKED_COLOR;
        else {
            //console.log(`appuntamento ${appointment.id} NON prenotato dall'utente ${userAttributes.sub}`);
            if (isNoLongerAvailable(appointment)) return NO_LONGER_AVAILABLE_COLOR;
            else if (isForbidden(appointment, registrationProfile, userAttributes))
                return RESERVED_COLOR;
            else if (canEdit)
                return PARTIALLY_BOOKED_COLOR;
            else return UNBOOKED_COLOR;
        }
    }
    // se sono qui l'appuntamento non è stato ancora prenotato da nessuno
    if (isNoLongerAvailable(appointment)) return NO_LONGER_AVAILABLE_COLOR
    
    // se non è riservato ed è ancora prenotabile è libero...
    return UNBOOKED_COLOR;
}

export const isAppointmentAvailableForBooking = (appointment, userAttributes, registrationProfile) => {
    const eventColor = getEventColorByAppointment(appointment, userAttributes, registrationProfile, false)
    return eventColor == UNBOOKED_COLOR;
}

export const getAppointmentStateLabel = (appointment, userAttributes, registrationProfile, canEdit) => {
    if (appointment == null) return appointmentstateLabel[Appointmentstate.NOT_FOUND];
   
    if (canEdit)
        {   
            if (appointment.state == Appointmentstate.CREATE)
                return appointmentstateLabel[Appointmentstate.CREATE];
            else
            if (appointment.state == Appointmentstate.UNBOOKED)
                {
                    return appointmentstateLabel[Appointmentstate.UNBOOKED];
                }
            else
            if (appointment.state == Appointmentstate.PARTIALLY_BOOKED) {
                let appLabel = appointmentstateLabel[Appointmentstate.PARTIALLY_BOOKED]
                appLabel[2] = { "currentBookings": appointment.attendees?.length || 0, "maxBookings": appointment.maxBookings }
                console.log("AAV appLabel:", appLabel)
                return appLabel
            }
            else return appointmentstateLabel[Appointmentstate.BOOKED];
        }
   
    if (isAppointmentAvailableForBooking(appointment, userAttributes, registrationProfile))
        return appointmentstateLabel[Appointmentstate.UNBOOKED];
    else return appointmentstateLabel[Appointmentstate.BOOKED];
}

export const canOpenAppointmentEditor = (appointment, userAttributes, registrationProfile, canEdit) => {
    //se ho pemessi da editor e appartengo alla organization dell'esperimento da 
    // prenotare posso sempre aprire il form
    console.log("registration profile:", userAttributes);
    if (canEdit && belongsToOrganization(userAttributes, appointment.organization)) { return true; }
    // se l'evento è passato e non sono editor non posso aprire il form
    if (isNoLongerAvailable(appointment)) { return false; }
    // se l'evento è prenotato dall'utente loggato  
    // o se è ancora possibile prenotare consento l'accesso
    if (isAppointmentBookedByUser(appointment, userAttributes)
        || isAppointmentAvailableForBooking(appointment, userAttributes, registrationProfile)) { return true; }
    // a parte i casi già comtemplati, non è consentito aprire il Form
    return false;
}

