export default `

mutation deleteContainer(
    $container: ID!
){
    deleteContainer(
        container: $container
    ){
        container
    }
}
`