import moment from 'moment'
import React, { useState, useEffect } from 'react';
import {
    Button, ButtonGroup, Container, Col, Row,  Input, Alert,
    Card, CardFooter, CardBody, CardHeader
} from 'reactstrap'
import { useSelector, useDispatch } from "react-redux";
import { actions as ExperimentActions } from '../store/slices/experiments'
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import * as UiSelectors from '../store/selectors/ui';
import { useTranslation } from 'react-i18next';
import {
    FiArrowUpLeft, FiArrowUp, FiArrowUpRight,
    FiArrowLeft, FiHome, FiArrowRight,
    FiArrowDownLeft, FiArrowDown, FiArrowDownRight,
    FiZoomIn, FiZoomOut, FiStopCircle
} from "react-icons/fi";

import { useParams } from 'react-router-dom'
import ReactTooltip from "react-tooltip";
import { selectors as ProfileSelectors } from '../store/slices/profile'
import { selectors as CatalogSelectors } from '../store/slices/catalog'
import {selectors as AppointmentsSelector, getAppointmentById } from '../store/slices/appointments'

export const DirectorSyncSession: React.FC = () => {

    const dispatch = useDispatch();
    const uiStatus = useSelector(UiSelectors.getLoadingStatus)
    const isDirector = useSelector(ProfileSelectors.isDirector);
    const experiments = useSelector(CatalogSelectors.getExperiments);
    const appointments = useSelector(AppointmentsSelector.getAppointments);
    const { t } = useTranslation('frontend', { useSuspense: false });

    const { organization, id, experiment_id }: any = useParams();
    const [currentCameraPreset, setCurrentCameraPreset] = useState({} as any);
    const [devicesSetup, setDevicesSetup] = useState(null as any);
  
    {/* 
    const cameraConfig = {
        "scenes": ["/Scene Scena 1", "/Scene Scena 1s", "/Scene Scena 1d",
            "/Scene Scena 2", "/Scene Scena 2s", "/Scene Scena 2d"],
        "devices": [
            {
                "id": "cam1",
                "name": "Camera 1",
                "PTZ": true,      // pilotabile da remoto (true) oppure no (false)
                "public": true, // pilotabile da tutti (true) o solo dalla regia (false)
                "presets": [{ "id": "1", "name": "home" }, { "id": "2", "name": "biliardo" }]
            },
            {
                "id": "cam2",
                "name": "Camera 2",
                "public": false,
                "PTZ": true,
                "presets": [{ "id": "1", "name": "scrivania" }, { "id": "2", "name": "sequenziatore" }]
            },
            {
                "id": "obs",
                "name": "Microscopio",
                "public": false,
                "PTZ": false,
                "presets": []
            },

        ]
    }
 */}

    useEffect(()=>{
        if (experiments==null || experiment_id==null) return;
        //console.log(`Experiment id:${experiment_id}`);
        const experiment = experiments[experiment_id];
        if (experiment==null) return;
        const newDevicesSetup = experiment["devices_setup"];
        if (newDevicesSetup!=null) setDevicesSetup(newDevicesSetup);
    },[experiments, experiment_id])


    const renderDevicePresetsOptions = (device: any) => {

        return (
            <>
            <option key={"-1"} value={"-1"}>{t("Nessuna selezione")}</option>
                {
                (device["presets"] as any).map((preset: any) => {
                    return <option key={preset["id"]} value={preset["id"]}>{preset["name"]}</option>
                })
                }
            </>
        )

    }

// type:"cam1preset" -> command 1,2,3,4

const sendPresetCommand = (command: String, type:String) => {
    //console.log(`SEND PRESET COMMAND:${command} su ${type}`);
    
    dispatch(ExperimentActions.willSendCommand(
        { organization: organization, session: id, command: command, type: type }));
}


    const sendCommand = (command: String, device: any, isPTZcommand:boolean) => {
        //console.log(`SEND COMMAND:${command} su ${device["id"]}`);
        if (isPTZcommand)
        {
            setCurrentCameraPreset((prevDevicePreset: any) => ({
                ...prevDevicePreset,
                [device["id"]]: "-1"
            }));
    
        }
        dispatch(ExperimentActions.willSendCommand(
            { organization: organization, session: id, command: command, type: device["id"] }));
    }


    const handleChangeDevicePreset = (device: any) => (ev: any) => {
        // Prevents React from resetting its properties:
        ev.persist();
        setCurrentCameraPreset((prevDevicePreset: any) => ({
            ...prevDevicePreset,
            [device["id"]]: (ev.target ? ev.target.value : device["id"])
        }));
        if (ev.target.value!="-1")
        {
         const command = `${ev.target.value}`
         sendPresetCommand(command, `${device["id"]}preset`);
        }
        
    }

    const areDevicesActive = () => {
        //moment(app["stopDate"]).isAfter(moment.now())
        const app = getAppointmentById(appointments, id);
        const areActive = app!=null && moment(app["startDate"]).isBefore(moment.now()) && moment(app["stopDate"]).isAfter(moment.now()) 
        //if (app!=null) alert("remote session id:"+ app["startDate"])
        return (isDirector || areActive);
    }

    const renderDevice = (device: any) => {

        return <Container>
            <Row>
                <Col>
                    <Row style={{ display: 'flex', justifyContent: 'center', fontSize: "0.8em" }}>
                        <b>{`${device["name"]}`}</b>
                    </Row>
                    <Row style={{ "border": "2px solid #007bff" }}>
                        {device["PTZ"] && renderCameraPTZ(device)}
                    </Row>
                    {device["presets"] && device["presets"].length > 0 && (
                        <>
                            <Row style={{ display: 'flex', justifyContent: 'center' }}>
                                <span style={{ marginTop: "10px", fontSize: "0.8em" }}><b>{`Preset ${device["name"]}`}</b></span>
                            </Row>
                            <Row>
                                {renderDevicePresets(device)}
                            </Row>
                        </>)}

                </Col>
            </Row>
        </Container>
    }


    const renderDevicePresets = (device: any) => {
        return (
            <Input id="selectPreset"
                disabled={false}
                value={`${currentCameraPreset[device["id"]]}`}
                onChange={handleChangeDevicePreset(device)}
                type="select">
                {renderDevicePresetsOptions(device)}
            </Input>

        )
    }

    const renderSelezioneScena = (sceneCommands: any) => {
         if (sceneCommands==null) return (<>No scenes found</>)

        const sceneCommandsOptions = sceneCommands.map((command: any, index: number) => <span style={{ fontSize: "0.7em" }}>
            <input type="radio"
                onChange={(ev: any) => { 
                    //console.log("Scene Selection changed:", ev.target.value);
                    sendCommand(sceneCommands[ev.target.value],{"id":"obs"},false);
                    }
                   
                  }
                value={`${index}`} name="scene" />{` ${command}`.slice(7)}</span>)
        return (
            <Container>
                <Row style={{ display: 'flex', justifyContent: 'center', fontSize: "0.8em" }}>
                    <b>{t(`Selezione scena`)}</b>
                </Row>
                <Row>
                    <Container style={{
                        display: 'flex', "border": "2px solid #007bff", padding: "10px",
                        justifyContent: 'start', alignItems: "flex-start", justifyItems: "center", flexDirection: "column"
                    }}
                        onChange={(e) => { }}>
                        {sceneCommandsOptions}
                    </Container>
                </Row>

            </Container>
        )

    }


    const renderCameraPTZ = (camera: any) => {

        const PTZ_buttonsStyle = { margin: "4px" }
        return <Container style={{ margin: "10px", padding: "10px" }}>
            <Row >
                <ButtonGroup>
                    <Button style={PTZ_buttonsStyle} color="primary" onClick={() => 
                        sendCommand("move=upleft", camera, true)} >
                        <FiArrowUpLeft />
                    </Button>
                    <Button style={PTZ_buttonsStyle} color="primary" onClick={
                        () => sendCommand("move=up", camera, true)}>
                        <FiArrowUp />
                    </Button>
                    <Button style={PTZ_buttonsStyle} color="primary" onClick={
                        () => sendCommand("move=upright", camera,true)}>
                        <FiArrowUpRight />
                    </Button>
                    <Button style={PTZ_buttonsStyle} color="success" data-for="zoomIn" data-tip="Zoom +" 
                    onClick={() => sendCommand("rzoom=+200", camera, true)}>
                        <FiZoomIn />
                    </Button>
                    <ReactTooltip id="zoomIn" />
                </ButtonGroup>
            </Row>
            <Row>
                <ButtonGroup>
                    <Button style={PTZ_buttonsStyle} color="primary" onClick={
                        () => sendCommand("move=left", camera, true)}>
                        <FiArrowLeft />
                    </Button>
                    <Button style={PTZ_buttonsStyle} data-for="home" data-tip="Home" color="success" onClick={
                        () => sendCommand("move=home", camera, true)}>
                        <FiHome />
                    </Button>

                    <Button style={PTZ_buttonsStyle} color="primary" onClick={
                        () => sendCommand("move=right", camera,true)} >
                        <FiArrowRight />
                    </Button>
                    <Button style={PTZ_buttonsStyle} data-for="stop" data-tip="Stop" color="success" onClick={
                        () => sendCommand("move=stop", camera,true)}>
                        <FiStopCircle />
                    </Button>
                    <ReactTooltip id="stop" />
                    <ReactTooltip id="home" />
                </ButtonGroup>
            </Row>
            <Row>
                <ButtonGroup>
                    <Button style={PTZ_buttonsStyle} color="primary" onClick={
                        () => sendCommand("move=downleft", camera,true)}>
                        <FiArrowDownLeft />
                    </Button>
                    <Button style={PTZ_buttonsStyle} color="primary" onClick={
                        () => sendCommand("move=down", camera,true)}>
                        <FiArrowDown />
                    </Button>
                    <Button style={PTZ_buttonsStyle} color="primary" onClick={
                        () => sendCommand("move=downright", camera,true)}>
                        <FiArrowDownRight />
                    </Button>
                    <Button style={PTZ_buttonsStyle} data-for="zoomOut" data-tip="Zoom -" color="success" onClick={
                        () => sendCommand("rzoom=-200", camera,true)}>
                        <FiZoomOut />
                    </Button>
                    <ReactTooltip id="zoomOut" />
                </ButtonGroup>
            </Row>
        </Container>
    }

    const renderAllDevices = (devices: any) => {
        return (
            devices && devices.filter((device: any) => (isDirector || device["public"]) && (device["PTZ"] || device["presets"].length > 0)).map((device: any) => {
                return (
                    <Col>
                        {renderDevice(device)}
                    </Col>)
            }))
    }

    return (

        <BlockUi tag="div" loader={<div><h1>Loading...</h1></div>} blocking={(uiStatus === true)}>
            <div className="App">
                {/*  <Header /> */}
                <div className="App-header">
                    <Container style={{
                        display: "flex",
                        justifyContent: "center",
                    }}>
                        <Row>
                            <Col>
                                <Card style={{ color: 'black' }}>
                                    {
                                    !areDevicesActive() &&
                                    <CardHeader>
                                        <Alert color="warning">Il controllo da remoto dei dispositivi è attivo solo durante la sessione sincrona</Alert>
                                    </CardHeader>
                                    }
                                   
                                    {(devicesSetup!=null) ?
                                    <CardBody style={{ margin: "10px", "border": "2px solid #007bff" }}>
                                        <Row>
                                            {renderAllDevices(devicesSetup["devices"])}
                                            <Col>
                                                {renderSelezioneScena(devicesSetup["scenes"])}
                                            </Col>
                                        </Row>

                                    </CardBody> :
                                    <CardBody>
                                        <Row>
                                            <Col>
                                            Nessuna configurazione dei dispositivi presente
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    }
                                    
                                    <CardFooter>

                                    </CardFooter>
                                </Card>
                            </Col>
                        </Row>


                    </Container>
                </div>
            </div>
            { /* <Footer /> */}
        </BlockUi>
    )
}

