import { useMemo, useState, useEffect } from 'react';
import moment from 'moment';
import { TrackType } from './Constants';
import { Alert } from "reactstrap"
import { getFormattedTime } from "./Utils"
import { useTranslation } from 'react-i18next';


const VALIDATOR_ERRORS = {
  NOT_FOUND: (itemType) => {
    return {
      "position": null,
      "itemType": itemType, 
      "msg": "validation_error_not_found" ,
      "details" : ""
    }
  },
  OVERLAPPING: (item) => {
    return {
      "position": item.start_time,
      "itemType": item.type,
      "msg": "validation_error_overlapping",
      "details" : item.title
    }
  }
}

const checkItemsOfType = (items, filterType) => {
    const sortedItems = items.filter((item) => {
      return item.type === filterType
    }).sort((video1, video2) => {
      return moment(video1.start_time).isBefore(moment(video2.start_time)) ? -1 : 1
    })

    if (sortedItems.length < 1) {
      return VALIDATOR_ERRORS.NOT_FOUND(filterType)
    }
    // verifico se esiste anche solo una sovrapposizione dei video
    for (let i = 0; i < sortedItems.length - 1; i++) {

      if (moment(sortedItems[i + 1].start_time).isBefore(moment(sortedItems[i].end_time)))
        return VALIDATOR_ERRORS.OVERLAPPING(sortedItems[i + 1])
    }
    return true;
  }

export const isSectionLiteCompliant = (section) => {
    // se la sezione non è etichettata pe le versioni Lite è compliant per definizione
    console.log("LT section:", section)
    if (!section.isLite) return true;
    const tagsCheck = checkItemsOfType(section.items, TrackType.TAG)
    if (tagsCheck === true) {
      const videoChecks = checkItemsOfType(section.items, TrackType.VIDEO)
      return videoChecks;
    }
    else return tagsCheck;
  }

 export const useLiteTimelineValidation = (sections, activeTab, timelineHash) => {

    const [liteCompliantSections, setLiteCompliantSections] = useState({});
    const [isTimelineLiteCompliant, setTimelineLiteCompliant] = useState(false);

    useEffect(() => {
      if (sections == null) return;
      console.log("Aggiornato numero sezioni", sections.length);
      const newCompliantStatus = {}
      for (let i = 0; i < sections.length; i++) {
        newCompliantStatus[i] = isSectionLiteCompliant(sections[i]);
      }
      setLiteCompliantSections(newCompliantStatus);
    }, [sections.length])



    useMemo(() => {
      if (timelineHash == null) return;
      //console.log(`USE_TIMELINEVAL ON TAB:${activeTab} hash:${timelineHash}`)
      const compliantSection = isSectionLiteCompliant(sections[activeTab]);
      setLiteCompliantSections({ ...liteCompliantSections, [activeTab]: compliantSection })
    },

      [timelineHash])

    useEffect(() => {
      const allTrue = (obj) => Object.values(obj).every(value => value === true);
      const isTLcompliant = allTrue(liteCompliantSections)
      //console.log("TL LITE COMPLIANT:", isTLcompliant);
      setTimelineLiteCompliant(isTLcompliant)
    }, [liteCompliantSections])
    //console.log(`USE_TIMELINEVAL RESULT COMp:${isTimelineLiteCompliant}:`, liteCompliantSections)
    return [isTimelineLiteCompliant, liteCompliantSections]
  }


export const LiteTimelineValidatorViewer = ({ firstItemStartTime, liteCompliantSections, activeTab, onErrorClicked }) => {

  const { t } = useTranslation('frontend', { useSuspense: false });

    if (liteCompliantSections[activeTab] == null ||
      liteCompliantSections[activeTab] === true) return null;
      const {position, itemType, msg, details }  = liteCompliantSections[activeTab] 
      const translated_msg = t(msg, {itemType, details})
      const errorPosition = position || firstItemStartTime
      return (<Alert onClick={() => onErrorClicked(errorPosition) } style={{ textAlign:"left",  cursor: 'pointer' }}
      color="danger">
          {`${getFormattedTime(firstItemStartTime, errorPosition)} - ${translated_msg}`}
        </Alert>)
      }