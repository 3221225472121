import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Alert, Button, Input, Form, FormGroup, Label } from 'reactstrap';
import { selectors as ChatbotSelector, actions as ChatbotAction } from '../../store/slices/chatbot'
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import { FaRegThumbsUp, FaRegThumbsDown } from "react-icons/fa";

const FeedbackModal = (props) => {
    const [feedback, setFeedback] = useState('');
    const [isMounted, setIsMounted] = useState(true);
    const [feedbackSent, setFeedbackSent] = useState(false);
    const chatMessages = useSelector(ChatbotSelector.getMessages);
    const dispatch = useDispatch();
    const { t } = useTranslation('frontend', { useSuspense: false });
    const handleFeedbackChange = (event) => {
        setFeedback(event.target.value);
    };

    useEffect(() => {
        if (feedbackSent) { setTimeout(() => { props.onToggle() }, 3000) }
    }, [feedbackSent])
    const sendFeedback = () => {

        const messages = chatMessages.slice(0, props.index + 1);
        const currentMessage = chatMessages[props.index]
        console.log("FM message:", currentMessage);
        const payload = {
            value: props.feedbackType,
            chatbotVersion: currentMessage.chatbotVersion,
            userType: currentMessage.userType,
            currentSection: currentMessage.currentSection,
            catalogExperimentId: currentMessage.catalogExperimentId,
            messages: messages,
            text: feedback
        }
        console.log("FEEDBACK MESSAGE PAYLOAD:", payload)
        dispatch(ChatbotAction.willCreateChatbotFeedback(payload))
        setFeedbackSent(true);
    }

    const handleSubmit = () => {
        if (isMounted) {
            // Handle the feedback submission (e.g., send it to a server)
            console.log("Feedback:", feedback);
            sendFeedback()
        }

    };

    useEffect(() => {
        // Imposta lo stato del montaggio al montaggio del componente
        setIsMounted(true);
        // Cleanup: cambia lo stato quando il componente si smonta
        return () => setIsMounted(false);
    }, []);

    return (
        <div>
            <Modal isOpen={props.feedbackType != null}>
                <ModalHeader>
                    <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                        {`Feedback`}

                        {props.feedbackType == "POSITIVE" ?
                            <FaRegThumbsUp style={{ position: "absolute", right: "20px" }} />
                            :
                            <FaRegThumbsDown style={{ position: "absolute", right: "20px" }} />
                        }



                    </div>
                </ModalHeader>
                <ModalBody>
                    {feedbackSent ? 
                      <Alert color="info" className="text-center">
                      {t("feedback_sent_info")}
                     </Alert>
                    :
                        <>
                            <Form>
                                <FormGroup>
                                    <Label for="feedback">{t("feedback_details")}</Label>
                                    <Input
                                        rows={5}
                                        type="textarea"
                                        name="feedback"
                                        id="feedback"
                                        placeholder={t("feedback_details")}
                                        value={feedback}
                                        onChange={handleFeedbackChange}
                                    />
                                </FormGroup>
                            </Form>
                            <Input readOnly style={{ fontStyle: "italic" }} type="textarea" rows={8} value={t("feedback_info")} />

                        </>


                    }

                </ModalBody>
                {
                    !feedbackSent && (
                        <ModalFooter>
                        <Button color="primary" onClick={() => { handleSubmit() }}>
                            {t("Invia")}
                        </Button>
                        <Button color="secondary" onClick={() => { props.onToggle() }}>
                            {t("Annulla")}
                        </Button>
                    </ModalFooter>
                    )
                }
            </Modal>
        </div>
    );
};

export default FeedbackModal;

/* da risolvere....  
errors
: 
Array(4)
0
: 
{path: null, locations: Array(1), message: 'Validation error of type NonInputTypeOnVariable: Wrong type for a variable'}
1
: 
{path: null, locations: Array(1), message: "Validation error of type VariableTypeMismatch: Var…'ChatbotFeedbackValue!' @ 'createChatbotFeedback'"}
2
: 
{path: null, locations: Array(1), message: "Validation error of type VariableTypeMismatch: Var…[ChatbotMessageInput]!' @ 'createChatbotFeedback'"}
3
: 
{path: null, locations: Array(1), message: "Validation error of type SubSelectionRequired: Sub…ontent @ 'createChatbotFeedback/messages/content'"}
length
: 
*/