import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const videoTranscriptionsSlice = createSlice({
    name: 'videoTranscriptions',
    initialState:  {
        videoTranscriptions : [],
        currentVideoTranscription: null as any,
    },
    reducers: {
        willLoadVideoTranscriptions: (state, action: PayloadAction<any>) => state,
        willGenerateVideoTranscription: (state, action: PayloadAction<any>) => state,
        willGetVideoTranscription: (state, action: PayloadAction<any>) => state,

        setVideoTranscriptions :  (state, action: PayloadAction<any>) => 
            { 
                const newVideoTranscriptions = action.payload;
                state.videoTranscriptions = newVideoTranscriptions;
            },

            setCurrentVideoTranscription :  (state, action: PayloadAction<any>) => 
                {
                    const newCurrentVideoTranscription = action.payload;
                    state.currentVideoTranscription = newCurrentVideoTranscription;
                }
    }
})

export const { actions, reducer }: any = videoTranscriptionsSlice;

export const selectors = {

    getCurrentVideoTranscription: (state: any) => {
        return state.videoTranscriptions.currentVideoTranscription;
       },

    getVideoTranscriptions: (state: any) => {
        return state.videoTranscriptions.videoTranscriptions;
      },
}