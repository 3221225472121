import {  call, takeLatest, put } from "redux-saga/effects";
import * as ChatbotApi from "../../api/chatbot";
import {
    actions as ChatbotActions
  } from "../slices/chatbot";

export function* sagas() {
    yield takeLatest(ChatbotActions.willCreateChatbotFeedback.type, willCreateChatbotFeedback);
}

function* willCreateChatbotFeedback(action: any):any {

   const  { value, chatbotVersion, userType, currentSection, messages, text, timestamp} = action.payload
   const feedbackMessages = (messages as any).map((item:any) => ({ "role" : item.role, "content" : item.content }))
  console.log("Feedback nessages:", feedbackMessages)
   const chatbotFeedback = yield call(ChatbotApi.createChatbotFeedback,
        value,chatbotVersion, userType, currentSection, 
        feedbackMessages, text, timestamp);

    console.log("Risultato di willCreateChatbotFeedback:", chatbotFeedback)
}
