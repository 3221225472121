import {  call, takeLatest, put } from "redux-saga/effects";
import {
    actions as VideoTranscriptionsActions
  } from "../slices/transcription";
import * as VideoTramscriptionsApi from "../../api/transcription";
import * as UiActions from "../actions/ui";
import { actions as UIActions } from '../slices/ui'
export function* sagas() {
    yield takeLatest(VideoTranscriptionsActions.willLoadVideoTranscriptions.type, willLoadVideoTranscriptions);
    yield takeLatest(VideoTranscriptionsActions.willGenerateVideoTranscription.type, willGenerateVideoTranscription);
    yield takeLatest(VideoTranscriptionsActions.willGetVideoTranscription.type, willGetVideoTranscription);
}

function* willLoadVideoTranscriptions(action: any):any {
    const videoItemId = action.payload;
    console.log("videoItemId di willLoadVideoTranscriptions->", videoItemId);
    yield put(UIActions.startActivityRunning("refreshVideoTranscriptionStatus"));
    const videoTranscriptions = yield call(VideoTramscriptionsApi.listVideoTranscriptions, videoItemId);
    console.log("Lista delle videoTranscriptions:", videoTranscriptions);
    yield put(VideoTranscriptionsActions.setVideoTranscriptions(videoTranscriptions));
    yield put(UIActions.stopActivityRunning("refreshVideoTranscriptionStatus"));
  }

  function* willGenerateVideoTranscription(action: any):any {
    const {experimentId, videoItemId} = action.payload;
    console.log(`willGenerateVideoTranscription of experimentId:${experimentId} - videoItemId:${videoItemId}`);
    try {
        yield put(UIActions.startActivityRunning("generateVideoTranscription"));
        let response = yield call(
        VideoTramscriptionsApi.generateVideoTranscription,
        experimentId,
        videoItemId
        );
        console.log("willGenerateVideoTranscription result", response);
        yield put(VideoTranscriptionsActions.willLoadVideoTranscriptions(videoItemId));
        
      } catch (e) {
        console.error("willGenerateVideoTranscription SAGA error", e);
      }
      yield put(UIActions.stopActivityRunning("generateVideoTranscription"));
  }

  function* willGetVideoTranscription(action: any):any {
    const transcriptionJobName = action.payload;
    console.log("willGetVideoTranscription (transcriptionJobName)", transcriptionJobName);
    yield put(UiActions.loading(true));
    try {
      let response = yield call(
        VideoTramscriptionsApi.getVideoTranscription,
        transcriptionJobName
      );
      console.log("willGetVideoTranscription result", response);
      yield put(VideoTranscriptionsActions.setCurrenVideoTranscription(response));
    } catch (e) {
      console.error("willGetVideoTranscription SAGA error", e);
      yield put(VideoTranscriptionsActions.setCurrenVideoTranscription(null));
    }
    yield put(UiActions.loading(false));
  }