import { API } from 'aws-amplify';
import { loader } from 'graphql.macro';

export const getUserProfileAsDirector = async (user:string) => {
    const query = loader('../graphql/getUserProfileAsDirector.gql');
    //console.log('getUserProfileAsDirector query: ', query)
    try {
      const result: any = await API.graphql(
        { query: query,
          variables: {user} });
      return result
    } catch (error) {
        //console.log(`Error in getUserProfileAsDirector quer for user: ${user}:`, error);
      throw error
    }
  }


  export const syncCalendars = async () => {
    const query = loader("../graphql/syncCalendars.gql");
    //console.log("syncCalendars. query: ", query);
    try {
      const result: any = await API.graphql({
        query: query
      });
      return result;
    } catch (error) {
      //console.log("Error in syncCalendars Query", error);
      throw error;
    }
  };
  