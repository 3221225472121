import React from 'react';
import {
  Container, Row, Col, Button
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { SearchBar } from './SearchBar';
import { Topic } from './Topic';
import { selectors as CatalogSelectors } from '../store/slices/catalog'
import { useSelector } from "react-redux";
import { Footer } from '../header';
import ReactTooltip from "react-tooltip";
import { VscCollapseAll, VscExpandAll } from "react-icons/vsc";
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

/* 
N aree tematiche (titolo) 
  N esperimenti o Percorsi (sequenza ordinata di esperimenti)
  ID, tags, titolo, descrizione (max 2 righe), 
  nome laboratorio <- organizzazione (sottotitolo), 
  gradi scolastici [primaria...università], opportunita' culturale Laboratorio,  
  fasi dell'esperimento, 
  tempo (60n min.) , frequenza mensile, ricercatori, 
  periodo di erogazione, link a timeline master,  link a dettagli 
  (con calendario con i prossimi appuntamenti di quell'esperimento, visibile a tutti)
*/

interface ReadOnlyURLSearchParams extends URLSearchParams {
  append: never;
  set: never;
  delete: never;
  sort: never;
}

export const useSearchParams = () =>  {
  const  searchQuery  = useLocation() as any;
  const [searchParams, setSearchParams] = useState( new URLSearchParams(searchQuery.search) as ReadOnlyURLSearchParams) as any;

  useEffect(() => {
    //console.log("SEARCH query:", searchQuery);
    const usp =  new URLSearchParams(searchQuery.search) as ReadOnlyURLSearchParams
    //console.log("SEARCH value:", usp.get("search"));
    setSearchParams(usp)
  }, [searchQuery])

  //console.log("SEARCH PARAM (use):", searchParams.get("search"))
 return searchParams;
}

export const Catalog = (props:any) => {
  const { t } = useTranslation('frontend', { useSuspense: false });
  const searchParams:any = useSearchParams()
  //console.log("SEARCH on Catalog>>",searchParams.get("search"))
  const catalog: any = useSelector(CatalogSelectors.getFilteredCatalog(null));
  const filteredIds: any = useSelector(CatalogSelectors.getFilteredIds);
  //console.log('with catalog: ', catalog)
  const [leftTopics, setLeftTopics] = React.useState([]);
  const [rightTopics, setRightTopics] = React.useState([]);
  const [allOpen, setAllOpen] = useState(false);

  const renderCatalog = () =>
  {
    //console.log('on useEffect catalog:', catalog);
      const _leftTopics: any = [];
      const _rightTopics: any = [];
      let c = 0;
      for (const topic of catalog) {
        //console.log("Topic filtered:", topic);
        // se la tematica non ha esperimenti non la mostro
        if (topic["esperimenti"].length<1) continue;
        if (c%2==0) {
          _leftTopics.push(topic);
        } else {
          _rightTopics.push(topic);
        }
        c+=1
      }
      setLeftTopics(_leftTopics);
      setRightTopics(_rightTopics);
  }

  React.useEffect(() => {
    //console.log("FilteredIds nullo -> catalog:", catalog);
    if (filteredIds==null)
    renderCatalog();
  } ,[catalog]);

  //Order topics in cols
  React.useEffect(() => {

   //console.log('filteredIds: ', filteredIds)
    console.log("Filtered catalog:", catalog)
    if (catalog != undefined) {
        renderCatalog();
      }
    return () => { }
  }, [filteredIds])

  return (
    <Container fluid>
      <Row>
        <Col>
        <div style={{display:"flex" , justifyContent:"flex-start" , marginBottom:"20px" }}>
        <SearchBar content={searchParams.get("search")}/>
        <Button style={{marginLeft:"10px"}} 
                data-for="collapseButton"
                data-type={"info"} 
                data-place="bottom" 
                data-tip={t("espandi/collassa tutto")}
              onClick={() => {
                setAllOpen(!allOpen);
              }}
              color="primary"
            >
              {
                allOpen ? 
                <VscCollapseAll
                style={{ fontSize: 20 }}
                color="white"
              />

              :

              <VscExpandAll
              style={{ fontSize: 20 }}
              color="white"
            />

                
              }
             
            </Button>
            <ReactTooltip id="collapseButton"/>
        </div>
        </Col>
      </Row>
      <Row>
        <Col xs="6">
          <div >
            {leftTopics.map((topic: any, index:number) => <Topic data-tip={t("Area tematica")} 
            allOpen={allOpen} 
            key={`LT_${topic.id}_${index}`} id={`LT_ID_${topic.id}_${index}`} content={topic} />)}
          </div>
        </Col>
        <Col xs="6">
          <div>
            {rightTopics.map((topic: any, index:number) => <Topic  
            allOpen={allOpen} 
            key={`RT_${topic.id}_${index}`}
            id={`RT_ID_${topic.id}_${index}`}  content={topic} />)}
          </div>
        </Col>
      </Row>
    {!props.footerHidden && <Footer/>}
    </Container>
  )
}