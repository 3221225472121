export default `

mutation cloneContainer(
    $container: ID!
    $owner: ID!
){
    cloneContainer(
        container: $container
        owner: $owner
    ){
        container
    }
}
`