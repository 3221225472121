import {useState, useEffect, forwardRef}  from 'react';
import isEqual from "lodash/isEqual";
import moment from 'moment';
import {TrackType} from './Constants'
import {selectors as PassCodeSelector, actions as PassCodeAction} from '../../store/slices/passCodeUsers'
//https://www.npmjs.com/package/activity-logger
import { useSelector, useDispatch } from "react-redux";
import beautify from 'js-beautify'
import { ulid } from 'ulid'
//@audit todo la attivazione del watcher va delegata alla sezione "data analitycs e salvata nel backend"
const ENABLED = true;

// see https://docs.google.com/document/d/1wlVgmcy9jMj9KjtEI8_lWhGn9X_A8ZHyULo5y13A2yo/edit#


export class TraceEvent {
    // IMPLEMENTATI
    static CLICK_ITEM = new TraceEvent("CLICK_ITEM", "l'utente clicca su un item di tipo DOC")
    static CLICK_TIMELINE = new TraceEvent("CLICK_TIMELINE", 
                        "l'utente cambia intenzionalmente la posizione sulla timeline")
    static ATTACHMENTS_PANEL_VISIBILITY_CHANGE = new TraceEvent("ATTACHMENTS_PANEL_VISIBILITY_CHANGE",
    "l'utente rende visibile o nasconde il pannello degli allegati audio/video")   

    static ATTACHMENTS_PANEL_RESIZE_CHANGE = new TraceEvent("ATTACHMENTS_PANEL_RESIZE_CHANGE",
    "l'utente ridimensiona il pannello degli allegati audio/video")   
    
    static NAVIGATOR_PANEL_VISIBILITY_CHANGE = new TraceEvent("NAVIGATOR_PANEL_VISIBILITY_CHANGE",
    "l'utente rende visibile o nasconde il pannello degli allegati audio/video") 

    static ZOOM_CHANGE = new TraceEvent("ZOOM_CHANGE", "cambio di stato dello zoom")

    static PIP_STATUS_CHANGE = new TraceEvent("PIP_STATUS_CHANGE", "Cambio di stato del Picture in Picture (true|false)")
    static FULLSCREEN_VIDEO_STATUS_CHANGE = new TraceEvent("FULLSCREEN_VIDEO_STATUS_CHANGE",
    "Cambio di stato del video in fullscreen del video (true|false)")
    static PLAYING_STATUS_CHANGE_REQUEST = new TraceEvent("PLAYING_STATUS_CHANGE",
    "Cambio di stato del player della timeline true|false")
    static VOLUME_STATUS_CHANGE = new TraceEvent("VOLUME_STATUS_CHANGE",
    "Cambio di stato del volume del video true|false")
    static AUDIO_SOLO_STATUS_CHANGE = new TraceEvent("AUDIO_SOLO_STATUS_CHANGE",
    "Cambio di stato del volume del video true|false")
    static NAVIGATE_TO_ITEM = new TraceEvent("NAVIGATE_TO_ITEM", 
                        "l'utente seleziona un item dal navigatore dei contenuti")


    static ATTACHMENTS_CURRENT_STATUS = new TraceEvent("ATTACHMENTS_CURRENT_STATUS", 
    "Evento triggerato ogni 30 secondi di sessione attiva per fotografare gli item correntemente attivi")

    static SESSION_VISIBILITY_CHANGE = new TraceEvent("SESSION_VISIBILITY_CHANGE", 
    "Evento triggerato quando l'utente mostra o nasconde la finestra con la sessione di lavoro")
    
    static SESSION_PAUSED = new TraceEvent("SESSION_PAUSE", 
    "Evento triggerato quando l'utente mette intenzionalmente in pausa la sessione di lavoro")
    
    static SESSION_RESUMED = new TraceEvent("SESSION_RESUME", 
    "Evento triggerato quando l'utente riprende intenzionalmente la sessione di lavoro")
    
    // BOOKMARKS TRACE * // 

    static ADD_BOOKMARK = new TraceEvent("ADD_BOOKMARK",
    "L'utente aggiunge un nuovo bookmark personale") 

    static UPDATE_BOOKMARK = new TraceEvent("UPDATE_BOOKMARK",
    "L'utente aggiorna un bookmark personale") 

    static REMOVE_BOOKMARK = new TraceEvent("REMOVE_BOOKMARK",
    "L'utente rimuove bookmark personale") 

    static NAVIGATE_TO_BOOKMARK = new TraceEvent("NAVIGATE_TO_BOOKMARK", 
                        "l'utente seleziona un bookmark dal navigatore dei contenuti")

    constructor(name, description) {
      this.name = name
      this.description = description
    }
  }

  class WatcherEvent {

    constructor (traceEvent, payload)
    {
        //console.log("TIMELINE WATCHER: traceEvent", traceEvent, payload)
        this.traceEvent = traceEvent
        this.payload = this.processPayload(payload);
        this.timestamp = this.timestamp
    }

        isValid = () => {
            return this.payload!=null;
        }

        processPayload = (payload) =>
        {
            let filteredPayload =  {}
            // tutti gli eventi devono contenere un payload valido
            if (payload==null) return null;

            switch(this.traceEvent) 
            {   
                case TraceEvent.SESSION_VISIBILITY_CHANGE:
                    {
                        filteredPayload = {...payload}
                        break;
                    } 
               
                case TraceEvent.ATTACHMENTS_CURRENT_STATUS:
                    {
                        filteredPayload = {...payload}
                        break;
                    } 
                case TraceEvent.NAVIGATE_TO_ITEM:
                    {
                        filteredPayload = {...payload}
                        break;
                    } 
                case TraceEvent.CLICK_TIMELINE:
                    {
                      filteredPayload = {...payload}
                      break;
                    }
                case TraceEvent.FULLSCREEN_VIDEO_STATUS_CHANGE:
                    {
                      filteredPayload = {...payload}
                      break;
                    }
                case TraceEvent.PIP_STATUS_CHANGE:
                    {
                        filteredPayload = {...payload}
                        break;
                    }
                case TraceEvent.ATTACHMENTS_PANEL_VISIBILITY_CHANGE:
                    {
                        filteredPayload = {...payload}
                        break;
                    }
                
                case TraceEvent.ATTACHMENTS_PANEL_RESIZE_CHANGE:
                    {
                        filteredPayload = {...payload}
                        break;
                    }
                
                case TraceEvent.NAVIGATOR_PANEL_VISIBILITY_CHANGE:
                    {
                        filteredPayload = {...payload}
                        break;
                    }
                case TraceEvent.ZOOM_CHANGE:
                    {
                        filteredPayload = {...payload}
                        break;
                    }
                case TraceEvent.CLICK_ITEM:
                    {
                        filteredPayload = {...payload};
                        filteredPayload["item"] = {"id" : payload["item"]["id"],  
                        "title" : payload["item"]["title"], "type" : payload["item"]["type"]}
                        break;
                    }
                case TraceEvent.PLAYING_STATUS_CHANGE_REQUEST:
                    {
                        filteredPayload = {...payload}
                        break;
                    }
                case TraceEvent.VOLUME_STATUS_CHANGE:
                    {
                        filteredPayload = {...payload}
                        break;
                    }
                case TraceEvent.AUDIO_SOLO_STATUS_CHANGE:
                    {
                        filteredPayload = {...payload}
                        break;
                    }
                case TraceEvent.SESSION_PAUSED:
                    {
                        filteredPayload = {...payload}
                        break;
                    }
                case TraceEvent.SESSION_RESUMED:
                    {
                        filteredPayload = {...payload}
                        break;
                    }
                case TraceEvent.ADD_BOOKMARK:
                    {
                        filteredPayload = {...payload}
                        break;
                    }
                case TraceEvent.UPDATE_BOOKMARK:
                    {
                        filteredPayload = {...payload}
                        break;
                    }
                case TraceEvent.REMOVE_BOOKMARK:
                    {
                        filteredPayload = {...payload}
                        break;
                    }
                case TraceEvent.NAVIGATE_TO_BOOKMARK:
                    {
                        filteredPayload = {...payload}
                        break;
                    }
                default:{
                    // prevengo l'invio di eventi non previsti dal watcher
                    filteredPayload = null
                }
            }
            return filteredPayload;
        }
    

    toJson = () =>
    {   const eventDate = moment.now()
        const formattedDate = moment(eventDate).format("YYYY/MM/DD - HH:mm:ss")
        return  {"type" : this.traceEvent.name, "timestamp" : eventDate, 
        "formattedDatetime" : formattedDate, "payload" : this.payload}
    } 
    
  }

const withWatcher = (WrappedComponent) =>
{
    return forwardRef((props,ref)=>{

        const [items,setItems] = useState([]);
        const [trace, setTrace] = useState(null);
        const [isPaused, setPaused] = useState(false);
        const currentPassCode = useSelector(PassCodeSelector.getCurrentPassCode);
        const dispatch = useDispatch();

        useEffect(() =>{
            //console.log("TIMELINE WATCHER: PASSCODE CURRENT PASSCODE:",currentPassCode);
            if (currentPassCode!=null) {
                // N.B il passcode con 10 caratteri è per convenzione l'unico
                // che prevede la acquisizione video... per questo motivo
                // il recordig_id è settato solo in quel caso!
                const storageTrace = JSON.parse(localStorage.getItem("RialeSessionTrace"));
                if (storageTrace == null) {
                    // aggiorno nel localstorage il valore del nuovo passCode nel caso sia nullo quello corrente
                    const creationDate = moment.now()
                    
                    const newTrace = {
                        "tracedResource" : WrappedComponent.name,
                        "passCode": currentPassCode,
                        "id": `session_${creationDate}`,
                        "expected_recordings" : 0,
                        "recording_id" : `${currentPassCode.length==10 ? ulid() : "-"}`,
                        "startDate" : creationDate,
                        "formattedStartDatetime" : moment(creationDate).format("YYYY/MM/DD - HH:mm:ss"),
                        "actions": []
                    }
                    setTrace(newTrace);
                    // salvo subito la nuova traccia nel localStorage, in modo da avere
                    // da subito a disposizione le informazioni disponibili anche per gli 
                    // altri componenti
                    localStorage.setItem("RialeSessionTrace", JSON.stringify(newTrace));

                }
                else {
                    setTrace(storageTrace);
                }
            }
            
            else
            {
                // la rimozione dal LocalStorage avviene in sede di didLogoutWithPasscode
                setTrace(null);
            }
           

            //console.log("TIMELINE WATCHER: PASSCODE CURRENT SESSION TRACE:", JSON.parse(localStorage.getItem("RialeSessionTrace")))

        }, [currentPassCode])


        useEffect(() =>{

            ////console.log("TIMELINE WATCHER: TRACE:", beautify(JSON.stringify(trace)));
            
        }, [trace])

        
       const onEventToWatch = (traceEvent,payload) =>
        {
            //console.log(`onEventToWatch called on: ${traceEvent.name} isPaused:${isPaused} currentTrace: ${trace} payload`, payload)
            // se la traccia corrente è nulla, significa che non c'è nessun utente loggato
            // con traccia valida e non faccio nulla
            // se sono in stato di pausa e l'evento non è quello di RESUME non faccio nulla
           if (trace==null || (isPaused && traceEvent!=TraceEvent.SESSION_RESUMED)) return;

           if (traceEvent==TraceEvent.SESSION_PAUSED)
            {setPaused(true);}
           else if (traceEvent==TraceEvent.SESSION_RESUMED)
             {setPaused(false);}
            

          let newTrace = {...trace}
          const watcherEvent = new WatcherEvent(traceEvent,payload);
          if (watcherEvent.isValid())
          {
            newTrace["actions"].push(watcherEvent.toJson())

            if (traceEvent==TraceEvent.SESSION_RESUMED)
            {   
                //console.log(`SESSION RESUMED: increasing expected recording to:${newTrace["expected_recordings"]+1}`);
                newTrace["expected_recordings"] +=1;
                const newSessionRecordingPath = `RIALE_session_${newTrace.recording_id}_${newTrace["expected_recordings"]}`
                dispatch(PassCodeAction.setCurrentSessionRecordingPath(newSessionRecordingPath))
            }

            setTrace(newTrace);
            //console.log(`onEventToWatch: Aggiorno la sessione dello store:`, newTrace)
            localStorage.setItem("RialeSessionTrace", JSON.stringify(newTrace));
          }
         
        }

        const onMessageReceived = (newItems) =>
        {

            if (!ENABLED) return;

            if (isEqual(items,newItems))
            return;
            //console.log("TIMELINE WATCHER: items modificati");
            processItems(newItems);
        }

        const processItems = (newItems) =>
        {
            let newTrace = {...trace};

            // t
            newItems.map((newItem) => {
                // se l'item non è mai comparso nel trace lo aggiungo
                if (newTrace[newItem.id]==null)
                {
                    //console.log("TIMELINE WATCHER: Aggiungo per la prima volta l'item:", newItem);
                    /*
                    {
                      "item_id"  : { "title" : "",
                                     "type" : "TAG",
                                      "events" : [{"timestamp" : 12345, "type" : "ENTER"},
                                                  {"timestamp" : 12399, "type" : "LEAVE"} ]

                        }
                    }

                    */
                    newTrace[newItem.id]={"title" : newItem["title"], "type" : newItem["type"],
                                          "events" : [{"type" : "ENTER", "timestamp" : moment.now() }]}
                }
                // se è già presente nel trace devo verificare se nello 
                // stato corrente degli items non è stato già aggiunto
                else {
                    if (!items.includes(newItem))
                    {
                        //console.log("TIMELINE WATCHER: item scomparso e riapparso");
                        newTrace[newItem.id]["events"].push({"type": "ENTER" , "timestamp" :moment.now()})
                    }
                    else{
                        //console.log("TIMELINE WATCHER: item già presente dal passaggio precedente: NO OP");
                    }
                }
            })

            // adesso devo verificare se per caso è scomparso un item tra quelli che c'erano nel check precedente e
            // in questo caso aggiungere il timestamp 
            items.map(item => {
                if (!newItems.includes(item))
                {
                    if (newTrace[item.id]!=null)
                    {
                        //console.log(`TIMELINE WATCHER: item ${item.title} scomparso rispetto al passaggio precedente`);
                        newTrace[item.id]["events"].push({"type": "LEAVE" , "timestamp" :moment.now()})
                    }
                }
            })

            setTrace(newTrace);
            setItems(newItems);
        }
        //onEventToWatch = (traceEvent,payload)
       return <WrappedComponent ref={ref} onEventToWatch={(traceEvent,payload)=> onEventToWatch(traceEvent,payload) } {...props}/>
    }
    )
}

export default withWatcher;