import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Badge, Label, Container, Button, Navbar, NavbarBrand, NavbarToggler, Collapse, Nav,
  NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap'
import { selectors as AuthSelectors } from './store/slices/auth'
import { actions as AuthActions } from './store/slices/auth'
import { selectors as ProfileSelectors } from './store/slices/profile'
import { Link } from "react-router-dom";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from 'react-i18next';
import LanguageSelector from './components/LanguageSelector';
import { RIALE_VERSION } from './config';
import { CenteredText } from "./styles/styles";
import logo from './assets/riale_logo_eu.png'
import footer_logos from './assets/riale_footer.png'
import { rialeGuideUrl, chiSiamoUrl } from "./config";
import CookieConsent from "react-cookie-consent";
import ReactTooltip from "react-tooltip";
import { IconLookup } from "@fortawesome/fontawesome-svg-core";
import { BsInfoCircle } from "react-icons/bs";
import { IconContext } from "react-icons";
import { selectors as UiSelector, actions as UIAction } from './store/slices/ui'
import { IconButton } from "@material-ui/core";
import { configuration } from "./config";

export const Footer = () => {
  return (
    <div style={{ marginBottom: "10px" }}>
      <Navbar color="white" style={{ display: "flex", justifyContent: "flex-end", alignContent: "flex-end", background: "white" }} >
        <div>
          <NavbarBrand>

            <img width={"100%"} height={"35px"}
              src={footer_logos} /></NavbarBrand>

        </div>
      </Navbar>
    </div>
  )
}

export const Header = ({ className, section, showMenu = false }: any) => {

  const [isOpen, setIsOpen] = React.useState(false);
  const [isAboutPlatformOpen, setAboutPlatformOpen] = React.useState(false);
  const [isHelpOpen, setIsHelpOpen] = React.useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const toggleHelp = () => setIsHelpOpen(!isHelpOpen);
  const isAuthenticated = useSelector(AuthSelectors.isAuthenticated);
  const userAttributes = useSelector(ProfileSelectors.getProfile)
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation('frontend', { useSuspense: false });

  const toggleAssistantOpen = () => { dispatch(UIAction.toggleAssistantOpen()) }

  const _section = section != undefined ? section : "Dashboard"
  //console.log("Dati dell'utente:", userAttributes);
  return (
    <Navbar className={className} color="primary" light expand="md">
      <NavbarBrand className="text-white font-weight-bold" href="/">
        <span>
          RIALE-EU - </span>{_section}</NavbarBrand>
      <NavbarToggler onClick={toggle} />
      <Collapse isOpen={isOpen} navbar>
        {showMenu ? (
          <Nav className="mr-auto" navbar>
            {isAuthenticated &&
              <>
                <NavItem>
                  <NavLink className="text-white" href="/dashboard/">{t("Dashboard")}</NavLink>
                </NavItem>
              </>
            }
          </Nav>
        ) : (
          <Nav className="mr-auto" navbar>
          </Nav>
        )}

        <Nav navbar>
          <>
            {isAuthenticated ? (
              <>
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle data-for="userProfile" data-tip={userAttributes.email} className="text-white" nav caret>{userAttributes.given_name} {userAttributes.family_name}</DropdownToggle>

                  <DropdownMenu right>
                    <DropdownItem>
                      <NavLink className={"text-primary"}
                        style={{ color: 'white', textDecoration: 'none' }}
                        tag={Link} to={"/dashboard"}>
                        Dashboard
                      </NavLink>
                    </DropdownItem>
                    <DropdownItem>
                      <NavLink className={"text-primary"}
                        style={{ color: 'white', textDecoration: 'none' }}
                        tag={Link} to={"/edit_profile"}>
                        {t("Modifica profilo")}
                      </NavLink>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem onClick={() => {
                      dispatch(AuthActions.willLogoutUser());
                    }}>{t("Logout")}</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>

                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle className="text-white" nav caret>
                    <FontAwesomeIcon
                      icon={faQuestionCircle as IconLookup}
                      style={{ fontSize: 20 }}
                      color="white"
                    /></DropdownToggle>
                  <DropdownMenu right>

                    <Collapse isOpen>

                      <DropdownItem>
                        <NavLink className={"text-primary"}
                          style={{ color: 'white', textDecoration: 'none' }} target="_blank"
                          href={chiSiamoUrl(i18n.language) as any}>
                          {t("Chi siamo")}
                        </NavLink>
                      </DropdownItem>
                      <DropdownItem>
                        <NavLink className={"text-primary"}
                          style={{ color: 'white', textDecoration: 'none' }} target="_blank"
                          href={rialeGuideUrl(i18n.language)}>
                          {t("Scarica la guida")}
                        </NavLink>
                      </DropdownItem>
                      <DropdownItem>
                        <NavLink className={"text-primary"}
                          style={{ color: 'white', textDecoration: 'none' }}
                          href="mailto:riale@crs4.it">
                          {t("Contattaci")}
                        </NavLink>
                      </DropdownItem>

                      <DropdownItem onMouseEnter={() => {
                        setAboutPlatformOpen(true)
                      }}

                        onMouseLeave={() => {
                          setAboutPlatformOpen(false)
                        }}

                      ><span style={{ marginLeft: "10px", color: "#0000AA" }}>{t("Informazioni sulla piattaforma")}</span>
                      </DropdownItem>

                      {isAboutPlatformOpen &&
                        <DropdownItem>
                          <CenteredText>
                            <div style={{ display: "flex", border: '1px solid #000', flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                              <img height={"50px"} style={{ padding: "10px", alignItems: "center" }} src={logo} />
                              <Label style={{ display: "flex", alignItems: "center" }}>
                                <i>{t("Versione")} {RIALE_VERSION}</i>
                              </Label>
                            </div>
                          </CenteredText>
                        </DropdownItem>
                      }



                    </Collapse>

                  </DropdownMenu>
                </UncontrolledDropdown>
                <ReactTooltip id={"userProfile"} />
              </>
            ) : (
              <>
                <NavItem>
                  <NavLink className="text-white" href="/login/">{t("Login")}</NavLink>
                </NavItem>
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle className="text-white" nav caret>
                    <FontAwesomeIcon
                      icon={faQuestionCircle as IconLookup}
                      style={{ fontSize: 20 }}
                      color="white"
                    /></DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem>
                      <NavLink className={"text-primary"}
                        style={{ color: 'white', textDecoration: 'none' }} target="_blank"
                        href={chiSiamoUrl(i18n.language) as any}>
                        {t("Chi siamo")}
                      </NavLink>
                    </DropdownItem>
                    <DropdownItem>
                      <NavLink className={"text-primary"}
                        style={{ color: 'white', textDecoration: 'none' }} target="_blank"
                        href={(rialeGuideUrl(i18n.language))}>
                        {t("Scarica la guida")}
                      </NavLink>
                    </DropdownItem>
                    <DropdownItem>
                      <NavLink className={"text-primary"}
                        style={{ color: 'white', textDecoration: 'none' }}
                        href="mailto:riale@crs4.it">
                        {t("Contattaci")}
                      </NavLink>
                    </DropdownItem>

                    <DropdownItem onMouseEnter={() => {
                      setAboutPlatformOpen(true)
                    }}

                      onMouseLeave={() => {
                        setAboutPlatformOpen(false)
                      }}

                    ><span style={{ marginLeft: "10px", color: "#0000AA" }}>{t("Informazioni sulla piattaforma")}</span>
                    </DropdownItem>

                    {isAboutPlatformOpen &&
                      <DropdownItem>
                        <CenteredText>
                          <div style={{ display: "flex", border: '1px solid #000', flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            <img height={"50px"} style={{ padding: "10px", alignItems: "center" }} src={logo} />
                            <Label style={{ display: "flex", alignItems: "center" }}>
                              <i>{t("Versione")} {RIALE_VERSION}</i>
                            </Label>
                          </div>
                        </CenteredText>
                      </DropdownItem>
                    }
                  </DropdownMenu>
                </UncontrolledDropdown>

              </>
            )
            }
          </>
        </Nav>

      </Collapse>
      <LanguageSelector />
      {
        (configuration[process.env.REACT_APP_STAGE || "beta"].chatbotEnabled) &&
        (
          <IconButton onClick={() => { toggleAssistantOpen() }}>
            <IconContext.Provider value={{ color: 'white', size: "1.2em" }}>
              <BsInfoCircle data-for={"menu"} data-tip={t("Ingrandisci il menu")} style={{
                cursor: "pointer", color: "#FFFFFF", background: "#007bff",
                marginLeft: "15px"
              }}
                className="mr-2" />
            </IconContext.Provider>
          </IconButton>
        )
      }

      <CookieConsent style={{ background: "#00AA20" }}
        buttonText={t("cookieAccept")}
        buttonStyle={{ color: "#4e503b", background: "white", fontSize: "13px" }}
        cookieName="rialeConsentCookie">{t("cookieAlert")}</CookieConsent>
    </Navbar>
  )
}