import { NavItem, NavLink, Nav } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FcTimeline } from "react-icons/fc";
import {RiAdminFill} from "react-icons/ri"
import ReactTooltip from "react-tooltip";

import {
  faMap,
  faChartPie,
  faBriefcase,
  faBook,
  faCalendarAlt
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectors as ProfileSelectors } from '../store/slices/profile'
import { useTranslation } from 'react-i18next';
import {GiHamburgerMenu, GiCancel} from "react-icons/gi";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { selectors as UiSelector, actions as UIAction } from '../store/slices/ui'
export const SideBar = ({active, onToggleOpen, isOpen}: any) => {

  const isDirector = useSelector(ProfileSelectors.isDirector);
  const isAssistantOpen = useSelector(UiSelector.isAssistantOpen);
  const { t, i18n } = useTranslation('frontend', { useSuspense: false });
  
 /*
 
  const userAttributes = useSelector(ProfileSelectors.getProfile)
  const isDirectionMenuVisible = () => { 
    return (userAttributes!=null && userAttributes.groups!=null 
    && userAttributes.groups.includes("director"))};
  */

  //console.log('active: ', active)
  return (
    <div style={{
      border: '0px solid #000',
      width: '240px',
      position: 'absolute',
      backgroundColor: '#f1f1f1',
      bottom: 0,
      top: `${isAssistantOpen ? 0 : "68px"}`, // 58,
      paddingRight:10
    }}>
      <Nav vertical className="list-unstyled pb-3">
      <NavItem>
        {
          isOpen ? (
            <div className="text-primary" style={{paddingTop:"8px", paddingBottom:"10px",background:"#007bff", marginTop:"15px", }}>
              <span style={{  marginLeft:"15px", color:"#FFFFFF",  textDecoration: 'none', fontSize:"1.2em", fontWeight: 'bolder'}}>Menu</span>
            <GiCancel data-for={"menu"} data-tip={ t("Iconizza il menu")}  style={{cursor:"pointer" , marginTop:"6px", color:"#FFFFFF",
               }} 
              className="mr-2 pull-right" onClick={()=> onToggleOpen()}/> 
              </div>
          )  :
          (
            <div className="text-primary" style={{paddingTop:"8px", paddingBottom:"10px",background:"#007bff", marginTop:"15px", }}>
            <GiHamburgerMenu data-for={"menu"} data-tip={ t("Ingrandisci il menu")} style={{cursor:"pointer" , color:"#FFFFFF", background:"#007bff",
                  marginLeft:"15px"}} 
                className="mr-2" onClick={()=> onToggleOpen()}/> 
                </div>
          )
        }
        
        </NavItem>

        <NavItem active={active === "dashboard" ? true : false} className="mb-1">

          <NavLink className={active === "dashboard" ? "text-primary" : "text-secondary"} style={{ textDecoration: 'none', fontWeight: 'bolder' }} tag={Link} to={"/dashboard"}>
            <FontAwesomeIcon data-for={"menu"}  data-tip={isOpen ? "" : t("Dashboard")} icon={faBriefcase as IconProp} className="mr-2" /> {isOpen && t("Dashboard")}
          </NavLink>
        </NavItem>
        
        <NavItem active={active === "catalog" ? true : false} className="mb-1">
          <NavLink className={active === "catalog" ? "text-primary" : "text-secondary"} style={{ textDecoration: 'none', fontWeight: 'bolder' }} tag={Link} to={"/catalog"}>
            <FontAwesomeIcon data-for={"menu"}  data-tip={isOpen ? "" : t("Catalogo")}  icon={faBook as IconProp} className="mr-2" /> {isOpen && t("Catalogo")}
          </NavLink>
        </NavItem>
        { isDirector &&
        <NavItem active={active === "map" ? true : false} className="mb-1">
          <NavLink className={active === "map" ? "text-primary" : "text-secondary"} style={{ textDecoration: 'none', fontWeight: 'bolder' }} tag={Link} to={"/map"}>
            <FontAwesomeIcon data-for={"menu"}  data-tip={isOpen ? "" : t("Mappa")}  icon={faMap as IconProp} className="mr-2" /> {isOpen && t("Mappa")}
          </NavLink>
        </NavItem>
        }

        <NavItem active={active === "calendar" ? true : false} className="mb-1">
          <NavLink className={active === "calendar" ? "text-primary" : "text-secondary"} style={{ color: 'white', textDecoration: 'none', fontWeight: 'bolder' }} tag={Link} to={"/calendar"}>
            <FontAwesomeIcon data-for={"menu"}  data-tip={isOpen ? "" : t("Calendario")}  icon={faCalendarAlt as IconProp} className="mr-2" /> {isOpen && t("Calendario")}
          </NavLink>
        </NavItem>
        <NavItem active={active === "timeline" ? true : false} className="mb-1">
          <NavLink className={active === "timeline" ? "text-primary" : "text-secondary"} style={{ color: 'white', textDecoration: 'none', fontWeight: 'bolder' }} tag={Link} to={"/timeline"}>
            <FcTimeline data-for={"menu"}  data-tip={isOpen ? "" : t("MyTimelines")}  className="mr-2" /> {isOpen && t("MyTimelines")}
          </NavLink>
        </NavItem>
        { isDirector &&
        (<NavItem active={active === "direction" ? true : false} className="mb-1">
          <NavLink className={active === "direction" ? "text-primary" : "text-secondary"} 
          style={{ color: 'white', textDecoration: 'none', fontWeight: 'bolder' }} tag={Link} to={"/direction"}>
            <RiAdminFill data-for={"menu"}  data-tip={isOpen ? "" : t("Regia")}  className="mr-2" /> {isOpen && t("Regia")}
          </NavLink>
        </NavItem>)
        }

      { isDirector &&
        (<NavItem active={active === "data_analytics" ? true : false} className="mb-1">
          <NavLink className={active === "data_analytics" ? "text-primary" : "text-secondary"} 
          style={{ color: 'white', textDecoration: 'none', fontWeight: 'bolder' }} tag={Link} to={"/data_analytics"}>
            <FontAwesomeIcon data-for={"menu"}  data-tip={isOpen ? "" : t("Analisi statistiche")}  icon={faChartPie as IconProp} className="mr-2" />  {isOpen && t("Analisi statistiche")}
          </NavLink>
        </NavItem>)
        }
      </Nav>
      <ReactTooltip id="menu"/>
    </div>
  )
}