export const BOOKED_WITH_DIRECTION_COLOR ="#28a745";
export const BOOKED_WITHOUT_DIRECTION_COLOR = "#dc3545";
export const UNBOOKED_COLOR = "#007bff";
export const PARTIALLY_BOOKED_COLOR = "#CC7bAA";
export const NO_LONGER_AVAILABLE_COLOR = "#AAAAAA"
export const RESERVED_COLOR = "#7B68EE"
export const NOT_FOUND_COLOR = "#AAAAAA"
export const BUSY_COLOR ="#dc3545";
export const BOOKED_COLOR = "#fd7e14";

