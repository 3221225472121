import { call, put, takeEvery, takeLatest, select } from 'redux-saga/effects'

import { actions as AppointmentActions } from '../slices/appointments'
import { actions as ProfileActions, selectors as ProfileSelectors } from '../slices/profile'
import { actions as AuthActions } from '../slices/auth'
import { actions as NotificationActions } from '../slices/notification'
import * as AuthApi from '../../api/auth'
import { SYNCHRONOUS_SESSION_TYPE } from './remotesessions'

export function* sagas():any {
  //console.log('inside profile saga')
  yield takeLatest(ProfileActions.willRetrieveProfileData.type, willRetrieveProfileData)
  yield takeLatest(AuthActions.didLoginUserSuccess.type, willRetrieveProfileData)
  yield takeLatest(ProfileActions.didRetrieveProfileData.type, willLoadAppointmentAndDirectors)
}

function* willLoadAppointmentAndDirectors(action: any):any {
const isDirector = yield select(ProfileSelectors.isDirector);
if (isDirector)
{
  yield put(AppointmentActions.willLoadDirectors());
  yield put(AppointmentActions.willLoadAppointmentsAndDirectors({"start" : "1900-01-20T10:00:00.000Z","end" 
  : "2050-01-20T10:00:00.000Z", "type" : SYNCHRONOUS_SESSION_TYPE}));
}
else
yield put(AppointmentActions.willLoadAppointments({"start" : "1900-01-20T10:00:00.000Z","end" : 
"2050-01-20T10:00:00.000Z", "type" : SYNCHRONOUS_SESSION_TYPE}));
   
}

function* willRetrieveProfileData(action: any):any {
  //console.log('in willGetProfileData function');
  try {
    const user = yield call(AuthApi.getAuthenticatedUser);
    //console.log('with user in willRetrieveProfileData: ', user)
    //console.log("User groups", user.signInUserSession.accessToken.payload["cognito:groups"]);
    const groups = user.signInUserSession.accessToken.payload["cognito:groups"];
    user.attributes["groups"] = (groups==null) ? [] : groups;
    yield put(ProfileActions.didRetrieveProfileData(user.attributes))
  } catch (error) {
    //console.log('Error retriving profile data', error);
    yield put(NotificationActions.willShowNotification({ message: (error as any).message, type: "danger" }));
  }

}