
// creating and downloading files
//https://jsfiddle.net/larrykluger/eo4dzptr/
//https://yashodgayashan.medium.com/zip-files-in-react-30fb77fd6a58
//https://stuk.github.io/jszip/documentation/examples.html
//https://www.npmjs.com/package/jszip


import { useDispatch, useSelector } from 'react-redux';
import { selectors as AnalyticsSessionsSelector, actions as AnalyticsSessionsActions } from '../store/slices/passCodeUsers'
import { useEffect, useState } from "react"
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {
  ButtonToolbar, ButtonGroup, Button, Modal, ModalBody, ModalFooter, ModalHeader,
  Card, Alert, CardBody, CardLink,
  CardHeader, CardFooter, CardTitle, CardSubtitle, Container, Row, Col, Form, FormGroup, Label, Input, FormText
} from "reactstrap";

import { FaDownload } from "react-icons/fa";
import { GrView, GrDocumentZip } from "react-icons/gr";
import ReactJson from 'react-json-view'
import JSZip from 'jszip'
import { saveAs } from "file-saver";
import beautify from 'js-beautify'
import moment from 'moment';
import { generatePasscode, RecordingPasscode } from '../utils/passcodeGenerator';
import ReactTooltip from "react-tooltip";
import NumberPicker from "react-widgets/NumberPicker";
import { ThemeContext, ThemeProvider } from 'styled-components';
import { base } from 'grommet-icons';
import { GoFileZip } from "react-icons/go";

import { IconContext } from "react-icons";
import IconButton from '@material-ui/core/IconButton';
import { useTranslation } from 'react-i18next';

export const AnalyticsSessionsAnalyzer = (props: any) => {

  const dispatch = useDispatch();
  const [currentAnalyticsSession, setCurrentAnalyticsSession] = useState(null) as any;
  const [currentClickedRow, setCurrentClickedRow] = useState(null) as any;
  const [currentSelectedRows, setCurrentSelectedRows] = useState({}) as any;
  const [currentFilename, setCurrentFilename] = useState("") as any;
  const [numPasscodes, setNumPasscodes] = useState(10) as any;
  const [passcodeWithRecording, setPasscodeWithRecording] = useState(false) as any;
  const { t, i18n } = useTranslation('frontend', { useSuspense: false });

  const analyticsSessionsRows = useSelector(AnalyticsSessionsSelector.getCurrentAnalyticsSessions)

  const theme = {
    global: {
      colors: {
        white: '#666666',
      }
    },
    icon: {
      size: {
        small: '12px',
        medium: '24px',
        large: '48px',
        xlarge: '96px',
      },
      extend: undefined,
    },
  } as any;

  const generatePassCodesFile = () => {
    let content = `Elenco di ${numPasscodes} codici per gli studenti (${passcodeWithRecording ? "con" : "senza"} acquisizione video dello schermo)\n\n`;
    const filename = (currentFilename != "") ?
      `${currentFilename}__${numPasscodes}_passcodes.txt` :
      `riale__${numPasscodes}_passcodes.txt`;
    const recordingType = (passcodeWithRecording==true ? RecordingPasscode.RECORDING : RecordingPasscode.NO_RECORDING); 
    for (let i = 1; i <= numPasscodes; i++) {
      content += i < 10 ? `Studente 0${i}: ${generatePasscode(recordingType)}\n` : `Studente ${i}: ${generatePasscode(recordingType)}\n`
    }
    var blob = new Blob([content], { type: "text/plain;charset=utf-8" });
    saveAs(blob, filename);
  }

  const generateSessionFile = (row: any) => {
    const content = beautify(row["content"])
    var blob = new Blob([content], { type: "text/plain;charset=utf-8" });
    saveAs(blob, `${row["ulid"]}.json`);
  }

  const exportSelectedSessionAsZipFile = () => {
    //if (analyticsSessionsRows==null) return;
    //console.log("Generating ZIP file with:", currentSelectedRows);
    let zip = new JSZip();
    const currentSelectedRowsKeys = Object.keys(currentSelectedRows) as any;

    currentSelectedRowsKeys.forEach((key: any) => {
      //console.log("ZIP key:", currentSelectedRows[key]);
      if (currentSelectedRows[key] != null) {
        zip.file(`${currentSelectedRows[key]["ulid"]}.json`,
          beautify(currentSelectedRows[key]["content"]))
      }

    });

    zip.generateAsync({ type: "blob" }).then(content => {
      saveAs(content, `riale_analytics_sessions_json_${moment.now()}.zip`);
    });
  }

  const renderPasscodesGeneratorForm = () => {
    return (
      <Form inline>
        <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
          <Label for="exampleEmail" className="mr-sm-2">{t("Genera file di testo con")}</Label>
          <NumberPicker min={1}
            onChange={(value: any) => setNumPasscodes(value)}
            style={{ width: "80px", margin: "10px" }} defaultValue={numPasscodes} />
        </FormGroup>
        <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
          <Label for="fileNameInput" className="mr-sm-2">{t("codici studente con suffisso iniziale")}</Label>
          <Input onChange={(e) => { setCurrentFilename(e.target.value) }} type="text"
            name="filenameInput" id="fileNameInput" placeholder={`${t("esempio")}: ${t("Scuola_01")}`} />
        </FormGroup>
        <FormGroup className="mb-2 mr-sm-2 mb-sm-0" check>
          <Input type="checkbox" onClick = {(event:any) => { 
            setPasscodeWithRecording(event.target.checked);
            }}/>
          {' '}
          <Label check>
            {"con acquisizione schermo"}
          </Label>
        </FormGroup>
        <Button color="primary" onClick={() => { generatePassCodesFile() }} >{t("Genera")}</Button>
      </Form>
    )
  }


  const renderSessionViewerModal = () => {

    return (currentAnalyticsSession && currentClickedRow && <Modal isOpen={currentAnalyticsSession != null}>
      <ModalHeader style={{
        display: "flex",
        justifyContent: "center",
      }}><b>Sessione:</b>{` ${currentClickedRow["ulid"]}`} <br></br>
        <b>Utente:</b>{` ${currentClickedRow["passcode"]}`}
      </ModalHeader>

      <ModalBody>
        <ReactJson style={{ padding: "10px" }} theme="monokai" displayDataTypes={false} collapsed={3}
          src={currentAnalyticsSession} />
      </ModalBody>
      <ModalFooter style={{
        display: "flex",
        justifyContent: "right",
      }}>



        <Button color="primary" onClick={() => {
          setCurrentClickedRow(null);
          setCurrentAnalyticsSession(null);
        }}>Chiudi</Button>

      </ModalFooter>
    </Modal>)
  }

  const paginationOptions = {
    sizePerPageList: [{
      text: `5 ${t("sessioni")}`, value: 5
    }, {
      text: `10 ${t("sessioni")}`, value: 10
    },
    {
      text: t('Tutte le sessioni'), value: (analyticsSessionsRows ? analyticsSessionsRows.length : 100)
    }
    ], pageStartIndex: 1
  }

  const creationDateFormatter = (cell: any, row: any) => {
    return moment(cell).format("YYYY/MM/DD - HH:mm")
  }

  const actionsFormatter = (cell: any, value: any) => {
    //console.log("Passo value:", value);
    return (
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>

        <ButtonToolbar>
          <ButtonGroup>
            <GrView data-for="analyticsSessions" data-tip={t("Dettagli sessione")} cursor="pointer"
              style={{ margin: "10px" }} color="#007bff" onClick={() => {
                //console.log("JSON BUTTON CLICKED:", value);
                setCurrentClickedRow(value);
                setCurrentAnalyticsSession(JSON.parse(value["content"]))
              }}>Vedi dettagli
        </GrView>

            <FaDownload data-for="analyticsSessions" data-tip={t("Scarica sessione")} cursor="pointer"
              style={{ margin: "10px" }} color="#007bff" onClick={() => {
                generateSessionFile(value);
              }}>Scarica Sessione in formato JSON
        </FaDownload>

          </ButtonGroup>

        </ButtonToolbar>
        <ReactTooltip id="analyticsSessions" />
      </div>

    )
  }

  const analyticsSessionsColumns = [
    {
      dataField: 'ulid',
      text: t("ULID di sessione"),
      headerStyle: () => {
        return { width: '23%' };
      },
      sort: true
    },
    {
      dataField: 'recording_id',
      text: t("recording_id"),
      headerStyle: () => {
        return { width: '23%' };
      },
      sort: true
    },

    {
      dataField: 'expected_recordings',
      text: t("# video"),
      headerStyle: () => {
        return { width: '10%' };
      },
      sort: true
    },

    {
      dataField: 'group',
      text: t("Gruppo"),
      sort: true
    },
    {
      dataField: 'passcode',
      text: t("Codice Studente"),
      sort: true
    },
    {
      dataField: 'timestamp',
      text: t("Data di creazione"),
      formatter: creationDateFormatter,
      sort: true
    },
    {
      dataField: 'content',
      text: t('Azioni'),
      formatter: actionsFormatter,
      headerStyle: () => {
        return { width: '8%' };
      },
      headerAlign: 'center'
    }
  ]

  const convertArrayToObject = (array: any, key: any) => {
    const initialValue = {}
    return array.reduce((obj: any, item: any) => {
      return { ...obj, [item[key]]: item, }
    }, initialValue);
  }

  useEffect(() => {
    //console.log("Sono nel costruttore: Carico TUTTE LE ANALYTICS SESSIONS");
    dispatch(AnalyticsSessionsActions.willLoadAnalyticsSessions("TEST_01"));
  }, [])

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    onSelect: (row: any, isSelect: any, rowIndex: any, e: any) => {
      //console.log("Select row", row);

      setCurrentSelectedRows((prevState: any) => ({
        ...prevState,
        [row.ulid]: (isSelect ? row : null)
      }));

      //console.log("(select row) Current Selected rows", currentSelectedRows);
    },
    onSelectAll: (isSelect: any, rows: any, e: any) => {
      //console.log(isSelect);
      //console.log(rows);
      let newSelectedRows = isSelect ? convertArrayToObject(rows, "ulid") : {}
      setCurrentSelectedRows(newSelectedRows);
      //console.log("(select all) Current Selected rows", currentSelectedRows);
    }
  } as any;

  return <div>
    {renderSessionViewerModal()}
    <Card className="mb-4" style={{ padding: "10px", borderColor: "#007bff" }}>
      <CardHeader style={{ backgroundColor: "#007bff", borderColor: "#007bff", paddingBottom: 0, color: 'white' }}>
        <CardTitle tag="h5">{t("Elenco delle sessioni degli studenti")}
          {analyticsSessionsRows && `   (${t("Totale")}:${analyticsSessionsRows.length})`}
          <IconButton
            onClick={() => { exportSelectedSessionAsZipFile() }}
            style={{ float: 'right', marginTop: '-10px', marginRight: '-10px' }}
          >
            <IconContext.Provider value={{ color: "white", className: "global-class-name" }}>
              <GoFileZip color="white" data-place="top"
                data-for="analyticsSessions"
                data-tip={t("Esporta selezionati in formato ZIP")}
              />
            </IconContext.Provider>
          </IconButton>
        </CardTitle>
      </CardHeader>
      <CardBody>
        {
          analyticsSessionsRows != null ?
            <BootstrapTable bootstrap4 keyField='ulid' data={analyticsSessionsRows || []}
              columns={analyticsSessionsColumns} selectRow={selectRow}
              pagination={paginationFactory(paginationOptions)} />
            :
            <Alert>{t("Caricamento sessioni in corso")}</Alert>
        }

      </CardBody>
      <CardFooter>
        {renderPasscodesGeneratorForm()}
      </CardFooter>



    </Card>

  </div>


}

