import  React, {  useEffect, useState, useRef, ForwardRefRenderFunction, forwardRef } from 'react';
import { VncScreen } from 'react-vnc';
import RFB from 'react-vnc/dist/types/noVNC/core/rfb';
import { useDispatch, useSelector } from 'react-redux';
import { selectors as CatalogSelector, actions as CatalogActions } from '../store/slices/catalog'
import { actions as ProfileActions, selectors as ProfileSelectors } from '../store/slices/profile'
import { actions as AppointmentActions, selectors as AppointmentsSelectors } from '../store/slices/appointments'
import { actions as ExperimentsActions, selectors as ExperimentsSelectors } from '../store/slices/experiments'
import { useParams } from 'react-router-dom'
import { Button } from 'reactstrap';
//https://github.com/roerohan/react-vnc/blob/HEAD/src/lib/VncScreen.tsx
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import {
  Container, Row, Col, Card, CardText, CardBody, CardLink,
  Modal, ModalHeader, ModalBody, ModalFooter,
  CardHeader, CardFooter, CardTitle, CardSubtitle, Badge, Input, ButtonToolbar
} from 'reactstrap';

import LanguageSelector from '../components/LanguageSelector';
import Countdown from 'react-countdown';
import moment from 'moment';
import { useTranslation } from 'react-i18next';


import { FaEye, FaEyeSlash } from 'react-icons/fa';
import FileGenerator from '../components/ISessionWorkExporter';
import './Sidebar.css'; // Importa il file CSS
import Sidebar from 'react-sidebar';
import { IconLookup } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import hash from "object-hash";

import SplitterLayout from 'react-splitter-layout';
//import 'react-splitter-layout/lib/index.css';
import './splitterHandle.css'


import MdReportEditor from '../components/MdReportEditor';

const DATE_TIME_FORMAT = "DD/MM/YYYY HH:mm";

const ConnectionState = {
  IDLE: "Idle",
  CONNECTING: "Connecting",
  CONNECTED: "Connected",
  DISCONNECTED: "Disconnected",
  CREDENTIALS_REQUIRED: "Credentials required",
  SECURITY_FAILURE: "Security Failure"
}

interface ImageCaptionModalProps {
  isOpen: boolean;
  toggleModal: () => void;
  imageSrc: string;
  imageCaption: string;
  onScreenshotConfirmed: (screenshot: any, downloadConfirmed: boolean) => void;
  onScreenshotDeleted: (index: number) => void;
  index: number;
}

interface UploadRequestModalProps {
  isOpen: boolean,
  closeModal: () => void,
  fileGeneratorRef: any,
  filename: string,
  author: string
}

const UploadRequestModal = (props: UploadRequestModalProps) => {

  const { t } = useTranslation('frontend', { useSuspense: false });

  const handleButtonClick = (action: any) => {
    // Esegui azioni in base al pulsante cliccato
    if (action === 'yes') {

      if (props.fileGeneratorRef.current != null) {
        console.log("URD:Procedo ad uploadare il report");
        props.fileGeneratorRef.current.generateFile(props.filename)
      }
      else {
        console.log("URD:Impossibile uploadare il report causa ref nullo");
      }
    }
    props.closeModal(); // Chiude la finestra modale
  };

  return (
    <div>
      <Modal isOpen={props.isOpen}>
        <ModalHeader>{t("Modifiche non salvate")}</ModalHeader>
        <ModalBody>
          {t("reportNotUploadedAlert")}
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={() => handleButtonClick('yes')}>Si</Button>
          <Button color="danger" onClick={() => handleButtonClick('no')}>No</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}


const ScreenshotPreviewModal: React.FC<ImageCaptionModalProps> = ({ isOpen, toggleModal,
  imageSrc, imageCaption, index,
  onScreenshotConfirmed, onScreenshotDeleted }) => {
  const [caption, setCaption] = useState<string>(imageCaption || '');
  const { t } = useTranslation('frontend', { useSuspense: false });
  const [downloadScreenshot, setDownloadScreenshot] = useState(false)

  const handleDelete = () => {
    onScreenshotDeleted(index);
  }

  // Funzione chiamata quando viene confermata la didascalia
  const handleConfirm = () => {
    // Qui puoi gestire la logica per salvare la didascalia
    ////console.log('Didascalia confermata:', caption);
    const screenshotObj = { "src": imageSrc, "caption": caption, "creation_time": moment() }
    onScreenshotConfirmed(screenshotObj, downloadScreenshot);
  };

  // Funzione chiamata quando viene chiusa la modal senza confermare
  const handleCancel = () => {
    toggleModal();
  };

  useEffect(() => {
    console.log("Cambiata imageCaption:", imageCaption);
    if (imageCaption != null)
      setCaption(imageCaption);
    else setCaption("");
  }, [imageCaption])

  return (
    <Modal isOpen={isOpen} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>{`Screenshot n.${index + 1}`}</ModalHeader>
      <ModalBody>
        {/* Immagine */}
        <img src={imageSrc} alt="Immagine" style={{ width: '100%', maxHeight: '400px', objectFit: 'contain' }} />

        {/* Casella di testo per la didascalia */}
        <Input
          type="textarea"
          placeholder="Inserisci una didascalia..."
          value={caption}
          onChange={(e) => setCaption(e.target.value)}
          style={{ marginTop: '10px' }}
        />
      </ModalBody>
      <ModalFooter style={{
        marginLeft: "25px", display: "flex", flexDirection: "row",
        alignContent: "flex-start",
        justifyContent: "space-between"
      }}>

        <div><Input checked={downloadScreenshot} type="checkbox"
          onChange={(event: any) => {
            ////console.log("Modificato donwloadScreenshot");
            setDownloadScreenshot(event.target.checked);
          }} />{`${t("Scarica")}`}</div>

        <div style={{
          margin: "10px", display: "flex", flexDirection: "row",
          alignContent: "flex-start",
          justifyContent: "space-between"
        }}>
          {/* Pulsante di conferma */}
          <Button style={{ marginRight: "10px" }} color="primary" onClick={handleConfirm}>
            {t("Salva")}
          </Button>
          {/* Pulsante di chiusura */}

          <Button color="danger" style={{ marginRight: "10px" }} onClick={handleDelete}>
            {t("Elimina")}
          </Button>

          <Button color="secondary" style={{ marginRight: "10px" }} onClick={handleCancel}>
            {t("Annulla")}
          </Button>


        </div>



      </ModalFooter>
    </Modal>
  );
};


const ISessionCountDown = (props: any) => {
  const [isessionReady, setIsessionReady] = useState(false);
  const [isessionStarted, setISessionStarted] = useState(false);
  const [isessionExpired, setISessionExpired] = useState(false);
  const isession = useSelector(AppointmentsSelectors.getInteractiveSessionById(props.id));
  const { t, i18n } = useTranslation('frontend', { useSuspense: false });

  const formatNumber = (number:any) => {
    return number < 10 ? `0${number}` : number;
  };

  const formatLabel = (showLabel:boolean) => {
    if (!showLabel) return ""
    else return `${t("Tempo rimanente")}:  `
  }

  const timeRenderer = (days:number,hours:number,minutes:number, seconds:number) =>
  { 
    const backgroundColor = "#007bff";
    let textColor = (days<1 && hours<1 && minutes<5) ? "orange" : "white"
    const trStyle = {color: textColor,backgroundColor, padding:"10px"}
   if (days<1 && hours<1) return  (<span style={trStyle} >{formatLabel(props.showLabel)}{formatNumber(minutes)}:{formatNumber(seconds)}</span>)
   else if (hours<1) return  (<span style={trStyle}>{formatLabel(props.showLabel)}{formatNumber(hours)}:{formatNumber(minutes)}:{formatNumber(seconds)}</span>)
   else 
   return (<span style={trStyle}>{formatLabel(props.showLabel)}{formatNumber(days)}:{formatNumber(hours)}:{formatNumber(minutes)}:{formatNumber(seconds)}</span>)
  
  }
  


  useEffect(() => {
    if (isession != null) {
      setISessionStarted(moment(isession.startDate).isBefore(moment()));
      if (props.onLoadedSession) { props.onLoadedSession(isession) }
    }
  }, [isession])




  useEffect(() => {

    if (isessionStarted && props.onStartedSession) {
      ////console.log("Session started");
      props.onStartedSession();
    }
    if (isession != null) setIsessionReady(true);

  }, [isession, isessionStarted])


  useEffect(() => {
    if (isessionExpired && props.onExpiredSession) {
      ////console.log("Session expired");
      props.onExpiredSession();
    }
  }, [isessionExpired])

  if (!isessionReady) return null;
  else
    return (
      <div>
        {
          !isessionStarted ?
            <Countdown key={"cstart"} date={isession.startDate}
            renderer={({ days, hours, minutes, seconds, completed })=>{
              return timeRenderer(days,hours,minutes,seconds)
              }}
              onTick={(tick: any) => { }}
              onComplete={(tick: any, completed: boolean) => {
                {
                  //console.log("Completato countDown di startDate");
                  setTimeout(() => { setISessionStarted(true); }, 1000);
                }
              }}
            />
            : <Countdown key={"cstop"} date={isession.stopDate}
              renderer={({ days, hours, minutes, seconds, completed })=>{
                  return timeRenderer(days,hours,minutes,seconds)
              }}
              onTick={(tick: any) => { }}
              onStart={(tick: any) => {
                //console.log("onStart di Stop date:"); 
              }}
              onComplete={(tick: any, completed: boolean) => {
                {
                  //console.log(`Completato countDown di stopDate: completed:${completed}`);
                  setISessionExpired(true);
                }
              }}
            />
        }
      </div>)
}



export const InteractiveSessionPage = () => {

  const { organization, interactive_session_id, experiment_id, device_id }: any = useParams();
  const [containerWidth, setContainerWidth] = useState(0);
  const containerRef = useRef(null as any);
  const connectionParams = useSelector(ExperimentsSelectors.getCurrentIDeviceParams)
  const experiments = useSelector(CatalogSelector.getExperiments);
  const isDirector = useSelector(ProfileSelectors.isDirector);
  const userAttributes = useSelector(ProfileSelectors.getProfile)
  const dispatch = useDispatch();
  const ref = useRef(null) as any;
  const reconnectionTimeoutRequest = useRef("") as any;
  const [isessionExperiment, setISessionExperiment] = useState(null) as any;
  const [isessionDevice, setISessionDevice] = useState(null);
  const [isession, setISession] = useState(null) as any;
  const [isessionExpired, setISessionExpired] = useState(false);
  const [isessionStarted, setISessionStarted] = useState(false);
  const [forceConnection, setForceConnection] = useState(false);
  const reportUploaded = useRef(false);
  const reportStorageHash = useRef("");
  const reportUploadedHash = useRef("");
  const [isUploadrequestModalOpen, setUploadRequestModalOpen] = useState(false)

  const { t, i18n } = useTranslation('frontend', { useSuspense: false });
  const [screenshots, setScreenshots] = useState([]) as any;
  const [comments, setComments] = useState([]) as any;
  const [lastUploadDatetime, setLastUploadedDatetime] = useState(t("mai spedito") as any)
  const sheetData = useRef({}) as any;
  const fileGeneratorRef = useRef() as any;

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [currentScreenshot, setCurrentScreenshot] = useState(null) as any;
  const [currentScreenshotIndex, setCurrentScreenshotIndex] = useState(screenshots.length) as any;
  const [panelWidthPercentage, setPanelWidthPercentage] = useState(50)
  const [vncPanelWidthPercentage, setVncPanelWidthPercentage] = useState(50)
  const [isSplitterDragging, setSplitterDragging] = useState(false);
  const onSetSidebarOpen = (open: boolean) => {
    setSidebarOpen(open);
  };
  const [downloadScreenshot, setDownloadScreenshot] = useState(false)
  const homeworkUrl = useSelector(ExperimentsSelectors.getUploadedResourceUrl)

  const updateHomeworkUrlInSession = () => {
    let payload = { ...isession };
    payload["homeworkUrl"] = homeworkUrl;
    console.log("isession da salvare (payload) con homework::", payload);
    dispatch(AppointmentActions.willPutHomeworkUrlInRemoteSession(payload));
  }

  const handleDragEnd = () => {
    // Quando la barra dello splitter viene rilasciata dopo il trascinamento,
    // aggiorna la larghezza del pane principale
    if (containerRef.current) {
      setContainerWidth(containerRef.current.clientWidth);
    }
  };

  useEffect(() => {
    if (containerRef.current) {
      console.log("Richiamato useEffect per containerRef.current:", containerRef.current.clientWidth);
      setContainerWidth(containerRef.current.clientWidth);
    }
  }, [containerRef.current])

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        console.log("Richiamato handleresize:", containerRef.current.clientWidth);
        setContainerWidth(containerRef.current.clientWidth);
      }
    };

    const handleWindowClose = (event:any) => {

      if (reportUploaded.current == false)
      {
          // Personalizza il messaggio di conferma
          const confirmationMessage = t("reportNotUploadedCloseWindowAlert");

          if (typeof event == 'undefined') {
              event = window.event;
          }       
          if (event) {
              event.returnValue = confirmationMessage;
          }
          return confirmationMessage;
      }
     
    };


    const handleOpenLink = (event: any) => {
      // Verifica se l'elemento cliccato è un link
      if (event.target.tagName === 'A') {
        // Impedisci al link di navigare normalmente
        event.preventDefault();
        // Apri il link in una nuova finestra
        window.open(event.target.href, '_blank');
      }
    }

     // Aggiungi un listener per l'evento beforeunload
     window.addEventListener('beforeunload', handleWindowClose);

    // Aggiungi un listener per il ridimensionamento della finestra
    window.addEventListener('resize', handleResize);
    // Listener per aprire tutti i collegamenti ipertestuali in una nuova finestra
    window.addEventListener('click', handleOpenLink);

    // Chiamalo una volta per inizializzare la larghezza
    //handleResize();

    // Rimuovi il listener quando il componente viene smontato
    return () => {
      window.removeEventListener('beforeunload', handleWindowClose);
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('click', handleOpenLink);
    }
  }, []);

  useEffect(() => {

    //console.log("HOMEWORK URL:", homeworkUrl);
    console.log("SESSION:", isession);
    if (isession != null && homeworkUrl != null) updateHomeworkUrlInSession();
  }, [homeworkUrl])

  useEffect(() => {
    const payload: any = {
      "organization": organization,
      "session": interactive_session_id
    }
    dispatch(ExperimentsActions.willGetIDeviceConnectionParams(payload))

  }, [])


  useEffect(() => {

    if (experiments != null) {

      const experiment = experiments[experiment_id];
      setISessionExperiment(experiment)
      if (experiment != null) {
        const device = experiment["interactive_devices"]?.find((exper: any) => exper["id"] == device_id)
        setISessionDevice(device)
        ////console.log(`experiment:`, experiment);
        ////console.log("device", device);
      }

    }
    else {
      setISessionExperiment(null);
      setISessionDevice(null);
    }


  }, [experiments, experiment_id, device_id])

  useEffect(() => {
    if (isessionStarted == true) { setSidebarOpen(true) }
  }, [isessionStarted])

  useEffect(() => {
    if (isessionExpired == true) {
      if (reportUploaded.current == false && !isDirector) {
        console.log("URD: SESSIONE PRATICA TERMINATA: RICHIESTA INVIO AUTOMATICO REPORT:", filename);
        setUploadRequestModalOpen(true)
      }

      setSidebarOpen(false);
    }
  }, [isessionExpired])

 
  const renderRemoteDevice = () => {
    return (connectionParams && (
      <div style={{ display: "flex" }}>
        <div style={{}} id="vncContent">  
        {/*<MemoVncScreen connectionParams={connectionParams} ref={ref} /> */}
        <VncScreen
            debug
            retryDuration={10000} // 10 secondi
            url={connectionParams?.wsUrl}
            loadingUI={<span>Caricamento in corso...</span>}
            rfbOptions={{
              "credentials":
                { "password": connectionParams?.password }
            } as any}
            onCredentialsRequired={(rfb?: RFB) => {
              console.log("IS:  connection error: Credenziali richieste:", rfb);
              
            }}
            onClipboard={(e: any) => {
              if (e.detail?.text != null)
                navigator.clipboard.writeText(e.detail.text);
            }}
           

            onSecurityFailure={(e: any) => {
              console.log("IS: ISession connection error: Security Failure:", e.reason);
            }}

            scaleViewport
            background="#000000"
            style={{
              width: `${sidebarOpen ? (100 - vncPanelWidthPercentage) : 75}vw`,
              height: `${sidebarOpen ? (100 - vncPanelWidthPercentage) * 3 / 4 : 75 * 3 / 4}vw`,
            }}
            ref={ref as any}
          />

        </div>

      </div>

    )
    )
  }

  const isReportAvailable = () => {
    if (isession == null) return false;
    return (forceConnection || (isessionStarted && !isessionExpired))
  }

  const renderRemoteDeviceConditionally = () => {
    if (isession == null) return "loading session...";
    if (forceConnection) return renderRemoteDevice();
    if (!isessionStarted && !isessionExpired)
      return (<p> {`${t("La sessione inizierà alle ore")}`}{` ${moment(isession.startDate).format(DATE_TIME_FORMAT)}`}</p>)
    if (isessionExpired) return <p>{t("Sessione terminata")}</p>
    return renderRemoteDevice();
  }

  const downloadScreenshotOnPC = (screenshot: any) => {
    //console.log("downloadScreenshotOnPC:", screenshot);
    // crea una immagine in base64 da fornire a Figure.Image
    const canvasImage = new Image();
    canvasImage.src = screenshot.src;
    // Crea un elemento <a> per il download dell'immagine
    const link = document.createElement('a');
    link.href = screenshot.src;
    link.download = 'screenshot_NEW.png';
    // Aggiungi l'elemento <a> al DOM e simula un clic
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

  }
  const filename = `isession_${isession?.id || "XXX"}`;



  const handleEdit = (index: number) => {
    // Logica per la modifica dello screenshot
    console.log("Edit screenshot at index", index);
    setCurrentScreenshotIndex(index);
    setCurrentScreenshot(screenshots[index]);
  }

  const handleScreenshotCaptured = (capturedScreenshot: any, index: number) => {
    // Logica per la modifica dello screenshot
    console.log("Edit screenshot at index", index);
    setCurrentScreenshotIndex(index);
    setCurrentScreenshot(capturedScreenshot);
  }

  const handleCommentChanged = (comment: any, index: number) => {
    // Logica per la modifica del commento
    console.log("Edit comment at index", index);
    let newComments = [...comments]
    newComments[index] = comment;
    // n.b devo salvare nel localstorage, NON in setComments!
    setComments(newComments);
    saveReportToLocalStorage(null, newComments, null, null, "Commenti", false)
  }

  const handleFileUploaded = (sendDateTime: any) => {
    if (sendDateTime != null)
      setLastUploadedDatetime(sendDateTime)
    saveReportToLocalStorage(null, null, null, sendDateTime, "lastUpdloadDatetime", true)

  }


  const handleRemove = (index: number) => {
    if (index >= 0 && index < screenshots.length) {
      const newScreenshots = [...screenshots] as any; // Creiamo una copia dell'array originale
      //newScreenshots.splice(index, 1); // Rimuoviamo lo screenshot alla posizione specificata
      newScreenshots[index] = null;
      setScreenshots(newScreenshots); // Aggiorniamo lo stato con il nuovo array di screenshots
      saveReportToLocalStorage(null, null, newScreenshots, null, "screenshots", false);
    }
    setCurrentScreenshot(null);

  }

  const saveReportToLocalStorage = (newSheetData: any,
    newComments: any,
    newScreenshots: any,
    newLastUploadDatetime: any, from: any, uploadedVersion: boolean) => {

    if (isession == null) return;
    //console.log("Richiamato saveReportToLocalStorage con uploadedVersion:", uploadedVersion);
    const REPORT_KEY = `REPORT_ISESSION_${isession["id"]}`
    const reportContent = {
      "uploaded": uploadedVersion, // || JSON.parse(localStorage.getItem(REPORT_KEY) as any)["uploaded"] ,
      "screenshots": newScreenshots || screenshots,
      "worksheet": newSheetData || JSON.parse(localStorage.getItem(REPORT_KEY) as any)["worksheet"] || [],
      "comments": newComments || comments,
      "lastUploadDatetime": newLastUploadDatetime || lastUploadDatetime ||
        JSON.parse(localStorage.getItem(REPORT_KEY) as any)["lastUploadDatetime"] || null
    }

    console.log(`Richiamato saveReportToLocalStorage con sheetdata non nullo? ${newSheetData != null} da ${from} WS[0]:`, reportContent["worksheet"][0]["data"][1]);

    // console.log("Modificato sheetData da localStorage:", reportContent);
    localStorage.setItem(REPORT_KEY, JSON.stringify(reportContent))

    // hash del report per rilevare eventuali modifiche in locale non uploadate
    //const newHash = hash(reportContent)
    //reportStorageHash.current = newHash;
    //if (uploadRequest) reportUploadedHash.current =newHash;
    reportUploaded.current = uploadedVersion
    fileGeneratorRef.current && fileGeneratorRef.current.isVersionUploaded(uploadedVersion)
  }




  const handleChangeSheetData = (newSheetData: any, sheetId: any) => {
    // console.log("Modificato sheetData da InteractiveSessionPage:", newSheetData, isession);
    console.log("sheet data:", sheetData.current);
    let currentSheetData = { ...sheetData.current }
    currentSheetData[sheetId] = newSheetData
    // qui non va aggiornato il valore di currentSheetData! 
    sheetData.current = currentSheetData;
    console.log("Commenti:", comments);
    // provoca un blocco dello sheetEditor e dà una eccezione di null su getActiveEditor()
    saveReportToLocalStorage(currentSheetData, null, null, null, "sheetData", false);
  }

  const isessionCountDownSingleInstance = <ISessionCountDown id={interactive_session_id} 
  
      showLabel
      onLoadedSession={(isession: any) => {
        if (isession != null) {
          const sessionLocalStorage: any = localStorage.getItem(`REPORT_ISESSION_${isession["id"]}`)
          if (sessionLocalStorage) {
            //console.log("SessionLocalStorage to be parsed:", sessionLocalStorage);
            const parsedSessionLocalStorage = JSON.parse(sessionLocalStorage);

            // aggiorno l'hash 
          //reportStorageHash.current = hash(parsedSessionLocalStorage);
          const isUploadedVersion = parsedSessionLocalStorage["uploaded"] == true
          reportUploaded.current = isUploadedVersion
          fileGeneratorRef.current && fileGeneratorRef.current.isVersionUploaded(isUploadedVersion)
          //console.log("parsedSessionLocalStorage:", parsedSessionLocalStorage);
          const localScreenshots = parsedSessionLocalStorage["screenshots"]
          if (localScreenshots != null) setScreenshots(localScreenshots);
          const worksheet = parsedSessionLocalStorage["worksheet"]
          if (worksheet != null) {
            sheetData.current = worksheet;
          }
          const newComments = parsedSessionLocalStorage["comments"]
          if (newComments != null) {
            setComments(newComments);
          }
          const newLastUploadDatetime = parsedSessionLocalStorage["lastUploadDatetime"]
          if (newLastUploadDatetime != null) { setLastUploadedDatetime(newLastUploadDatetime) }

        }

      }

      setISession(isession)
    }}
    onStartedSession={() => { setISessionStarted(true); }}
    onExpiredSession={() => {
      setISessionExpired(true);
      //console.log("sessionExpired");
    }}
  />;

  return (
    <Container fluid>
      <LanguageSelector hidden />
      <UploadRequestModal
        isOpen={isUploadrequestModalOpen}
        closeModal={() => { setUploadRequestModalOpen(false) }}
        fileGeneratorRef={fileGeneratorRef}
        filename={filename}
        author = {userAttributes ? `${userAttributes.given_name} ${userAttributes.family_name}` : "N/A"}
        />

      <ScreenshotPreviewModal isOpen={currentScreenshot != null}
        toggleModal={() => {
          setCurrentScreenshot(null);
        }}
        imageSrc={currentScreenshot?.src}
        imageCaption={currentScreenshot?.caption}
        index={currentScreenshotIndex}
        onScreenshotDeleted={(index: number) => { handleRemove(index) }}
        onScreenshotConfirmed={function (screenshot: any, download: boolean): void {
          const newScreenshots = [...screenshots];
          // aggiunta dello screenshot all'indice specificato 
          // (se l'indice esiste già lo screenshot viene sovrascritto)
          newScreenshots[currentScreenshotIndex] = screenshot
          setScreenshots(newScreenshots);
          setDownloadScreenshot(download);
          if (download == true) downloadScreenshotOnPC(screenshot);
          setCurrentScreenshot(null);
          saveReportToLocalStorage(null, null, newScreenshots, null, "screenshots", false);
        }} />
      <Row>
        <Col
          className={`content`}
          onClick={() => onSetSidebarOpen(false)}>
          <Card className={`mb-4 w-100`} style={{
            borderColor: "#007bff", width: "100%"
          }}>
            <CardHeader style={{
              backgroundColor: "#007bff",
              borderColor: "#007bff",
              paddingBottom: 10,
              color: 'white'

            }}>
              <CardTitle tag="h5">{isessionDevice &&
                `${isessionDevice["titolo"]}`}
              </CardTitle>
              <CardSubtitle style={{ marginLeft: "2px" }} tag="h6">{isessionExperiment && isessionExperiment["titolo"]}</CardSubtitle>
            </CardHeader>
            <CardBody>
              <Row>
                <Col style={{ display: "flex", justifyContent: "center" }}>
                  {
                    renderRemoteDeviceConditionally()
                  }
                </Col>
              </Row>
            </CardBody>
            {isDirector &&

              <CardFooter>
                {
                  isession != null &&
                  <div style={{ display: "flex", alignContent: "flex-start", justifyContent: "space-between" }}>
                    {
                      (!isessionExpired) && (
                        <div style={{ display: "flex", marginLeft: "10px" }}>
                          {isessionCountDownSingleInstance}
                        </div>
                      )
                    }
                    {isDirector && <span><Input type="checkbox" onClick={(event: any) => {
                      setForceConnection(event.target.checked);
                    }} />{`${t("Forza collegamento")}`}</span>}
                    <span>{`${t("Inizio")}: ${moment(isession.startDate).format(DATE_TIME_FORMAT)}`}</span>
                    <span>{`${t("Fine")}: ${moment(isession.stopDate).format(DATE_TIME_FORMAT)}`}</span>
                  </div>
                }
              </CardFooter>
            }

          </Card>
        </Col>
        <Sidebar sidebarClassName='sidebar'
          pullRight={true}
          open={sidebarOpen}
          onSetOpen={onSetSidebarOpen}
          sidebar={
            <div style={{ marginRight: "0px", marginLeft: "10px" }}>

              <CardHeader style={{
                backgroundColor: "#007bff",
                borderColor: "#007bff",
                padding: 10,
                color: 'white',
                display: 'flex',

                // justifyContent: 'center', // Centrare verticalmente
                alignItems: 'center', // Centrare orizzontalmente
              }}>

                <div style={{ textAlign: 'left' }}> {/* Rimane allineato a sinistra */}
                  <CardTitle tag="h5">{isessionDevice &&
                    `${isessionDevice["titolo"]}`}
                  </CardTitle>
                  <CardSubtitle style={{ marginLeft: "2px" }} tag="h6">{isessionExperiment && isessionExperiment["titolo"]}</CardSubtitle>

                </div>
              </CardHeader>


              <SplitterLayout percentage
                onDragStart={() => { setSplitterDragging(true) }}
                onDragEnd={() => { setVncPanelWidthPercentage(panelWidthPercentage); setSplitterDragging(false) }}
                onSecondaryPaneSizeChange={(newSize) => {
                  console.log("New Panel Size:", newSize);


                  setPanelWidthPercentage(newSize)
                }}
                secondaryInitialSize={panelWidthPercentage} 
                primaryMinSize={5} 
                secondaryMinSize={5}>

                <div ref={containerRef} style={{ display: 'block', width: '100%', paddingTop: "10px", marginRight: "50px" }}>
                  {
                    isSplitterDragging ? <span>loading...</span> :

                      <div style={{
                        display: "flex", flexDirection: "column",
                        justifyContent: "center"
                      }}>

                        {renderRemoteDeviceConditionally()}
                      </div>

                  }
                </div>
                <div >
                  <Card>
                    <CardBody style={{ maxHeight: '85vh', overflowY: 'auto' }} >
                      {isessionDevice &&
                        <MdReportEditor key={"md_report_editor"}
                          isession={isession}
                          isessionDevice={isessionDevice}
                          screenshots={screenshots}
                          comments={comments}
                          lastUploadDatetime={lastUploadDatetime}
                          title={(isessionDevice && `${isessionDevice["titolo"]}`) as any}
                          handleEdit={handleEdit}
                          handleRemove={handleRemove}
                          handleChangeSheetData={handleChangeSheetData}
                          onScreenshotCaptured={handleScreenshotCaptured}
                          onCommentChanged={handleCommentChanged}
                          onFileUploaded={handleFileUploaded}
                        />
                      }

                    </CardBody>
                    <CardFooter>
                      <FileGenerator
                        ref={fileGeneratorRef}
                        saveLocally={isDirector}
                        contentContainerId={'md_report'}
                        filename={filename}
                        author={userAttributes ? `${userAttributes.given_name} ${userAttributes.family_name}` : "N/A"}
                        lastUploadDatetime={lastUploadDatetime}
                        onFileUploaded={handleFileUploaded}
                      />
                    </CardFooter>
                  </Card>
                </div>

              </SplitterLayout>

            </div>
          }
        >
          <div>

            {isReportAvailable() ?
              <>
                <span className="sidebar-remaining-time"
                  style={{  fontSize: "18px" }}>
                  {isessionCountDownSingleInstance}</span>
                <div style={{ display: "flex", justifyContent: "flex-start" }}>

                  <Button style={{ background: "#FFFFFFFF", color: "#123456" }}
                    onClick={() => onSetSidebarOpen(!sidebarOpen)}
                    className="sidebar-button"
                  ><span style={{ marginRight: "5px" }}>{sidebarOpen ? t("nascondi Report") : t("mostra Report")}</span>
                    {sidebarOpen ? <FaEyeSlash cursor="pointer"
                      style={{ fontSize: 24, border: '1px solid #007bff"', }} color="#007bff" /> :
                      <FaEye cursor="pointer"
                        style={{ fontSize: 24, border: '1px solid #007bff"', }} color="#007bff" />}
                  </Button>

                  <Button className="help-button"
                    href={(isessionDevice as any)["attachments"]["guide"]}
                    target={"_blank"}
                    download
                    style={{ background: "#007bff" }}>
                    <FontAwesomeIcon
                      icon={faQuestionCircle as IconLookup}
                      style={{ fontSize: 20 }}
                      color="white"
                    />
                  </Button>

                </div>

              </> :
              <span className="sidebar-remaining-time"
                style={{ display: "flex", fontSize: "18px", color: "#FFFFFFFF" }}>
                {isessionCountDownSingleInstance}</span>
            }
          </div>


        </Sidebar>
      </Row>
    </Container>
  )

}


