
import { selectors as UiSelector, actions as UIAction } from '../store/slices/ui'
import { useState, useRef, useEffect } from 'react';
import { SideBar } from '../components/SideBar'
import SplitterLayout from 'react-splitter-layout';
import { useSelector, useDispatch } from "react-redux";
import { selectors as AuthSelectors } from '../store/slices/auth'
import { AIChatbot } from './AIAssistant/WebSocketClient';
import PdfViewer from './PdfViewer';
import { rialeGuideUrl } from "../config"; //"it-IT" 
import {
  Card, CardBody,
  CardHeader, CardTitle, CardFooter
} from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { GiCancel } from "react-icons/gi";

export const Content = ({ children, className, active, section, experimentId }: any) => {
  const dispatch = useDispatch();
  const isLogged = useSelector(AuthSelectors.isLogged)
  const isMenuOpen = useSelector(UiSelector.isMenuOpen);
  const isAssistantOpen = useSelector(UiSelector.isAssistantOpen);
  const isGuideOpen = useSelector(UiSelector.isGuideOpen);
  const guidePageNumber = useSelector(UiSelector.getGuidePageNumber);
  const toggleMenuOpen = () => { dispatch(UIAction.toggleMenuOpen()) }
  const toggleGuideOpen = () => { dispatch(UIAction.toggleGuideOpen()) }
  const [panePercent, setPanePercent] = useState(30);
  const containerRef = useRef(null);  // Crea un ref per il div contenitore
  const [containerWidth, setContainerWidth] = useState(0);
  const { t, i18n } = useTranslation('frontend', { useSuspense: false });


  useEffect(() => {
    setTimeout(()=>{
      if (containerRef.current) {
        // Calcola la larghezza corrente del div
        const width = (containerRef.current as any).getBoundingClientRect().width;
        console.log("Content width:", width);
        setContainerWidth(width);
      }
      else {console.log("Content width: n.a")}
    }, 0)
  }, []);

  useEffect(() => {
    setTimeout(()=>{
      if (containerRef.current) {
        // Calcola la larghezza corrente del div
        const width = (containerRef.current as any).getBoundingClientRect().width;
        console.log("Content width:", width);
        setContainerWidth(width);
      }
    }, 0)
  }, [panePercent]);

  const renderMainContent = () => {
    return (<div>
      {
        isLogged && (
          <SideBar active={active} isOpen={isMenuOpen} onToggleOpen={() => toggleMenuOpen()} />
        )
      }

      <div className={className} style={{
        border: '0px solid #000',
        // width: '240px',
        position: 'absolute',
        backgroundColor: 'white',
        bottom: 0,
        top: `${isAssistantOpen ? 0 : "68px"}`, //56
        left: (!isLogged ? 0 : (!isMenuOpen ? 48 : 240)),
        right: 0
      }}>

        {children}
      </div>
    </div>)
  }

  return (isAssistantOpen ?
    (<SplitterLayout percentage={true}
      secondaryInitialSize={30}
      secondaryMinSize={0}
      onSecondaryPaneSizeChange={(perc) => {
        setPanePercent(perc);
      }}
    >
    
        <div ref={containerRef}>
          {isGuideOpen ?
          (<Card className="mb-4" style={{ height: "84vh", paddingTop: "10px", borderColor: "#007bff" }}>
            <CardHeader style={{ backgroundColor: "#007bff", borderColor: "#007bff", paddingBottom: 0, color: 'white' }}>
              <CardTitle>
                <div style={{ display: "flex", justifyContent: "space-between", alignContent: "center" }}>
                  <span style={{
                    marginLeft: "5px", color: "#FFFFFF", textDecoration: 'none',
                    fontSize: "1.2em", fontWeight: 'bolder'
                  }}>
                    {t("riale_guide")}
                  </span>
                  <GiCancel data-for={"menu"} data-tip={t("Chiudi")} style={{
                    size: "1.2em",
                    marginTop: "6px",
                    cursor: "pointer",
                    color: "#FFFFFF",
                  } as any}
                    onClick={() => toggleGuideOpen()} />

                </div>
              </CardTitle>
            </CardHeader>
            <CardBody style={{ overflowY: "auto" }}>
              <PdfViewer containerWidth={containerWidth} 
              pdfUrl={rialeGuideUrl("it-IT")} pageNumber={guidePageNumber} />
            </CardBody>
          </Card>)
          :
            (renderMainContent())
            }
        </div>
       
      <div>
        <AIChatbot containerWidth={panePercent} key={`chatBot_${section || ""}_exp_${experimentId} || "na"`} currentSection={section} experimentId={experimentId} />
      </div>
    </SplitterLayout>) :
    (
      renderMainContent()
    )
  )
}