import { call, put, takeLatest, select } from 'redux-saga/effects'
import { actions as PassCodeUserActions } from '../slices/passCodeUsers'
import * as AnalyticsSessionsApi from "../../api/analytics_sessions";
import { configuration } from "../../config"
import moment from 'moment';
import { compress, decompress } from 'compress-json'


const stage: string = process.env.REACT_APP_STAGE != undefined ? process.env.REACT_APP_STAGE : "dev"

export function* sagas() {
  yield takeLatest(PassCodeUserActions.willLogoutWithPassCode.type, willLogoutWithPassCode)
  yield takeLatest(PassCodeUserActions.willLoadAnalyticsSessions.type, willLoadAnalyticsSessions)
}


/*
function* willGetPublicExperiment(action: any) {
  const experiment = action.payload;
  //console.log("In willGetPublicExperiment (id):", experiment);
 
  const publicExperimentUrl = `${configuration[stage]["publicExperimentEndpoint"]}${experiment}`;
  //console.log("In willGetPublicExperiment (publicExperimentUrl):", publicExperimentUrl);
  yield put(UiActions.loading(true));
  try {
 
    const response = yield call(() =>
    fetch(publicExperimentUrl)
      .then(response => response.json())
      .then(myJson => myJson)
  );
    //console.log("In willGetPublicExperiment (response):" , response);
    //console.log("willGetPublicExperiment result", response);
    yield put(ExperimentsActions.setCurrentPublicExperiment(response));
  } catch (e) {
    //console.log("willGetPublicExperiment error", e);
    yield put(ExperimentsActions.setCurrentPublicExperiment(null));
  }
  yield put(UiActions.loading(false));
 
}
*/

function* willLoadAnalyticsSessions(action: any):any {
  const group = `${configuration[stage]["analyticsSessionGroup"]}`
  //"TEST_BETA_NOAUTH_01";

  const compressedAnalytics_sessions = yield call(AnalyticsSessionsApi.listAnalyticsSessions, group, null);

  const analytics_sessions = yield compressedAnalytics_sessions.map((session:any) => {
    let temp = Object.assign({}, session);
    try {
      temp["content"] =  JSON.stringify(decompress(JSON.parse(session["content"])))
      temp["recording_id"] = JSON.parse(temp["content"])?.recording_id || "-"
      temp["expected_recordings"] = JSON.parse(temp["content"])?.expected_recordings || 0;
      return temp;
      // nel caso la decompressione fallisca, restituisco la sessione originaria
    } catch(error)
    {
      return session
    }
  })
  
  //console.log("AnalyticsSessions:", analytics_sessions);
  yield put(PassCodeUserActions.setCurrentAnalyticsSessions(analytics_sessions));
}

//@audit LOGOUT WITH PASSCODE
function* willLogoutWithPassCode(action: any):any {
  //console.log('in willLogoutWithPassCode function with ', action);
  let sessionTrace = yield (JSON.parse(localStorage.getItem("RialeSessionTrace") as any))
  //console.log("DBA:willLogout SessionTrace:", sessionTrace);

  let error = null;
  if (sessionTrace != null) {
    // aggiungo la data di fine
    const stopDate = moment.now();
    sessionTrace["stopDate"] = stopDate;
    sessionTrace["formattedStopDatetime"] = moment(stopDate).format("YYYY/MM/DD - HH:mm:ss");
    
    // la sessione viene compressa prima di essere convertita in json
    const sessionData = JSON.stringify(compress(sessionTrace))
    
    const group = `${configuration[stage]["analyticsSessionGroup"]}` // "TEST_BETA_NOAUTH_01";
    const passcode = action.payload["passCode"];
    const createAnaliticsSessionUrl = `${configuration[stage]["createAnaliticsSessionEndpoint"]}`;
    const contentBody = JSON.stringify({ group: group, passcode: passcode, content: sessionData })
    //console.log("Content body", contentBody);

    //https://stackoverflow.com/questions/29775797/fetch-post-json-data
    try {
      const response = yield call(() =>
        fetch(createAnaliticsSessionUrl, {
          method: 'POST', headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: contentBody
        })
          .then(response => response.json())
          .then(myJson => myJson).catch(e => {
            //console.log("DBA:Eccezione nel salvataggio:", e);
            error = e;
          })
      );
      //console.log("DBA:In willLogoutWithPassCode (response)-->", response);
      if (response["errorType"]!=null)
      {
        error = response["errorMessage"]
        //console.log("DBA:willLogoutWithPassCode error", error);
      }
    } catch (e) {
      error = e;
      //console.log("DBA:willLogoutWithPassCode error", e);
    }
  }
  else{
  error = "Session Trace is null!";
  }
  // consento il logout solo in caso non ci sia un errore nel salvataggio della sessione
  if (error==null) {
    //console.log("DBA: Nessun errore: procedo con la rimozione del trace e il logout");
    localStorage.removeItem("RialeSessionTrace");
    yield put(PassCodeUserActions.didLogoutWithPassCode())

    //@audit TO BE FIXED La chiamata seguente sembra  non funzionare
    //yield logoutFromRialenet()
  }
  else{
    //console.log("DBA: Impossibile procedere al salvataggio della sessione:",error)
    yield put(PassCodeUserActions.didSessionSavingError(error));
  }
}

function* logoutFromRialenet():any {
  //console.log("DBA RIALENET:Tentativo di logout:");
 const url = "https://rialenet-forum.herokuapp.com/logout";
 const response = yield call(() =>
 fetch(url, {
   method: 'GET', headers: {
     'Accept': 'application/json',
     'Content-Type': 'application/json'
   }
 })
   .then(response => response.json())
   .then(myJson =>  myJson).catch(e => {
     //console.log("DBA RIALENET:Eccezione nel logout:", e);
   })
);
}

/*
  function* willLogoutWithPassCodeFromAuthUser(action:any) {
    //console.log('in willLogoutWithPassCode function with ', action);
    const group = "TEST_BETA_01"

    try {
      let response = yield call(
        AnalyticsSessionsApi.createAnalyticsSession,
        group,
        action.payload["passCode"],
        sessionData
      );
      //console.log("createAnalyticsSession result", response);

    } catch (e) {
      //console.log("Amplify createAnalyticsSession error", e);
    }

    yield put(PassCodeUserActions.didLogoutWithPassCode())
  }
  */
