import { call, put, takeLatest, select, fork } from 'redux-saga/effects'
import { actions as CatalogActions, selectors as CatalogSelector } from '../slices/catalog'
import * as CatalogApi from '../../api/catalog'
//import localCatalog from '../../catalog/catalog_IT.json'

const COMING_SOON_LABS_THEMATIC_ID = "50"

export function* sagas() {
    yield takeLatest(CatalogActions.willGetExperiment.type, willGetExperiment);
    yield takeLatest(CatalogActions.willLoadOrganizationExperiments.type, willLoadOrganizationExperiments); 
    yield takeLatest(CatalogActions.willLoadCurrentSharedExperiments.type, willLoadCurrentSharedExperiments);
    yield takeLatest(CatalogActions.willLoadCatalog.type, willLoadCatalog);
    
    yield fork(willLoadCatalog, {}); //load experiment from catalog at startup

}
function* willLoadCatalog(action: any):any {
    //console.log("catalogo caricato (SAGA) PRIMA:");
    try{

    ////console.log("Local Catalog:",localCatalog)
    const catalog = yield call (CatalogApi.getCatalog);
    // ordino il catalogo in ordine alfabetico per tema ---
    //console.log("TEST catalog:", catalog["catalog"][0])
    
    catalog["catalog"].sort((t1:any,t2:any)=>{
        // COMING_SOON_LABS_THEMATIC_ID -> id della sezione "In arrivo"
        if (t1.id==COMING_SOON_LABS_THEMATIC_ID) return 1;
        else if (t2.id==COMING_SOON_LABS_THEMATIC_ID) return -1;
        else return (t1.titolo>t2.titolo ? 1 : -1)})
    
    //const catalog = localCatalog;
    yield put(CatalogActions.didLoadCatalog(catalog["catalog"]));
    yield put(CatalogActions.setExperiments(catalog["esperimenti"])); 
    yield put(CatalogActions.setLabs(catalog["labs"] || {})); 
    return catalog;
    } catch(exception) {
        //console.log("catalogo caricato (SAGA) ERROR:", exception)
    }
   
}

function* willLoadCurrentSharedExperiments(action:any) {
    const currentExperiment = action.payload;
    //console.log("willLoadCurrentSharedExperiments currentExperiment:", currentExperiment);
    
    if (currentExperiment==null || currentExperiment["timeline_pubbliche"]==null)
    {
        yield put(CatalogActions.setCurrentSharedExperiments([]));
        return; 
    }
    let experiments = [] as any; 

    for (let i=0; currentExperiment.timeline_pubbliche.length;i++)
    {
        const id = currentExperiment.timeline_pubbliche[i];
        //console.log("willLoadCurrentSharedExperiments found id:",id); 
    }
    //yield call(CatalogApi.getExperiments, null as any);
    yield put(CatalogActions.setCurrentSharedExperiments(experiments)); 
}




function* willLoadOrganizationExperiments(action:any):any {
    const organization = action.payload;
    let experiments  = yield select(CatalogSelector.getExperiments);
    if (experiments==null || Object.values(experiments).length<1)
    {
        const catalog =  yield willLoadCatalog({})
          experiments = catalog["esperimenti"];
    }
    const organizationExperiments = Object.values(experiments).filter((exper:any) => 
    {return Array.isArray(organization) ? organization.includes(exper["organization"]) : exper["organization"]==organization})

   // const experiments = yield call (CatalogApi.getExperiments, organization);
    //console.log("SAGA: Organization experiments::", organizationExperiments);
    yield put(CatalogActions.setCurrentOrganizationExperiments(organizationExperiments)); 
}


function* willGetExperiment(action: any): any {
    const experimentId = action.payload;
    //console.log("SAGA: willGetExperiment on:", experimentId);
    
    try{

        if (experimentId==null || experimentId=="")
        {   
            ////console.log("SAGA: willGetExperiment imposto a null setCurrentExperiment");
            yield put(CatalogActions.setCurrentExperiment(null)); 
            return;
        }


       let experiment = yield select(CatalogSelector.getExperiment(experimentId));
       ////console.log("SAGA willGetExperiment Trovato da Store:", experiment);
       if (experiment==null)
       {
          const catalog =  yield willLoadCatalog({})
          experiment = catalog["esperimenti"][experimentId];
          ////console.log("SAGA willGetExperiment Trovato da Api:", experiment);
       }
       // ----------------------------------------------------------------------

        yield put(CatalogActions.didGetExperiment(experiment)); 
        yield put(CatalogActions.setCurrentExperiment(experiment));
           
     } catch (error) {
       
        //console.log("SAGA: willGetExperiment fails error ", error)
        throw error
      }
}

