export default `

mutation publishContainer(
    $container: ID!
){
    publishContainer(
        container: $container
    ){
        publishedcontainer
    }
}
`