import React from 'react';
//https://react-icons.netlify.app/#/icons/fa
import * as FontAwesome from "react-icons/fa";

export const TAGS_TRACK_ID = 0;

export const SubtitlesMode =  {
    OFF : "Off",
    IT : "it",
    EN: "en"
}

export const MOBILE_SECTION_PREFIX = "mobile__";

export const SubtitlesModeList = [SubtitlesMode.OFF, SubtitlesMode.EN, SubtitlesMode.IT]

export const TrackType = {
    TAG: 'Tag',
    DOC: 'Documento',
    VIDEO: 'Video',
    IOT: "Iot"
}

export const ItemEvent = {
    CREATED: 'Created',
    CHANGED: 'Changed',
    DELETED: 'Deleted'
}


export const DefItemColors = (trackType) => {

    switch (trackType) {

        case TrackType.TAG:
            return {
                color: "rgb(255,255,255)", // white
                bgColor: 'rgb(55,120,40)'
            }   

        case TrackType.VIDEO:
            return {
                color: "rgb(255,255,255)",
                bgColor: "rgb(0,0,200)",
            }

        case TrackType.DOC:
            return {
                color: "rgb(255,255,255)",
                bgColor: 'rgb(160,147,243)'
            }

        case TrackType.IOT:
            return {
                color: "rgb(255,255,255)",
                bgColor: 'rgb(189, 171, 134)'
            }

        default:
            return {
                color: "rgb(255,255,255)", // white
                bgColor: "rgb(0,0,255)"
            }  // blue
    }
}

export const DefItemPriority = (item) => {
    switch (item.type) {
        case TrackType.TAG:
            return 10; 
        case TrackType.VIDEO:
            return 20;
        case TrackType.DOC:
            return 30;
        case TrackType.IOT:
            return 40;
        default:
            return 100;
    }
}

export const DefItemIcons = (item, props) => {
    let iconName = "FaRegFile";
    switch (item.type) {

        case TrackType.TAG:
            iconName = "FaRegFlag";
            break;

        case TrackType.VIDEO:
            iconName = "FaRegFileVideo";
            break;

        case TrackType.DOC:
            iconName = "FaRegFile";
            break;

        case TrackType.IOT:
            iconName = "FaRegFileImage";
            break;

        default:
            iconName = "FaRegFile";
            break;
    }

    return React.createElement(FontAwesome[iconName], props);
}