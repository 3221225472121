// Video Player Api Doc:    https://video-react.js.org/components/player/
// React Timeline API Doc:  https://github.com/namespace-ee/react-calendar-timeline#api
// React Timeline examples: https://github.com/namespace-ee/react-calendar-timeline/tree/master/examples
// Moment: https://stackoverflow.com/questions/18623783/get-the-time-difference-between-two-datetimes/18624295
// Reactstrap: https://reactstrap.github.io/components/alerts/
// Conditional props: https://stackoverflow.com/questions/31163693/how-do-i-conditionally-add-attributes-to-react-components
// States and Props: https://stackoverflow.com/questions/40063468/react-component-initialize-state-from-props
// Player Sync: https://stackoverflow.com/questions/87808628/is-there-a-way-to-keep-two-or-more-video-elements-synchronized-using-react
// React Portals:https://www.deadcoderising.com/react-16-render-a-child-component-into-another-dom-node-using-portals/
//               https://css-tricks.com/using-react-portals-to-render-children-outside-the-dom-hierarchy/
// React-calendar-timeline examples: https://codesandbox.io/examples/package/react-calendar-timeline

//How to capture video frame
//https://jsfiddle.net/gkkzvt4x/
//https://github.com/CookPete/react-player/issues/341
//https://codesandbox.io/s/generate-video-thumbnails-3qtubg

import React, { Component, useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import ModalItemEditor, { ItemPreview } from "./Timeline/ItemEditor";
import RialeModalPanel, { Portal } from "./Timeline/RialeModalPanel";
import TimelinePlayer, { ToolbarPosition, ZoomSlider } from "./Timeline/TimelineToolbar";
import RialeIotViewer from "./Timeline/RialeIotViewer";
import RialeVideoSyncPlayer from './Timeline/RialeVideoSyncPlayer'; //Backup
import { getFormattedTime, getTrackById } from './Timeline/Utils';
import { actions as BookmarksActions, selectors as BookmarksSelectors } from '../store/slices/bookmarks'
import { IconContext } from "react-icons";
import { IoIosCloseCircleOutline } from "react-icons/io"
import IconButton from '@material-ui/core/IconButton';
import { Button, Label, Input, Card, Form, FormGroup, CardBody, CardFooter, CardText } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import Timeline, { CursorMarker, CustomMarker, TimelineHeaders, DateHeader, SidebarHeader } from 'react-calendar-timeline'
import containerResizeDetector from 'react-calendar-timeline/lib/resize-detector/container'
// make sure you include the timeline stylesheet or the timeline will not be styled
//import 'react-calendar-timeline/lib/Timeline.css'
import './react-calendar-timeline.css';
import './riale_timeline_custom.css';
import { LiteTimelineValidatorViewer} from "./Timeline/LiteTimelineValidator"

import Alert from 'react-bootstrap/Alert'
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { GiHamburgerMenu } from "react-icons/gi";
import { FaChevronCircleLeft } from "react-icons/fa";
import { TAGS_TRACK_ID, TrackType, ItemEvent, DefItemIcons } from './Timeline/Constants';
import { getTimelineItems } from './Timeline/Utils';
import { Badge, CardTitle } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import withWatcher, { TraceEvent } from './Timeline/TimelineWatcher';
import { useSelector, useDispatch } from "react-redux";
import { get_fake_iot_item } from './Timeline/FakeItems';
import RialeItemsNavigator from './Timeline/RialeItemNavigator';
import SplitterLayout from 'react-splitter-layout';
import 'react-splitter-layout/lib/index.css';
//https://reactjsexample.com/tag/sidebar/
import { LinearProgress } from '@material-ui/core';
import ReactTooltip from "react-tooltip";
import { AiFillLock, AiFillUnlock } from "react-icons/ai"
import update from 'immutability-helper';
import { ThumbnailsRenderer } from './Timeline/ThumbnailsManager';
import BookmarksEditor from './Timeline/BoolmarksEditor';
import { selectors as ProfileSelectors } from '../store/slices/profile'
import { selectors as PassCodeSelector } from '../store/slices/passCodeUsers'
//core
import "primereact/resources/primereact.min.css";
//theme
//import "primereact/resources/themes/lara-light-indigo/theme.css"; 
//import "primereact/resources/themes/nano/theme.css";
//import "primereact/resources/themes/vela-blue/theme.css";
//import "primereact/resources/themes/arya-blue/theme.css";
import "primereact/resources/themes/md-light-deeppurple/theme.css";  // TOP
import { ContextMenu } from 'primereact/contextmenu';
import Ruler from "@scena/react-ruler";
// ESEMPIO SIDEBAR
//https://github.com/zesik/react-splitter-layout/blob/master/example/javascripts/components/TogglableSidebarLayout.jsx

//ZIndex
//https://coder-coder.com/z-index-isnt-working/

const keys = {
  groupIdKey: "id",
  groupTitleKey: "title",
  groupRightTitleKey: "rightTitle",
  itemIdKey: "id",
  itemTitleKey: "title",
  itemDivTitleKey: "title",
  itemGroupKey: "track",
  itemTimeStartKey: "start_time",
  itemTimeEndKey: "end_time",
  groupLabelKey: "title"
};
// intervallo in ms tra 2 chiamate all'evento che notifica lo stato corrente della timeline
const WATCHER_EVENT_INTERVAL = 30000;

// INIZIALIZZAZIONE DELLA FINESTRA TEMPORALE DELLA TIMELINE
// finestra temporale di visualizzazione (default: 1 giorno)
var TIMELINE_WINDOW_DURATION = moment.duration(40, 'minutes');
const MIN_ZOOM = 10 * 60 * 1000; // 10 minuti in ms
const MAX_ZOOM = 10 * 24 * 86400 * 1000; // 10 giorni in ms

const ADD_IOT_FAKE_ITEM = false; // disabilitato in quanto upload funzionante

class RialeTimelineViewerNT extends Component {

  constructor(props) {
    super(props);
    this.rialeTimelineRef = React.createRef();
    this.innerTimelineRef = React.createRef();
    this.contextMenuRef = React.createRef();
    this.contextCanvasMenuRef = React.createRef();

    //console.log("COSTRUTTORE RIALE TIMELINE VIEWER");
    if (this.props.duration != null) {
      //console.log(`Trovata prop di duration diverso da null pari a ${this.props.duration}`);
      TIMELINE_WINDOW_DURATION = this.props.duration;
    }
    else {
      //console.log(`Trovata prop di duration NULL: Lascio il default a ${TIMELINE_WINDOW_DURATION}`);
    }

    // inizialmente le tracce sono tutte in stato di lock 
    const initialLockedGroups = props.tracks.reduce((acc, cur) => ({ ...acc, [cur.id]: true }), {});


    // la posizione del cursore la imposto al temoo iniziale
    const currentPositionDate = this.props.startDateTime == null ? this.getInitialStartTime(this.props.items) : this.props.startDateTime;
    //moment(visibleTimeStart).add(TIMELINE_WINDOW_DURATION/4);

    // la giornata odierna di default -> codice aggiunto anche in componentDidMount
    // la finestra è centrata rispetto al cursore della posizione corrente
    const visibleTimeStart = moment(currentPositionDate).add(-TIMELINE_WINDOW_DURATION / 8);
    // la fine della finestra è dettata dall'estensione della finestra temporale
    const visibleTimeEnd = moment(visibleTimeStart).add(TIMELINE_WINDOW_DURATION);
    // -----------------------------
    //console.log("Items passati a viewer:", props.items);
    let tl_items = getTimelineItems(props.items, initialLockedGroups, props.canEdit, true);
    //console.log(tl_items);
    if (ADD_IOT_FAKE_ITEM) {
      //console.log("tentativo creazione item di IOT di test...");
      const item = get_fake_iot_item()
      //console.log(`Item IOT: ${item.start_time}`);
      tl_items.push(item);
    }


    this.state = {
      // i gruppi sono le varie tracce (di tipo TAG,VIDEO, DOCUMENT)
      groups: props.tracks,
      lockedGroups: initialLockedGroups,
      // contiene tutti gli item (i tag sono item a tutti gli effetti)
      items: tl_items,
      // contiene la url corrente del JSON coi campioni IOT,
      iot_items: [],
      // posizione temporale corrente
      currentPositionDate,
      // posizione temporale iniziale della finestra
      visibleTimeStart,
      //estremi temporali della timeline sulla base degli item correnti
      firstItemStartTime: this.getInitialStartTime(tl_items),
      lastItemEndTime: this.getFinalEndTime(tl_items),
      // posizione temporale finale della finestra
      visibleTimeEnd,
      timelineWindowDuration: TIMELINE_WINDOW_DURATION,
      source: null, // "http://www.w3schools.com/html/mov_bbb.mp4",
      videoUpdateCode: 0, // usato per avvisare il sync player di un cambio di posizione manuale
      videoItems: [],
      videoReadyState: 0,
      buffering: false, // indica se il video è in fase di caricamento (buffering o player.state.isWaiting)
      videoActivated: false,
      selectedItem: null,
      addModalOpen: false,
      intervalId: -1,
      timeline_t0: null,
      timeline_e0: null,
      collidingItems: [],
      itemDraggingTime: null,
      draggingItem: null, // usato per tracciare lo spostamento dei tag
      timelineIsPlaying: false, // indica se la timeline è in stato di play
      autoScroll: false, // se posto a true, forza l'autoScroll programmato (disabilita il manuale)
      showTimeCursor: false,
      videoPanelVisible: true,
      attachmentsPanelVisible: localStorage.getItem("attachmentsPanelVisible") == null || localStorage.getItem("attachmentsPanelVisible") == "true" ? true : false,
      itemsNavigatorPanelVisible: localStorage.getItem("itemsNavigatorPanelVisible") == "true",
      secondaryPaneWitdhPerc: 0, // 35 // [TODO] variabile da eliminare...non più usata...
      modalPanelsReady: false,
      areVideoLoading: false,
      timelineEditingEnabled: true,
      unlockTrackWarningEnabled: true,
      timelineAlertState: { "variant": "info", "message": "", "duration": -1 },
      eventWhatcherIntervalId: -1,
      windowIsActive: false,
      toolbarPosition: localStorage.getItem("toolbarPosition") || ToolbarPosition.BOTTOM,
      isBookmarksEditorOpen: false,
      isSidebarOpen: localStorage.getItem("isSidebarOpen") == "true",
      duplicateRequest: false // settato per richiedere la duplicazione di un item al posto della modifica di uno esistente
    };
    ////console.log("state", this.state)
  }


  getCurrentPositionDate = () =>{
    return this.state.currentPositionDate;
  }

  getInitialStartTime = (items) => {
    if (items == null || items.length < 1)
      return moment().startOf("day");

    let firstItem = items[0];
    let firstDateTime = moment(items[0].start_time)
    for (let i = 1; i < items.length; i++) {
      if (moment(items[i].start_time).isBefore(firstDateTime))
        firstDateTime = moment(items[i].start_time)
      firstItem = items[i]
    }
    //console.log(`GIST-> Item iniziale della timeline:${firstDateTime}`);
    firstDateTime.subtract(firstDateTime.seconds(), "seconds")
    //console.log(`GIST-> Data iniziale della timeline:${firstDateTime}`);
    //console.log(`Primo item: ${firstItem.id}`);
    return firstDateTime;
  }

  getFinalEndTime = (items) => {
    if (items == null || items.length < 1)
      return moment().startOf("day");

    let lastItem = items[items.length - 1];
    let lastDateTime = moment(items[items.length - 1].end_time)
    for (let i = 0; i < items.length - 1; i++) {
      if (moment(items[i].end_time).isAfter(lastDateTime))
        lastDateTime = moment(items[i].end_time)
      lastItem = items[i]
    }
    //console.log(`Data finale della timeline:${lastDateTime}`);
    //console.log(`Ultimo item: ${lastItem.id}`);
    return lastDateTime;
  }

  goHome = () => {
    const firstDateTime = this.getInitialStartTime(this.state.items);
    this.handlePlayerDatetimeChanged(firstDateTime)
  }

  gotoEnd = () => {
    const lastDateTime = this.getFinalEndTime(this.state.items);
    this.handlePlayerDatetimeChanged(lastDateTime)
  }

  onItemDeleted = (item) => {
    if (item == null)
      return;

    //console.log(`Richiesta rimozione Item: ${item.title} con id ${item.id}`);

    const new_items = this.state.items.slice();
    for (let index in new_items) {
      if (new_items[index].id == item.id) {
        //console.log(`Elimino l'item ${item.title} con id ${item.id}`);
        new_items.splice(index, 1);
        //console.log("Notifico al parent la eliminazione dell'item");
        this.onItemChanged(item, ItemEvent.DELETED);
      }
    }
    this.setState({ items: new_items }, () => {
      // aggiorno le eventuali collisioni con gli item
      this.updateItemCollisions();

    });
  }

  onItemEdited = (item) => {
    const new_items = this.state.items.slice();
    //console.log(`Cerco l'item con id ${item.id}`);
    const oldItem = this.getItemById(item.id);

    //console.log("RICHIESTA MODIFICA ITEM");
    if (oldItem == null) {
      //console.log(`Creo un nuovo item ${item.title}`);
      new_items.push(item);
      //console.log("Notifico al parent la CREAZIONE di un nuovo item");
      this.onItemChanged(item, ItemEvent.CREATED);
    }
    else {
      for (let index in new_items) {
        if (new_items[index].id == item.id) {
          //console.log(`Aggiorno l'item ${item.title}`);
          new_items[index] = item;
          //console.log(`Notifico al parent la MODIFICA di un item esistente: ${new_items != this.state.items}`);
          this.onItemChanged(item, ItemEvent.CHANGED);
        }
      }
    }
    //console.log("Aggiorno la lista degli item da onItemEdited->", this.state.draggingItem);
    this.setState({
      items: new_items,
    }, () => {
      this.setState({ selectedItem: this.getItemById(item.id) });
      this.updateItemCollisions();
      this.updateVideoCollisions();
      //console.log(`UNDO-REDO onItemChanged (${item.title}):`, this.state.items)

    });

  }

  toggle = () => {
    //console.log(`Invocato toogle! DuplicateRequest:`, this.state.duplicateRequest);
    // se ho chiesto di chiudere la finestra resetto duplicateRequest a false
    const duplicateRequest = (this.state.addModalOpen==true ? false : this.state.duplicateRequest);
    this.setState(
      prevState => ({ addModalOpen: !prevState.addModalOpen,
                      duplicateRequest
      })
    );
  }

  toggleTimelineEditing = () => {
    //console.log(`Invocato onToggleTimelineEditing!`, !this.state.timelineEditingEnabled);
    this.props.onTimelineEditingEnabled(!this.state.timelineEditingEnabled);
    this.setState(
      prevState => ({
        timelineEditingEnabled: !prevState.timelineEditingEnabled,

        items: getTimelineItems(this.props.items, this.state.lockedGroups, this.props.canEdit, !prevState.timelineEditingEnabled)
      })
    );

    this.focusTimeline();

  }

  getItemById = (itemId) => {
    const { items } = this.state;
    //console.log("Dentro getItemById");
    let itemIndex;
    for (itemIndex in items) {
      const item = items[itemIndex]
      //console.log(`Analisi item: ${item}`)
      if (item.id == itemId)
        return item;
    }
    return null;
  }

  getItemsByGroupId = (groupId) => {
    const { items } = this.state;
    let groupItems = [];

    for (let itemIndex in items) {
      const item = items[itemIndex]

      if (item.track == groupId)
        groupItems.push(item);
    }
    return groupItems;
  }


  getGroupById = (groupId) => {
    const { groups } = this.state;
    ////console.log(`Ho trovato {groups.length} gruppi`);

    for (let groupIndex in groups) {
      const track = groups[groupIndex]
      ////console.log(`Individuato gruppo con id: ${track.id} contro ${groupId}`);
      if (track.id == groupId)
        return track;
    }
    console.warn(`Non ho trovato alcun gruppo con id:${groupId}`);
    return null;
  }

  componentWillUnmount() {
    //console.log("RIALE-EU Richiamato componentWillUnMount");
    this.onEventToWatch(TraceEvent.SESSION_VISIBILITY_CHANGE, {
      "visible": false,
      "internalEvent": "componentWillUnmount"
    })
    //alert("STAI USCENDO!")
  }

  componentDidMount() {
    //console.log("Richiamato componentDidMount con visibility:", this.props.windowIsActive);
    //console.log(`Main panel Width: ${this.rialeTimelineRef.current.offsetWidth}`)

    /*
    this.onEventToWatch(TraceEvent.SESSION_VISIBILITY_CHANGE, 
      {"visible" : this.props.windowIsActive, "when": "componentDidMount"})
    this.setState({"windowIsActive" : this.props.windowIsActive});
    */
    //i18n.changeLanguage('en-US');
    //console.log("TLStart: Passato startDateTime:", this.props.startDateTime);
    // la posizione del cursore la imposto al temoo iniziale
    const currentPositionDate = this.props.startDateTime == null ? this.getInitialStartTime(this.props.items) : this.props.startDateTime;
    //moment(visibleTimeStart).add(TIMELINE_WINDOW_DURATION/4);

    // la giornata odierna di default -> codice aggiunto anche in componentDidMount
    // la finestra è centrata rispetto al cursore della posizione corrente
    const visibleTimeStart = moment(currentPositionDate).add(-TIMELINE_WINDOW_DURATION / 8);
    // la fine della finestra è dettata dall'estensione della finestra temporale
    const visibleTimeEnd = moment(visibleTimeStart).add(TIMELINE_WINDOW_DURATION);


    const eventWatcherIntervalId = setInterval(() => {
      if (this.props.windowIsActive) {
        this.onEventToWatch(TraceEvent.ATTACHMENTS_CURRENT_STATUS, {});
      }

    }, WATCHER_EVENT_INTERVAL)

    this.setState({
      "modalPanelsReady": true, visibleTimeStart,
      visibleTimeEnd, currentPositionDate, eventWhatcherIntervalId: eventWatcherIntervalId
    }, () => {
      this.updateItemCollisions();
    });


  }

  componentDidUpdate(prevProps, prevState) {
    ////console.log(`DEBUG: isActive LOGGED:${this.props.isLoggedWithPasscode}  PRE:${prevProps.windowIsActive} NOW:${this.props.windowIsActive}`);

    if (this.state.windowIsActive !== this.props.windowIsActive) {
      this.onEventToWatch(TraceEvent.SESSION_VISIBILITY_CHANGE, {
        "visible": this.props.windowIsActive,
        "internalEvent": "componentDidUpdate"
      })
      this.setState({ "windowIsActive": this.props.windowIsActive })
    }

    if (this.props.isLoggedWithPasscode === true && prevProps.watcherIsPaused === true && this.props.watcherIsPaused === false) {
      //console.log("PEXP status: avviso il watcher che il tracciamento è ripristinato");
      this.onEventToWatch(TraceEvent.SESSION_RESUMED);
    }

    // questo mi viene stampato
    ////console.log(`Richiamato componentDidUpdate con #items prima: ${prevProps.items.length}`);
    ////console.log(`Richiamato componentDidUpdate con #items dopo: ${this.props.items.length}`);
    ////console.log(`--> MOMENT: prima ${prevProps.startDateTime} Poi ${this.props.startDateTime}`);

    if (prevProps.startDateTime !== this.props.startDateTime ||
      prevProps.duration !== this.props.duration) {
      //const pEq = _.isEqual(prevProps, this.props);
      ////console.log(`Proprietà modificate? ${!pEq}`);
      //console.log(`modificato: prima ${prevProps.startDateTime} Poi ${this.props.startDateTime}`);
      this.setState({ timelineWindowDuration: this.props.duration }, () => {
        const newPositionDate = moment(this.props.startDateTime).add(this.state.timelineWindowDuration / 8);
        this.updateCurrentDatetimePosition(newPositionDate)
      })
    }


    if (prevProps.items != this.props.items || prevProps.tracks != this.props.tracks) {
      //console.log("Aggiorno tracce ed items");
      const { selectedItem, draggingItem, itemDraggingTime } = this.state;

      this.setState(
        {
          groups: this.props.tracks,
          items: getTimelineItems(this.props.items, this.state.lockedGroups, this.props.canEdit, this.state.timelineEditingEnabled)
        },
        () => {

          const newItemDraggingTime = itemDraggingTime != null ?
            (
              (draggingItem != null && this.getItemById(draggingItem.id) != null)
                ? this.getItemById(draggingItem.id).start_time : null
            ) : null;
          //console.log("Imposto lastItemEndTime a:", moment(this.getFinalEndTime(this.state.items)).format("DD HH:mm:ss"));
          this.setState({
            selectedItem: selectedItem != null ? this.getItemById(selectedItem.id) : null,
            firstItemStartTime: this.getInitialStartTime(this.state.items),
            lastItemEndTime: this.getFinalEndTime(this.state.items),
            itemDraggingTime: newItemDraggingTime,
            draggingItem: draggingItem != null ? this.getItemById(draggingItem.id) : null
          });

          if (prevProps.activeTab !== this.props.activeTab) {
            //console.log(`Cambio TAB: Selezione altro esperimento:startDateTime:${this.props.startDateTime}`);
            // la giornata odierna di default
            const newPositionDate = this.props.startDateTime == null ? this.getInitialStartTime(this.props.items) : this.props.startDateTime;

            //console.log(`Cambio TAB: ${newPositionDate}`);
            this.handlePlayerDatetimeChanged(newPositionDate);
          }
          else {
            //console.log("In componentDidUpdate aggiorno collisioni")
            // sulla base del nuovo tempo aggiorna l'elenco degli item che collidono
            this.updateItemCollisions();
            // sulla base del nuovo tempo aggiorna l'elenco dei video che collidono
            this.updateVideoCollisions(null, true);
          }
        });



    }
  }

  scrollTo = (visibleTimeStart, visibleTimeEnd) => {
    //console.log(`ZSLIDER onScrollTo: TL ${visibleTimeStart} - ${visibleTimeEnd}`);
    this.setState({
      autoScroll: true,
      visibleTimeStart,
      visibleTimeEnd
    }, ()=>{this.setState({ autoScroll: false })});
  }

  handleTimeChange = (visibleTimeStart, visibleTimeEnd, updateScrollCanvas) => {
    /*
    //console.log(
      "TIME CHANGE",
      moment(visibleTimeStart, "x").format(),
      moment(visibleTimeEnd, "x").format()
    );
   */
    ////console.log(`TLT: FIRST ${this.state.firstItemStartTime}, VISIBLE: ${moment(visibleTimeStart)}`);

    this.setState({
      visibleTimeStart,
      visibleTimeEnd
    });
    // aggiorno il canvas
    updateScrollCanvas(visibleTimeStart, visibleTimeEnd);

  }

  verticalLineClassNamesForTime = (timeStart, timeEnd) => {
    const currentTimeStart = moment(timeStart);
    const currentTimeEnd = moment(timeEnd);

    let classes = [];
    if (currentTimeEnd.isBefore(this.state.firstItemStartTime) ||
      currentTimeStart.isAfter(this.state.lastItemEndTime))
      // check for public holidays
      classes.push("out-of-range");
    return classes;
  }

  /** 
   * Riposiziona il marker della timeline nella posizione indicata
   * centrandolo rispetto allo schermo e scrollando adeguatamente la timeline
   * secondo la finestra aggiornata coi nuovi (visibleTimeStart,visibleTimeEnd)
   * @param currentPositionDate nuova data e orario della timeline
   */
  updateCurrentDatetimePosition = (newPositionDate) => {

    const { timelineWindowDuration, currentPositionDate, secondaryPaneWitdhPerc, visibleTimeStart } = this.state;
    //const newVisibleTimeStart = moment(visibleTimeStart).add(newPositionDate).subtract(currentPositionDate);
    ////console.log(`AGGIORNO la posizione del marker con finestra temporale di ${timelineWindowDuration.humanize()}`);
    ////console.log(`CP:${currentPositionDate} -> ${newPositionDate}`);
    // [TODO] AGGIORNARE OPPORTUNAMENTE LA FINESTRA TEMPORALE...
    const newVisibleTimeStart = moment(newPositionDate).subtract(timelineWindowDuration / 8);//(200/(1+secondaryPaneWitdhPerc)));
    const newVisibleTimeEnd = moment(newVisibleTimeStart).add(timelineWindowDuration);
    ////console.log(`Nuova finestra: Da ${newVisibleTimeStart} a ${newVisibleTimeEnd} Pos:${newPositionDate}`);
    ////console.log("Abilito temporaneamente AUTO SCROLL");
    // abilito l'autoScroll temporaneamente per centrare la finestra nella data selezionata
    this.setState({
      currentPositionDate: newPositionDate,
      autoScroll: true,
      visibleTimeStart: newVisibleTimeStart,
      visibleTimeEnd: newVisibleTimeEnd
    }, () => {
      // disabilito l'autoScroll
      ////console.log("Disabilito AUTO SCROLL"); 
      this.setState({ autoScroll: false, });

      // sulla base del nuovo tempo aggiorna l'elenco degli item che collidono
      this.updateItemCollisions(newPositionDate);

    });
  }

  handleZoomChanged = (newDuration) => {
    const { currentPositionDate } = this.state;
    if (newDuration == null) {
      this.onEventToWatch(TraceEvent.ZOOM_CHANGE,
        { "type": "ZOOM_RESET", "windowDuration": TIMELINE_WINDOW_DURATION.asMilliseconds() });

      this.setState({ timelineWindowDuration: TIMELINE_WINDOW_DURATION }, () => { this.updateCurrentDatetimePosition(currentPositionDate) });
      return;
    }

    const currentDurationInMs = newDuration.asMilliseconds();

    this.onEventToWatch(TraceEvent.ZOOM_CHANGE,
      {
        "type": (currentDurationInMs > this.state.timelineWindowDuration.asMilliseconds() ? "ZOOM_OUT" : "ZOOM_IN"),
        "windowDuration": currentDurationInMs
      });

    //console.log(`Min: ${MIN_ZOOM} Max: ${MAX_ZOOM} Durata proposta: ${currentDurationInMs}`);
    if (currentDurationInMs >= MIN_ZOOM && currentDurationInMs <= MAX_ZOOM) {
      this.setState({ timelineWindowDuration: newDuration }, () => { this.updateCurrentDatetimePosition(currentPositionDate) });

    }
    else if (currentDurationInMs < MIN_ZOOM) {
      this.setState({ timelineWindowDuration: moment.duration(MIN_ZOOM) }, () => { this.updateCurrentDatetimePosition(currentPositionDate) });
    }
    else if (currentDurationInMs > MAX_ZOOM) {
      this.setState({ timelineWindowDuration: moment.duration(MAX_ZOOM) }, () => { this.updateCurrentDatetimePosition(currentPositionDate) });
    }
  }

  /** 
   * Calcola la nuova posizione del marker della timeline e 
   * richiama il metodo di aggiornamento della nuova posizione
   */
  timelineProgress = (velFactor) => {
    // t_i = e_0 + (now() - t_0)*velFactor
    const { timeline_t0, timeline_e0 } = this.state;
    if (velFactor == null)
      velFactor = 1
    const newPositionDate = moment(moment(timeline_e0) + (moment() - moment(timeline_t0)) * velFactor);
    // aggiorna la posizione del marker sulla base dell'istante corrente
    this.updateCurrentDatetimePosition(newPositionDate);
    //this.setState( {currentPositionDate: newPositionDate});

    // verifica la collisione con risorse video
    this.updateVideoCollisions(newPositionDate, true, false); // true,false PRIMA [VERIFICARE]
    const { videoActivated } = this.state;

    // in caso di collisione col video arresto il timer del Player della Timeline
    // in modo che subentri l'handler del player video per leggere il tempo corrente
    // e aggiornare il marker
    if (videoActivated === true) {
      clearInterval(this.state.intervalId);
      //console.log("Collisione video trovata. Ho disabilitato il player della timeline");
    }
  }


  /**
  * Aggiorna le collisioni tra la data corrente e gli eventuali video
  * Imposta opportunamente lo stato videoActivate a null (in assenza di collisioni) oppure - per ora -  al primo item di tipo video trovato...da generalizzare a N video...)
  * @param positionDate data corrente da verificare 
  * @param updateVideoCode informa il RialeVideoSyncPlayer che è stato eseguito un cambio di tempo proveniente dal parent
  * @param canvasClick specifica se la chiamata arriva da una interazione dell'utente (true) o dal progress della timeline interna
  */
  updateVideoCollisions = (positionDate, updateVideoCode, canvasClick) => {

    const { items, currentPositionDate } = this.state;

    // se non specificato, si intende che la richiesta di aggiornatmento della posizione della timeline
    // provenga dal Player della time line e NON dal SyncPlayer
    if (updateVideoCode == null)
      updateVideoCode = true;
    if (canvasClick == null)
      canvasClick = true;

    ////console.log(`Verifico collisione video che per ora risulta-->: ${videoActivated}`);

    const positionDateCmp = (positionDate == null) ? currentPositionDate : positionDate;
    const videoItems = this.getCollidingVideoItems(items, positionDateCmp);

    // verifico se i videoItems sono cambiati e 
    // nel caso aggiorno lo stato
    this.checkVideoItems(videoItems, updateVideoCode, positionDateCmp, canvasClick);
  }

  getCollidingVideoItems = (items, positionDateCmp) => {
    let videoItems = [];
    for (let index in items) {
      //const track = this.getGroupById(items[index].track)
      ////console.log(`Analisi del gruppo:${track.title}`);
      ////console.log(`Orario di confronto:${moment(positionDateCmp)} su ${items.length} items`);

      if (items[index].type === TrackType.VIDEO) {
        ////console.log(`Trovato gruppo VIDEO:${track.title}`);

        ////console.log(`ANALIZZO Item Start: ${items[index].start_time} Item End: ${items[index].end_time}`);
        if (moment(positionDateCmp).isBetween(items[index].start_time, items[index].end_time, null, '[)')) {
          videoItems.push(items[index]);
        }
      }
    }
    return videoItems;
  }

  checkVideoItems = (items, updateVideoCode, currentPositionDate, canvasClick) => {
    ////console.log("CHECK VIDEO COLLISIONS");
    const { videoItems } = this.state;

    if (items.length < 1 && canvasClick) {
      //console.log("Timeline gestita dal setInterval:Aggiorno il valore della progress timeline!");
      this.setState({
        timeline_t0: moment(),
        timeline_e0: moment(currentPositionDate)
      }
      )
    }

    let modified = false;
    if (items.length != videoItems.length) {
      //console.log(`TIMELINE: Numero videoItems non corrispondenti: ${items.length} contro ${videoItems.length}`);
      //console.log(`TIMELINE: Posizione stato interno Timeline  ${moment(this.state.currentPositionDate).format("HH:mm:ss")}
       //  CONTRO ${moment(currentPositionDate).format("HH:mm:ss")}`);
      modified = true;
      if (items.length > 0) {
        //console.log("Ho un videoItem, arresto il progressInterval del play della timeline");
        clearInterval(this.state.intervalId);
      }


    }
    else {
      for (let i = 0; i < items.length; i++) {
        if (`${items[i].id}`.localeCompare(`${videoItems[i].id}`) != 0 ||
          `${items[i].title}`.localeCompare(`${videoItems[i].title}`) != 0 ||
          `${items[i].description}`.localeCompare(`${videoItems[i].description}`) != 0 ||
          `${items[i].subtitlesUrl}`.localeCompare(`${videoItems[i].subtitlesUrl}`) != 0 ||
          !moment(items[i].start_time).isSame(videoItems[i].start_time) ||
          !moment(items[i].start_offset).isSame(videoItems[i].start_offset) ||
          !moment(items[i].end_offset).isSame(videoItems[i].end_offset)
        ) {
          //console.log(`ID dei videoItems non corrispondenti: *${items[i].id}* contro *${videoItems[i].id}*`);
          modified = true;
          break;
        }
      }

    }

    if (modified) {
      //console.log(`Aggiorno i video items che sembrano cambiati in orario ${moment(currentPositionDate).format("HH:mm:ss")}, Ne ho ${items.length} updateVideoCode ?:${updateVideoCode}`);
      this.setState(prevState => ({
        videoUpdateCode: updateVideoCode ? (prevState.videoUpdateCode + 1) % 10 : prevState.videoUpdateCode,
        videoItems: items, videoActivated: items.length > 0
      }));
    }
    // caso in cui gli item NON siano stati modificati
    else if (updateVideoCode) {


      ////console.log(`UpdateVideoCode: Valore corrente: ${this.state.videoUpdateCode}`);
      this.setState(prevState => ({
        videoUpdateCode: (prevState.videoUpdateCode + 1) % 10
      }));
    }
  }


  /** 
   * Verifica le intersezioni tra la posizione corrente (o quella specificata)
   * della timeline e i vari items e aggiorna la list
   * @param positionDate posizione della timeline di cui verificare le intersezioni
  */
  updateItemCollisions = (positionDate) => {
    
    const { items, currentPositionDate } = this.state;
    let collidingItems = [];
    let iotItems = [];

    const positionDateCmp = (positionDate == null) ? currentPositionDate : positionDate;
    for (let index in items) {
      if (moment(positionDateCmp).isBetween(items[index].start_time, items[index].end_time, null, '[)')) {
        collidingItems.push(items[index]);
        const url = items[index].source;
        if (url != null && items[index].type == TrackType.IOT) {
          iotItems.push(items[index]);
        }
      }
    }
   
    this.setState({ collidingItems, iot_items: iotItems });
    // comunico all'eventuale watcher lo stato attuale degli item in collisione
    //this.onEventToWatch(TraceEvent.ATTACHMENTS_STATUS_CHANGE,{});
  }

  /** 
   * Avvia il Play della timeline usando la funzione timelineProgress
  */
  playTimeline = () => {

    const { videoActivated } = this.state;
    // se il video risulta attivo l'avanzamento della 
    // timeline è delegato al RialeVideoSuncPlayer
    if (videoActivated) {
      //console.log(`Timeline in play mediante SyncPlayer`);
      this.setState({ timelineIsPlaying: true })
      return;
    }
    //console.log("Richiamata playTimeline()");
    const { currentPositionDate, intervalId } = this.state;
    // se esiste già in esecuzione un timer della timeline lo arresta
    if (intervalId != null)
      clearInterval(intervalId);

    let newIntervalId = setInterval(this.timelineProgress, 10);
    this.setState({
      intervalId: newIntervalId,
      timeline_t0: moment(),
      timeline_e0: moment(currentPositionDate),
      timelineIsPlaying: true
    });
  }


  /** 
   * Mette in pausa la timeline rimuovendo il Timer (clearInterval)
   */
  pauseTimeline = () => {
    //console.log("Richiamata pauseTimeline()");
    clearInterval(this.state.intervalId);
    this.setState({ timelineIsPlaying: false });
  }

  togglePlayPauseTimeline = () => {
    if (this.state.timelineIsPlaying) this.pauseTimeline();
    else this.playTimeline();
  }

  toggleTimeCursor = () => {
    //console.log(`Aggiornamento timeCursor: ${this.state.showTimeCursor}`);
    this.setState(
      prevState => ({ showTimeCursor: !prevState.showTimeCursor })
    );
  }

  addTimelineBookmark = () => {
    this.props.dispatchSelectedBookmark(null);
    this.setState({ isBookmarksEditorOpen: true })
  }

  updateTimelineBookmark = (bookmark) => {
    this.props.dispatchSelectedBookmark(bookmark);
    this.setState({ isBookmarksEditorOpen: true })
  }

  toggleAttachments = () => {
    //console.log(`Aggiornamento visibilità pannello degli attachments::: ${this.state}`);
    this.onEventToWatch(TraceEvent.ATTACHMENTS_PANEL_VISIBILITY_CHANGE,
      { "visible": !this.state.attachmentsPanelVisible });
    localStorage.setItem("attachmentsPanelVisible", !this.state.attachmentsPanelVisible);
    this.setState(
      prevState => ({ attachmentsPanelVisible: !prevState.attachmentsPanelVisible })
    );
  }

  gotoSelectedItemStart = () => {
    const { selectedItem } = this.state;
    if (selectedItem != null) {
      this.handlePlayerDatetimeChanged(selectedItem.start_time)
    }
  }

  gotoSelectedItemEnd = () => {
    const { selectedItem } = this.state;
    if (selectedItem != null) {
      this.handlePlayerDatetimeChanged(selectedItem.end_time)
    }
  }

  toggleItemsNavigator = () => {
    //console.log(`Aggiornamento vista Navigator::: ${this.state}`);
    this.onEventToWatch(TraceEvent.NAVIGATOR_PANEL_VISIBILITY_CHANGE,
      { "visible": !this.state.itemsNavigatorPanelVisible });
    localStorage.setItem("itemsNavigatorPanelVisible", !this.state.itemsNavigatorPanelVisible);
    this.setState(
      prevState => ({ itemsNavigatorPanelVisible: !prevState.itemsNavigatorPanelVisible })
    );
  }

  onEventToWatch = (traceEvent, payload) => {
    if (this.props.onEventToWatch == null || this.props.isLoggedWithPasscode !== true || this.props.watcherIsPaused) return;
    const { collidingItems, currentPositionDate } = this.state;
    let newPayload = { ...payload }
    newPayload["collidingItems"] = collidingItems.map(item => {
      return {
        "id": item["id"],
        "title": item["title"], 
        "type": item["type"],
        "start_time" : item["start_time"],
        "end_time" : item["end_time"],
        "duration" : item["duration"]
      }
    });
    newPayload["timelineId"] = this.props.id;
    newPayload["activeTab"] = this.props.activeTab;
    newPayload["currentTimelinePosition"] = moment(currentPositionDate).valueOf();
    newPayload["playing"] = this.state.timelineIsPlaying;
    newPayload["attachmentsPanelVisible"] = this.state.attachmentsPanelVisible;
    newPayload["navigatorPanelVisible"] = this.state.itemsNavigatorPanelVisible;
    this.props.onEventToWatch(traceEvent, newPayload);
  }

  confirmToggleLock = (group) => {

    const { t } = this.props;
    const { unlockTrackWarningEnabled } = this.state;

    // se sto attivando il lock su una traccia non ha senso 
    // mostrare l'alert (o se ho disabilitato la finestra di warning)
    if (!this.state.lockedGroups[group.id] || !unlockTrackWarningEnabled) {
      this.toggleLock(group);
      return;
    }


    const options = {
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui' style={{ border: "2px solid #007bff", borderRadius: '0.25rem' }}>
            <Card style={{ paddingLeft: "10px", paddingRight: "10px" }} fluid={"true"}>
              <CardTitle>
                <center>
                  <b>{t(`${group.title}`)}</b>
                </center>
              </CardTitle>
              <CardText>
                {`${t("tl:track_unlock_warning")}`}
              </CardText>
              <CardBody>
                <center>
                  <Button color="secondary" style={{ marginRight: "5px" }}
                    onClick={() => {
                      this.toggleLock(group);
                      onClose();
                    }}
                  >
                    {t("tl:yes")}
                  </Button>
                  <Button color="secondary" onClick={onClose}>No</Button>
                </center>
              </CardBody>

              <CardFooter>
                <Form>
                  <FormGroup check={!unlockTrackWarningEnabled}>

                    <Label>
                      <Input type="checkbox" name="trackUnlockWarning"
                        id="trackUnlockWarning"

                        onChange={(event) => {
                          //console.log("Event! State:", this.state.unlockTrackWarningEnabled);
                          this.setState(prevState => ({
                            unlockTrackWarningEnabled: !prevState.unlockTrackWarningEnabled
                          }
                          ))
                        }}
                      />

                      {t("track_warning_show")}
                    </Label>

                  </FormGroup>
                </Form>
              </CardFooter>
            </Card>
          </div>
        );
      }
    };

    confirmAlert(options);

  }

  toggleLock = (group) => {
    const { lockedGroups } = this.state;
    const new_lockedGroups = update(lockedGroups,
      { [group.id]: { $set: !lockedGroups[group.id] } }
    );
    this.setState({
      lockedGroups: new_lockedGroups,
    }, () => {
      ////console.log(`DEBUG: Cliccato gruppo con canEdit:${this.props.canEdit} ---> ${group.id} (${group.title})`,  this.state.lockedGroups);
      this.setState({
        items: getTimelineItems(this.state.items,
          this.state.lockedGroups, this.props.canEdit, this.state.timelineEditingEnabled)
      })
    });
  }


  renderIotItems() {
    const { iot_items, currentPositionDate, timelineIsPlaying, visibleTimeStart,
      visibleTimeEnd, timelineWindowDuration, posixPanelRect }
      = this.state;
    ////console.log(`Individuati su renderIotItems ${iot_items.length} items`);
    return iot_items.map((iot_item, index) => (
      <div style={{ backgroundColor: "white" }} key={index}>
        <RialeIotViewer
          className="iotViewer"
          iotItem={iot_item}
          bounds={"window"}
          currentPositionDate={currentPositionDate}
          timelineWindowDuration={timelineWindowDuration}
          visibleTimeStart={visibleTimeStart}
          visibleTimeEnd={visibleTimeEnd}
          timelineIsPlaying={timelineIsPlaying}
          posixPanelRect={posixPanelRect}
          onContainerRectChanged={this.onPosixPanelRectChanged}
        />
      </div>
    ))
  }


  onPosixPanelRectChanged = (state) => {
    //console.log(`IotViewer Rect Changed x:${state.x} y:${state.y} w:${state.width} h:${state.height}`);
    const posixPanelRect = { x: state.x, y: state.y, width: state.width, height: state.height };
    //console.log("Aggiorno Posix rect panel");
    this.setState({ posixPanelRect });
  }

  /**
   * esegue il render degli item correntemente attivi
   * in funzione della posizione corrente del marker della timeline
   */
  renderItemsPreview() {
    ////console.log("Dentro renderItemsPreview");
    const { collidingItems } = this.state;
    return collidingItems.map((item, index) => (
      item.type != TrackType.VIDEO &&
      <center key={index}>
        <ItemPreview item={item}
          onEventToWatch={this.onEventToWatch} index={index} key={item.id} />
      </center>
    ));
  }

  /**
   * Esegue il rendering delle barre verticali (marker) associate 
   * agli item di tipo TAG
   */
  renderMarkersTag() {
    const tags = this.getItemsByGroupId(TAGS_TRACK_ID);
    const { itemDraggingTime, draggingItem } = this.state;
    const markerDate = itemDraggingTime;
    // (draggingItem!=null && draggingItem.id==tag.id ?  itemDraggingTime :parseInt(tag.start_time.valueOf()));
    return tags.map((tag, index) => (

      <CustomMarker date={(draggingItem != null && draggingItem.id == tag.id ? itemDraggingTime : parseInt(tag.start_time.valueOf()))}
        key={tag.id}>
        {({ styles, date }) => {
          const customStyles = {
            ...styles,
            backgroundColor: `${tag.bgColor}`,
            top: 0,
            width: '3px',
            //zIndex: 100
          }
          return <div style={customStyles}>
          </div>
        }
        }
      </CustomMarker>
    ));
  }

  itemRenderer = ({ item, timelineContext, itemContext, getItemProps, getResizeProps }) => {
    const bgColor = item.bgColor == null ? 'blue' : item.bgColor;
    const selectedBgColor = item.selectedBgColor == null ? 'rgb(200,100,0)' : item.selectedBgColor;
    const color = (item.color == null || itemContext.selected) ? 'white' : item.color;
    const backgroundColor = itemContext.selected ? (itemContext.dragging ? "red" : selectedBgColor) : bgColor;
    //const itemTooltip = itemContext.selected ? (itemContext.dragging ? moment(item.start_time).format("HH:mm:ss") : "") : "";
    ////console.log(`ITEM TOOLTIP:${itemTooltip}`);
    //const itemTooltip = (itemContext.dragging ? moment(item.start_time).format("HH:mm:ss") : "???");
    return (
      <div {...getItemProps({
        style: {
          fontSize: '18px',
          paddingLeft: `${TrackType.VIDEO && item.thumbnailsUrl ? 0 : 10}px`,
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
          backgroundColor,
          color
        }
      })}>
        {
          item.type == TrackType.VIDEO && item.thumbnailsUrl?.length > 0 ?
            <>
              <ThumbnailsRenderer key={`${item.id}`} item={item} itemContext={itemContext} />
              {/*<div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.title}</div> */}
            </>
            :
            <div style={{
              float: "left", textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden"
            }}>
              <div title={item.title} style={{ marginBottom: 10, marginRight: 5, width: 20, height: 20, float: 'left', padding: 0, flex: 1 }}>
                <IconContext.Provider value={{ color: color, className: "global-class-name" }}>
                  {DefItemIcons(item)}
                </IconContext.Provider>
              </div>
              {itemContext.title}
            </div>
        }
      </div>)


  }




  renderLockIcon = (group, isSidebarOpen, isEditingEnabled) => {
    const { t } = this.props;
    return (
      <IconButton disabled={!isEditingEnabled}
        style={{ backgroundColor: `white` }}
        onClick={() => { if (isEditingEnabled) this.confirmToggleLock(group) }}>

        {this.state.lockedGroups[group.id] == true ?
          <IconContext.Provider value={{
            color: `${isEditingEnabled ? "red" : "blue"}`,
            className: "global-class-name", size: "0.8em"
          }}>
            {(isSidebarOpen && isEditingEnabled) ? <AiFillLock data-place="top" data-tip={t("tl:tip_unlock_track")} /> :

              <div style={{ display: "flex", justifyContent: "center" }} data-place="top" data-tip={`${isEditingEnabled ? t('tl:tip_unlock_track') : ""} ${t(group.title)}`}>

                {DefItemIcons(group)}
              </div>
            }

          </IconContext.Provider>
          :
          <IconContext.Provider value={{
            color: `${isEditingEnabled ? "green" : "blue"}`,
            className: "global-class-name", size: "0.8em"
          }}>
            {(isSidebarOpen && isEditingEnabled) ? <AiFillUnlock data-place="top" data-tip={t("tl:tip_lock_track")} /> :
              DefItemIcons(group, {
                "data-place": "top", "data-tip": `${!isSidebarOpen ? t(group.title) : isEditingEnabled ?
                  t('tl:tip_lock_track') : ""} ${t(group.title)}`
              })}
          </IconContext.Provider>
        }
      </IconButton>)
  }


  /** 
   * Callback richiamata dal RialeSyncVideoPlayer 
  */
  handleProgress = (currentPositionDate, itemIndex) => {
    //console.log(`Arrivato Progress time da RVS (playing:${this.state.timelineIsPlaying}) (Item ${itemIndex}): ${currentPositionDate}`);

    this.setState({ currentPositionDate, areVideoLoading: false }, () => {
      this.updateVideoCollisions(currentPositionDate, false);
      this.updateItemCollisions();
      if (this.state.timelineIsPlaying) {
        this.updateCurrentDatetimePosition(currentPositionDate);
      }

    });

    ////console.log(`Progress time: ${currentPositionDate}`);
    if (this.state.timelineIsPlaying) {
      this.updateCurrentDatetimePosition(currentPositionDate);
      ////console.log("Verifico nuove collisioni video da handleProgress...");
      //n.b: in questo caso l'aggiornamento proviene dal SyncPlayer
      // [TODO percheè null e non currentPositionDate?!]
      this.updateVideoCollisions(currentPositionDate, false); // prima era null e non si capisce perchè...
    }
    else
      this.setState({ currentPositionDate }, () => {
        this.updateItemCollisions();
        // this.updateVideoCollisions();
      });
  }

  handleEnded = () => {
    //console.log(`Video Terminati in orario ${this.state.currentPositionDate}`);
    const { timelineIsPlaying } = this.state;
    this.setState({ videoActivated: false, areVideoLoading: false }, () => {
      if (timelineIsPlaying) {
        //console.log("La timeline risulta in stato di play quindi la avvio");
        this.playTimeline();
      }
      else {
        //console.log("La timeline risulta in pausa e quindi non la riavvio");
      }
    });

  }

  handleStartVideoLoading = () => {
    //console.log(`Richiamato handleStartVideoLoading`);
    this.setState({ areVideoLoading: true });
  }

  getTimeCursorWidth = () => {
    return (this.state.showTimeCursor ? '2px' : '0px');
  }

  getTimeCursorContent = (date, customStyles) => {
    if (!this.state.showTimeCursor)
      return null;
    else
      return <div style={{ ...customStyles }}>
        <Badge color="primary">{getFormattedTime(this.state.firstItemStartTime, date)}</Badge>
        {
          this.getCollidingVideoItems(this.state.items, date).filter((item) => { return item.thumbnailsUrl?.length > 0 }).map((item) => {
            if (this.state.selectedItem != null && item.id != this.state.selectedItem.id) return null;
            return (<ThumbnailsRenderer
              key={`${item.id}`}
              item={item} preview showTime
              atTime={item.start_offset + parseInt(moment(date).diff(item.start_time) / 1000)}
              itemContext={{ dimensions: { width: "100px", height: "80px" } }} />)
          })
        }

      </div>
  }

  handleSecondaryPaneSizeChange = (secondaryPaneWitdhPerc) => {
    //console.log(`NEW PANE SIZE: ${secondaryPaneWitdhPerc} %`);
    const { currentPositionDate } = this.state;
    //this.setState({secondaryPaneWitdhPerc}); //, () => this.updateCurrentDatetimePosition(currentPositionDate));
  }

  setAlertMessage(message, variant, duration) {
    this.setState({
      "timelineAlertState": {
        "variant": variant == null ? "info" : variant, message, duration
      }
    });

    if (duration != null && duration > 0)
      setTimeout(async () => {
        this.setState(
          { "timelineAlertState": { "message": null, "variant": "info", "duration": -1 } })
      }, duration);
  }

  renderAlertMessage() {
    const { timelineAlertState } = this.state;

    if (timelineAlertState.message == null || timelineAlertState.message == "") return null;

    return <Alert variant={timelineAlertState.variant} onClose={() => this.setState(
      { "timelineAlertState": { "message": null, "variant": "info", "duration": -1 } })}
      dismissible={timelineAlertState.duration == null || timelineAlertState.duration < 0}>
      {timelineAlertState.message}
    </Alert>
  }

  renderAttachmentsAndVideoArea = () => {
    const { t } = this.props;
    const { videoItems, currentPositionDate, videoUpdateCode, timelineIsPlaying } = this.state;


    return (

      (containerWidth, containerHeight, visible) => (

        <div>
          <SplitterLayout percentage secondaryInitialSize={55} primaryMinSize={2} secondaryMinSize={2}>


            <div className="attachmentsHandle" style={{ display: 'block', width: '100%' }}>
              <CardTitle className="handle" style={{
                color: "white", background: "#007bff",
                paddingTop: '8px', paddingBottom: '8px',
                cursor: "move"
              }}><h4><b><center>{t("tl:Sezione Allegati")}</center></b></h4></CardTitle>

              <div style={{ 'backgroud': "white", 'overflowY': 'auto', 'height': containerHeight - 80 }}>
                {this.renderItemsPreview()}
              </div>
            </div>

            <div className="videoHandle" style={{ display: 'block', width: '100%' }}>
              <CardTitle className="handle" style={{
                color: "white", background: "#007bff",
                paddingTop: '8px', paddingBottom: '8px',
                cursor: "move"
              }}><h4><b><center>{t("tl:Sezione Video")}</center></b></h4>
              </CardTitle>
              <div style={{ 'overflowY': 'auto', 'height': containerHeight - 80 }}>

                <RialeVideoSyncPlayer items={videoItems}
                  maxPlayers={4}
                  currentPositionDate={currentPositionDate}
                  videoUpdateCode={videoUpdateCode}
                  timelinePlaying={timelineIsPlaying}
                  onProgress={this.handleProgress}
                  onEnded={this.handleEnded}
                  onStartLoading={this.handleStartVideoLoading}
                  onEventToWatch={this.onEventToWatch}
                  onPauseTimeline={() => this.pauseTimeline()}
                  onPlayTimeline={() => this.playTimeline()}
                  onDatetimeChangeRequest={this.handlePlayerDatetimeChanged}
                  areVideoLoading={this.state.areVideoLoading}
                />

              </div>
            </div>




          </SplitterLayout>
          <div>
            <h4>
              <span
                className="handle"
                style={{
                  cursor: "move",
                  color: "#007bff",
                  width: "100%",
                  display: 'flex',
                  justifyContent: 'center',
                }}>
                {visible && (
                  <IconButton
                    style={{
                      verticalAlign: 'top', marginLeft: 'auto'
                    }}

                    onClick={() => {
                      this.toggleAttachments();
                    }}>

                    <IconContext.Provider style={{ verticalAlign: 'top' }}
                      value={{ color: "white", background: "#007bff", size: "1.0em" }}>
                      <IoIosCloseCircleOutline />
                    </IconContext.Provider>
                  </IconButton>)
                }
              </span>
            </h4>
          </div>

        </div>
      ))
  }

  confirmItemDelete = (item) => {
    //console.log("In confirmItemDelete");
    const selectedItem = item != null ? item : this.state.selectedItem;
    // se non ho selezionato un item o se l'item è appartenente a una traccia in 
    // stato di lock, non procedo con la procedura di eliminazione
    if (selectedItem == null
      || this.state.lockedGroups[selectedItem.track]
    ) return;

    const { t } = this.props;

    const options = {
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui' style={{ border: "2px solid #007bff", borderRadius: '0.25rem' }}>
            <Card style={{ padding: "10px" }} fluid>


              <CardTitle><b>{`${t("tl:item_delete")}`}</b></CardTitle>
              <CardText>
                {`${t("tl:item_delete_confirm")} ${selectedItem.title}?`}
              </CardText>
              <CardBody>
                <center>
                  <Button color="secondary" style={{ marginRight: "5px" }}
                    onClick={() => {
                      this.onItemDeleted(selectedItem);
                      onClose();
                    }}
                  >
                    {t("tl:yes")}
                  </Button>
                  <Button color="secondary" onClick={onClose}>No</Button>
                </center>
              </CardBody>
            </Card>
          </div>
        );
      }
    };

    confirmAlert(options);
  }

  eventFire(el, etype) {
    if (el.fireEvent) {
      (el.fireEvent('on' + etype));
    } else {
      var evObj = document.createEvent('Events');
      evObj.initEvent(etype, true, false);
      //console.log("FOCUS ON TIMELINE Event", el)
      //el.dispatchEvent(evObj);
    }
  }

  focusTimeline = () => {
    //.selectItem(this.state.selectedItem);  //this.eventFire(this.innerTimelineRef.current,'Click');   
    //console.log("FOCUS ON TIMELINE...focus..", this.innerTimelineRef);
    //this.innerTimelineRef.container.firstElementChild.click()
    //this.eventFire(this.innerTimelineRef,'click');
  }

  renderTimePlayer() {
    const { items, selectedItem,
      timelineIsPlaying,
      areVideoLoading,
      currentPositionDate, draggingItem, itemDraggingTime,
      timelineWindowDuration, timelineEditingEnabled } = this.state;
    return (<TimelinePlayer
      currentPositionDate={currentPositionDate}
      toolbarPosition={this.state.toolbarPosition}
      onChangeToolbarPosition={(newPos) => { localStorage.setItem("toolbarPosition", newPos); this.setState({ toolbarPosition: newPos }) }}
      items={items}
      firstItemStartTime={this.state.firstItemStartTime}
      lastItemEndTime={this.state.lastItemEndTime}
      canEdit={this.props.canEdit}
      visibleTimeStart={this.state.visibleTimeStart}
      visibleTimeEnd={this.state.visibleTimeEnd}
      zoomDuration={timelineWindowDuration}
      onZoomChanged={this.handleZoomChanged}
      selectedItem={selectedItem}
      draggingItem={draggingItem}
      itemDraggingTime={itemDraggingTime}
      isPlaying={timelineIsPlaying}
      areVideoLoading={areVideoLoading}
      isAttachmentsPanelVisible={this.state.attachmentsPanelVisible}
      isTimeCursorVisible={this.state.showTimeCursor}
      isEditingEnabled={timelineEditingEnabled}
      isItemsNavigatorPanelVisible={this.state.itemsNavigatorPanelVisible}
      isPublic={this.props.isPublic}
      onPlay={this.playTimeline}
      onPause={this.pauseTimeline}
      onEventToWatch={this.onEventToWatch}
      onToggleTimeCursor={this.toggleTimeCursor}
      onToggleTimelineEditing={this.toggleTimelineEditing}
      onToggleAttachments={this.toggleAttachments}
      onAddBookmark={this.addTimelineBookmark}
      onToggleItemsNavigator={this.toggleItemsNavigator}
      onDateChanged={this.handlePlayerDatetimeChanged}
      onGoHomeRequest={this.goHome}
      onGoToEndRequest={this.gotoEnd}
      onCopyItemToClipboard={this.copyItemToClipboard}
      onCopyLinkToClipboard={this.copyLinkToClipboard}
      onPasteItemFromClipboard={this.pasteItemFromClipboard}
      onUndo={this.handleUndo}
      onRedo={this.handleRedo}
      canUndo={this.props.canUndo}
      canRedo={this.props.canRedo}
      onScrollTo={this.scrollTo}
    />)
  }

  render() {

    const { t } = this.props;

    const { items, groups, selectedItem, selectedGroup, addModalOpen, videoItems,
      visibleTimeStart, visibleTimeEnd, timelineIsPlaying,
      firstItemStartTime, lastItemEndTime,
      currentPositionDate, autoScroll,
      areVideoLoading, timelineEditingEnabled, duplicateRequest } = this.state;

    const newGroups = groups
      .map(group => {
        return Object.assign({}, group, {
          title: (
            <div>{this.renderLockIcon(group, this.state.isSidebarOpen, this.props.canEdit && this.state.timelineEditingEnabled)}{' '}<b>{t(group.title)}</b></div>
          )
        });
      });


    const itemGroup = (selectedItem != null) ? this.getGroupById(selectedItem.track) : selectedGroup;


    var timelineCommonProps = {
      scrollRef: el => (this.scrollRef = el),
      ref: el => { this.innerTimelineRef = el },
      groups: newGroups,
      items: items,
      itemTouchSendsClick: true,
      keys: keys,
      lineHeight: 50,
      itemHeightRatio: 0.65,
      minZoom: MIN_ZOOM, // 10 minuti in ms
      maxZoom: 1 * MAX_ZOOM, // 1 anno in ms
      //traditionalZoom : true,
      itemTouchSendsClick: false,
      sidebarWidth: this.state.isSidebarOpen ? 230 : 50,
      fullUpdate: true,
      onItemSelect: this.handleItemSelect,
      onItemDeselect: this.handleItemDeselect,
      onItemDrag: this.handleItemDrag,
      onItemClick: this.handleItemClick,
      onItemDoubleClick: this.handleItemDoubleClick,
      onCanvasDoubleClick: this.handleCanvasDoubleClick,
      onCanvasClick: this.handleCanvasClick,
      onZoom: this.handleZoom,
      onBoundsChange: (canvasTimeStart, canvasTimeEnd) => {
        ////console.log("BOUNDS CHANGED!")
      },
      onItemContextMenu: this.handleItemContextMenu,
      onCanvasContextMenu: this.handleCanvasContextMenu,
      timeSteps: {
        second: 1,
        minute: 1,
        hour: 1,
        day: 1,
        month: 1,
        year: 1,
      },
      resizeDetector: containerResizeDetector,
      dragSnap: 1 * 1000, // risoluzione di 1 secondo
      onItemMove: this.handleItemMove,
      onItemResize: this.handleItemResize,
      verticalLineClassNamesForTime: this.verticalLineClassNamesForTime,
      itemRenderer: this.itemRenderer,
      style: { zIndex: 10 },
      onTimeChange: this.handleTimeChange,
    }

    // zoom e scroll manuale nel caso il player della timeline non sia in esecuzione e non stia
    // forzando lo scroll automatico
    if (!timelineIsPlaying && !autoScroll) {
      // zoom manuale 
      timelineCommonProps.defaultTimeStart = moment(visibleTimeStart);
      timelineCommonProps.defaultTimeEnd = moment(visibleTimeEnd);
    }
    // se la timeline è in Play oppure sono in modalità di autoScroll forzato, lo scrolling è gestito dalla TimelineToolbar
    else {
      // zoom programmato
      timelineCommonProps.visibleTimeStart = parseInt(visibleTimeStart.valueOf());
      timelineCommonProps.visibleTimeEnd = parseInt(visibleTimeEnd.valueOf());
    }



    return (

      <div id="portalareadiv" className="portalarea" ref={this.rialeTimelineRef}>

        <KeyboardEventHandler
          handleKeys={['del', 'alt+home', 'alt+end', 'ctrl+c', 'ctrl+v', 'ctrl+z', 'ctrl+y', 'ctrl+b', 'ctrl+shift+z', 'alt+p', 'alt+a', 'alt+n', 'alt+h', 'alt+pageup', 'alt+pagedown']}
          onKeyEvent={(key, e) => {
            //console.log(`KEY EVENT:do something upon keydown event of ${key}`);
            if (key == "del") {
              this.confirmItemDelete();
            }
            else if (key == "ctrl+c") {
              //console.log("KEY EVENT:Copia!")
              this.copyItemToClipboard();
            }
            else if (key == "ctrl+v") {
              //console.log("KEY EVENT:Incolla!")
              this.pasteItemFromClipboard();
            }
            else if (key == "ctrl+z") {
              //console.log("KEY EVENT:Undo!")
              this.handleUndo();
            }
            else if (key == "ctrl+y" || key == "ctrl+shift+z") {
              //console.log("KEY EVENT:Redo!")
              this.handleRedo();
            }
            else if (key == "ctrl+b") {
              //console.log("KEY EVENT:Add Bookmark")
              this.addTimelineBookmark();
            }
            else if (key == "alt+p") {
              //console.log("KEY EVENT:togglePlayPauseTimeline")
              this.togglePlayPauseTimeline();
            }
            else if (key == "alt+a") {
              //console.log("KEY EVENT:toggleAttachments")
              this.toggleAttachments();
            }
            else if (key == "alt+n") {
              //console.log("KEY EVENT:toggleItemsNavigator")
              this.toggleItemsNavigator();
            }
            else if (key == "alt+pageup") {
              //console.log("PAGE UP")
              this.gotoSelectedItemStart();
            }
            else if (key == "alt+pagedown") {
              //console.log("PAGE DOWN")
              this.gotoSelectedItemEnd();
            }
            else if (key == "alt+h") {
              //console.log("GO HOME")
              this.goHome()
            }
            else if (key == "alt+end") {
              //console.log("GOTOEND")
              this.gotoEnd()
            }
          }}
        />


        <div className="my-pane" style={{
          borderStyle: "solid", borderColor: "grey",
          'overflowY': 'auto', 'height': '100%'
        }}>




          {addModalOpen && (


            <ModalItemEditor buttonLabel="Aggiungi"
              experimentId = {this.props.id}
              isOpen={addModalOpen}
              toggle={this.toggle}
              onItemEdited={this.onItemEdited}
              onItemDeleted={this.confirmItemDelete}
              visibleTimeStart={parseInt(visibleTimeStart.valueOf())}
              visibleTimeEnd={parseInt(visibleTimeEnd.valueOf())}
              item={selectedItem}
              track={itemGroup}
              start={currentPositionDate.toDate()}
              firstItemStartTime={firstItemStartTime}
              lastItemEndTime={lastItemEndTime}
              duplicate={duplicateRequest}
            />
          )}

          <div id="itemsNavigator"></div>
          <div id="portal"></div>
          {this.state.toolbarPosition == ToolbarPosition.TOP && (<center>
            {this.renderTimePlayer()}
            {/*<ReactTooltip id="toolbar"/>*/}
          </center>
          )}


          <div>
            {
              <ContextMenu style={{fontSize:"0.8em"}}  ref={this.contextCanvasMenuRef}
                model={[

                  {
                    visible: (this.state.selectedGroup && !this.state.lockedGroups[this.state.selectedGroup.id] && this.props.canEdit && this.state.timelineEditingEnabled),
                    label: `${t("context_menu_new")} ${t(`${this.state.selectedGroup?.type}`) || ""}`,
                    icon: 'fa fa-plus',
                    command: (e) => {
                      this.state.selectedGroup && this.handleCanvasDoubleClick(
                        this.state.selectedGroup.id, this.state.currentPositionDate, e)
                    }
                  },

                  {
                    label: `${t("context_menu_new_bookmark")} (ctrl+b)`,
                    icon: 'fa fa-bookmark', command: (e) => {
                      this.addTimelineBookmark();
                    }
                  },

                  {
                    label: `${t("context_menu_paste")} (ctrl+v)`, icon: 'fa fa-paste',
                    visible: (this.props.canEdit && this.state.timelineEditingEnabled),
                    command: (e) => {
                      this.pasteItemFromClipboard();
                    }
                  },

                ]

                }
              />

            }

            <ContextMenu style={{fontSize:"0.8em"}} ref={this.contextMenuRef} 
              model={[

                {
                  label: `${t("context_menu_duplicate")}`,
                  visible: ( this.state.selectedItem!=null  && !this.state.lockedGroups[selectedItem.track] 
                            && this.props.canEdit && this.state.timelineEditingEnabled),
                  icon: 'fa fa-clone', command: (e) => {
                    this.handleItemDuplicate();
                  }
                },

                {
                  label: `${t("context_menu_copy")} (ctrl+c)`,
                  icon: 'fa fa-copy', command: (e) => {
                    this.copyItemToClipboard();
                  }
                },
                {
                  label: `${t("context_menu_paste")} (ctrl+v)`, icon: 'fa fa-paste',
                  visible: (this.props.canEdit && this.state.timelineEditingEnabled),
                  command: (e) => {
                    this.pasteItemFromClipboard();
                  }
                },

                {
                  label: `${t("tip_goto_selected_item_start")} (alt+PageUp)`, icon: 'fa fa-step-backward',
                  visible: (this.state.selectedItem!=null),
                  command: (e) => {
                    this.gotoSelectedItemStart();
                  }
                },
                {
                  label: `${t("tip_goto_selected_item_end")} (alt+PageDown)`, icon: 'fa fa-step-forward',
                  visible: (this.state.selectedItem!=null),
                  command: (e) => {
                    this.gotoSelectedItemEnd();
                  } 
                },
        {
                  label: t("context_menu_edit"), icon: 'fa fa-pencil',
                  visible: (this.state.selectedItem && this.props.canEdit && !this.state.lockedGroups[this.state.selectedItem.track] && this.state.timelineEditingEnabled),
                  command: (e) => {
                    this.handleItemDoubleClick(this.state.selectedItem?.id, e, this.state.currentPositionDate);
                  }
                },
                {
                  visible: (this.state.selectedGroup && !this.state.lockedGroups[this.state.selectedGroup.id] && this.props.canEdit && this.state.timelineEditingEnabled),
                  label: `${t("context_menu_new")} ${t(`${this.state.selectedGroup?.type}`) || ""}`,
                  icon: 'fa fa-plus',
                  command: (e) => {
                    if (this.state.selectedGroup) {
                      this.setState({ selectedItem: null }, () => {
                        this.handleCanvasDoubleClick(
                          this.state.selectedGroup.id, this.state.currentPositionDate, e)
                      })

                    }
                  }
                },

                {
                  label: `${t("context_menu_new_bookmark")} (ctrl+b)`,
                  icon: 'fa fa-bookmark', command: (e) => {
                    this.addTimelineBookmark();
                  }
                },

                {
                  label: `${t("context_menu_delete")} (canc)`,
                  visible: (this.state.selectedItem && this.props.canEdit && !this.state.lockedGroups[this.state.selectedItem.track] && this.state.timelineEditingEnabled),
                  icon: 'fa fa-trash',
                  command: (e) => {
                    this.state.selectedItem && this.confirmItemDelete(this.state.selectedItem);
                  }
                }]}

            />
          </div>

          <Timeline id="timeline" {...timelineCommonProps}>

            <TimelineHeaders className="sticky">
             
              <SidebarHeader>
                {({ getRootProps }) => {
                  return (
                    this.state.isSidebarOpen ? (
                      <div {...getRootProps()} className="text-primary" style={{
                        width: timelineCommonProps.sidebarWidth,
                        paddingTop: "4px", paddingBottom: "4px", background: "#007bff", marginTop: "15px",
                      }}>
                        <span style={{
                          marginLeft: "15px", color: "#FFFFFF", textDecoration: 'none',
                          fontSize: "1.1em", fontWeight: 'bolder'
                        }}></span>
                        <FaChevronCircleLeft data-for={"menu"} data-tip={t("Iconizza il menu")}
                          style={{
                            cursor: "pointer", marginTop: "4px", color: "#FFFFFF",
                          }}
                          className="mr-2 pull-right" onClick={() => {
                            this.setState({ isSidebarOpen: false })
                            localStorage.setItem("isSidebarOpen", "false")
                            //@audit info inventarsi qualcosa per forzare il refresh

                          }} />
                      </div>
                    ) :
                      (
                        <div {...getRootProps()} className="text-primary"
                          style={{
                            width: timelineCommonProps.sidebarWidth, paddingTop: "8px",
                            paddingBottom: "10px", background: "#007bff", marginTop: "15px",
                          }}>
                          <GiHamburgerMenu data-for={"menu"} data-tip={t("Ingrandisci il menu")} style={{
                            cursor: "pointer", color: "#FFFFFF", background: "#007bff",
                            marginTop: "5px", marginLeft: "18px"
                          }}
                            className="mr-2" onClick={() => {
                              this.setState({ isSidebarOpen: true })
                              localStorage.setItem("isSidebarOpen", "true")
                            }} />
                        </div>
                      )
                  )
                }}
              </SidebarHeader>
              {/*<DateHeader unit="hour" labelFormat={
                  (momentInterval, unit, labelWidth) => {
                    return `${getFormattedTime(this.state.firstItemStartTime, 
                      this.state.visibleTimeStart)} - ${
                        getFormattedTime(this.state.firstItemStartTime, 
                          this.state.visibleTimeEnd)}`
                  }
                  } />*/}
              <DateHeader
                data={{ "firstItemStartTime": this.state.firstItemStartTime }}
                style={{ height: 50 }}
                labelFormat={
                  (momentInterval, unit, labelWidth) => {
                    let myFormat = null;
                    if (labelWidth < 50) myFormat = "mm"
                    else if (labelWidth < 150) myFormat = "HH:mm"

                    if (myFormat == null) return `${getFormattedTime(this.state.firstItemStartTime, momentInterval[0])} - ${getFormattedTime(this.state.firstItemStartTime, momentInterval[1])}`
                    else return `${getFormattedTime(this.state.firstItemStartTime, momentInterval[0], myFormat)}`
                  }
                }
                intervalRenderer={({ getIntervalProps, intervalContext }) => {
                  const isBetween = this.props.bookmarks.some((bookmark) => {
                    return moment(bookmark.position).isBetween(moment(intervalContext.interval.startTime), moment(intervalContext.interval.endTime))
                  })
                  const customStyle = isBetween ?
                    {
                      cursor: "pointer", fontSize: "14px", paddingTop: "3px", border: '0.5px solid #111111',
                      background: "green", color: "white", height: "100%"
                    }
                    :
                    {
                      cursor: "pointer", fontSize: "14px", paddingTop: "3px", border: '0.5px solid #111111',
                      background: "#EEEEEE", color: "blue", height: "100%"
                    }

                  return (
                    <div
                      {...getIntervalProps({
                        style: customStyle
                      })}
                    >      <center>
                        {intervalContext.intervalText}
                      </center>
                    </div>
                  );
                }}
              />


            </TimelineHeaders>
            {this.renderMarkersTag()}


            <CustomMarker date={parseInt(currentPositionDate.valueOf())}>

              {({ styles, date }) => {
                const customStyles = {
                  ...styles,
                  backgroundColor: 'red',
                  width: '2px',
                  zIndex: 200
                }
                return <div style={customStyles}>
                </div>
              }

              }
            </CustomMarker>

            <CursorMarker>
              {({ styles, date }) => {
                const customStyles = {
                  ...styles,
                  backgroundColor: 'black',
                  width: this.getTimeCursorWidth(),
                  zIndex: 200,
                  top: 0
                }
                //return <div style={{...customStyles}}><span style={{color:'red', backgroundColor:'yellow', 'margin-left': '5px'}}><b>&nbsp;{moment(date).format("HH:mm:ss.SSS")}&nbsp;</b></span></div>
                return this.getTimeCursorContent(date, customStyles)
              }
              }
            </CursorMarker>


          </Timeline>
         
          {timelineIsPlaying && areVideoLoading && <LinearProgress />}
          {/** perchè c'era questo div?? [@todo]
          <div style={{ margin:"50px", width: "100%", height: "24px" }}>
          </div>
        */}
          <center>
            <LiteTimelineValidatorViewer 
                          firstItemStartTime  = {this.state.firstItemStartTime}
                          liteCompliantSections = {this.props.liteCompliantSections}
                          onErrorClicked={this.handlePlayerDatetimeChanged}
                          activeTab = {this.props.activeTab}  />
            {this.renderAlertMessage()}
            {this.state.toolbarPosition == ToolbarPosition.BOTTOM && (<>
              {this.renderTimePlayer()}
              <ReactTooltip id="toolbar" />
            </>
            )}

          </center>
          {this.renderIotItems()}

        </div>

        <BookmarksEditor
          firstItemStartTime={this.state.firstItemStartTime}
          lastItemEndTime={this.state.lastItemEndTime}
          experimentId={this.props.id}
          activeTab={this.props.activeTab}
          userId={""}
          onEventToWatch={this.onEventToWatch}
          currentPositionDate={currentPositionDate}
          isOpen={this.state.isBookmarksEditorOpen}
          onClose={() => this.setState({ isBookmarksEditorOpen: false })}
        />

        {this.state.modalPanelsReady &&
          <Portal target="portal">
            <RialeModalPanel visible={this.state.attachmentsPanelVisible}
              title={moment(currentPositionDate).toString()}
              bounds={"window"}
              initialWidth={480}
              initialHeight={this.rialeTimelineRef.current.offsetHeight}
              initialX={this.rialeTimelineRef.current.offsetWidth - 500}
              initialY={0}
              lockAspectRatio={false}
              onEventToWatch={this.onEventToWatch}
              onContainerRectChanged={(state) => {}}
            >
              {this.renderAttachmentsAndVideoArea()}
            </RialeModalPanel>
          </Portal>

        }
        {this.state.modalPanelsReady && this.state.itemsNavigatorPanelVisible &&
          <Portal target="itemsNavigator">
            <RialeModalPanel visible={this.state.itemsNavigatorPanelVisible}
              title={moment(currentPositionDate).toString()}
              bounds={"window"}
              initialWidth={500}
              initialHeight={this.rialeTimelineRef.current.offsetHeight}
              initialX={0}
              initialY={0}
              onContainerRectChanged={(state) => {}}
            >
              {
                (containerWidth, containerHeight, visible) => {
                  return (
                    <RialeItemsNavigator
                      onEventToWatch={this.onEventToWatch}
                      height={containerHeight}
                      onItemSelected={this.handlePlayerDatetimeChanged}
                      onItemEditRequest={this.handleItemDoubleClick}
                      onItemCreateRequest={this.createItemFromNavigator}
                      onBookmarkCreateRequest={this.addTimelineBookmark}
                      onBookmarkUpdateRequest={this.updateTimelineBookmark}
                      onBookmarkSelected={this.handlePlayerDatetimeChanged}
                      onClosePanel={this.toggleItemsNavigator}
                      currentPositionDate={currentPositionDate}
                      title={this.props.title}
                      visible={visible}
                      canEdit={this.props.canEdit && this.state.timelineEditingEnabled}
                      lockedGroups={this.state.lockedGroups}
                      tracks={groups}
                      items={items}
                      firstItemStartTime={this.state.firstItemStartTime}
                      lastItemEndTime={this.state.lastItemEndTime}
                    />)

                }
              }

            </RialeModalPanel>
          </Portal>
        }



      </div>
    )

  } // end of renderer

  onItemChanged = (item, event) => {
    this.props.onItemChanged(item, event);
  }

  handleItemDrag = (itemDragObject) => {
    // n.b questo metodo non dovrebbe essere mai invocato nel caso di canEdit = false
    if (!this.props.canEdit || !this.state.timelineEditingEnabled) {
      //console.log("Utente non abilitato allo spostamento degli item");
      return;
    }

    if (itemDragObject.eventType == "move") {
      //console.log(`Sto spostando l'item ${itemDragObject.itemId} in time: ${itemDragObject.time}`);
      this.setState({
        itemDraggingTime: itemDragObject.time,
        draggingItem: this.getItemById(itemDragObject.itemId)
      });
    }
    else {
      this.setState({ itemDraggingTime: null, draggingItem: null });
    }

  }


  handleItemMove = (itemId, dragTime, newGroupOrder) => {

    //console.log(`Richiamato handleItemMove dell'item ${itemId} sul nuovo gruppo ${newGroupOrder}`);
    const { items, groups } = this.state;

    const track = groups[newGroupOrder];
    //console.log(`Id del gruppo di destinazione: ${track.id}`)
    //console.log(`Id dell'item da analizzare: ${itemId}`)

    if (!this.props.canEdit || !this.state.timelineEditingEnabled) {
      //console.log("Utente NON abilitato allo spostamento degli item");
      return;
    }

    if (this.getItemById(itemId).type !== track.type) {
      //console.log(`Impossibile spostare un item di tipo ${this.getItemById(itemId).type} su una traccia di tipo ${track.type}`);
      return;
    }

    const new_items = items.map(item =>
      item.id === itemId
        ? Object.assign({}, item, {
          start_time: moment(dragTime),
          end_time: moment(dragTime + item.end_time - item.start_time),
          track: track.id
        })
        : item
    );

    //console.log(new_items);
    this.setState({ items: new_items }, () => {
      //console.log(`handleItemMove:Notifico al parent la MODIFICA di un item esistente`);
      this.onItemChanged(this.getItemById(itemId), ItemEvent.CHANGED);

    });


    //console.log("Moved", itemId, dragTime, newGroupOrder);
    //aggiorno l'insieme delle collisioni degli items
    this.updateItemCollisions(this.state.currentPositionDate);
    // sulla base del nuovo tempo aggiorna l'elenco dei video che collidono
    this.updateVideoCollisions(this.state.currentPositionDate, true);
  };

  handleItemResize = (itemId, time, edge) => {
    const { items } = this.state;
    //console.log(`Richiamato Resize on ${edge}`);
    // non consento il resize sulla sinistra

    if (!this.props.canEdit || !this.state.timelineEditingEnabled) {
      //console.log("Utente NON abilitato al ridimensionamento degli item");
      return;
    }

    if (edge !== "right") return;

    this.setState({
      items: items.map(item =>
        item.id === itemId
          ? Object.assign({}, item, {
            start_time: edge === "left" ? moment(time) : moment(item.start_time),
            end_time: edge === "left" ? moment(item.end_time) : moment(time),
            duration: moment(time).diff(moment(item.start_time), 'seconds', true)
          })
          : item
      )
    }, () => {
      this.setState({ selectedItem: this.getItemById(itemId) }, () => {
      });
      //console.log(`handleItemResize:Notifico al parent la MODIFICA di un item esistente`);
      this.props.onItemChanged(this.getItemById(itemId), ItemEvent.CHANGED)

    });

    //console.log("Resized", itemId, moment(time).format(), edge);
  };

  handleZoom = (timelineContext) => {
    // var x = new moment()
    //var y = new moment()
    //var duration = moment.duration(x.diff(y))
    // returns duration object with the duration between x and y
    const newWindowDuration = moment.duration(moment(timelineContext.visibleTimeEnd).diff(timelineContext.visibleTimeStart));
    ////console.log(`HandleZoom con newWindowDuration:${newWindowDuration.humanize()}`);
    this.setState({
      timelineWindowDuration: newWindowDuration,
      visibleTimeStart: timelineContext.visibleTimeStart,
      visibleTimeEnd: timelineContext.visibleTimeEnd
    });
  }

  handlePlayerDatetimeChanged = (newPositionDate) => {
    //console.log("newPositionDate->", newPositionDate.valueOf());
    //console.log("newPositionDate (OR)->", moment(newPositionDate.valueOf()).format());
    if (!moment(newPositionDate).isValid()) {
      //console.log(`Invalid Date: ${newPositionDate}`);
      return;
    }

    this.updateCurrentDatetimePosition(newPositionDate);
    // sulla base del nuovo tempo aggiorna l'elenco dei video che collidono
    this.updateVideoCollisions(newPositionDate, true);
  }


  copyItemToClipboard = () => {
    const { t } = this.props;
    if (this.state.selectedItem != null) {
      navigator.clipboard.writeText(JSON.stringify(this.state.selectedItem));
      this.setAlertMessage(t("tl:copy_success", { "itemTitle": this.state.selectedItem["title"] }),
        "info", 3000);
    }
  }

  copyLinkToClipboard = () => {
    const { t } = this.props;
    const { currentPositionDate } = this.state
    const urlString = window.location.href.split("?")[0];
    const url = `${urlString}?start_time=${moment(currentPositionDate).valueOf()}&active_tab=${this.props.activeTab}`
    if (url != null) {
      navigator.clipboard.writeText(url);
      this.setAlertMessage(t("tl:copy_link_success", { "url": url }),
        "info", 3000);
    }
  }




  pasteItemFromClipboard = async () => {
    const { t } = this.props;
    setTimeout(async () => {
      const jsonItem = await navigator.clipboard.readText();
      //console.log('clipboard contents with timeout', jsonItem)
      try {
        let item = JSON.parse(jsonItem)
        //console.log("Pasted Item:", item)
        //console.log("Pasted Item Locked groups:", item)

        const targetTrack = getTrackById(this.props.tracks, item.track)
        //console.log("Target Track;", targetTrack)
        if (targetTrack == null) throw "Invalid Track Value (null)";

        if (this.state.lockedGroups[item.track]) {
          this.setAlertMessage(t("tl:paste_forbidden", {
            "itemTitle": item["title"],
            "trackTitle": targetTrack["title"]
          }),
            "danger", -1);
          //console.log("Pasted Item: Impossibile incollare")
          return;
        }


        // change id (it is a new Item) and start time and emd time based on current datetime timeline position
        //if (item["title"]==null || item["source"])
        item["id"] = uuidv4();
        item["end_time"] = moment(this.state.currentPositionDate).add(moment(item["end_time"])).subtract(moment(item["start_time"]))
        item["start_time"] = moment(this.state.currentPositionDate)

        if (item["end_time"].isBefore(item["start_time"]))
          throw "Invalid item duration (it cannot be <0";

        //console.log("Pasted Item (date changed):", item)
        this.onItemEdited(item);
        this.handleItemSelect(item.id, null, moment(item["end_time"]));

        const DATE_TIME_FORMAT = "DD/MM/YYYY HH:mm:ss";
        this.setAlertMessage(t("tl:paste_success", {
          "itemTitle": item["title"],
          "trackTitle": targetTrack["title"], "itemPosition": moment(item["start_time"]).format(DATE_TIME_FORMAT)
        }),
          "info", 2500);
      } catch (e) {
        //console.log("Error parsing clipboard", e)
        this.setAlertMessage(t("tl:paste_format_error"),
          "danger", -1);
      }
    }, 100);
  }

  handleCanvasClick = (groupId, time, e) => {
    this.onEventToWatch(TraceEvent.CLICK_TIMELINE,
      { "position": time });

    if (moment(time).isBefore(this.state.firstItemStartTime) || moment(time).isAfter(this.state.lastItemEndTime)) {
      //console.log("TLBOUNDS: out of bounds!")
      return;
    }

    this.setState({
      currentPositionDate: moment(time),
      selectedGroup: this.getGroupById(groupId),
    }, () => {
      // sulla base del nuovo tempo aggiorna l'elenco degli item che collidono
      this.updateItemCollisions(time);

      // sulla base del nuovo tempo aggiorna l'elenco dei video che collidono
      this.updateVideoCollisions(time, true);

    });
  }

  handleItemDuplicate = () => {
   
   const {selectedItem} = this.state;
   //console.log("handleItemDuplicate on:", selectedItem);
    if (selectedItem != null) {
   
      if (this.state.timelineIsPlaying && this.props.canEdit) { this.pauseTimeline(); }
      const newPositionDate =  this.state.currentPositionDate;
      const selectedGroup = this.getGroupById(selectedItem.track)
      this.setState({
        currentPositionDate: newPositionDate,
        addModalOpen: this.props.canEdit && !this.state.lockedGroups[selectedItem.track] && this.state.timelineEditingEnabled,
        selectedItem: this.state.selectedItem,
        duplicateRequest:true,
        selectedGroup: selectedGroup,
        modalUpload: false
      })
    }
  }
  handleItemDoubleClick = (itemId, e, time) => {
    if (this.state.timelineIsPlaying && this.props.canEdit) { this.pauseTimeline(); }


    const selectedItem = this.getItemById(itemId);

    const newPositionDate = moment(time).isValid() ? moment(time) : this.state.currentPositionDate;
    const selectedGroup = this.getGroupById(selectedItem.track)
    //console.log(`handleItemDoubleClick  CanEdit:${this.props.canEdit} Editing Enabled: ${this.state.timelineEditingEnabled}`);

    this.setState({
      currentPositionDate: newPositionDate,
      addModalOpen: this.props.canEdit && !this.state.lockedGroups[selectedItem.track] && this.state.timelineEditingEnabled,
      selectedItem,
      selectedGroup: selectedGroup,
      modalUpload: false
    })

    // sulla base del nuovo tempo aggiorna l'elenco dei video che collidono
    this.updateVideoCollisions(time, true);

  }

  createItemFromNavigator = (groupId, time, e) => {
    this.setState({ selectedItem: null }, () => {
      this.handleCanvasDoubleClick(groupId, time, e)
    })
  }

  // Gestisce il click sui punti della timeline dove NON ci sono item
  handleCanvasDoubleClick = (groupId, time, e) => {

    // se il click viene eseguito su una traccia con il lock, ignoro il doppio click
    if (this.state.lockedGroups[groupId]) {
      //console.log(`Traccia ${groupId} in stato di lock: ignoro il doppio click`);
      return;
    }


    if (this.state.timelineIsPlaying && this.props.canEdit && this.state.timelineEditingEnabled) {
      this.pauseTimeline();
    }

    // sulla base del nuovo tempo aggiorna l'elenco dei video che collidono
    this.updateVideoCollisions(time, true);

    ////console.log(`canvas Click sul tempo: ${moment(time)} Evento:${e}`);
    if (groupId == null) {
      alert("Doppio click con zona non associata a un gruppo")
      return;
    }

    const selectedGroup = this.getGroupById(groupId)
    //console.log(`Canvas double click su gruppo ${selectedGroup.id}: ${selectedGroup.title} CanEdit:${this.props.canEdit} Editing enabled: ${this.state.timelineEditingEnabled}`);
    //alert(`Istante selezionato: ${ moment(time)}`);

    this.setState({
      currentPositionDate: moment(time),
      addModalOpen: this.props.canEdit && this.state.timelineEditingEnabled,
      selectedGroup
    })
  }

  handleItemDeselect = (e) => {
    this.setState({
      itemDraggingTime: null,
      draggingItem: null, selectedItem: null
    });
  }

  handleItemContextMenu = (itemId, e, time) => {
    //console.log("CM->handleItemContextMenu");
    if (this.contextMenuRef.current) {
      //console.log("CM->handleItemContextMenu show", this.contextMenuRef.current.getElement());
      e.persist();
      this.contextCanvasMenuRef.current?.hide(e);
      this.contextMenuRef.current.show(e);
    }
  }

  handleCanvasContextMenu = (groupId, time, e) => {
    const selectedGroup = this.getGroupById(groupId);
    this.setState({ selectedGroup });

    //console.log("CM->handleCanvasContextMenu");
    if (this.contextCanvasMenuRef.current) {
      //console.log("CM->handleCanvasContextMenu show", this.contextCanvasMenuRef.current.getElement());
      e.persist();
      this.contextMenuRef.current?.hide(e);
      this.contextCanvasMenuRef.current.show(e);
    }
  }



  //Gestisce la selezione di un item (richiamato solo se l'item non era già selezionato)
  handleItemSelect = (itemId, e, time) => {

    const item = this.getItemById(itemId);

    const track = this.getGroupById(item.track);
    const source_url = item.source;
    //console.log(`Selezionato item ${itemId} su gruppo ${track.id} con sorgente::: ${source_url}`);
    //console.log(`Item select sul tempo: ${time} Evento:${e}`);

    this.setState({
      currentPositionDate: moment(time),
      selectedItem: item,
      selectedGroup: track,
      itemDraggingTime: null,
      draggingItem: null
    }, () => {
      //console.log(`Dopo aggiornamento di stato il gruppo vale: ${track}`);
      //aggiorno l'insieme delle collisioni degli items
      this.updateItemCollisions(time);
      this.updateVideoCollisions(time, true);

    })

  }

  handleUndo = () => {
    if (this.props.canEdit && this.state.timelineEditingEnabled) {
      this.props.onUndo();
    }

  }

  handleRedo = () => {
    if (this.props.canEdit && this.state.timelineEditingEnabled) {
      this.props.onRedo();
    }
  }

  loadVideo = (item, time) => {
    // differenza di tempo in millisecondi
    const videoPos = moment(time).diff(item.start_time);
    const videoPosInSeconds = parseInt(videoPos / 1000);

    //console.log(`Carico il video ${item.source} e lo posiziono al secondo ${videoPosInSeconds}`)
    this.changeVideoSource(item.source, videoPosInSeconds);
  }

  // gestisce il click su un item (richiamato DOPO la callback onItemSelect)
  handleItemClick = (itemId, e, time) => {


    const item = this.getItemById(itemId);
    const source_url = item.source;
    //console.log(`Cliccato item ${itemId} del gruppo ${item.track} con sorgente::: ${source_url}`);
    //console.log(`Item Click sul tempo: ${time} Evento:${e}`);
    const track = this.getGroupById(item.track)
    ////console.log("Metto in pausa il player...");
    //this.player.pause();

    this.setState({
      currentPositionDate: moment(time),
      selectedItem: item,
      selectedGroup: track

    }, () => {
      //aggiorno l'insieme delle collisioni degli items
      this.updateItemCollisions(time);
      // sulla base del nuovo tempo aggiorna l'elenco dei video che collidono
      this.updateVideoCollisions(time, true);

    })
  }



  // Verificare perchè questo metodo non viene mai chiamato
  handleCustomMarker = () => {
    //console.log("Marker cliccato");
  }

}


export const InnerRialeTimelineViewer = withTranslation("tl", { withRef: true })(withWatcher((RialeTimelineViewerNT)));

const RialeTimelineViewer = forwardRef((props,ref)=>{

  const { activeTab, sections, id } = props;
  const dispatch = useDispatch();
  const bookmarks = useSelector(BookmarksSelectors.getBookmarks);
  const currentPassCode = useSelector(PassCodeSelector.getCurrentPassCode);
  const userAttributes = useSelector(ProfileSelectors.getProfile)
  const userEmail = userAttributes?.email
  const innerTimelineRef = useRef();

  const dispatchSelectedBookmark = (bookmark) => {
    dispatch(BookmarksActions.setSelectedBookmark(bookmark));
  }

  useImperativeHandle(ref, () => ({
    getCurrentPositionDate: () => {if (innerTimelineRef.current!=null) return innerTimelineRef.current.getCurrentPositionDate();
                                   else return "NON PERVENUTO";
      }
  }));

  useEffect(() => {
    dispatch(BookmarksActions.loadBookmarks({
      experimentId: id, activeTab: activeTab, userId: (
        currentPassCode || userEmail || "")
    }))
  }, [activeTab, id, userEmail, currentPassCode]
  )

  ////console.log(`experimentID: ${id} activeTab: ${activeTab}`)
  ////console.log("activeTab", activeTab, sections[activeTab]);
  return (
    <InnerRialeTimelineViewer {...props} ref={innerTimelineRef}
      dispatchSelectedBookmark={dispatchSelectedBookmark}
      bookmarks={bookmarks}
      items={sections && sections[activeTab] ? sections[activeTab]["items"] : []} />
  )
})

export default RialeTimelineViewer