import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from "react-redux";
import { actions as BookmarksActions, selectors as BookmarksSelectors } from '../../store/slices/bookmarks'
import DateTime from 'react-datetime';
import { v4 as uuidv4 } from 'uuid';
import {
    Button, Modal, ModalFooter,
    ModalHeader, ModalBody,
    Form, FormGroup, Label, Input, FormFeedback, FormText
} from "reactstrap"
import { Troubleshoot } from "grommet-icons";
import moment from 'moment';
import  {TraceEvent} from './TimelineWatcher';
import {TimePicker} from './TimelineToolbar'
import { getFormattedTime } from "./Utils";

const BookmarksEditor = (props) => {
    const { currentPositionDate, isOpen, experimentId, activeTab, userId, onClose } = props;
    const bookmark = useSelector(BookmarksSelectors.getSelectedBookmark);
    // Modal open state
    const { t, i18n } = useTranslation('tl', { useSuspense: false });
    const dispatch = useDispatch();
    const [butOkLabel,setButOkLabel] = useState((bookmark == null) ? t("tl:Aggiungi") : t("tl:Conferma_modifiche"));
    const [titleLabel,setTitleLabel] = useState((bookmark == null) ? t("tl:Aggiunta") : t("tl:Modifica"));
    const [id,setId] = useState(bookmark?.id || uuidv4());
    const [titleValue, setTitleValue] = useState("");
    const [descriptionValue, setDescriptionValue] = useState(bookmark?.description || "");
    const [invalidTitle, setInvalidTitle] = useState(true);
    const [invalidStartDate, setInvalidStartDate] = useState(false);
    const [currentDateStart , setCurrentDateStart] = useState(moment(bookmark?.position || currentPositionDate));

    const DATE_TIME_FORMAT = "DD/MM/YYYY HH:mm:ss";
    const DATE_FORMAT = "DD/MM/YYYY";
    const TIME_FORMAT = "HH:mm:ss";

    useEffect(()=>{
        setId(bookmark?.id || uuidv4());
        setTitleValue(bookmark?.title || "");
        setDescriptionValue(bookmark?.description ||"");
        setCurrentDateStart(moment(bookmark?.position || currentPositionDate))
        setButOkLabel((bookmark == null) ? t("tl:Aggiungi") : t("tl:Conferma_modifiche"))
        setTitleLabel((bookmark == null) ? t("tl:Aggiunta") : t("tl:Modifica"))
        setInvalidTitle(bookmark==null || bookmark?.title=="");
    },[bookmark, isOpen])

    const handleTitleChange = (event) => {
        setTitleValue(event.target.value);
        setInvalidTitle(event.target.value == "");
    }

    const handleDescriptionChange = (event) => {
        setDescriptionValue(event.target.value);
    }

    const onChangeDateStart = (newDate) =>
    {
      //console.log(`selezionata DateStart: ${newDate}`);
      try{
        const newDateStart =  moment(newDate,DATE_TIME_FORMAT);
        //console.log(`selezionata DateStart riformattata: ${newDateStart}`);
        if (newDateStart.isValid())
        {
          setCurrentDateStart(newDateStart);
          setInvalidStartDate(false);
        }
        else 
        {
          //console.log(`Invalid DateStart: ${newDate}`);
          setInvalidStartDate(true);
        }
        
      } catch (error){
         //console.log(`Invalid DateStart: ${newDate} -> ${error}`);
         this.setState({invalidStartDate:true});
      }
      
    }

   

    const createBookmark = () =>
    {
        const newBookmark = {"id" : id , "title" : titleValue, "description" : descriptionValue, 
                        "position" : moment(currentDateStart) }
        //console.log(`Adding bookmark:${currentDateStart}`, newBookmark);
        dispatch(BookmarksActions.addBookmark(newBookmark));
        props.onEventToWatch(TraceEvent.ADD_BOOKMARK,newBookmark)
    }

    const updateBookmark = () =>
    {
        const updatedBookmark = {"id" : id , "title" : titleValue, "description" : descriptionValue, 
                        "position" : moment(currentDateStart) }
        //console.log(`Updating bookmark:`, updatedBookmark);
        dispatch(BookmarksActions.updateBookmark(updatedBookmark));
        props.onEventToWatch(TraceEvent.UPDATE_BOOKMARK,updatedBookmark)
    }

    

    const removeBookmark = () => {
        const bookmarkRemoved = {"id" : id , "title" : titleValue, "description" : descriptionValue, 
        "position" : moment(currentDateStart) }
        //console.log("BOOKMARKid request on id:", id);
        dispatch(BookmarksActions.removeBookmark(id));

        props.onEventToWatch(TraceEvent.REMOVE_BOOKMARK,bookmarkRemoved)
    }

    return (
        <Modal isOpen={isOpen}>
            <ModalHeader>
                {titleLabel} {t("tl:Bookmark")}
            </ModalHeader>
            <ModalBody>
                <FormGroup>
                    <Label for="txtTitle">
                        <b>{t("tl:name")}</b></Label>
                    {
                        invalidTitle ? (<Input invalid id="txtTitle" style={{ 'width': '100%' }} type="text" value={titleValue} onChange={handleTitleChange} />
                        ) :
                            (<Input valid id="txtTitle" style={{ 'width': '100%' }} type="text" value={titleValue} onChange={handleTitleChange} />
                            )
                    }
                    <FormFeedback>{t("tl:item_editor_enter_name")}</FormFeedback>
                </FormGroup>
                <FormGroup>
                    <Label for="txtDescription" >
                        <b>{t("tl:description")}</b></Label>
                    <textarea id="txtDescription" style={{ 'width': '100%' }} type="text" value={descriptionValue}
                        onChange={handleDescriptionChange} />
                </FormGroup>

                <FormGroup>
                    {/* 
                    <DateTime
                        onChange={onChangeDateStart}
                        dateFormat={DATE_FORMAT}
                        timeFormat={TIME_FORMAT}
                        initialValue={moment(currentPositionDate).format(DATE_TIME_FORMAT)}
                    />
                    */}

                <TimePicker firstItemStartTime={props.firstItemStartTime} 
                              lastItemEndTime={props.lastItemEndTime} 
                              currentPositionDate= { moment(currentDateStart)}
                              onChange={onChangeDateStart}
                              initialValue={moment.utc(moment.duration(
                                moment(currentDateStart).diff(moment(props.firstItemStartTime))).asSeconds())}
                              />


                    {invalidStartDate && <FormText><span style={{ color: "red" }}>{t("tl:invalidDate")}</span></FormText>}

                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button disabled={invalidTitle || invalidStartDate} color="primary" onClick={() => { 
                    (bookmark ? updateBookmark() : createBookmark()); onClose(); }}>{butOkLabel}</Button>{' '}
                <Button color="secondary" onClick={() => { onClose() }}>{t("tl:Ignora")}</Button>
                {bookmark != null && <Button color="danger" onClick={() => {
                    removeBookmark();
                    onClose() }}>{t("tl:Elimina")}</Button>}

            </ModalFooter>
        </Modal>
    );
}

export default BookmarksEditor;