import React, { useState, useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useDispatch, useSelector } from 'react-redux';
import { selectors as CatalogSelector, actions as CatalogActions } from '../store/slices/catalog'
import {selectors as AppointmentsSelector, actions as AppointmentActions} from '../store/slices/appointments'
import { useTranslation } from 'react-i18next';
import { ButtonToolbar, ButtonGroup } from 'reactstrap';
import {MdConnectedTv} from "react-icons/md";
import { push } from 'connected-react-router';
import ReactTooltip from "react-tooltip";


export const DirectorRemoteDevices = () => {
    
    const { t } = useTranslation('frontend', { useSuspense: false });
    const paginationOptions = {
        sizePerPageList: [{
            text: '5th', value: 5
        }
        ], pageStartIndex: 1
    }

    const experimentsWithInteractiveDevices =
        useSelector(CatalogSelector.getExperimentsWithInteractiveDevices) as any;
    const interactiveDevicesCredentials = 
        useSelector(AppointmentsSelector.getInteractiveDevices) as any;
    

    const [data, setData] = useState([])
    const dispatch = useDispatch();
    
   
    useEffect(() => {
        //console.log("EID ->", experimentsWithInteractiveDevices);
        buildData();
    }, [experimentsWithInteractiveDevices])

    const buildData = () => {
        if (experimentsWithInteractiveDevices == null) { setData([]) }
        else {
            let newData = [] as any;
            for (const exp of experimentsWithInteractiveDevices as any) {
                for(const device of exp.interactive_devices)
                {
                    newData.push({
                        "experiment_id": exp.id,
                        "experiment_title": exp.titolo,
                        "device_id": device.id,
                        "device_title": device.titolo,
                        "device_description": device.descrizione
                    });
                }
            }
            setData(newData);
        }
    }


    const actionsFormatter = (cell: any, value: any) => {
        //console.log("Passo value:", value);
        return (
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
    
            <ButtonToolbar>
              <ButtonGroup>
                <MdConnectedTv  data-tip={t("Collegati al dispositivo")} 
                   cursor="pointer"
                   size={32}
                  style={{ margin: "10px" }} color={interactiveDevicesCredentials[value["device_id"]] ?  "#007bff" : "red"} 
                  onClick={() => {  
                    


                    const remoteDeviceUrl = `/idevices/${value.experiment_id}/${value.device_id}`
                    dispatch(CatalogActions.setCurrentExperimentWithIDevice(value))
                    //dispatch(push(remoteDeviceUrl))
                    const win = window.open(remoteDeviceUrl, 'winname', 'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=1200,height=800') as any;
                    /*
                    win.onload(()=>{
                        //win.postMessage(JSON.stringify({"prova": 1000}),"*");
                    })
                    */
                    win!.focus();
                    }}>
            </MdConnectedTv>
              </ButtonGroup>

            </ButtonToolbar>
          </div>
    
        )
      }

    const columns = [{
        dataField: 'experiment_id',
        text: 'id',
        hidden: true
    },
    {
        dataField: 'device_id',
        text: 'id',
        hidden: true
    },
    {
        dataField: 'device_title',
        text: t('Esperienza')
    },
    {
        dataField: 'experiment_title',
        text: t('Esperimento')
    },
   
    {
        dataField: 'device_description',
        text: t('descrizione')
    },
    {
        dataField: 'content',
        text: t('Azioni'),
        formatter: actionsFormatter,
        headerStyle: () => {
            return { width: '8%' };
        },
        headerAlign: 'center'
      }
]


    return (<>
        <BootstrapTable bootstrap4 keyField='device_id' data={
            data
        }
            columns={columns} pagination={paginationFactory(paginationOptions)} />
    </>)
}