import { useState, useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm'
import rehypeRaw from "rehype-raw";
import SheetEditor from './ISessionSheetEditor';
import { ScreenshotCaptureComponent } from './ScreenshotComponents';
import FileGenerator from './ISessionWorkExporter';
import { Input } from 'reactstrap';
import Figure from 'react-bootstrap/Figure';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faArrowUp, faArrowDown, faTrash } from '@fortawesome/free-solid-svg-icons';
import { RiScreenshot2Fill } from "react-icons/ri";
import { Button, ButtonToolbar } from 'reactstrap';
import question from '../assets/question.png'
import { useTranslation } from 'react-i18next';
import "./MdReport.css"
const MdComment = (props) => {
  const [message,setMessage] = useState(props.value || "");
  return <Input style={{mrginTop:"20px", marginBottom:"20px"}}
    onBlur={(ev)=> {
      console.log(`Comment ${props.index} blurred-> forward content:`, message);
      props.onChange(message, props.index); 
    }}
    type="textarea"
    value={message}
    onChange={(ev) => { setMessage(ev.target.value);}}
    rows= {props.numRows || "5"}
    placeholder= {props.placeholder || "Inserisci il tuo messaggio qui..."}
  />
}
const MdScreenshot = (props) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const { screenshot, index } = props;
  const { t } = useTranslation('frontend', { useSuspense: false });

  //const handleEdit = (index) => {handleEdit}
  //const handleRemove = (index) => {}
   const areButtonVisible = () => 
   {
    return  (screenshot==null) || (hoveredIndex === index && hoveredIndex != null) 
   }

  return (
    <span key={`cont_fig${index}`} style={{padding:"20px"}}
      onMouseEnter={() => {
        setHoveredIndex(index)
        //console.log("on enter");
      }}
      onMouseLeave={() => {
        setHoveredIndex(null);
        //console.log("on leave");
      }}
    >
      <Figure key={`fig${index}`}
        onDoubleClick={(ev) => props.handleEdit(index)}>
        <div style={{ marginTop:"20px", display: "flex", 
                      alignItems: "center", 
                      justifyContent: "flex-start", 
                      flexDirection: "column" }}>
          <Figure.Image
            onDoubleClick={(ev) => props.handleEdit(index)}
            width={screenshot?.src ? "75%" : "35%"}
            height={screenshot?.src ? "75%" : "35%"}
            alt={`screenshot n.${index+1} non caricato`}
            src={screenshot?.src || question}
          />
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Figure.Caption onDoubleClick={(ev) => props.handleEdit(index)}>
            <i>{screenshot?.caption || ""}</i>
          </Figure.Caption>
        </div>
        
        { areButtonVisible() && (
          <ButtonToolbar>
           
            <ScreenshotCaptureComponent
              downloadScreenshot={false}
              onCapture={(image64) => {
                console.log(`Captured image ${index}:`);
                props.onScreenshotCaptured(image64, index)
                //setCurrentScreenshotIndex(screenshots.length)
                //setCurrentScreenshot(image64);

              }}
              elementId={"vncContent"} />

          {
            screenshot && <>
             <Button style={{ marginRight: '5px', marginTop:"10px" }}color="primary" onClick={() => props.handleEdit(index)}>
             <span style={{marginRight:"8px"}}>{t("Modifica")}</span>
              <FontAwesomeIcon icon={faEdit} /></Button>
            <Button style={{ marginRight: '5px', marginTop:"10px" }}color="danger" onClick={() => props.handleRemove(index)}>
              <span style={{marginRight:"8px"}}>{t("Elimina")}</span><FontAwesomeIcon icon={faTrash} />
            </Button>
            </>
          }
           
          </ButtonToolbar>)}
      </Figure>
    </span>
  );
}

const ImageFromUrlToBase64Converter = ({ imageUrl,alt }) => {
  const [base64Image, setBase64Image] = useState('');

  useEffect(() => {
    const loadImageFromUrl = async () => {
      try {
        const response = await fetch(imageUrl);
        const blob = await response.blob();
        const reader = new FileReader();
        reader.onloadend = () => {
          setBase64Image(reader.result);
        };
        reader.readAsDataURL(blob);
      } catch (error) {
        console.error('Error loading image:', error);
      }
    };

    loadImageFromUrl();
  }, [imageUrl]);

  return (
    <div>
      {base64Image && (
        <img src={base64Image} alt={alt} style={{ maxWidth: '100%' }} />
      )}
    </div>
  );
}

const MdReportEditor = (props) => {

  let indiceScreenshot = -1;
  let indiceCommento = -1;
  let idSheet = -1;

  const [markdownContent, setMarkdownContent] = useState('');
  const { t } = useTranslation('frontend', { useSuspense: false });

  //console.log("Found reportTemplate:", props.isessionDevice);
  const reportTemplate = props.isessionDevice["attachments"]["reportTemplate"]

  // Renderer personalizzato per le immagini
  const renderers = {
    sheet: (props2) => {
      //console.log("Trovato sheet con props:", props2);
      idSheet = idSheet + 1
      return <span style={{ paddingBottom: "20px" }}>
        {props.isession != null && props.isessionDevice != null &&
          (
            <SheetEditor
              key = {`sheet_${idSheet}`}
              id = {idSheet}
              index={props2.index ? JSON.parse(props2.index) : 0}
              readOnly={props2.readOnly ? JSON.parse(props2.readOnly) : null}
              maxRows={props2.maxrows ? JSON.parse(props2.maxrows) : null}
              hiddenColumns={props2.hiddencolumns ? JSON.parse(props2.hiddencolumns) : []}
              isession={props.isession}
              isessionDevice={props.isessionDevice}
              onChange={(sheetData, sheetId) => {
                console.log(`Richiesta modifica sheet con id:${sheetId} e index: ${props2.index}`, sheetData);
                props.handleChangeSheetData(sheetData, sheetId);
              }} />
          )
        }

      </span>

    },

    comment: (props2) => {
     
      indiceCommento = indiceCommento + 1
      console.log(`Trovato comment ${indiceCommento} con props:`, props2);
      return <MdComment index={indiceCommento} 
                        value={(props.comments && props.comments[indiceCommento]) || ""}
                        placeholder={props2.placeholder}
                        onChange={(value, index) => props.onCommentChanged(value, index)}
                        numRows={props2.numrows ? JSON.parse(props2.numrows) : 5} />
    },

    screenshot: (props2) => {
      //console.log("Trovato screenshot con props:", props2);
      indiceScreenshot = indiceScreenshot + 1
      return <MdScreenshot index={indiceScreenshot}
        screenshot={props.screenshots[indiceScreenshot]}
        handleEdit={props.handleEdit}
        handleRemove={props.handleRemove}
        onScreenshotCaptured={props.onScreenshotCaptured} />
    },
   
    img({ src, alt }) {
      const imageUrl = `${reportTemplate["mediaFolderUrl"]}/${src}`
      // conversione in base64 per poter integrare la immagine nel report HTML
      // da inviare!

      //console.log("Individuata immagine_>:",imageUrl);
      //return <img style={{width:"40vw"}} src={imageUrl} alt={alt} />;
      return <ImageFromUrlToBase64Converter imageUrl={imageUrl} alt={alt} />
    }
  };

  useEffect(() => {
    const fetchMarkdown = async () => {
      try {
        // Effettua una richiesta per ottenere il contenuto del file .md
        const response = await fetch(reportTemplate["mdUrl"]);
        // Legge il contenuto della risposta come testo
        const markdownText = await response.text();
        // Imposta il contenuto del file .md nello stato
        setMarkdownContent(markdownText);
      } catch (error) {
        console.error('Error fetching markdown:', error);
      }
    };

    fetchMarkdown();
  }, []); // Chiamata solo all'avvio


  return (
    <div style={{ padding:"10px", display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
      <div id="md_report" >
        {/* Renderizza il contenuto del file .md utilizzando ReactMarkdown */}
        <ReactMarkdown
          rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]}
          components={renderers}>{markdownContent}</ReactMarkdown>
      </div>
    </div>
  );
}

export default MdReportEditor;