import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AppointmentAttendeeViewer } from './Calendar/AppointmentAttendeesViewer';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner,
  Form, FormGroup, Label, Input, FormFeedback, Alert
} from 'reactstrap';
import { ActivityButton } from '../components/ActivityButton'
import DateTime from 'react-datetime';
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux';
import { selectors as UiSelector, actions as UiActions } from '../store/slices/ui'
import { selectors as AppointmentsSelector, actions as AppointmentActions, Appointmentstate, getAppointmentById } from '../store/slices/appointments'
import { actions as RemoteSessionsActions } from '../store/slices/appointments'
import { actions as CatalogActions, selectors as CatalogSelectors } from '../store/slices/catalog'
import { selectors as ProfileSelectors } from '../store/slices/profile'
import { selectors as UsersSelectors, actions as UsersActions } from '../store/slices/users'
import { selectors as AuthSelectors } from '../store/slices/auth'
import { livelliScolastici, getLivelloScolastico, getLivelloScolasticoByCode } from '../pages/constants'
import ReactCountryFlag from "react-country-flag";
import countryList from "react-select-country-list";
import Select from "react-select";
import { SchoolPicker } from '../components/SchoolsManager';
import { INTERACTIVE_SESSION_TYPE, SYNCHRONOUS_SESSION_TYPE } from '../store/sagas/remotesessions';
import RialeAccordion from './RialeAccordion';
import NumberPicker from "react-widgets/NumberPicker";
import { getAppointmentStateLabel, appointmentstateLabel, isAppointmentBookedByUser, canChangeAppointmentLanguageSelections } from "../components/Calendar/appointmentsUtils"
import { ForeignSchoolSelector, ForeignSchoolGradeSelector, ItalianSchoolLevelAndGradeSelector } from '../components/Calendar/SchoolGradeSelector'

const DATE_TIME_FORMAT = "DD/MM/YYYY HH:mm";
const DATE_FORMAT = "DD/MM/YYYY";
const TIME_FORMAT = "HH:mm";

const AppointmentEditor = (props: any) => {


  const dispatch = useDispatch();
  const closeModal = () => { dispatch(UiActions.closeAppointmentEditor({ type: props.type })); };
  const isOpen = useSelector(UiSelector.isAppointmentEditorOpen(props.type)) as any;
  const [isBookingActionPerformed, setBookingActionPerformed] = useState(false);
  const initialData = useSelector(UiSelector.getInitialData);
  // DATI dell'utente autenticato e del prenotante... da aggiustare
  const userAttributes = useSelector(ProfileSelectors.getProfile);
  const totBookedSessions = useSelector(AppointmentsSelector.getAmountOfBookedAppointments)
  const attendeeAttributes = useSelector(UsersSelectors.getCurrentUserData)
  const [attendeeInfo, setAttendeeInfo] = useState(null) as any;

  const { t, i18n } = useTranslation('frontend', { useSuspense: false });
  const catalog = useSelector(CatalogSelectors.getCatalog)

  //console.log("userAttributes?", userAttributes);
  const [deleteAppointmentStateModal, setDeleteAppointmentStateModal] = useState(false);
  const [cancelAppointmentStateModal, setCancelAppointmentStateModal] = useState(false);
  const [bookingRequestPending, setBookingRequestPending] = useState(false);
  const isStudent = useSelector(AuthSelectors.isStudent);
  const backendErrorAlertModal = useSelector(UiSelector.getBackendErrorAlertModalInfo);
  const currentRegistrationProfile = useSelector(AuthSelectors.getRegistrationProfile)
  const isIdeaTeacher = useSelector(AuthSelectors.isIdeaTeacher);
  // nuomero massimo di sessioni di prenotazione per utente (future)
  const MAX_BOOKING_SESSIONS = isIdeaTeacher ? 2 : 2; // 2 : 1
  //console.log(`IDEA TEACHER: ${isIdeaTeacher} MAX_BOOKING_SESSIONS: ${MAX_BOOKING_SESSIONS} TOT BOOKED:${totBookedSessions}`)

  const [currentSelectedExperiment, setCurrentSelectedExperiment] = useState(initialData?.id != null ? initialData : null);
  const [currentTitle, setCurrentTitle] = useState(initialData == null ||
    initialData.title == null ? "" : initialData.title);
  


  useEffect(() => {
    setBookingActionPerformed(false);
    setSavedAppointment(null);
  }, [isOpen])

  const getInteractiveDeviceById = (initialData: any, ideviceId: any) => {

    const currentExperiment = experiments[initialData?.title] || currentSelectedExperiment
    //console.log(`AE: InitialData: on deviceId:${ideviceId}`, initialData, currentExperiment);
    if (currentExperiment == null) return { "titolo": "<Not found>", "descrizione": "N.A (2)" }
    const result = currentExperiment["interactive_devices"]?.filter((device: any) => {
      return device["id"] == ideviceId
    })
    if (result?.length > 0) return (result[0])
    else return { "titolo": "<Not found 2>", "descrizione": "N.A (2)" }
  }

  const getInteractiveDeviceId = (initialData: any) => {
    if (initialData?.type == null || !initialData.type.startsWith(INTERACTIVE_SESSION_TYPE)) return null;
    const isessionId = initialData.type.split(`${INTERACTIVE_SESSION_TYPE}__`)[1]
    //console.log("ISESSION ID:", isessionId)
    return isessionId
  }

  const [currentInteractiveDeviceId, setCurrentInteractiveDeviceId] = useState(getInteractiveDeviceId(initialData));

  //console.log("currentInteractiveDeviceId:", currentInteractiveDeviceId);

  const [currentLabTitle, setCurrentLabTitle] = useState(initialData == null ||
    initialData.lab == null ? "" : initialData.lab);

  const [currentNotes, setCurrentNotes] = useState(initialData == null ||
    initialData.note == null ? "" : initialData.note);

  const [currentStartDate, setCurrentStartDate] = useState((initialData == null ||
    initialData.startDate == null) ? moment.now() : moment(initialData.startDate));

  const [currentDuration, setCurrentDuration] = useState(initialData == null ||
    initialData.stopDate == null ? 0 : moment(initialData.stopDate).diff(initialData.startDate) / 60000);

  const [appointmentstate, setAppointmentstate] = useState(
    initialData == null || initialData.state == null ? "create" : initialData.state as String);


  const [savedAppointment, setSavedAppointment] = useState(null) as any;

  const [invalidTitle, setInvalidTitle] = useState(currentTitle == null || currentTitle == "");
  const [invalidLabTitle, setInvalidLabTitle] = useState(currentLabTitle == null || currentLabTitle == "");
  const [invalidDateStart, setInvalidDateStart] = useState(false);
  const [invalidDateEnd, setInvalidDateEnd] = useState(false);

  // creazione di N slot di sessioni pratiche consecutive 
  const [multiSlotBooking, setMultislotBooking] = useState(false)
  const [numBookingSlots, setNumBookingSlots] = useState(1)
  const [bookingSlotsGap, setBookingSlotGap] = useState(5) // distanza in minuti tra 2 slot
  const [maxBookings, setMaxBookings] = useState(1);
  const [isBookedByMe, setIsBookedByMe] = useState(false);
  const [interactiveDeviceOptions, setInteractiveDeviceOptions] = useState([]);
  const appointments = useSelector(AppointmentsSelector.getAppointmentsOrInteractiveSessions(props.type));

  const organizationExperiments = useSelector(CatalogSelectors.getCurrentOrganizationExperiments);
  const experiments = useSelector(CatalogSelectors.getExperiments);

  const currentExperimentForOrganization = initialData == null ? null :
    experiments && experiments[initialData.title];

  const [currentSchool, setCurrentSchool] = useState(currentRegistrationProfile?.school || "")

  const [currentLanguage, setCurrentLanguage] = useState(initialData == null ||
    initialData.language == null ? "IT" : initialData.language);
  const [appointmentToBeSaved , setAppointmentToBeSaved] = useState(false);

  const [currentAppointmentStateLabel, setCurrentAppointmentStateLabel] = useState(appointmentstateLabel[Appointmentstate.NOT_FOUND]);
  const canEdit = userAttributes != null && userAttributes.groups != null &&
    userAttributes.groups.includes("editor") &&
    (currentExperimentForOrganization == null ||
      userAttributes.groups.includes(`organization_${currentExperimentForOrganization["organization"]}`));


  const countries = useMemo(() => countryList(), []);
  const countryOptions = useMemo(() => countryList().getData().filter((country) => {
    if (experiments==null || currentTitle==null || 
        experiments[currentTitle]==null || experiments[currentTitle]["languages"]==null) return country.value == "IT";
    else return experiments[currentTitle]["languages"].includes(country.value)
    /*
    if (canEdit && (currentTitle == null || organizationExperiments == null)) return country.value == "IT";
    for (let i = 0; i < organizationExperiments.length; i++) {
      // se sono di fronte all'esperimento selezionato posso subito stabilire se la lingua corrente è inclusa
      if (organizationExperiments[i]["id"] == currentTitle) {
        if (organizationExperiments[i]["languages"] == null)
          return country.value == "IT";
        else
          return organizationExperiments[i]["languages"].includes(country.value)
      }

    }
    // in tutti gli altri casi restituisco il default, ossia la lingua italiana
    return country.value == "IT";
    */

  }), [experiments, currentTitle])






  useEffect(() => {
    //console.log("Carico esperimenti di competenza dell'editor", userAttributes);
    if (userAttributes == null || userAttributes.groups == null) return;
    const organizationGroups = userAttributes.groups.filter((e: string, i: number) => { return e.startsWith("organization_") });
    const organizations = organizationGroups.map((e: string, i: number) => e.substring("organization_".length))
    //@audit-info passo tutte le organization che trovo...
    // 
    if (organizations.length < 1) return;
    //console.log("Ho trovato le  organization:", organizations);
    dispatch(CatalogActions.willLoadOrganizationExperiments(organizations));
    //console.log("currentInteractiveDeviceId:", currentInteractiveDeviceId);
  }, [userAttributes, catalog]);

  useEffect(() => {
    // This will be now called when the locale is changed
    //console.log("Notifica lingua aggiornata: Ricarico il catalogo", i18n.language)
    dispatch(CatalogActions.willLoadCatalog({}));

  }, [i18n.language]);

  const renderLivelliScolasticiOptions = () => {
    //console.log("livelli scolastici:", livelliScolastici);
    return livelliScolastici.map(
      (e: any, i: number) => <option key={e["code"]} value={e["code"]}>{t(e["description"])}</option>);
  }

  const getExperimentOptions = () => {
    return organizationExperiments.filter((e: any) => {
      return (
        props.type == INTERACTIVE_SESSION_TYPE ? e["interactive_devices"]?.length > 0 :
          e["live"] == true)
    });
  }

  const renderInteractiveDeviceOptions = () => {
    return interactiveDeviceOptions.map(
      (device: any, i: number) => <option key={device["id"]} value={device["id"]}>{device["titolo"]}</option>);
  }

  const renderExperimentOptions = () => {
    //console.log("organizationExperiments:", organizationExperiments);
    return getExperimentOptions().map(
      (e: any, i: number) => <option key={e["id"]} value={e["id"]}>{e["titolo"]}</option>);
  }

  useEffect(() => {
    let attendeeData = null;
    if (!canEdit) {
      if (savedAppointment != null)
        attendeeData = savedAppointment && savedAppointment.attendees?.find((att: any) => { return att["user"] == userAttributes.sub })
      else
        attendeeData = initialData && initialData.attendees?.find((att: any) => { return att["user"] == userAttributes.sub })
      console.log("new attendeeData;", attendeeData);
    }
    else {
      //TODO gestire tab utente per gestione deli editor
    }
    console.log("AF:Setting attendee info (l234):", attendeeData)
    setAttendeeInfo(attendeeData);

    // impostazione currentSchool nel caso fosse stata precaricata
    if (attendeeInfo != null) setCurrentSchool(attendeeInfo.school);

    setCurrentLanguage(
      (initialData && initialData.language) ||
      (organizationExperiments[0] && organizationExperiments[0]["languages"] && organizationExperiments[0]["languages"][0]) ||
      "IT")
    if (initialData?.title != null)
      setCurrentDuration(getInitialDuration(initialData))

    setMaxBookings(initialData?.maxBookings || savedAppointment?.maxBookings || 1)
  }, [savedAppointment, initialData]);


  useEffect(() => {
    console.log(`AttendeeInfo: reqPending:${bookingRequestPending} school:(${currentSchool})`, attendeeInfo)
  }, [attendeeInfo])

  const getInitialDuration = (initialData: any) => {
    const experimentID = initialData.title;
    //console.log("Duration experiments:", experiments)
    const experiment = experiments[experimentID]
    if (experiment == null) {
      console.error(`Esperimento ${experimentID} nullo... restituisco duration pari a 0"`)
      return 0;
    }
    const ideviceId = getInteractiveDeviceId(initialData)
    if (ideviceId == null) return experiment["durata"]
    else {
      const idevice = experiment["interactive_devices"].filter((idevice: any) => {
        return idevice.id == ideviceId
      })
      return idevice[0]["durata"]
    }
  }

  useEffect(() => {
    if (organizationExperiments != null && organizationExperiments.length > 0) {
      //console.log("IS: Individuati org experiments:", organizationExperiments);
      const experimentOptions = getExperimentOptions()
      if (props.type == INTERACTIVE_SESSION_TYPE) {
        //console.log("IS: experimentOptions:", experimentOptions);
        if (experimentOptions.length > 0) { // Nuova sessione interattiva
          if (initialData == null || initialData.id == null) {
            setCurrentInteractiveDeviceId(experimentOptions[0]["interactive_devices"][0]["id"])
            setCurrentDuration(experimentOptions[0]["interactive_devices"][0]["durata"])
            //console.log("IS: setCurrentInteractiveDeviceId:", currentInteractiveDeviceId || experimentOptions[0]["interactive_devices"][0]["id"]);

          }
          // sessione interattiva preesistente
          else {
            const deviceId = getInteractiveDeviceId(initialData)
            setCurrentInteractiveDeviceId(deviceId)
            // [TODO] indice 0 da correggere...potrebbe essere stato selezionato un altro lab!
            //console.log("Initial Duration in minutes:", getInitialDuration(initialData))
            setCurrentDuration(getInitialDuration(initialData))
          }
        }
      }
      else {
        //console.log("IS: NO INTERACTIVE SESSION");
        if (initialData == null || initialData.id == null) {
          if (experimentOptions?.length > 0) {
            setCurrentDuration(experimentOptions[0]["durata"])
          }
          else { setCurrentDuration(60); }
        }
        else setCurrentDuration(getInitialDuration(initialData))
      }

    }
  }, [organizationExperiments, initialData])

  useEffect(
    () => {
       console.log("APP_RELOAD: MODIFICATO LO STATO DI UN APPUNTAMENTO con initialData:", initialData);
      if (initialData != null && initialData.id != null) {
        const appointment = getAppointmentById(appointments as any, initialData.id as any);
        if (appointment != null) {
          //console.log("APP_RELOAD: MODIFICATO LO STATO DI UN APPUNTAMENTO con savedAppointment:", appointment);
          setSavedAppointment(appointment);
          setAppointmentstate(appointment.state);
          //console.log(`MODIFICATO LO STATO DI UN APPUNTAMENTO User SubID ${userAttributes.sub} ha aperto App di:${appointment.owner}:`,);
        }

      }
    },
    [appointments]
  )

  const setCountryLabels = () => {
    countries.setLabel("IT", "Italiano");
    countries.setLabel("FR", "Français");
    countries.setLabel("GB", "English");
    countries.setLabel("NO", "Norvégien");
    countries.setLabel("Scelta libera", t("Scelta libera"));
  }

  ////console.log("Profilo:", userAttributes);
  useEffect(
    () => {
      //console.log("INITIAL DATA CHANGED!", initialData);
      //console.log("SESSION_TYPE:", props.type);
      //setCountryLabels();

      if (initialData != null) {
        console.log("initialData->", initialData)
        // se non sono un editor devo caricare i dati del mio profilo!
        if (!canEdit) {
          dispatch(UsersActions.willGetUser(userAttributes.sub))
        }
        else {
          // [TODO] caricare i dati del tab selezionato per l'utente corrente!
          // l'editor deve vedere i dati dei vari utenti che hanno prenotato
        }

        setCurrentTitle(initialData.title);
        setCurrentLabTitle(initialData.lab);
        setCurrentNotes(initialData.note == null ? "" : initialData.note);
        setCurrentStartDate(moment(initialData.startDate));
        setAppointmentstate(initialData.state == null ? Appointmentstate.CREATE : initialData.state);
        setInvalidTitle(initialData.title == "" || initialData.title == null);
        setInvalidLabTitle(initialData.lab == "" || initialData.lab == null);
        if (!canEdit && countryOptions!=null && currentLanguage=="Scelta libera") 
          {setCurrentLanguage(countryOptions[0].value);
           setAppointmentToBeSaved(true);
          }
      }
    },
    [initialData, canEdit]
  )

  useEffect(()=>{
    if (!canEdit && countryOptions!=null && currentLanguage=="Scelta libera") 
      {setCurrentLanguage(countryOptions[0].value);
       setAppointmentToBeSaved(true);
      }
  }, [initialData,canEdit, countryOptions, currentLanguage])

  useEffect(() => {
    const newVal = isAppointmentBookedByUser(savedAppointment || initialData, userAttributes)
    //console.log(`DEB:: isBookedByMe:${newVal} SAVed, INITIAL:`, savedAppointment, initialData)
    setIsBookedByMe(newVal);
  }, [savedAppointment, initialData, userAttributes])

  useEffect(() => {
    // console.log(`DEB:: AppointmentsState:${appointmentstate}`)
  }, [appointmentstate])

  const renderMaxBookingsFormGroup = () => {
    return (
      <FormGroup className="mb-2 mr-sm-2 mb-sm-0" check>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", flexDirection: "row", marginBottom: "30px" }}>
          <Label style={{ marginLeft: "-20px", fontWeight: "bold" }} className="mr-sm-2">{t("Numero massimo di prenotazioni")}</Label>
          <NumberPicker min={1} max={10} disabled={props.type == INTERACTIVE_SESSION_TYPE || appointmentstate != Appointmentstate.CREATE}
            onChange={(value: any) => setMaxBookings(value)}
            style={{ width: "80px", marginRight: "5px", marginLeft: "2px" }} defaultValue={maxBookings} />
        </div>
      </FormGroup>
    )

  }
  const renderMultiSlotBookingFormGroup = () => {
    return (

      <FormGroup className="mb-2 mr-sm-2 mb-sm-0" check>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", flexDirection: "row", marginBottom: "30px" }}>
          <Input type="checkbox" onClick={(event: any) => {
            setMultislotBooking(event.target.checked);
          }} />
          {' '}
          <Label check>
            {"crea"}
          </Label>
          <NumberPicker min={1} max={10} disabled={!multiSlotBooking}
            onChange={(value: any) => setNumBookingSlots(value)}
            style={{ width: "80px", marginRight: "8px", marginLeft: "5px" }} defaultValue={numBookingSlots} />
          <Label className="mr-sm-2">{"sessioni distanziate"}</Label>
          <NumberPicker min={5} max={360} disabled={!multiSlotBooking}
            onChange={(value: any) => setBookingSlotGap(value)}
            style={{ width: "80px", marginRight: "5px", marginLeft: "2px" }} defaultValue={numBookingSlots} />
          <Label className="mr-sm-2">{t("minuti")}</Label>
        </div>
      </FormGroup>

    )
  }

  const saveSingleAppointment = (customStartDate: any) => {
    //console.log("saveAppointment initialData:", initialData);
    const appointmentId = initialData != null && initialData.id != null ?
      initialData.id : null;

    const endDate = moment(customStartDate).add(currentDuration, "minutes").toDate();
    //console.log(`Appointment id: ${appointmentId} duration: ${currentDuration} end:${endDate}`);
    const appointment = {
      "id": appointmentId,
      "state": appointmentstate,
      // ATTENDEES ( e i vecchi attendees sono aggiornati solo in book e unbook, non in update)
      "title": currentTitle,
      "lab": currentLabTitle,
      "startDate": moment(customStartDate).toDate(), // toDate() necessario per evitare la eccezione di rendering nel calendar
      "stopDate": endDate,
      "note": currentNotes,
      "organization": initialData != null ? initialData.organization : "",
      "language": currentLanguage,
      "maxBookings": maxBookings,
      "type": (props.type == INTERACTIVE_SESSION_TYPE ?
        `${INTERACTIVE_SESSION_TYPE}__${currentInteractiveDeviceId}` : props.type)
    }

    console.log("IS: Sto salvando il seguente appointment:", appointment);
    // Salvo nel db
    if (appointmentId == null)
      dispatch(RemoteSessionsActions.willCreateAppointment(appointment))
    else
      dispatch(RemoteSessionsActions.willUpdateAppointment(appointment))
  }

  const confirmAppointment = () => {
    setBookingRequestPending(true);
    const appointment = getAppointmentById(appointments as any, initialData.id as any);
    if (appointment != null) {
      let bookedAppointment = { ...appointment }
      // aggiunta informazione sulla scuola
      // da notare che le seguenti informazioni verranno scritte lato backend nel
      // campo "attendees"
      bookedAppointment.school = currentSchool
      bookedAppointment.schoolGrade = attendeeInfo.schoolGrade
      bookedAppointment.schoolLevel = attendeeInfo.schoolLevel
      bookedAppointment.schoolSection = attendeeInfo.schoolSection
      bookedAppointment.country = attendeeInfo.country
      bookedAppointment.minAge = attendeeInfo.minAge
      bookedAppointment.maxAge = attendeeInfo.maxAge
      bookedAppointment.language = (appointmentToBeSaved ? currentLanguage : null);
  
      console.log("Sto prenotando l'appuntamento:", bookedAppointment);
      dispatch(AppointmentActions.willBookAppointment(bookedAppointment));
      
      setBookingActionPerformed(true);
    }
    else {
      //console.log("Appointment not found (null) ID:", initialData.id);
    }
  }

  const saveAppointment = () => {
    console.log("Numero di slot:", numBookingSlots);
    if (!multiSlotBooking || numBookingSlots < 2) {
      saveSingleAppointment(currentStartDate);
    }
    else {
      let tmpStartDate = currentStartDate
      for (let i = 0; i < numBookingSlots; i++) {
        console.log("Slot n.", i);
        saveSingleAppointment(tmpStartDate);
        tmpStartDate = moment(tmpStartDate).add(currentDuration, "minutes").add(bookingSlotsGap, "minutes");
      }
    }
  }

  const requestCancelAppointment = () => {
    setCancelAppointmentStateModal(true);
  }

  const closeCancelAppointmentModal = () => {
    setCancelAppointmentStateModal(false);
  }


  const cancelAppointment = () => {

    closeCancelAppointmentModal();

    const appointment = getAppointmentById(appointments as any, initialData.id as any);
    if (appointment != null) {
      console.log("SAGA sto passando da editor app:", appointment);
      dispatch(AppointmentActions.willUnbookAppointment(appointment));
      setBookingActionPerformed(true);
    };
  }


  const requestDeleteAppointment = () => {
    if (canEdit)
      setDeleteAppointmentStateModal(true);
  }

  const closeDeleteAppointmentModal = () => {
    setDeleteAppointmentStateModal(false);
  }

  const deleteAppointment = () => {
    //console.log("Richiesta rimozione app con id:" + initialData.id);
    closeDeleteAppointmentModal();
    dispatch(AppointmentActions.willDeleteAppointment(initialData));
  }



  const handleDateStartChange = (newDate: any) => {
    //console.log(`selezionata DateStart: ${newDate}`);
    try {
      const newDateStart = moment(newDate, DATE_TIME_FORMAT);
      if (newDateStart.isValid()) {
        setInvalidDateStart(false);
        setCurrentStartDate(newDate);
      }
      else {
        setInvalidDateStart(true);
      }

    } catch (error) {
      //console.log(`Invalid DateStart: ${newDate} -> ${error}`);
      setInvalidDateStart(true);
    }

  }

  const handleDuration = (event: any) => {
    //console.log(`Selezionata durata di ${event.target.value}`);
    setCurrentDuration(parseInt(event.target.value));
  }


  const handleNotesChange = (event: any) => {
    setCurrentNotes(event.target.value);
  }


  useEffect(() => {
    console.log(`AttendeeInfo: PULSANTE PRENOTA: cambio stato appointmentState:`, appointmentstate);
    setBookingRequestPending(false);
  }, [appointmentstate]);

  useEffect(() => {
    if ((savedAppointment != null || initialData != null) && currentRegistrationProfile != null) {
      const res = getAppointmentStateLabel(savedAppointment || initialData, userAttributes, currentRegistrationProfile, canEdit)
      console.log("getAppointmentStateLabel: ISR", initialData, savedAppointment, res)

      setCurrentAppointmentStateLabel(res)
      setBookingRequestPending(false); // TEST DA VERIFICARE
      
    }
    if (savedAppointment?.language!=null ) setCurrentLanguage(savedAppointment.language);
  }, [appointmentstate, savedAppointment, initialData, userAttributes, currentRegistrationProfile, canEdit]);

  useEffect(() => {
    //console.log("Imposto il corretto Lab all'esperimento di default currentLabTitle:", currentLabTitle);
    //console.log("Imposto il corretto Lab all'esperimento di default initial data:", initialData);
    //console.log("Imposto il corretto Lab all'esperimento di default organizationExperiments", organizationExperiments);

    if (organizationExperiments != null && (initialData == null || initialData.id == null)) {
      if (organizationExperiments.length > 0) {
        const options = getExperimentOptions()
        if (options.length > 0) {
          updateExperimentData(options[0]["id"]);
        }
      }

      //updateExperimentData(organizationExperiments[0]["id"]);
    }
    return () => { }
  }, [organizationExperiments, initialData]);


  useEffect(() => {
    //console.log(`Selezionato Lab:${currentLabTitle} Device:${currentInteractiveDeviceId}`);
    if (currentInteractiveDeviceId != null) {
      //setCurrentDuration(getInitialDuration())
    }
  }, [currentLabTitle, currentInteractiveDeviceId])


  useEffect(() => {
    //console.log(`Selezionato Esperimento:`, currentSelectedExperiment);

  }, [currentSelectedExperiment])

  const updateExperimentData = (experimentId: any) => {
    const selectedExperiment = experiments[experimentId];
    setCurrentSelectedExperiment(selectedExperiment);
    console.log("AED: imposto l'esperimento:", selectedExperiment)
    setCurrentTitle(selectedExperiment["id"]);
    setInvalidTitle(selectedExperiment["id"] == "");
    setCurrentLabTitle(selectedExperiment["laboratorio"]);
    setInvalidLabTitle(selectedExperiment["laboratorio"] == "");
    setInteractiveDeviceOptions(selectedExperiment["interactive_devices"] || []);
  }

  const handleExperimentChange = (event: any) => {
    const selectedExperimentID = event.target.value
    console.log("AED: Selected Experiment id:", selectedExperimentID);
    updateExperimentData(selectedExperimentID);
    //console.log("TIPO EVENTO:", props.type);
    if (props.type == INTERACTIVE_SESSION_TYPE) {
      setCurrentDuration(experiments[selectedExperimentID]["interactive_devices"][0]["durata"]);
      setCurrentInteractiveDeviceId(experiments[selectedExperimentID]["interactive_devices"][0]["id"])
    }
    else {
      setCurrentDuration(experiments[selectedExperimentID]["durata"]);
    }
  }

  const handleInteractiveDeviceChange = (event: any) => {
    const ideviceID = event.target.value
    //console.log("Selezionato dispositivo con id:", ideviceID)
    setCurrentInteractiveDeviceId(ideviceID)
    setCurrentDuration(currentSelectedExperiment["interactive_devices"].filter((idevice: any) => {
      return idevice["id"] == ideviceID;
    })[0]["durata"])
  }



  const handleLabTitleChange = (event: any) => {
    setCurrentLabTitle(event.target.value);
    setInvalidLabTitle(event.target.value == "");
  }


  const isValidForm = () => {
    return !invalidTitle && !invalidLabTitle && !invalidDateStart && !invalidDateEnd  // && attendeeInfo != null
  }

  const headerInfo = (initialData == null || initialData.id == null) ?
    t((props.type == INTERACTIVE_SESSION_TYPE ? "Nuova sessione interattiva" : "Nuova sessione sincrona")) : `${t("Modifica prenotazione")}` // n.${initialData.id}


  //@audit-info pulsanti di prenotazione
  const renderBookingButtons = () => {

    const cancelAppointmentButtonVisible = !canEdit && isBookedByMe;
    //console.log("cancelAppointmentButtonVisile ?", cancelAppointmentButtonVisible);
    return (
      (!canEdit && totBookedSessions < MAX_BOOKING_SESSIONS &&
        (!isStudent || props.type == INTERACTIVE_SESSION_TYPE) &&
        appointmentstate != Appointmentstate.BOOKED && !isBookedByMe &&
        <ActivityButton name="bookAppointment" color="primary"
          disabled={bookingRequestPending || currentSchool == "" || attendeeInfo == null || attendeeInfo.errors === true || attendeeInfo.minAge == null || attendeeInfo.maxAge == null} 
          onClick={confirmAppointment}>
          {t("Prenota")}
        </ActivityButton>
      )
      ||
      (cancelAppointmentButtonVisible &&

        <ActivityButton name="unbookAppointment" color="primary" onClick={requestCancelAppointment}>{t("Disdici")}</ActivityButton>)
    )
  }


  const renderZoomMeetingUrl = () => {
    if (initialData == null || initialData.joinUrl == null || initialData.joinUrl.length <= 0) return null;
    const isMeetingUrlVisible = canEdit || isAppointmentBookedByUser(savedAppointment || initialData, userAttributes)
    if (!isMeetingUrlVisible) return null;

    return (<div style={{ marginTop: "10px" }}><b>Meeting:{` `}<a href={initialData.joinUrl}>{initialData.joinUrl}</a></b></div>)
  }
  const renderMeetingAccount = () => {
    if (initialData == null || initialData.joinUrl == null || initialData.joinUrl.length <= 0) return null;
    const isMeetingAccountVisible = canEdit
    if (!isMeetingAccountVisible) return null;
    return (<div style={{ marginTop: "10px" }}><b>Zoom Account:{` `}</b>{initialData.hostEmail}</div>)
   
  }
  const getCurrentExperimentName = () => {
    const currentEsperiment = experiments && experiments[currentTitle];
    if (currentEsperiment != null) return currentEsperiment["titolo"]
    else return "N.A"
  }

  const renderCountryFlags = () => {
    if (currentLanguage!="Scelta libera") 
    return ( <ReactCountryFlag
      countryCode={
        currentLanguage
      }
      style={{ fontSize: '1.4em', marginTop: "5px" }}
      svg
      cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
      cdnSuffix="svg"
      title={currentLanguage}
    />)
    else return (
      <span style={{display:"inline-flex", justifyContent: "flex-start" }}>{
        countryOptions.map((country, index)=>{
          return ( <ReactCountryFlag key={`countryFlag_${index}`}
            countryCode={
              country.value
            }
            style={{ fontSize: '1.4em', marginTop: "5px" , marginRight:"5px" }}
            svg
            cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
            cdnSuffix="svg"
            title={currentLanguage}
          />

          )
      })}</span>
    )
  }

  //console.log("AppointmentEditor:isOpen:", isOpen);
  return (<Modal isOpen={isOpen}>
    <ModalHeader>{headerInfo}
    </ModalHeader>

    <ModalBody>

      <Modal isOpen={deleteAppointmentStateModal}>
        <ModalHeader>{t("Rimozione dell'evento")}</ModalHeader>
        <ModalBody>{t("Confermare la rimozione dell'appuntamento? (Eventuali prenotazioni saranno annullate)")}</ModalBody>
        <ModalFooter>
          <ActivityButton name="deleteAppointment" color="primary" onClick={deleteAppointment}>{t("Si")}</ActivityButton>{' '}
          <Button color="secondary" onClick={closeDeleteAppointmentModal}>{t("No")}</Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={cancelAppointmentStateModal}>
        <ModalHeader>{t("Cancellazione della prenotazione")}</ModalHeader>
        <ModalBody>{t("Confermare la cancellazione dell'appuntamento?")}</ModalBody>
        <ModalFooter>
          <ActivityButton color="primary" name="unbookAppointment" onClick={cancelAppointment}>{t("Si")}</ActivityButton>{' '}
          <Button color="secondary" onClick={closeCancelAppointmentModal}>{t("No")}</Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={backendErrorAlertModal[0]}>
        <ModalHeader>{t("Errore nel salvataggio dei dati")}</ModalHeader>
        <ModalBody><b>{t("Dettagli dell'errore:")}</b>
          <p>{backendErrorAlertModal[1]}</p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary"
            onClick={() => dispatch(UiActions.closeBackendErrorAlertModal())}>Ok</Button>{' '}
        </ModalFooter>
      </Modal>

      <Form>

        <FormGroup>
          <Label for="txtTitle">
            <b>{t("Esperimento")}</b></Label>
          {canEdit ?
            <Input id="selectTitle"
              disabled={!canEdit || initialData?.id != null}
              value={`${currentTitle}`}
              onChange={handleExperimentChange}
              type="select">

              {renderExperimentOptions()}
            </Input>

            : <Input id="selectTitle"
              readOnly
              value={getCurrentExperimentName()}

              type="text">

            </Input>

          }

          <FormFeedback>Campo obbligatorio</FormFeedback>
        </FormGroup>

        <FormGroup>
          <Label for="txtLab">
            <b>{t("Laboratorio")}</b>
          </Label>
          <Input id="txtLab" readOnly={true} type="text"
            value={currentLabTitle || "N.A"} onChange={handleLabTitleChange} />

        </FormGroup>

        {props.type == INTERACTIVE_SESSION_TYPE &&
          (
            <FormGroup>
              <Label for="txtRemoteDevice">
                <b>{t("Esperienza")}</b>
              </Label>
              {canEdit ?
                <Input id="selectRemoteDevice"
                  disabled={!canEdit || initialData?.id != null}
                  value={`${currentInteractiveDeviceId}`}
                  onChange={handleInteractiveDeviceChange}
                  type="select">

                  {renderInteractiveDeviceOptions()}
                </Input>

                : <Input id="selectExperienceTitle"
                  readOnly
                  value={getInteractiveDeviceById(initialData, getInteractiveDeviceId(initialData))["titolo"]}
                  type="text">

                </Input>

              }
            </FormGroup>
          )
        }

        {props.type == INTERACTIVE_SESSION_TYPE &&
          (
            <FormGroup>
              <div style={{ marginBottom: "20px" }}>
                <RialeAccordion title={`${t("Descrizione")}`}> {
                  canEdit ? getInteractiveDeviceById(initialData, currentInteractiveDeviceId)["descrizione"] :
                    getInteractiveDeviceById(initialData, getInteractiveDeviceId(initialData))["descrizione"]
                } </RialeAccordion>
              </div>
            </FormGroup>
          )
        }

        <FormGroup>
          <Label for="textareaNotes"><b>{t("Note")}</b></Label>
          <Input readOnly={!canEdit} type="textarea" name="text"
            value={currentNotes}
            id="textareaNotes" onChange={handleNotesChange} />
        </FormGroup>

        <FormGroup>
          <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridGap: 20 }}>
            <div><b>{t("Inizio")}</b></div>
            <div><b>{t("Durata")}</b></div>
          </div>

          <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridGap: 20 }}>
            <div>
              <FormGroup>
                <DateTime inputProps={{ disabled: !canEdit || (appointmentstate == Appointmentstate.BOOKED) }}
                  onChange={handleDateStartChange}
                  dateFormat={DATE_FORMAT}
                  timeFormat={TIME_FORMAT}
                  initialValue={moment(currentStartDate).format(DATE_TIME_FORMAT)}
                />
              </FormGroup>
            </div>
            <div>

              <Input id="selectDuration"
                disabled={true}
                value={`${currentDuration} ${t("minuti")}`}
                type="text">
              </Input>
            </div>
          </div>
        </FormGroup>

        {initialData?.id == null && props.type == INTERACTIVE_SESSION_TYPE && renderMultiSlotBookingFormGroup()}

        {canEdit ?
          (

            <>
              {renderMaxBookingsFormGroup()}
              {appointmentstate != Appointmentstate.CREATE && <AppointmentAttendeeViewer initialData={initialData} enableUnbooking={true as any} />}
            </>

          )

          :
          <>

            <FormGroup>
              <Label style={{ borderColor: (currentSchool?.trim() == "") ? 'red' : '#ced4da' }} ><i><b>{t("Scuola")}</b></i></Label>
              {
                (currentRegistrationProfile?.country == "IT" || currentRegistrationProfile?.country == null) ?
                  <SchoolPicker
                    disabled={canEdit || totBookedSessions >= MAX_BOOKING_SESSIONS ||
                      isBookedByMe}
                    initialValue={attendeeInfo?.school || currentRegistrationProfile?.school || ""}
                    onChange={(option: any) => {
                      let schoolLabel = option || "";
                      //console.log("Selezionato:", schoolLabel["tipo"]);
                      schoolLabel = !schoolLabel["codice"] ? schoolLabel : `(${option["codice"].toUpperCase()}) ${option["tipo"]} ${option["istituto"]} - ${option["comune"]}`
                      setCurrentSchool(schoolLabel);
                      //console.log("Scuola selezionata:", option)
                    }} />
                  :
                  <ForeignSchoolSelector
                    disabled={canEdit || totBookedSessions >= MAX_BOOKING_SESSIONS || isBookedByMe}
                    school={currentRegistrationProfile.school || ""}
                    onChange={(school: any) => { setCurrentSchool(school) }}
                  />
              }

            </FormGroup>
            {
              (currentRegistrationProfile?.country == "IT" || currentRegistrationProfile?.country == null) ?
                <ItalianSchoolLevelAndGradeSelector level={attendeeInfo?.schoolLevel}
                  grade={attendeeInfo?.schoolGrade}
                  section={attendeeInfo?.schoolSection}
                  disabled={canEdit || totBookedSessions >= MAX_BOOKING_SESSIONS ||
                    isBookedByMe}
                  onChange={(value: any) => {
                    const newAttendeeInfo =
                      { ...attendeeInfo, ...value }

                    console.log("Nuovo livello e grado:", newAttendeeInfo)
                    console.log("AF:Setting new attendee info (l911):", newAttendeeInfo)
                    setAttendeeInfo(newAttendeeInfo);
                  }} />
                :
                <ForeignSchoolGradeSelector
                  country={currentRegistrationProfile?.country}
                  minAge={attendeeInfo?.minAge}
                  maxAge={attendeeInfo?.maxAge}
                  disabled={canEdit || totBookedSessions >= MAX_BOOKING_SESSIONS || isBookedByMe}
                  onChange={(value: any) => {
                    const newAttendeeInfo =
                      { ...attendeeInfo, ...value }
                    console.log("Nuovo range eta:", newAttendeeInfo)
                    console.log("AF:Setting new attendee info (l889):", newAttendeeInfo)
                    setAttendeeInfo(newAttendeeInfo);
                  }}
                />
            }
          </>
        }

        <FormGroup>
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>

            <Label style={{ marginRight: "5px", marginTop: "5px" }}> <b>{t("Stato")}:</b>
              <b>
                <span style={{ marginTop: "0px", padding: "5px", color: "white", background: `${currentAppointmentStateLabel[0]}`, marginLeft: "5px", marginRight: "20px" }}>
                  {t(currentAppointmentStateLabel && currentAppointmentStateLabel[1], currentAppointmentStateLabel[2])}
                </span>
              </b>
            </Label>


            {initialData && !initialData.type?.startsWith(INTERACTIVE_SESSION_TYPE) &&
              (<div style={{ marginTop: "10px", display: "flex", justifyContent: "flex-start", flexDirection: "row" }}>
                <Label style={{ marginRight: "10px", marginTop: "5px" }}><b>{t("Lingua")}:</b></Label>
               {renderCountryFlags()}

                <div style={{ marginLeft: "10px", color: "black", width: "200%" }}>
                  <Select key={`select_${currentLanguage}`}
                    isDisabled={!canChangeAppointmentLanguageSelections(initialData, canEdit)}
                    isSearchable={canChangeAppointmentLanguageSelections(initialData, canEdit)}
                    options={(canEdit && countryOptions.length > 1) ? [...countryOptions, 
                      { "value": "Scelta libera", "label": t("Scelta libera")}] : countryOptions}
                    defaultValue={
                      
                      currentLanguage ?
                  
                      { "value": currentLanguage, "label": countries.getLabel(currentLanguage) || t(currentLanguage) }
                      :
                      { "value": "IT", "label": "Italian" }
                    }
                    onChange={(mycountry: any) => {
                      console.log("Country;", mycountry, currentLanguage);

                      setCurrentLanguage(mycountry.value)
                    }}
                  />
                </div>
              </div>)}

          </div>
        </FormGroup>


      </Form>


      {renderZoomMeetingUrl()}
      {renderMeetingAccount()}
      {totBookedSessions >= MAX_BOOKING_SESSIONS && appointmentstate != Appointmentstate.BOOKED &&
        <Alert color="danger">
          {t("maxBookingAlert")}
        </Alert>
      }
    </ModalBody>
    <ModalFooter>
      {renderBookingButtons()}


      {canEdit &&
        <>
          <ActivityButton name="deleteAppointment" disabled={initialData == null || initialData.id == null}
            color="danger" onClick={requestDeleteAppointment}>{t("Elimina")}</ActivityButton>
          <ActivityButton name={`${initialData?.id == null ? "createAppointment" : "updateAppointment"}`} disabled={!isValidForm()} color="primary" onClick={saveAppointment}>{t("Conferma")}</ActivityButton>{' '}
        </>
      }
      <Button color="secondary" onClick={closeModal}>{canEdit ? t("Annulla") : t("Chiudi")}</Button>
    </ModalFooter>

  </Modal>);
}

export default AppointmentEditor;