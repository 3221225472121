import { useState, useRef, forwardRef, useImperativeHandle } from 'react';
import { Button } from 'reactstrap';
import { saveAs } from 'file-saver';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { actions as ExperimentsActions, selectors as ExperimentsSelector } from '../store/slices/experiments';
import { useDispatch } from 'react-redux';


interface Props {
  filename: string;
  author: string;
  contentContainerId: string;
  lastUploadDatetime: string | null;
  saveLocally?: boolean;
  onFileUploaded?: (sendDatetime: string) => void;
}

const FileGenerator = forwardRef<any, Props>((props, ref) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('frontend', { useSuspense: false });
  const [locallyModified, setLocallyModified] = useState(false);
 

  const styles = Array.from(document.styleSheets)
    .map((styleSheet) => {
      try {
        return Array.from(styleSheet.cssRules)
          .map((cssRule) => cssRule.cssText)
          .join('\n');
      } catch (e) {
        console.log('Non è possibile accedere alle regole CSS dall\'altra origine:', e);
        return '';
      }
    })
    .join('\n');

  useImperativeHandle(ref, () => ({
    isVersionUploaded(value: boolean) {
      //console.log("RICHIAMATO ISVERSIONUPLOADED con valore:", value);
      setLocallyModified(!value);
    },
    generateFile(filename: string, author:string) { 
      console.log("URD:RICHIAMATO generateFile con filename:", filename);
      generateFile(filename, author) }
  }), []);

  const buildHTML = (bodyContent: string, sendDatetime: string, author: string) => {
    return `<!DOCTYPE html>
    <html>
    <head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Hands-on Session Report</title>
    <style>${styles}</style>
    </head>
    <body style="margin:10px;">
    <div style="display:flex;flex-direction:column; margin-bottom:20px;">
      <div>Autore: <b>${author}</b></div> 
      <div>Orario invio: <b>${sendDatetime}</b></div>
    </div>
     ${bodyContent}
    </body>
  </html>`;
  };

  const uploadHTML = async (blob: Blob, filename: string) => {
    if (blob) {
      try {
        const file = new File([blob], filename, { type: "text/html" });
        dispatch((ExperimentsActions as any).willUploadFile(file));
        return true;
      } catch (e) {
        console.log("RSR: error creating blob:", e);
        return false;
      }
    }
    else {console.log("RSR: Blob nullo, impossibile procedere all'upload del file HTML");}
    return false;
  };


  const generateFile = async (filename: string, author: string) => {
    const sendDatetime = moment().format("DD/MM/YYYY HH:mm");
    const htmlContent = buildHTML(document.getElementById(props.contentContainerId)?.innerHTML || '', 
                        sendDatetime, author);
    
    const blob = new Blob([htmlContent], { type: 'text/html;charset=utf-8' });

    if (props.saveLocally === true) {
      console.log("URD: save locally")
      saveAs(blob, `${filename}.html`);
    }
    else{console.log("URD: NOT saved locally")}

    const uploadSuccess = await uploadHTML(blob, `${filename}.html`);
    console.log("URD: uploadSuccess:", uploadSuccess)
    if (uploadSuccess && props.onFileUploaded) props.onFileUploaded(sendDatetime);
    else alert("Siamo spiacenti, ma si sono verificati dei problemi nell'invio del report.");
  };

  return (
    <div style={{ display: "flex", textAlign: "center", justifyContent: "space-between" }}>
      {(props.lastUploadDatetime == null || props.lastUploadDatetime.toLowerCase() == t("mai spedito")) ?
        <span style={{ color: "red" }}>{`${t("mai spedito")}`}</span> :
        <span style={{ color: "green" }}>{`${t("Inviato il")}: ${props.lastUploadDatetime}`}</span>
      }
      {
        locallyModified && <span style={{ color: "red" }}>{`${t("modificato")}`}</span>
      }
      <Button disabled={!locallyModified} color="primary" onClick={(ev) => { generateFile(props.filename, props.author) }}>
        {t("Invia")}
      </Button>
    </div>
  );
});

export default FileGenerator;