import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from 'react-router-dom'
import { useLocation } from 'react-router-dom';
import { selectors as ExperimentsSelector, actions as ExperimentsActions } from '../store/slices/experiments'
import { RouteConst } from '../consts/common';
import { push } from 'connected-react-router';

export const LatestPublicExperimentRedirect = (props) => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const publicExperiments = useSelector(ExperimentsSelector.getPublicExperiments)
    const  searchQuery  = useLocation();
    const [statusCode, setStatusCode] = useState("")

    useEffect(() => {
        //console.log("LatestPublicExperimentRedirect  WillLoadPublicExperiments on id:", id);
        dispatch(ExperimentsActions.willLoadPublicExperiments(id));
        }, [id]);


    useEffect(() => {
        //console.log("LatestPublicExperimentRedirect Page publicExperiments:", publicExperiments);
        //console.log("LatestPublicExperimentRedirect Search query:", searchQuery?.search);
        if (publicExperiments!==null && publicExperiments[id])
        {
            setStatusCode(200) 
            const lastPublicExpID = publicExperiments[id].slice(-1)[0];
            const publicLinkUrl = `${RouteConst.Public}/${lastPublicExpID.experiment}${searchQuery?.search || ""}`
            //console.log("LatestPublicExperimentRedirect publicLinkUrl", publicLinkUrl);
            dispatch(push(publicLinkUrl)); 
        }
          else{
            setStatusCode(301) 
          }
        }, [publicExperiments, searchQuery]);

    return <div>Redirect...{statusCode}</div>;
}