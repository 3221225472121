export default `
query getPublishedContainer($container:ID!){
    getPublishedContainer(container:$container){
        meta
        visibility
        container
        publishedcontainer
        title
        created
        modified
        description
        start_time
        end_time
        owner
        creator
        experimentsjson
   } 
}
`