import { pdfjs, Document, Page, Outline } from 'react-pdf';
import { useTranslation } from 'react-i18next';
import { useEffect, useState, useRef } from "react";
import { Spinner } from 'reactstrap';
import 'react-pdf/dist/cjs/Page/AnnotationLayer.css';
import 'react-pdf/dist/cjs/Page/TextLayer.css';
import './PdfViewer.css'
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const options = {
    cMapUrl: '/cmaps/',
    standardFontDataUrl: '/standard_fonts/',
};

const PdfViewer = (props) => {
    const { t } = useTranslation('frontend', { useSuspense: false });
    const [numPages, setNumPages] = useState();
    const [pageNumber, setPageNumber] = useState(props.pageNumber || 1);
    const [isDocumentLoading, setIsDocumentLoading] = useState(true)
    const pageRefs = useRef({});
    const pageMargin = 5;

    useEffect(() => {
        console.log("Page Number aggiornato:", props.pageNumber);
        if (props.pageNumber != null) {
            setPageNumber(props.pageNumber)
        }
    }, [props.pageNumber])

    useEffect(() => {
        if (!isDocumentLoading) scrollToPage(pageNumber)
    }, [isDocumentLoading, pageNumber])

    useEffect(()=>{
        setIsDocumentLoading(true)
    }, [props.containerWidth])

    const scrollToPage = () => {
        setTimeout(() => {
            if (pageRefs.current && pageRefs.current[pageNumber]) {
                console.log("Scrolling to page:" + pageNumber)
                pageRefs.current[pageNumber].scrollIntoView({ behavior: 'smooth' });
            }
        }, 0)
    }

    const onDocumentLoadSuccess = (info) => {
        console.log("onDocumentLoadSuccess:", info.numPages)
        setTimeout(() => {
            //setIsDocumentLoading(false);
        }, 0)
        setNumPages(info.numPages);
    }

    const renderBufferingSpinner = () => {
        return (<div className="spinner-overlay">
            <Spinner style={{ width: '3rem', height: '3rem' }} />
        </div>)
    }

    return (<div>
        <Document
            options={options}
            file={props.pdfUrl}
            onLoadSuccess={(e) => onDocumentLoadSuccess(e)}>
            {Array.from(new Array(numPages), (el, index) => (
                <div style={{ margin: `${pageMargin}px` }} key={`divPage_${index + 1}`}
                    ref={el => { pageRefs.current[index + 1] = el; }}
                >
                    <Page width={props.containerWidth}
                        onLoadSuccess={(p)=>{
                            //if (p._pageIndex==pageNumber)
                            setTimeout(()=> {setIsDocumentLoading(false)},0);
                            console.log(`Pagina caricata ${p._pageIndex} currentPage: ${pageNumber}`)}}
                        key={`page_${index + 1}`}
                        pageNumber={index + 1} />
                </div>

            ))}
            <Outline />
        </Document>
        {isDocumentLoading && renderBufferingSpinner()}
    </div>)
}

export default PdfViewer