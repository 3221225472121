import { API } from 'aws-amplify';
import { loader } from 'graphql.macro';
//import {keyBy} from 'lodash';

const getRegisteredUsersByToken = async (
  nextToken:any) => {

  const query = loader('../graphql/getUsers.gql');
  //console.log('getUsers query: ', query)
  try {
    const result: any = await API.graphql({
      query: query,
      variables: { "nextToken" : nextToken }
    });
    return result;
  } catch (error) {
    //console.log("Error in getRegisteredUsersByToken Query", error)
    throw error
  }
}

export const getRegisteredUsers = async () => {
try {
  let nextToken = ""
  let users = []
  while (nextToken!=null)
  {
    const result = await getRegisteredUsersByToken(nextToken=="" ? null : nextToken)
    console.log("getUsers partial result:", result);
    nextToken =  result["data"]["getUsers"]["nextToken"];
    users.push(...result["data"]["getUsers"]["items"]);
  }
  //console.log("getRegisteredUsers result:", users);
  return users
  
} catch (error) {
  //console.log("Error in getRegisteredUsers Query", error)
  throw error
}
}

export const getStudentsByTeacherCode = async (teacherCode:any) => {
  try {
    const query = loader("../graphql/listStudentsByTeacherCode.gql");
    //console.log(`getStudentsByTeacherCode query on code ${teacherCode}: `, query);
    try {
      const result: any = await API.graphql({
        query: query,
        variables: {
          "teacherCode" : teacherCode
        },
      });
      //console.log(`getStudentsByTeacherCode result: `, result);
      return result.data.listStudentsByTeacherCode;
    
    } catch (error) {
      //console.log("Error in getStudentsByTeacherCode Query", error);
      throw error;
    }
    
  } catch (error) {
    //console.log("Error in getRegisteredUsers Query", error)
    throw error
  }
  }

/*
export const getRegisteredUsersOld = async (nextToken:any) => {
    const query = loader('../graphql/getUsers.gql');
    //console.log('getUsers query: ', query)
    try {
      const result: any = await API.graphql({ query: query,
        variables: {  "nextToken" : nextToken } });
       
       const registeredUsers = keyBy(result.data.getUsers,"id")
       //console.log("getRegisteredUsers result:", registeredUsers);
      return registeredUsers
    } catch (error) {
        //console.log("Error in getRegisteredUsers query:", error);
      return {}
    }
  }
  */