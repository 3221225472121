import ReactExport from "react-data-export";
import {useState, useEffect}  from 'react';
import moment from 'moment';

import {FaFileExcel} from "react-icons/fa";
import { IconContext } from "react-icons";
import IconButton from '@material-ui/core/IconButton';
import ReactTooltip from "react-tooltip";
import {getClasseConcorsoByCode} from "../pages/constants"
import { useTranslation } from 'react-i18next';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;


const UsersReportDownloader = (props) => {

    const [usersDataArray, setUsersDataArray] = useState([])
    const { t, i18n } = useTranslation('frontend', { useSuspense: false });
    const usersDataSet = [
        {
          columns: [
            {title: "IdUser",  width: {wpx: 100}, style:{font:{sz:"14",  bold:true}, alignment: {wrapText : true, vertical: "center", horizontal:"center"}}},//pixels width 
            {title: "EmailVerificata", width: {wpx: 90}, style:{font:{sz:"14",  bold:true}, alignment: {wrapText : true, vertical: "center", horizontal:"center"}}},
            {title: "Nome", width: {wpx: 150}, style:{font:{sz:"14",  bold:true}, alignment: {wrapText : true, vertical: "center", horizontal:"center"}}},
            {title: "Cognome", width: {wpx: 150}, style:{font:{sz:"14",  bold:true}, alignment: {wrapText : true, vertical: "center", horizontal:"center"}}},//char width 
            {title: "Email", width: {wpx: 150}, style:{font:{sz:"14",  bold:true}, alignment: {wrapText : true, vertical: "center", horizontal:"center"}}},
            {title: "DataUltimaModifica", width: {wpx: 100}, style:{font:{sz:"14",  bold:true}, alignment: {wrapText : true, vertical: "center", horizontal:"center"}}},
            {title: "Abilitato", width: {wpx: 80}, style:{font:{sz:"14",  bold:true}, alignment: {wrapText : true, vertical: "center", horizontal:"center"}}},
            {title: "ideaSchool", width: {wpx: 50}, style:{font:{sz:"14",  bold:true}, alignment: {wrapText : true, vertical: "center", horizontal:"center"}}},
            {title: "school", width: {wpx: 150}, style:{font:{sz:"14",  bold:true}, alignment: {wrapText : true, vertical: "center", horizontal:"center"}}},
            {title: "schoolLevel", width: {wpx: 120}, style:{font:{sz:"14",  bold:true}, alignment: {wrapText : true, vertical: "center", horizontal:"center"}}},
            {title: "schoolMatter", width: {wpx: 150}, style:{font:{sz:"14",  bold:true}, alignment: {wrapText : true, vertical: "center", horizontal:"center"}}},
            {title: "schoolType", width: {wpx: 120}, style:{font:{sz:"14",  bold:true}, alignment: {wrapText : true, vertical: "center", horizontal:"center"}}},
            {title: "acceptedNewsletter", width: {wpx: 120}, style:{font:{sz:"14",  bold:true}, alignment: {wrapText : true, vertical: "center", horizontal:"center"}}},
          ],
        data: usersDataArray
        }
      ]


      useEffect(() =>{
        if (props.users!=null)
        setUsersDataArray(
          getUsersArrayData(props.users)
          )
      }, [props.users]);

    const getUsersArrayData = (users) =>
    {
      const data = []
      const {onlyAccepted}  = props
        users.forEach((user) => {
          // aggiungo il record purchè l'utente abbia sottoscritto la newsletter
          // oppure non sia specificato il filtro "onlyAccepted"
          if(user["newsletter_accepted"] || !onlyAccepted)
          data.push([
             //IDUser
             {value: user["id"], style:{alignment:{vertical: "center"}} },
             //EmailVerificata
             {value: user["email_verified"] ? "Si" : "No", style:{alignment:{vertical: "center"}} },
             //Nome
             {value: user["given_name"], style:{alignment:{vertical: "center"}} },
              //Cognome
              {value: user["family_name"], style:{alignment:{vertical: "center"}} },
              //Email
              {value: user["email"], style:{alignment:{vertical: "center"}} },
              //DataUltimaModifica
            {value: moment( user["last_modified_date"]).format("DD/MM/YY HH:mm") , style:{alignment:{vertical: "center"}} },
            //Abilitato
            {value: user["enabled"] && user["email_verified"]=="true" ? "Si" : "No", style:{alignment:{vertical: "center"}} },
            //IdeaSchool -> deprecato
            {value: "--"},
            // school
            {value: user["school"], style:{alignment:{vertical: "center"}} },
             // schoolLevel
             {value: user["schoolLevel"], style:{alignment:{vertical: "center"}} },
             // schoolMatter
             {value: user["schoolLevel"], style:{alignment:{vertical: "center"}} },
             // schoolTyoe
             {value: user["schoolType"], style:{alignment:{vertical: "center"}} },
             //acceptedNewsletter
             {value: user["newsletter_accepted"]  ? "Si" : "No", style:{alignment:{vertical: "center"}} }
          ])
        })
    
      return data
    }

    const getFileName = () => {
      
        const now_date = moment(moment.now()).format("DD_MM_YY")
        const now_time = moment(moment.now()).format("HH:mm")
        return `rindirizziNewsletter_${now_date}_h_${now_time}`;
      }
      
    return (
        <>
          <ExcelFile 
          filename= {getFileName()}
          element = {
            <IconButton 
            style={{float: 'right', marginTop:'-10px',  marginRight:'-10px'}}
             >
                <IconContext.Provider
                value={{ color: `white`,size: "1em"}}>
                   <FaFileExcel data-place="top"  
                   data-for="excelExportTooltip" data-tip={t("Esporta in formato Excel gli utenti che aderiscono alla newsletter")}
                   />
                </IconContext.Provider>
            </IconButton>
          }
          > 
              <ExcelSheet dataSet={usersDataSet} name="indirizziNewletter"></ExcelSheet>
            
          </ExcelFile>
           <ReactTooltip id="excelExportTooltip"/> 
           </>
      );
}

export default UsersReportDownloader;