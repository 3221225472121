import update from 'immutability-helper';

import * as UiTypes from '../types/ui';
import { boolean } from 'yup';

const initialState: UiTypes.UiInitialState = {
    loadingStatus: false,
    errorMessage:{},
    uploading: false,
    update_progress: 0,
    saveButton: {
        text: "NO CHANGES",
        color: "info"
    }
}

export const uiReducer = (state = initialState, action: UiTypes.UiActionTypes) => {
    switch (action.type) {
        case UiTypes.UiTypes.LOADING:
            return update(state, {
                loadingStatus: {$set: action.status}
            })
        case UiTypes.UiTypes.DID_SIGNIN_FAILED:
        return update(state, {
            errorMessage: {$set: action.error}
        })
        case UiTypes.UiTypes.SAVE_BUTTON_ACTION:
            switch(action.status){
                case "NO CHANGES":
                    return update(state, {
                        saveButton: { text: { $set: action.status }, color:{$set: "info"} } 
                    })
                case "SAVED":
                    return update(state, {
                        saveButton: { text: { $set: action.status }, color:{$set: "success"} } 
                    })
                case "TO SAVE":
                    return update(state, {
                        saveButton: { text: { $set: action.status }, color:{$set: "warning"} } 
                    })
            }
        case UiTypes.UiTypes.UPLOAD_SUCCESS:
            return update(state, { uploading: { $set: false } });
        case UiTypes.UiTypes.UPLOADING:
            return update(state, { uploading: { $set: true } });
        case UiTypes.UiTypes.UPLOAD_UPDATE_PROGRESS:
            return update(state, { update_progress: { $set: action.value } })
        default:
            return state;
    }
}