import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Spinner } from 'reactstrap';
import { selectors as UISelectors } from '../store/slices/ui';

import { useTranslation } from 'react-i18next';
import ReactTooltip from "react-tooltip";

export const TooltipButton = ({duration, tooltipMessage,onClickMessage, onChildClick, children, ...rest} :any) => {
  const [clicked, setClicked] = useState(false);
  const { t } = useTranslation('frontend', { useSuspense: false });

  useEffect(()=>{
    setTimeout(()=>{setClicked(false)},duration);
  },[clicked])

   return (
    <div style={{display:"flex", cursor:"pointer"}} data-for={`copyUrl`} 
    data-tip=""
    onClick={() => { 
      setClicked(true);
      onChildClick();
    }  }  >
                 {children}
                 {clicked && <span style={{color:"green" , marginLeft:"5px"}}>{onClickMessage}</span>}
        <ReactTooltip id = {`copyUrl`} 
          getContent={() => { 
            return (
                <span>{tooltipMessage}</span>
                )
            }}/>
    </div>
   )
}

export const ActivityButton = ({name, disabled, children, ...rest}: any) => {

  const isActivityRunning = useSelector(state => UISelectors.activityRunningSelector(state, name));

  return (
    <>
    {
      isActivityRunning ?(
        < Button disabled {...rest}>{children}  <Spinner size = "sm" color = "light" /></Button >
      ): (
        < Button disabled={disabled} {...rest}>{children}</Button >
        )
    }
    </>
  )
}