import MobileLightTimeline from "./LightTimeline2";
import { useTranslation } from 'react-i18next';
import "./LightVideoPlayer.css"
import { Footer } from '../../header';
import {useState, useEffect } from "react";
import { ReadMoreText } from "./ItemEditor";
const MobileTimeline = (props) => {
    const { experiment, activeTab, startDateTime} = props;
    const [isLookingForLite , setLookingForLite] = useState(true)
    const [firstLiteSectionIndex, setFirstLiteSectionIndex] = useState(null);
    const { t } = useTranslation('tl', { useSuspense: false });

    const getFirstLiteSectionIndex = () => {
        for(let i=0; i< experiment.sections.length;i++)
           {
            if (experiment.sections[i].isLite===true) return i;
           }  
           
           return null;
       }
    
      useEffect(()=>{
        if (experiment!=null)
        {   
            const liteVersionIndex = activeTab || getFirstLiteSectionIndex()
            setFirstLiteSectionIndex(liteVersionIndex)
            setLookingForLite(false)
        }
         
      },[experiment])

      useEffect(()=>{
        console.log(`firstLiteSectionIndex ACTIVE TAB:${activeTab}->`,firstLiteSectionIndex);
      }, [firstLiteSectionIndex])

    if (firstLiteSectionIndex==null) return (<p>{`${isLookingForLite ? 
        "searching for mobile version..." : "No mobile version found"}`}</p>) 
    else
        return(
        <div style={{display:"flex", flexDirection:"column", justifyContent:"flex-start"}}>
            <center>
            <div style={{margin:"10px"}}><h3>{experiment.title}</h3></div>
            </center>
            <center>
                <span style={{fontSize:"0.8em"}}>{`(${experiment.sections[firstLiteSectionIndex]["title"]})`}</span>
            </center>
            
            <div style={{margin:"10px"}}>
             <ReadMoreText text={experiment.description}
              maxLines={3}
              lineHeight={20}/>
            </div>
        <MobileLightTimeline startDateTime={startDateTime}
            mobileSection={experiment.sections[firstLiteSectionIndex]} />

            <Footer/>
        </div>
       
    )

}

export default MobileTimeline;