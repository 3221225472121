import React from 'react';
import AppointmentsViewer from '../components/appointmentsViewer';
import { Catalog } from '../components/Catalog'
import { Header, Footer } from '../header'
import {
  Row, Col, Card, CardBody,
  CardHeader, CardTitle, Alert
} from 'reactstrap';
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import { selectors as AuthSelectors } from '../store/slices/auth'
import { Content } from '../components/Content';

export const HomePage = () => {

  const isAuthenticated = useSelector(AuthSelectors.isAuthenticated);
  const { t, i18n } = useTranslation('frontend', { useSuspense: false });

  return (
    <>
      <Header className="mb-3 text-white" section={t("Catalogo - Aree tematiche, percorsi ed esperimenti")} showMenu={false} />
      <Content active="catalog" section="catalog" className="pt-3">
      <Catalog footerHidden={true} />
    
     
      <Row className="m-0">
        <Col xs="12">
          <Alert color="warning" className="text-center">
            <Link to="/login">
              {t("calendar_access_warning")}
            </Link>
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <Footer />
        </Col>
      </Row>
      </Content>
    </>
  )
}