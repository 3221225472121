export default `

mutation addExperiment(
    $meta: String,
    $title: String!,
    $description: String!,
    $experiment: ID,
    $container: ID!,
    $model: String!,
    $tags: [String],
    $tracks: [String],
    $items: [String],
    $owner: String
){
    addExperiment(
        meta: $meta
        title: $title
        description: $description
        experiment: $experiment
        container: $container 
        model: $model
        tags: $tags
        tracks: $tracks
        items: $items
        owner: $owner
    ){
        experiment
        created
    }
}
`