import {extractThumbnailsFromZipFileUrl} from '../../utils/thumbnailsGenerator';
import { useEffect, useState, useRef } from "react";
import * as _ from 'lodash';
import VideoThumbnail from './react-video-thumbnail';
import {Progress, Label, Badge, Spinner, Button, Popover,PopoverBody,PopoverHeader, Tooltip} from "reactstrap"
import { DefItemIcons} from './Constants';
import { IconContext } from "react-icons";
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Slider from 'rc-slider';

 
export const ThumbnailsBuilder = (props) => {

    const {item, requestCode} = props
    const snapshots = _.range(0.1,parseInt(item.duration),props.step || 20);
    const [currentSnapshotIndex, setCurrentSnapshotIndex] = useState(0)
    const [thumbnails, setThumbnails] = useState({})
    const [running,setRunning] = useState(false)
    const [end,setEnd] = useState(false)
    const [currentRequestCode,setCurrentRequestCode] = useState(-1)
    const { t, i18n } = useTranslation('timeline', { useSuspense: false });
    useEffect(()  => {
        //console.log(`TBUILDER: end:${end} on ${item?.source} Indice ${currentSnapshotIndex} di ${snapshots.length-1}`)
    }, 
    [currentSnapshotIndex])


    

    const restart = () =>
    {   
        //console.log("TBuilder: restart build!")
        setEnd(false)
        setCurrentSnapshotIndex(0)
        setRunning(true)
    }

    useEffect(()  => {
        //console.log(`TBUILDER run`);
        if (requestCode!=currentRequestCode)
        {
            setCurrentRequestCode(requestCode)
            restart()
        }
    }, 
    [requestCode])
    
    useEffect(()  =>    
    {
        if (end==true) {
            //console.log("TBUILDER: end!")
            props.onEnd(thumbnails)}
    }, [end])

    return (running && !end &&  
        <>
       <div style={{paddingTop:"10px"}}>
       
        <Progress id="thumbBuildProgress" value={parseInt(100*(currentSnapshotIndex+1)/snapshots.length)}>{`${currentSnapshotIndex+1}/${snapshots.length}`}</Progress>
        <Label for={"thumbBuildProgress"}>{
        t("tl:thumbnail_build_progress" , {"currentSnapshotIndex" :currentSnapshotIndex+1,
                                           "totalSnapshotIndexes" : snapshots.length})}</Label>
        {/*`BUILDER:Building thumb n.${currentSnapshotIndex+1} of ${snapshots.length} ${snapshots[currentSnapshotIndex]} sec */}
        </div>
        <div style={{height:"20px"}}>
    <VideoThumbnail 
          key={currentSnapshotIndex}
          videoUrl={item?.source}
          width={120}
          height={80}
          thumbnailHandler={(thumbnail) => {

            //console.log(`TBUILDER: Thumbnail ${currentSnapshotIndex} creata at ${snapshots[currentSnapshotIndex]}`);  
            setThumbnails((prevThumbs)=> ({...prevThumbs, [snapshots[currentSnapshotIndex]] : thumbnail}))
            if (currentSnapshotIndex>=snapshots.length-1)
            {
             setEnd(true);
            }
            else setCurrentSnapshotIndex((prevIndex) => (prevIndex+1))
        }
            
        }

  snapshotAtTime={snapshots[currentSnapshotIndex]}
  renderThumbnail={false}
  />
  </div>
  </>)

}

export const ThumbnailsRenderer = (props) =>
{   

    const usePrevious = (value) => {
        const ref = useRef();
        useEffect(() => {
          ref.current = value; //assign the value of ref to the argument
        },[value]); //this code will run when the value of 'value' changes
        return ref.current; //in the end, return the current ref value.
      }

    const {item, itemContext} = props
    const [currentItem, setCurrentItem] = useState(item)
    const [url,setUrl] = useState(null)
    const [thumbnails, setThumbnails] = useState(null)
    const [thumbIndexes, setThumbIndexes] = useState([])
    const [itemDimensions, setItemDimensions] = useState(itemContext.dimensions)
    const [processing, setProcessing] = useState(false)
    const [thumbWidth, setThumbWidth] = useState(40) // larghezza di ciascuna thumbnail in pixel

    const prevItem = usePrevious(currentItem);


    const retrieveIndexesFromContext = (allIndexes) => 
    {
        const width = itemDimensions.width;
        const thumbWidth = 40
        let renderedIndexes = []
        const first = parseInt(item.start_offset)
        const last = parseInt(item.end_offset)
        const duration = last-first

        for (let p=0;p<width;p+=thumbWidth)
        {   
            const posInSec = first+p*(duration/width)
            ////console.log(`TRF: posInSec:${posInSec}`);
            const index = getNearestThumbnailPosition(posInSec, props.step || 20)
            
            renderedIndexes.push(index)
        }
        ////console.log(`TRF indexes on ${itemDimensions.width} diff(${itemContext.dimensions.width-itemDimensions.width}):`, renderedIndexes)
        return renderedIndexes;
    }


    const retrieveIndexes = (thumbnails) =>
    {   
        let allIndexes = []
        let renderedIndexes = []
       // //console.log("Thumb:" , thumbnails)
        const first = parseInt(item.start_offset)
        const last = parseInt(item.end_offset)
        const ta =  Object.keys(thumbnails || {})

        ta.map((value) => {
            const index = parseInt(value)
            if (index>=first && index<=last) 
            {allIndexes.push(index)}
            });
        
        if (!props.preview)
        {   
            /*
            // Larghezza minima consentita per una thumbnail
            const minThumbWidth = 40
            
            // numero di thumbnail totale a disposizione
            const nta = allIndexes.length
            // dimensione di ciascuna thumbnail per far occupare tutto l'item
            const renderedThumbWidth = minThumbWidth // Math.max(minThumbWidth,parseInt(Math.round(itemDimensions.width/nta)))

             // numero di thumbnail da considerare
        const numbOfThumbs = Math.max(1,parseInt(Math.ceil(itemDimensions.width/renderedThumbWidth)))
        
        
        // indici di thumbnails da saltare
        const thumbsStep = Math.max(1,parseInt(Math.round(nta/numbOfThumbs)))
        //console.log(`TRF: Considero ${numbOfThumbs} su ${nta} disponibili con step:${thumbsStep}`);
        // sulla base della dimensione fisica dell'item (livello di zoom)
        // e su quella fissa delle thumbnails
        for (let i=0;i<nta;i+=thumbsStep)
        {   ////console.log("TR2:Indice:",i)
            renderedIndexes.push(allIndexes[i])
        }
        */
        renderedIndexes = retrieveIndexesFromContext(allIndexes);

       // //console.log(`Caricati indici TRA ${first} e ${last} con step:${thumbsStep} thumb width:${renderedThumbWidth} numbOfThumbs:${numbOfThumbs} -WIDTH:`,itemDimensions.width, renderedIndexes);
        //setThumbWidth(renderedThumbWidth);
        setThumbIndexes(renderedIndexes);
        }
        else
        setThumbIndexes(allIndexes);
       
       // //console.log("indici caricati con successo:", allIndexes)
    }

    useEffect(()  => {
        if (itemContext.dimensions.width!=itemDimensions.width)
        {
            ////console.log(`RT: itemContext ${item.id} dimension changed (diff(${itemContext.dimensions.width-itemDimensions.width})):`, itemContext.dimensions.width,itemDimensions.width)
            ////console.log("RT itemContext:", itemContext)
            setItemDimensions(itemContext.dimensions)
        }
    },
    [itemContext])

    
    useEffect(()  => {
        async function retrieveThumbs(){
            try{
                setProcessing(true);
                const thumbnails = props.thumbnails || await extractThumbnailsFromZipFileUrl(item.thumbnailsUrl)
                ////console.log("TR: Thumbnails ricavate:", Object.keys(thumbnails))
                setThumbnails(thumbnails)
                
            } catch (error) {
                    //console.log("error extracting thumbs:",error)
                    setThumbnails(null)
                    setProcessing(false);
            }
        }

        // salvo il nuovo valore dell'item
        setCurrentItem(item);

        ////console.log("TR: CHANGED ITEM:", prevItem, item)
        if (item?.thumbnailsUrl) 
        {   
            if (url!=item.thumbnailsUrl)
            {   ////console.log(`TR: useEffect su item -> retrieveThumbs on url:${item?.thumbnailsUrl}`)
                setUrl(item.thumbnailsUrl)
                retrieveThumbs();
            }

            else if(prevItem.start_offset!=item.start_offset || prevItem.end_offset!=item.end_offset
                || prevItem.duration!=item.duration)
                {
                ////console.log("TR: new indexes...")
                  retrieveIndexes(thumbnails);
                } 
        }
      }, [item]);

    
      useEffect(() =>{
             // //console.log("RT: Thumbnail modificate:", thumbnails)
              retrieveIndexes(thumbnails);
              if (thumbnails!=null && props.onLoaded) {
                setProcessing(false);  
                props.onLoaded(thumbnails)}
      }, [thumbnails, itemDimensions])


      useEffect(() =>{
        if (props.thumbnails!=null)
        setThumbnails(props.thumbnails)
        }, [props.thumbnails])


    if (item==null) return null;
      const numThumbnails = (thumbnails!=null && thumbIndexes.length) || 0

      const getFormattedDuration = (second) => {
        const formattedSeconds = second<10 ?
        `00:0${parseInt(second)}` :
        (
            second<60 ?  
           `00:${parseInt(second)}` :
           `${moment.duration(second, "seconds").format("HH:mm:ss")}`
        )
        return formattedSeconds
      }

      const getNearestThumbnailPosition = (posInSec, step) =>
      {
          ////console.log(`TPS: posInSec: {posInSec} step:${step}`)
        const distance = posInSec % step;
        const pos = (distance < step/2 ? (posInSec-distance) : (posInSec+step-distance))
        return parseInt(Math.round(pos))
      }

      const renderThumbnailAtSecond = (second,thumbKey) => {
        return ( thumbnails[second] == null ? null :
            <div key={thumbKey} style={{display:"flex" , alignItems:"center", justifyContent:"center" , flexDirection:"column"}}>
         <img style={{margin:"0px" , border: "1px solid #000"}} 
         width={`${props.preview ? itemContext.dimensions.width/numThumbnails : thumbWidth}px`} 
         height={`${itemContext.dimensions.height}px`}
         src={thumbnails[second]} alt={`Thumb at sec.${second}`} />
         { props.showTime &&
             <Badge  color="primary" style={{margin:"5px"}}>
             {getFormattedDuration(second)}
             </Badge>
         }
         </div>)
      }

      return (
           (numThumbnails>0) ?
        <div style={{ display:"flex" , justifyContent:`${props.preview ? "flex-start" : "flex-start"}`, margin:`${props.preview ? 2:0}px` }}>
          { props.atTime!=null ? renderThumbnailAtSecond(getNearestThumbnailPosition(props.atTime, props.step || 20),0) :
           thumbIndexes.map((second,key) =>{
               ////console.log(`Render thumb at ${second}`)
               return renderThumbnailAtSecond(second,key)
            }
          )}
       </div>
       :
       <>
       <div title={item.title} style={{ marginRight: 10, width: 20, height: 20, float: 'left', padding: 0, flex: 1}}>
             {/**
              <IconContext.Provider value={{ color: item.color, className: "global-class-name" }}>
              {DefItemIcons(item)}
            </IconContext.Provider>
              */

           (processing && <Spinner size="sm" style={{margin:"8px"}}/>)

             }
             
              
      </div>
      <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.title}</div>
      </>
    
      )
}
   

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const TSlider= createSliderWithTooltip(Slider);

export const ThumbnailsSlider = (props) => {
    
    const {item} = props;
    const [currentPosition, setCurrentPosition] = useState(item?.start_offset || 0);

    return (<TSlider min={item.start_offset} max={item?.end_offset || 0} value={currentPosition} 
        tipFormatter={(position) => {
            return <ThumbnailsRenderer 
                key={`${item.id}`}
                item={item} preview showTime 
                atTime={item.start_offset+position}
                itemContext={{dimensions:{width:"100px" , height:"80px"}}}/>
        }}
        onChange={(newPos) => { setCurrentPosition(newPos)} } />)
}