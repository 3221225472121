import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import {
  Card, CardBody,
  CardFooter,
  CardHeader, CardTitle,
  Col, Row,
  TabContent, TabPane, Nav, NavItem, NavLink
} from 'reactstrap';
import { Content } from '../components/Content';
import AppointmentEditor from '../components/appointmentsEditor';
import AppointmentsViewer from '../components/appointmentsViewer';
import { Header, Footer } from '../header';
import { INTERACTIVE_SESSION_TYPE, SYNCHRONOUS_SESSION_TYPE } from '../store/sagas/remotesessions';

export const CalendarPage = (props: any) => {

  const { t, i18n } = useTranslation('frontend', { useSuspense: false });
  const [activeTab, setActiveTab] = useState("0");


  const renderContent = () => {
    return (
      <>
        <Nav style={{ marginBottom: "10px" }} tabs>
          <NavItem>
            <NavLink style={activeTab === '0' ?
              { cursor: "arrow", fontWeight: "bold", background: "#EEEEEE" } : { cursor: "pointer", fontWeight: "normal" }}

              onClick={() => { setActiveTab('0'); }}
            >
              {t("Sessioni sincrone")}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={activeTab === '1' ?
                { cursor: "arrow", fontWeight: "bold", background: "#EEEEEE" } : { cursor: "pointer", fontWeight: "normal" }}

              onClick={() => { setActiveTab("1"); }}
            >
              {t("Sessioni pratiche")}
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="0">
            <Row className="m-0">
              <Col xs="12">
                <Card className="mb-4" style={{ borderColor: "#007bff" }}>
                  <CardHeader style={{ backgroundColor: "#007bff", borderColor: "#007bff", paddingBottom: 0, color: 'white' }}>
                    <CardTitle tag="h5" className="text-center">{t("Calendario delle sessioni sincrone")} </CardTitle>
                  </CardHeader>
                  <CardBody>
                    <AppointmentsViewer type={SYNCHRONOUS_SESSION_TYPE} experimentId={props.experimentId} />
                    {!props.editorDisabled && <AppointmentEditor type={SYNCHRONOUS_SESSION_TYPE} />}
                  </CardBody>
                  <CardFooter>
                    <Footer />
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </TabPane>

          <TabPane tabId="1">
            <Row className="m-0">
              <Col xs="12">
                <Card className="mb-4" style={{ borderColor: "#007bff" }}>
                  <CardHeader style={{ backgroundColor: "#007bff", borderColor: "#007bff", paddingBottom: 0, color: 'white' }}>
                    <CardTitle tag="h5" className="text-center">{t("Calendario delle sessioni pratiche")} </CardTitle>
                  </CardHeader>
                  <CardBody>
                    <AppointmentsViewer type={INTERACTIVE_SESSION_TYPE} experimentId={props.experimentId} />

                    {!props.editorDisabled && <AppointmentEditor type={INTERACTIVE_SESSION_TYPE} />}
                  </CardBody>
                  <CardFooter>
                    <Footer />
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </TabPane>
        </TabContent>

      </>
    )
  }

  return (
    <>
      {!props.hideMenu ?
        <>
          <Header className="mb-0 text-white" section={t("Calendario")} showMenu={false} />
          <Content active="calendar" section="calendar" className="pt-3">
            {renderContent()}
          
        </Content> 
        </>
        :
      renderContent()
    }
    </>
  )
}