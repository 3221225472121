import { Collapse  } from "reactstrap"
import { AiOutlineCaretDown, AiOutlineCaretUp } from "react-icons/ai";
import { useState } from "react";

const RialeAccordion = ({ children, ...props }) => {

    const [isOpen, setOpen] = useState(false);

    return (
        <div witdh={"100%"} style={{ display: "flex", flexDirection: "column" }}>
            <div 
            onClick={(ev) => { setOpen(!isOpen) }}
            style={{ cursor:"pointer", display: "flex", justifyContent: "space-between", marginBottom: "5px", padding: "5px", backgroundColor: "#EEEEEE" }}>
                <span color="#007BFF"><b> {props.title} </b>
                </span>
                {isOpen ?
                    <AiOutlineCaretUp size={"1.6em"} cursor="pointer" color='blue' onClick={() => { setOpen(false) }}></AiOutlineCaretUp> :
                    <AiOutlineCaretDown size={"1.6em"} cursor="pointer" color='blue' onClick={() => { setOpen(true) }}></AiOutlineCaretDown>
            }
            </div>


            <Collapse isOpen={isOpen}>
                {children}
            </Collapse>
        </div>
    )
}

export default RialeAccordion;