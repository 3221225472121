import { useTranslation } from 'react-i18next';
import {useEffect} from "react"
import html2canvas from 'html2canvas';
import { IconButton } from '@material-ui/core';
import { RiScreenshot2Fill } from "react-icons/ri";
import { Button } from 'reactstrap';
export const ScreenshotCaptureComponent = (props: any) => {
    const { t } = useTranslation('frontend', { useSuspense: false });
  
    useEffect(() => {
      ////console.log("Da ScreenshotCaptureComponent: downloadScreenshot:", props.downloadScreenshot);
    }, [props.downloadScreenshot])
  
    const captureScreenshot = (elementId: any) => {
      const element = document.getElementById(elementId);
  
      html2canvas(element as any).then(canvas => {
        // Ottieni l'URL dell'immagine
        const imgData = canvas.toDataURL('image/png');
  
        // crea una immagine in base64 da fornire a Figure.Image
        const canvasImage = new Image();
        canvasImage.src = imgData;
        props.onCapture && props.onCapture(canvasImage);
        ////console.log("Valore di downloadScreenshot:", props.downloadScreenshot);
  
        if (props.downloadScreenshot === true) {
          // Crea un elemento <a> per il download dell'immagine
          const link = document.createElement('a');
          link.href = imgData;
          link.download = 'screenshot.png';
          // Aggiungi l'elemento <a> al DOM e simula un clic
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      });
    };
  
    return (
      <Button style={{ marginRight: '5px', marginTop:"10px" }} color="primary"  
                onClick={() => captureScreenshot(props.elementId)}>
                  <span style={{marginRight:"8px"}}>{t("Screenshot")}</span>
        <RiScreenshot2Fill cursor="pointer"
          style={{ fontSize: 30 }} />
            </Button>)
     {/*
      <IconButton style={{ borderRadius: "0", background: "#EEEEEE", border: '2px solid blue' }}
        color="primary" onClick={(ev) => {
          captureScreenshot(props.elementId)
        }}>
        <RiScreenshot2Fill cursor="pointer"
          style={{ fontSize: 30, marginRight: "5px"}} />
      </IconButton>
  
    );
     */}
  }