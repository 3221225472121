import * as UiTypes from '../types/ui';

export type Loading = (
  status: boolean
) => UiTypes.Loading;

export const loading: Loading =(
  status: boolean
)=>({
  status,
  type: UiTypes.UiTypes.LOADING
})

export type DidSignInFailedAction = (
  error: any
) => UiTypes.IDidSignInFailed;

export const didSignInFailedAction: DidSignInFailedAction = (
  error: any
) => ({
  error,
  type: UiTypes.UiTypes.DID_SIGNIN_FAILED
});

export type SaveButtonAction = (
  status: string
) => UiTypes.ISaveButtonAction;

export const saveButtonAction: SaveButtonAction = (
  status: string
) => ({
  status,
  type: UiTypes.UiTypes.SAVE_BUTTON_ACTION
});

export type UploadSuccess = (
) => UiTypes.IUploadSuccess;

export const uploadSuccess: UploadSuccess =(
)=>({
  type: UiTypes.UiTypes.UPLOAD_SUCCESS
})
export type StartUpload = (
  ) => UiTypes.IStartUpload;
  
  export const startUpload: StartUpload =(
  )=>({
    type: UiTypes.UiTypes.UPLOADING
  })
export type UploadUpdateProgressAction = (
  value:any
  ) => UiTypes.IUploadUpdateProgressAction;
  
export const uploadUpdateProgress: UploadUpdateProgressAction =(
  value:any
)=>({
  value,
  type: UiTypes.UiTypes.UPLOAD_UPDATE_PROGRESS
})


// export const abortUpload = (val: any): IdeaContentTypes => {
//   return { type: IdeaTypeEnums.ABORT_UPLOAD }
// };

// export const uploadSuccess = (): IdeaContentTypes => {
//   return { type: IdeaTypeEnums.UPLOAD_SUCCESS }
// };

// export const uploadUpdateProgress = (value: any): IdeaContentTypes => {
//   return { type: IdeaTypeEnums.UPLOAD_UPDATE_PROGRESS, value: value }
// };