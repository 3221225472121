import * as React from 'react';
import { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Select from "react-select";
import ReactCountryFlag from "react-country-flag";
import countryList from "react-select-country-list";
import {
  Card, CardBody,
  CardTitle, CardSubtitle, Button,
  Container, FormGroup, Input, Label, FormFeedback,
  Col, Row, FormText
} from 'reactstrap';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from "react-router-dom";
import { SchoolPicker, SchoolSubjectPicker } from '../components/SchoolsManager';
import { actions as AuthActions, selectors as AuthSelectors } from '../store/slices/auth'
import { selectors as ProfileSelectors } from '../store/slices/profile'
import { ActivityButton } from '../components/ActivityButton'
import { gradi, tipologieUtenti, getClasseConcorsoByCode } from "./constants"

import ReactMarkdown from 'react-markdown'


export const RegistrationForm = (props: any) => {

  const dispatch = useDispatch();
  const history = useHistory();
  const registrationError = useSelector(AuthSelectors.getRegisteredError);
  const isLogged = useSelector(AuthSelectors.isLogged)
  const isRegistered = useSelector(AuthSelectors.isRegistered);
  const userAttributes = useSelector(ProfileSelectors.getProfile);
  const currentPrivacyVersion = useSelector(AuthSelectors.getCurrentPrivacyVersion)
  const currentRegistrationProfile = useSelector(AuthSelectors.getRegistrationProfile)
  const newsletterSubscribe = useSelector(AuthSelectors.getNewsletterSubscribe)
  const [privacyContentMD, setPrivacyContentMD] = useState("Informativa sulla privacy in caricamento...");

  const [isTeacherOfNet, setTeacherOfNet] = useState(currentRegistrationProfile && currentRegistrationProfile.ideaSchool)
  const { t } = useTranslation('frontend', { useSuspense: false });

  const countryOptions = useMemo(() => countryList().getData(), [])
  const [countryVal, setCountryVal] = useState((currentRegistrationProfile && currentRegistrationProfile.country) || "IT")


  const checkKeyDown = (e: any) => {
    if ((e.charCode || e.keyCode) === 13) e.preventDefault();
  };

  const RegistrationSchema = Yup.object().shape({
    type: Yup.string(),
    teacherCode: Yup.string().when('type', {
      is: (type: string) => (type == "student"),
      then: Yup.string()
        .min(36, t('Campo troppo corto!'))
        .max(36, t('Campo troppo lungo!'))
        .required(t('Campo obbligatorio')),
      otherwise: Yup.string()
    }),

    school: Yup.string().when('type', {
      is: (type: string) => (type == "staff_riale" || type=="student"),
      then: Yup.string(),
      otherwise: Yup.string()
        .min(3, t('Campo troppo corto!'))
        .max(200, t('Campo troppo lungo!'))
        .required(t('Campo obbligatorio'))
    }),

    school_address: Yup.string().when('type', {
      is: (type: string) => (type == "staff_riale" || type=="student"),
      then: Yup.string(),
      otherwise: Yup.string()
        .min(3, t('Campo troppo corto!'))
        .max(200, t('Campo troppo lungo!'))
        .required(t('Campo obbligatorio'))
    }),

    subject: Yup.string().when('type', {
      is: (type: string) => (type != "teacher" && type!=""),
      then: Yup.string(),
      otherwise: Yup.string()
        .min(3, t('Campo troppo corto!'))
        .max(50, t('Campo troppo lungo!'))
        .required(t('Campo obbligatorio'))
    }),
/* QUESTO VINCOLO PROVOCA UN BUG: LA SCELTA E' CONTROLLATA DA MENU A DISCESA
    grade: Yup.string().when('type', {
      is: (type: string) => (type != "teacher" && type!=""),
      then: Yup.string(),
      otherwise: Yup.string()
        .min(3, t('Campo troppo corto!'))
        .max(50, t('Campo troppo lungo!'))
        .required(t('Campo obbligatorio'))
    })
    */
  });

  const renderGradeOptions = () => {
    //console.log("gradi scolastici:", gradi);
    return gradi.map(
      (e: any, i: number) => <option key={e["code"]} value={e["code"]}>{t(e["description"])}</option>);
  }

  const renderTypeOptions = () => {
    //console.log("tipologie utenti:", tipologieUtenti);
    return tipologieUtenti.filter(
      (option,index) =>{ return option["code"]!="student" }).map(
      (e: any, i: number) => <option key={e["code"]} value={e["code"]}>{t(e["description"])}</option>);
  }


  React.useEffect(() => {
    //console.log(`new countryVal:${countryVal}`)
  }, [countryVal])

  React.useEffect(() => {

    const readPrivacy = async () => {
      fetch(currentPrivacyVersion.url
      )
        .then(response => {
          return response.text()
        })
        .then(text => {
          setPrivacyContentMD(text)
        })
    };

    readPrivacy();

    return () => { }

  }, [currentPrivacyVersion])


  React.useEffect(() => {
    //console.log('effect registrationError value changed: ', registrationError);
    return () => { }
  }, [registrationError])

  
  React.useEffect(() => {
    
    return () => { }
  }, [])

  React.useEffect(() => {
    //console.log("CurrentProfile:", currentRegistrationProfile)
  }, [currentRegistrationProfile])



  const formTitle: string = (props.edit ? t("Modifica Profilo") : t("Registrazione Utente"))
  const confirmButtonMsg = (props.edit ? t("Conferma le modifiche") : t("Completa la registrazione"))

  const doFunAndReturn = (fun: any, field1: string, value1: string, field2?: string, value2?: string) => {
    //console.log("RegistrationForm setFieldValue");
    if (field1 != null) fun(field1, value1, true)
    if (field2 != null) fun(field2, value2, true)
  }

  const getCountryMessageLabel = (code: string) => {
    if (code == tipologieUtenti[0]["code"]) return t("Nazione in cui risiede la tua scuola")
    else if (code == tipologieUtenti[1]["code"]) return t("Nazione in cui risiede il tuo ente di ricerca")
    else return t("Nazionalità")
  }


  const asyncDoFunAndReturn = (fun: any, field1: string, value1: string, field2?: string, value2?: string) => {
    setTimeout(doFunAndReturn, 10, fun, field1, value1, field2, value2)
    return value1
  }
  const renderSubjectFromCode = (code: any) => {
    const option = getClasseConcorsoByCode(code);
    if (code != null) //console.log(`Opzione Subject->: ${code}:${option}`)
    if (!option) return null;
    else return (`(${option["code"]?.toUpperCase()}) ${option["description"]}`)
  }
  const [selectedSchool, setSelectedSchool] = useState(currentRegistrationProfile ? currentRegistrationProfile.school : "")
  

  return (!isLogged ? <Redirect to="/login" /> :
    <Container>
      <Card className="mt-3 mt-lg-10">
        <CardBody>
          <CardTitle tag="h5" className="text-center">{formTitle} ({userAttributes.email})</CardTitle>
          {!props.edit &&
            <CardSubtitle tag="h6" className="mb-2 text-muted text-center">{t("Completa registrazione", { "username": userAttributes.given_name })}</CardSubtitle>
          }
          <Formik
            initialValues={{
              school: currentRegistrationProfile ? currentRegistrationProfile.school : "",
              school_address: currentRegistrationProfile ? currentRegistrationProfile.schoolType : "",
              subject: (currentRegistrationProfile && currentRegistrationProfile.schoolMatter) ? currentRegistrationProfile.schoolMatter : "",
              grade: (currentRegistrationProfile && currentRegistrationProfile.schoolLevel) ? currentRegistrationProfile.schoolLevel : gradi[0]["code"],
              privacyAccepted: currentRegistrationProfile && currentRegistrationProfile.privacy && props.edit,
              newsLetterRejected: !newsletterSubscribe,

              type: (currentRegistrationProfile && currentRegistrationProfile.type) ? currentRegistrationProfile.type : tipologieUtenti[0]["code"],
              ideaSchool: false,
              teacherCode: currentRegistrationProfile?.teacherCode || "", // da testare il --
            }}
            validationSchema={RegistrationSchema}
            validateOnMount={true}
            initialTouched={{ school: true , teacherCode : true }}
            validateOnBlur={true}
            validateOnChange={true}
            onSubmit={values => {
              
              const payload =  {
                school: (values.type==tipologieUtenti[2]["code"] ? "--" : values.school),
                schoolType: (values.type==tipologieUtenti[2]["code"] ? "--" : values.school_address),
                schoolMatter: (values.type==tipologieUtenti[0]["code"] ? values.subject : "--"),
                schoolLevel:  (values.type==tipologieUtenti[0]["code"] ? values.grade : "--"),
                privacy: values.privacyAccepted,
                ideaSchool: isTeacherOfNet,
                // la sottoscrizione è la negazione del rejected
                subscribeEmail: !values.newsLetterRejected,
                type: values.type,
                country: ((countryVal as any).value || countryVal || "IT"),
                teacherCode: ((tipologieUtenti[1] && values.type==tipologieUtenti[1]["code"]) ? values.teacherCode : ""),
              }

              //console.log(`SUBMIT OF Regitration Form of ${values.type} in onsubmit with: `, values, payload)

              dispatch(AuthActions.willRegisterUser(payload));
            }}
          >
            {({ errors, handleSubmit, touched, setFieldValue, handleChange, setFieldTouched, values, initialValues, validateForm }) => (

                
              <form id="registrationForm" onSubmit={handleSubmit} onKeyDown={(e) => checkKeyDown(e)}>


                <div style={{ border: `2px solid ${!values.grade || errors.type ? "red" : "green"}`, margin: "10px", padding: "10px" }}>
                  <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridGap: 50 }}>
                    <FormGroup>
                      <b><Label for="type">{t("Tipo di utente")}</Label></b>
                      <Input
                        disabled={isRegistered}
                        type="select" name="type" id="type"
                        value={values.type}
                        autoComplete="current-type" tag={Field} onChange={handleChange}
                        component={"select"}>

                        {renderTypeOptions()}
                      </Input>
                    </FormGroup>
                   
                    
                    {tipologieUtenti[1] && values.type == tipologieUtenti[1]["code"] ?
                    <FormGroup>
                      <b><Label for="teacherCode">{t("Codice docente")}</Label></b>
                      <Input id="teacherCode"
                        name="teacherCode"
                        label="teacherCode"
                        defaultValue={values.teacherCode}
                        onChange={handleChange}
                        invalid={(!initialValues.teacherCode && !values.teacherCode) || errors.teacherCode ? true : false}
                        type="text" ></Input>
                        <FormFeedback>{errors.teacherCode}</FormFeedback>
                    </FormGroup> :
                     <FormGroup>
                     <b><Label for="type">{getCountryMessageLabel(values.type)}</Label></b>
                     <div id="countryFlag"
                       className="marginBottom"
                       style={{ display: "flex", alignItems: "center", width: "100%" }}
                     >
                       <ReactCountryFlag
                         countryCode={
                           (countryVal as any).value || countryVal
                         }
                         svg
                         cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                         cdnSuffix="svg"
                         title={(countryVal as any).value || countryVal}
                       />
                       <div
                         style={{ marginLeft: "10px", color: "black", width: "100%" }}
                       >
                         <Select
                           isSearchable={true}
                           options={countryOptions}
                           defaultValue={
                             countryOptions.filter((v) => { return v["value"] == countryVal })[0]}
                           onChange={(mycountry: any) => { //console.log("Country;", mycountry); 
                           // il cambio di nazionalità resetta per sicurezza i valori correnti di scuola, indirizzo e materia
                           
                           /* COMMENTATO PER TEST */
                           setFieldValue("school", "", true)
                           setFieldValue("school_address", "", true);
                           setFieldValue("subject", "", true);
                           setCountryVal(mycountry.value) }}
                         />
                       </div>
                     </div>
                   </FormGroup>
                    }


                  </div>
                </div>

                {values.type == tipologieUtenti[2]["code"] && <>
                  <div style={{ border: `2px solid ${!values.school || errors.school ? "red" : "green"}`, margin: "10px", padding: "10px" }}>
                    <FormGroup>
                      <b><Label for="school">{t("Nome dell'ente di ricerca")}</Label></b>
                      <Input id="school"
                        name="school"
                        label="school"
                        defaultValue={values.school}
                        onChange={handleChange}
                        invalid={(!initialValues.school && !values.school) || errors.school ? true : false}
                        type="text" ></Input>
                      <FormFeedback>{errors.school}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <b><Label for="school_address">{t("Area di ricerca")}</Label></b>
                      <Input id="school_address"
                        name="school_address"
                        label="school_address"
                        defaultValue={values.school_address}
                        onChange={handleChange}
                        invalid={(!initialValues.school_address && !values.school_address) || errors.school_address ? true : false}
                        type="text" ></Input>
                      <FormFeedback>{errors.school_address}</FormFeedback>
                    </FormGroup>
                  </div>
                </>}

                {values.type == tipologieUtenti[0]["code"] && <>
                  <div style={{ border: `2px solid ${!values.school || errors.school ? "red" : "green"}`, margin: "10px", padding: "10px" }}>
                    {countryVal != "IT" ? <>
                      <FormGroup>
                        <b><Label for="school">{t("Nome dell'Istituto scolastico")}</Label></b>
                        <Input id="school"
                          name="school"
                          label="school"
                          defaultValue={values.school}
                          onChange={(ev) => {
                            //console.log(`Changed school: values,school: ${values.school} errors:${errors.school}`);
                            handleChange(ev)}}
                          invalid={(!initialValues.school && !values.school) || errors.school ? true : false}
                          type="text" ></Input>
                        <FormFeedback>{errors.school}</FormFeedback>
                      </FormGroup>
                      <FormGroup>
                        <b><Label for="school_address">{t("Tipologia di Istituto")}</Label></b>
                        <Input id="school_address"
                          name="school_address"
                          label="school_address"
                          defaultValue={values.school_address}
                          onChange={(ev) => {
                            console.log(`Changed school address: values,school: ${values.school_address} errors:${errors.school_address}`);
                            handleChange(ev)}}
                          invalid={(!initialValues.school_address && !values.school_address) || errors.school_address ? true : false}
                          type="text" ></Input>
                        <FormFeedback>{errors.school_address}</FormFeedback>
                      </FormGroup>
                      <FormGroup>
                        <b><Label for="subject">{t("Materia insegnata")}</Label></b>
                        <Input id="subject"
                          name="subject"
                          label="subject"
                          defaultValue={values.subject}
                          onChange={handleChange}
                          invalid={(!initialValues.subject && !values.subject) || errors.subject ? true : false}
                          type="text" ></Input>
                        <FormFeedback>{errors.subject}</FormFeedback>
                      </FormGroup>
                      <FormGroup>
                        <b><Label for="grade">{t("Grado scolastico")}</Label></b>
                        <Input id="grade"
                          name="grade"
                          label="grade"
                          defaultValue={values.grade}
                          onChange={handleChange}
                          invalid={(!initialValues.grade && !values.grade) || errors.grade ? true : false}
                          type="text" ></Input>
                        <FormFeedback>{errors.grade}</FormFeedback>
                      </FormGroup>
                    </> :
                      <>
                        <FormGroup>
                          <Label><i><b>{t("Trova la tua scuola")}</b></i></Label>
                          <SchoolPicker 
                          initialValue={values.school ||
                            (props.edit && asyncDoFunAndReturn(setFieldValue, "school",
                              initialValues.school, "school_address", initialValues.school_address)) || ""}
                          onChange={(option: any) => {
                            let schoolLabel = option || "";
                            //console.log("Selezionato:", schoolLabel["tipo"]);
                            schoolLabel = !schoolLabel["codice"] ? schoolLabel : `(${option["codice"].toUpperCase()}) ${option["tipo"]} ${option["istituto"]} - ${option["comune"]}`
                            setSelectedSchool(schoolLabel);
                            setFieldValue("school", schoolLabel, true)
                            setFieldValue("school_address", (option ? option["tipo"] : ""), false);
                            //console.log("Scuola selezionata:", option)

                          }} />
                        </FormGroup>

                        <FormGroup>
                          <b><Label for="school">{t("La mia scuola attuale")}</Label></b>
                          <Input value={values.school 
                          } readOnly={true}
                            tag={Field}
                            onChange={(ev) => {
                              console.log("Changed school");
                              handleChange(ev)}}
                            invalid={(!initialValues.school && !values.school) || errors.school ? true : false}
                            type="text" />
                          <FormFeedback>{`valore:${values.school} errore:${errors.school}`}</FormFeedback>
                        </FormGroup>
                      </>}

                  </div>
                  {countryVal == "IT" &&
                    <div style={{ border: `2px solid ${!values.subject || errors.subject ? "red" : "green"}`, margin: "10px", padding: "10px" }}>

                      <FormGroup>
                        <Label><b><i>{t("Trova la tua materia")}</i></b></Label>
                        <SchoolSubjectPicker defaultValue={values.subject}
                          onChange={(option: any) => {
                            let schoolSubjectLabel = option || "";
                            //console.log("SSP: Selezionato:", schoolSubjectLabel);
                            setFieldValue("subject", option, true);
                            //console.log("SSP:Materia selezionata:", option)

                          }}
                        />
                        {errors.subject && <span style={{ color: "red", fontSize: "0.8em" }}>{`${errors.subject}`}</span>}
                        {/*<FormFeedback>{errors.subject}</FormFeedback>*/}
                      </FormGroup>

                      <FormGroup>
                        <b><Label for="school">{t("La mia materia insegnata attuale")}</Label></b>
                        <Input value={renderSubjectFromCode(values.subject) ||  "" 
                         
                        } readOnly={true}

                          onChange={handleChange}
                          invalid3={!values.subject || errors.subject ? true : false}
                          invalid={(!initialValues.subject && !values.subject) || errors.subject ? true : false}
                          type="text" />
                        <FormFeedback>{errors.subject}</FormFeedback>
                      </FormGroup>
                    </div>
                  }

                  {countryVal == "IT" &&
                    <div style={{ border: `2px solid ${!values.grade || errors.grade ? "red" : "green"}`, margin: "10px", padding: "10px" }}>
                      <FormGroup>
                        <b><Label for="grade">{t("Grado scolastico")}</Label></b>
                        <Input
                          type="select" name="grade" id="grade"
                          value={values.grade}
                          autoComplete="current-grade" tag={Field} onChange={handleChange}
                          component={"select"}>

                          {renderGradeOptions()}
                        </Input>
                      </FormGroup>
                    </div>}

                </>
                }
                <FormGroup>
                  <Label>
                    {
                      <Input type="checkbox" name="newsLetterRejected" id="newsLetterRejected"
                        placeholder={t("Scegliere se accettare la sottoscrizione alle newsletter di Riale")}
                        autoComplete="current-newsLetterRejected" tag={Field} />
                    }
                    {t("newsletter_option")}
                  </Label>
                </FormGroup>
                { !props.edit &&
                  (<FormGroup>
                    <b><Label for="privacyContent">{t("Informativa sulla privacy")}</Label></b>
                    <div style={
                      {
                        border: '1px solid black'
                      }
                    } id="privacyContent">
                      <ReactMarkdown children={privacyContentMD} />
                    </div>
                  </FormGroup>
                  )
                }

                {  !props.edit &&
                  <FormGroup>
                    <Label>
                      {
                        <Input type="checkbox" name="privacyAccepted" id="privacyAccepted"
                          placeholder={t("Leggere e accettare la normativa sulla privacy")}
                          autoComplete="current-privacyAccepted" tag={Field} />
                      }
                      {t("Ho letto l'informativa sulla privacy e ne accetto le condizioni")}
                    </Label>
                  </FormGroup>
                }

                <ActivityButton disabled={!values.privacyAccepted ||
                  (values.type!="student" && !initialValues.school && errors.school != undefined)
                  || (values.type!="student" && !initialValues.subject && errors.subject != undefined)
                  || (values.type=="student" && !initialValues.teacherCode && errors.teacherCode != undefined)
                }
                  type="submit" form="registrationForm" color="primary" block>{confirmButtonMsg}</ActivityButton>
                {!props.edit ?
                  <Button name="logout" color="primary" block
                    onClick={() => { dispatch(AuthActions.willLogoutUser()); }

                    }>Logout</Button>
                  :

                  <Button name="cancel" color="primary" block
                    onClick={() => { history.push("/"); }
                    }>{t("Annulla")}</Button>

                }
              </form>
            )}
          </Formik>
        </CardBody>
      </Card>
    </Container>
  )
}