import * as React from 'react';

import {
  Card, CardText, CardBody,
  CardTitle, CardSubtitle, Button,
  Container, FormGroup, Input, Label, FormFeedback,
  Col, Row
} from 'reactstrap';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { actions as AuthActions, selectors as AuthSelectors } from '../store/slices/auth'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { ActivityButton } from '../components/ActivityButton'
import PasswordRevealer from '../components/PasswordRevealer';



export const SignupPage = () => {

  const dispatch = useDispatch();
  const loginError = useSelector(AuthSelectors.getLoggedError);
  let history = useHistory();
  const { t } = useTranslation('frontend', { useSuspense: false });


  const SignupSchema = Yup.object().shape({
    givenName: Yup.string()
      .min(2,  t('Campo troppo corto!'))
      .max(50, t('Campo troppo lungo!'))
      .required(t('Campo obbligatorio')),
    familyName: Yup.string()
      .min(2, t('Campo troppo corto!'))
      .max(50, t('Campo troppo lungo!'))
      .required(t('Campo obbligatorio')),
    email: Yup.string()
      .email()
      .min(2, t('Campo troppo corto!'))
      .max(80, t('Campo troppo lungo!'))
      .required(t('Campo obbligatorio')),
    password: Yup.string()
      .min(2, t('Campo troppo corto!'))
      .max(50, t('Campo troppo lungo!'))
      .required(t('Campo obbligatorio')),
    passwordConfirm: Yup.string()
      .test('passwords-match', 'Passwords must match', function (value) {
        return this.parent.password === value
      })
      .min(2, t('Campo troppo corto!'))
      .max(50, t('Campo troppo lungo!'))
      .required(t('Campo obbligatorio')),
    emailConfirm: Yup.string()
      .test('email-match', 'Emails must match', function (value) {
        return this.parent.email === value
      })
      .min(2, t('Campo troppo corto!'))
      .max(80, t('Campo troppo lungo!'))
      .required(t('Campo obbligatorio')),
  });

  React.useEffect(() => {

    //console.log('effect loginError: ', loginError);

    return () => { }

  }, [loginError])

  return (
    <Container>
      <Card className="mt-3 mt-lg-10">
          <CardBody>
            <CardTitle tag="h5" className="text-center">{t("Signup Page")}</CardTitle>
            <CardSubtitle tag="h6" className="mb-2 text-muted text-center">{t("Insert Email e Password")}</CardSubtitle>
            <Formik
              initialValues={{
                email: '',
                emailConfirm: '',
                familyName: '',
                givenName: '',
                password: '',
                passwordConfirm: ''
              }}
              validationSchema={SignupSchema}
              validateOnBlur={true}
              onSubmit={values => {
                //console.log('in onsubmit with: ', values)
                dispatch(AuthActions.willSignupUser({ email: values.email, password: values.password, family_name: values.familyName, given_name: values.givenName, history: history }));
              }}
            >
              {({ errors, touched, setFieldValue, values }) => (
                <Form>
                  <FormGroup>
                    <Label for="givenName">{t("Name")}</Label>
                    <Input invalid={errors.givenName && touched.givenName ? true : false} type="text" name="givenName" id="givenName" placeholder={t("Name")} tag={Field} />
                    {errors.givenName && touched.givenName ? (
                      <FormFeedback>{errors.givenName}</FormFeedback>
                    ) : null}
                  </FormGroup>
                  <FormGroup>
                    <Label for="familyName">{t("Surname")}</Label>
                    <Input invalid={errors.familyName && touched.familyName ? true : false} type="text" name="familyName" id="familyName" placeholder={t("Surname")} tag={Field} />
                    {errors.familyName && touched.familyName ? (
                      <FormFeedback>{errors.familyName}</FormFeedback>
                    ) : null}
                  </FormGroup>
                  <FormGroup>
                    <Label for="email">{t("Email Address")}</Label>
                    <Input invalid={errors.email && touched.email ? true : false} type="text" name="email" id="email" placeholder={t("Email")} tag={Field} />
                    {errors.email && touched.email ? (
                      <FormFeedback>{errors.email}</FormFeedback>
                    ) : null}
                  </FormGroup>
                  <FormGroup>
                    <Label for="emailConfirm">{t("Confirm Email Address")}</Label>
                    <Input invalid={errors.emailConfirm && touched.emailConfirm ? true : false} type="text" name="emailConfirm" id="emailConfirm" placeholder={t("Confirm Email Address")} tag={Field} />
                    {errors.emailConfirm && touched.emailConfirm ? (
                      <FormFeedback>{errors.emailConfirm}</FormFeedback>
                    ) : null}
                  </FormGroup>
                  <FormGroup>
                    <Label for="password">Password</Label>
                    <PasswordRevealer invalid={errors.password && touched.password ? true : false} name="password" id="password" placeholder={t("enter a new password")} tag={Field} />
                    {errors.password && touched.password ? (
                      <FormFeedback>{errors.password}</FormFeedback>
                    ) : null}
                  </FormGroup>
                  <FormGroup>
                    <Label for="passwordConfirm">{t("Confirm Password")}</Label>
                    <PasswordRevealer invalid={errors.passwordConfirm && touched.passwordConfirm ? true : false} name="passwordConfirm" id="passwordConfirm" placeholder={t("Confirm Password")} tag={Field} />
                    {errors.passwordConfirm && touched.passwordConfirm ? (
                      <FormFeedback>{errors.passwordConfirm}</FormFeedback>
                    ) : null}
                  </FormGroup>
                  <Row>
                    <Col>
                      <ActivityButton type="submit" name="signup" color="primary" block>{t("Signup")}</ActivityButton>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col><Button color="primary" block to="/login" outline tag={Link}>{t("Login")}</Button></Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </CardBody>
      </Card>
    </Container>
  )
}