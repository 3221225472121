import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectors as UsersSelectors, actions as UsersActions } from '../../store/slices/users'
import { FaTrash } from 'react-icons/fa';
import { IconContext } from "react-icons";
import { selectors as AppointmentsSelector, actions as AppointmentActions, getAppointmentById } from '../../store/slices/appointments'
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardText,
  Row,
  Col,
  CardHeader,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Alert
} from 'reactstrap';
import { getLivelloScolastico } from '../../pages/constants'
import classnames from 'classnames';


export const AppointmentAttendeeViewer = ({ initialData, enableUnbooking }) => {
  const [userTabs, setUserTabs] = useState({})
  const [userTabsToRender, setUserTabsToRender] = useState({})
  const dispatch = useDispatch();
  const attendeeAttributes = useSelector(UsersSelectors.getCurrentUserData)
  const appointments = useSelector(AppointmentsSelector.getAppointmentsOrInteractiveSessions(initialData.type))
  const [attendeeToLoadIndex, setAttendeeToLoadIndex] = useState(-1);
  const [appointment, setAppointment] = useState(null);
  const { t } = useTranslation('frontend', { useSuspense: false });

  const setupUsers = () => {
    const attendees = appointment.attendees || [];
    console.log("AAV app AttendeeViewer:", attendees)
    const newUserTabs = {}
    for (let i = 0; i < attendees?.length; i++) {
      newUserTabs[attendees[i]["user"]] = attendees[i]
    }
    setUserTabs(newUserTabs);
    setAttendeeToLoadIndex(0);
  }

  useEffect(() => {
    // Trick importante per essere sicuri che attendeeAttributes passi al valore null prima di ricaricare i dati
    // altrimenti, se dovesse già avere l'id di un utente prenotato, questo non verrebbe notificato
    // nello useEffect e l'utente non verrebbe caricato nella lista!
    dispatch(UsersActions.willGetUser(null))

    console.log("DAB: appuntamenti aggiornati", appointments, initialData);
    const updatedAppointment = getAppointmentById(appointments, initialData.id);
    if (updatedAppointment != null) {
      setAttendeeToLoadIndex(-1);
      setUserTabsToRender({});
      setAppointment(updatedAppointment);
    }
    else {
      console.log("DAB: appointment null!");
    }

  }, [appointments])


  useEffect(() => {
    if (appointment != null) {
      setupUsers();
    }
  }, [appointment])

  useEffect(() => {
    console.log("USER TABS:", userTabs);
    if (appointment?.attendees == null) return;
    if (attendeeToLoadIndex >= 0 && attendeeToLoadIndex < appointment.attendees.length) {
      console.log(`DAB: willGetUser on index ${attendeeToLoadIndex} : ${appointment.attendees[attendeeToLoadIndex]["user"]}`)
      dispatch(UsersActions.willGetUser(appointment.attendees[attendeeToLoadIndex]["user"]))
    }
  }, [attendeeToLoadIndex])


  useEffect(() => {
    console.log(`DAB: arrivato attendeeAttributes on index:${attendeeToLoadIndex}:`, attendeeAttributes, userTabs)
    if (attendeeAttributes != null) {
      const newUserTabs = { ...userTabsToRender }
      // aggiungo l'utente solo se l'id che arriva corrisponde ad uno degli attendee
      if (userTabs[attendeeAttributes.id] != null) {
        newUserTabs[attendeeAttributes.id] = { ...userTabs[attendeeAttributes.id], ...attendeeAttributes }
        console.log(`DAB: Aggiornamento newUserTabsToRender on index ${attendeeToLoadIndex}:`, newUserTabs)
        setUserTabsToRender(newUserTabs)
        setAttendeeToLoadIndex(attendeeToLoadIndex + 1);
      }
      //console.log("Caricamento Attributes and new UserTabs:", attendeeAttributes, newUserTabs);
    }
  }, [attendeeAttributes])

  if (appointment == null) return null;
  if (appointment.attendees == null || appointment.attendees?.length < 1)
    return (<Alert color="info">{t("Totale prenotazioni", {"currentBookings":appointment.attendees?.length || 0, 
  "maxBookings": appointment.maxBookings })}</Alert>)
  else if (appointment && appointment.attendees && Object.keys(userTabsToRender).length == appointment.attendees?.length && appointment.attendees?.length > 0)
    return (
      <div style={{paddingBottom:"10px",display:"flex", flexDirection:"column"}}>
        <UserTabs appointment={appointment} users={Object.values(userTabsToRender)} enableUnbooking={enableUnbooking} />
        <span style={{fontWeight:"bold"}}>
          {t("Totale prenotazioni", {"currentBookings":appointment.attendees?.length, 
                                      "maxBookings": appointment.maxBookings })}
          </span>
      </div>)
      
      else return (<Alert color="danger">{`USERS NOT Matching attendees: ${appointment.attendees?.length} toRender:${Object.keys(userTabsToRender || {}).length} `}</Alert>);
}

const UserTabs = ({ appointment, users, enableUnbooking }) => {
  const [activeTab, setActiveTab] = useState('0');
  const [modal, setModal] = useState(false);
  const { t } = useTranslation('frontend', { useSuspense: false });
  const dispatch = useDispatch();

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const toggleModal = () => setModal(!modal);

  const willUnbookUserTab = () => {
    const payload = { "appointment": appointment, "attendeeId": users[activeTab]["user"] };
    dispatch(AppointmentActions.willUnbookAppointmentByEditor(payload));
    toggleModal();
  };


  const getClasseItalianaInfo = (level, grade, section) =>
    {
      const levelInfo = (level==1) ? t("Secondaria di primo grado") :  t("Secondaria di secondo grado")
      return `${levelInfo} (${grade}°${section || ""})`
    }

  const renderUserTab = (user) => {
    return (
      <CardHeader bg={"info"}>
        <CardText><b>{t("Utente prenotato")}: </b>{`${user.given_name} ${user.family_name}`} </CardText>
        <CardText><b>{t("Email")}: </b>{`${user.email}`}</CardText>
        <CardText><b>{t("Istituto Scolastico")}: </b>{`${user.school}`}</CardText>
        { (!user["country"] || user["country"]=="IT") ? 
        <CardText><b>{t("Classe partecipante")}: </b>{getClasseItalianaInfo(user.schoolLevel,user.schoolGrade,user.schoolSection)}</CardText> :
        <CardText><b>{t("Nazione")}: </b>{`${user["country"]}`}</CardText> 
        }
        <CardText><b>{t("eta_studenti")}: </b>{`${t("Da")} ${user.minAge} ${t("A").toLowerCase()} ${user.maxAge} ${t("anni")}`}</CardText>
      </CardHeader>
    );
  };

  return (
    <div>
      <Nav tabs>
        {users.map((user, index) => (
          <NavItem key={index}>
            <NavLink
              style={
                activeTab === `${index}` ? { cursor: "arrow", fontWeight: "bold", background: "#EEEEEE" } : { cursor: "pointer", fontWeight: "normal" }
              }
              className={classnames({ active: activeTab === `${index}` })}
              onClick={() => { toggle(`${index}`); }}
            >
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                <span style={{ marginRight: "10px" }}>{user.given_name} {user.family_name} </span>
                {
                  enableUnbooking && (
                    <IconContext.Provider value={{ color: `red`, className: "global-class-name", size: "1.0em" }}>
                      <FaTrash style={{ cursor: "pointer" }} onClick={toggleModal} data-place="top" data-tip={t("tl:tip_unbook")} />
                    </IconContext.Provider>
                  )
                }
              </div>
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <TabContent activeTab={activeTab}>
        {users.map((user, index) => (
          <TabPane tabId={`${index}`} key={index}>
            <Row>
              <Col sm="12">
                <Card body>
                  {renderUserTab(user)}
                </Card>
              </Col>
            </Row>
          </TabPane>
        ))}
      </TabContent>
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>{t("Conferma")}</ModalHeader>
        <ModalBody>
          {t("Sei sicuro di voler annullare la prenotazione per")} {users[activeTab].given_name} {users[activeTab].family_name}?
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={willUnbookUserTab}>{t("Conferma")}</Button>
          <Button color="secondary" onClick={toggleModal}>{t("Annulla")}</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

