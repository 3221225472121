export enum UiTypes {
    LOADING = 'content/LOADING',
    DID_SIGNIN_FAILED = 'auth/DID_SIGNIN_FAILED',
    SAVE_BUTTON_ACTION= 'auth/SAVE_BUTTON_ACTION',
    ABORT_UPLOAD = "ui/ABORT_UPLOAD",
    START_ADDCONTENT = "ui/START_ADDCONTENT",
    UPLOAD_SUCCESS = "ui/UPLOAD_SUCCESS",
    UPLOAD_UPDATE_PROGRESS = "ui/UPLOAD_UPDATE_PROGRESS",
    UPLOADING = "ui/UPLOADING"

  }
  export interface UiInitialState {
    loadingStatus: boolean,
    errorMessage: {},
    uploading: boolean,
    update_progress: number,
    saveButton: {
      text: string,
      color: string
    }
  }

  export interface Loading {
    readonly type: UiTypes.LOADING;
    readonly status: boolean
  }
 
  export interface IDidSignInFailed {
    readonly type: UiTypes.DID_SIGNIN_FAILED;
    readonly error: any;
  }

  export interface ISaveButtonAction {
    readonly type: UiTypes.SAVE_BUTTON_ACTION;
    readonly status: string;
  }

  export interface IUploadAbortAction {
    readonly type: UiTypes.ABORT_UPLOAD
}
export interface IUploadSuccess {
  readonly type: UiTypes.UPLOAD_SUCCESS
}
export interface IStartUpload {
  readonly type: UiTypes.UPLOADING
}

export interface IUploadUpdateProgressAction {
  readonly type: UiTypes.UPLOAD_UPDATE_PROGRESS,
  readonly  value: any
}
  
  export type UiActionTypes =
  | Loading
  | IDidSignInFailed
  | ISaveButtonAction
  | IUploadAbortAction
  | IUploadSuccess
  | IUploadUpdateProgressAction
  | IStartUpload
;
