
import ReactTooltip from 'react-tooltip';
import { useEffect, useState, useRef, useLayoutEffect, useMemo } from 'react'
import { Progress, Badge } from "reactstrap";
import { ThumbnailsRenderer } from "./ThumbnailsManager"
import moment from "moment";
import {getFormattedTimeByPositionInSeconds} from './Utils';
import './TimeProgress.css';
import { IconContext } from "react-icons";
import {DefItemIcons, DefItemPriority} from './Constants';
import useResizeObserver from "use-resize-observer";

const useMousePosition = () => {
    const [
        mousePosition,
        setMousePosition
    ] = useState({ x: null, y: null });

    useEffect(() => {
        const updateMousePosition = ev => {
              setMousePosition({ x: ev.clientX, y: ev.clientY });
        };

        window.addEventListener('mousemove', updateMousePosition);

        return () => {
            window.removeEventListener('mousemove', updateMousePosition);
        };
    }, []);

    return mousePosition;
};

const useBindedMousePosition = () => {
    const [x, setX] = useState(0);
    const [y, setY] = useState(0);
  
    const bind = useMemo(
      () => ({
        onMouseMove: (event) => {
          setX(event.nativeEvent.offsetX);
          setY(event.nativeEvent.offsetY);
        }
      }),
      []
    );
  
    return [x, y, bind];
  };
  


export const TimelineProgress = (props) =>
{
    const  {firstItemStartTime, lastItemEndTime, currentPositionDate, items } = props;
    const [collidingItems, setCollidingItems] = useState([])
    const [currentPositionInSeconds, setCurrentPositionInSeconds] = useState(0);
    const [timelineDurationInSeconds, setTimelineDurationInSeconds] = useState(null);
    //const [width, setWidth] = useState(1);
    const [isMouseOver, setMouseOver] = useState(false);
    const { ref, width = 1, height = 1 } = useResizeObserver();

    const [x, y, bind] = useBindedMousePosition();

    useEffect(() => {
        setCurrentPositionInSeconds(moment.duration(
            moment(currentPositionDate).diff(moment(firstItemStartTime))).asSeconds())
        setTimelineDurationInSeconds(moment.duration(
            moment(lastItemEndTime).diff(moment(firstItemStartTime))).asSeconds())
    ////console.log(`TProg:  posData: ${currentPositionDate} current: ${currentPositionInSeconds} duration: ${timelineDurationInSeconds}`)
    }, [firstItemStartTime,lastItemEndTime, currentPositionDate])

    useEffect(()=>{
        if (isMouseOver==true)
        {
            if (items!=null)
             {
            let fitems = items.filter((item)=>{
                return (moment(firstItemStartTime).add(getTimelinePosition(),"seconds").isBetween(item.start_time,item.end_time, null, '[)'))
            })
            fitems.sort((item1, item2) => {return (DefItemPriority(item1)- DefItemPriority(item2))})
            //console.log("Sorted items:", fitems)
            setCollidingItems(fitems)
            }
        else setCollidingItems([]);

        }
    }, [isMouseOver,x])
    
    /*
    useLayoutEffect(() => {
        //setWidth(ref.current?.offsetWidth);
        ref.current &&  setWidth(ref.current.getBoundingClientRect().width)
      }, [ref.current]);
        */

      const renderCollidingItemsPreview = () =>
      {
       return <div style={{display:"flex",flexDirection: "column", alignContent:"flex-start", justifyContent:"flex-start"}}>
        {collidingItems.map((item, index) =>{
            return (
           
            <div key={index} style={{ paddingLeft:"5px", paddingRight:"5px",  textAlign:"left", marginTop:"2px", marginBottom:"2px", color: item.color, backgroundColor: item.bgColor}}>
                 <IconContext.Provider value={{ color: item.color, className: "global-class-name" }}>
                     {DefItemIcons(item)}
                     </IconContext.Provider>
                {`    ${item.title}`}</div>
      )
        })}
       </div>
      }

      const getTimelinePosition = ()=>
    {  if (firstItemStartTime==null) return 0;
        const result = timelineDurationInSeconds*x/(width-20)
        //console.log(`MP: mousePos.x: ${x} -> width: ${width-20} resultSec:${result}`)
       return  result;

    }

    return (
        <div ref={ref} {...bind} style={{padding:"0px"}}>
        <Progress style={{margin:"10px", cursor:"pointer"}} data-for="timeProgress"
        data-tip="" animated={props.isLoading} 
        value={100*(currentPositionInSeconds||0)/(timelineDurationInSeconds||1)} 
        onMouseOver={()=>{
            //console.log("MO: Mouse Over");
            setMouseOver(true)
        }}

        onMouseOut={()=>{
            //console.log("MO: Mouse Out")
            setMouseOver(false)
        }}
        onClick={(ev) =>{
            const newPos = moment(firstItemStartTime).add(getTimelinePosition(),"seconds");
            if (props.isPlaying)
            {
                props.onPause();
                props.onDateChanged(moment(newPos));
                setTimeout(props.onPlay,1000)
            }
            else
            {
                props.onDateChanged(moment(newPos)); 
            }
            
         }}
        />
           <ReactTooltip className={"customTheme"} disableInternalStyle type="light" id="timeProgress" 
            offset={{top: 0, left: 0}}
            border={true}
            getContent={() => {
                return ( isMouseOver && (
                    <div style={{display:"flex", flexDirection: "column", justifyContent:"flex-start"}}>
                       
                       {renderCollidingItemsPreview()}
                       <h5><Badge  color="primary" style={{marginTop:"15px", marginLeft:"10px"}}>
                                    {
                                    getFormattedTimeByPositionInSeconds(getTimelinePosition())
                                    }
                        </Badge></h5> 
                       
                    </div>)
                )
            }} />
        </div>
    )
}

const ThumbnailsProgress = (props) => {
    const { item, playedSeconds } = props;

    const duration = (item?.end_offset || 0) - (item?.start_offset||0);
   
    const ref = useRef(null);

    const [width, setWidth] = useState(1);
    const [height, setHeight] = useState(1);

   
    

  useLayoutEffect(() => {
    setWidth(ref.current?.offsetWidth);
    setHeight(ref.current?.offsetHeight);
  }, []);
  
    useEffect(() => {
        ReactTooltip.rebuild()
    })

    const mousePosition = useMousePosition();

    const getVideoPosition = ()=>
    {  if (item==null) return 0;
       return  item.start_offset+duration*(mousePosition?.x/width)
        //return item.start_offset+(item.end_offset - item.start_offset)*(mousePosition?.x/width)
    }

    const getFormattedVideoPosition = ()=>
    {
       const selectedPosition = getVideoPosition()
       const formattedPlayerSeconds = selectedPosition<10 ?
        `00:0${parseInt(selectedPosition)} / ${moment.duration(duration,"seconds").format("hh:mm:ss")}` :
        (
            selectedPosition<60 ?  
           `00:${parseInt(selectedPosition)} / ${moment.duration(duration,"seconds").format("hh:mm:ss")}` :
           `${moment.duration(selectedPosition, "seconds").format("hh:mm:ss")} / ${moment.duration(item.end_offset,"seconds").format("hh:mm:ss")}`
        )
        return formattedPlayerSeconds
    }

    return (duration>0 && <div ref={ref}>

        <Progress style={{marginLeft:"-8px" , marginRight:"10px", cursor:"pointer"}} data-for="thumbsRenderer2"
         data-tip="" animated={props.animated} value={100*((playedSeconds-(item?.start_offset||0)))/duration} 
         
         onClick={(ev) =>{
            const newPos = moment(item.start_time).add(getVideoPosition(),"seconds").add(-item.start_offset,"seconds");
            //console.log(`VTP: duration:${duration} video Pos:${getVideoPosition()} start:${ moment(item.start_time)} newPos:${newPos}`);
            props.onDatetimeChangeRequest(moment(newPos));


         }}/>
        <ReactTooltip disableInternalStyle type="dark" id="thumbsRenderer2" offset={{top: 0, left: 0}}
            border={true}
            overridePosition={({ left, top },
                currentEvent, currentTarget, node) => {
                  
                const d = document.documentElement;
                  /*
                left = Math.min(d.clientWidth - node.clientWidth, left);
                
                */
                //top = Math.min(d.clientHeight - node.clientHeight, top);
                //top = Math.max(0, top);
                top= d.clientHeight- node.clientHeight-70;
                
                const right = (mousePosition?.x || 0) + 80;
                //console.log(`POP:left:${left} top:${top}`);
                if (right>=width)
                {
                    left= width - 140
                }
                else
                left = Math.max(0, (-80+ (mousePosition?.x || 0)));
                return { top, left }
                
                
            }}

            getContent={() => {
                return (
                    <div style={{padding:"0px"}}>
                        <Badge  color="primary" style={{marginLeft:"10px"}}>
                                    {
                                    getFormattedVideoPosition()
                                    }
                        </Badge>
                       {item!=null && <ThumbnailsRenderer
                            key={`${item.id}`}
                            item={item} preview showTime
                            atTime={item.start_offset+((mousePosition?.x || 0)*item.duration/width)}
                            itemContext={{ dimensions: { width: "100px", height: "80px" } }} />
                        }
                    </div>
                )
            }} />
    </div>
    )

}

export default ThumbnailsProgress;