export default `

mutation addEmptyContainer(
    $meta: String,
    $visibility: String!,
    $container: ID,
    $title: String!,
    $description: String!,
    $start_time: AWSDate!,
    $end_time: AWSDate!
){
    addEmptyContainer(
        meta: $meta
        visibility: $visibility
        container: $container
        title: $title
        description: $description
        start_time: $start_time
        end_time: $end_time
    ){
            container
            title
    }
}
`