export enum StorageConst {
    AuthToken = 'AUTH_TOKEN'
  }
  
  export enum RouteConst {
    Root = '/',
    Forgot = '/reset',
    Home = '/',
    Timeline = '/timeline',
    Public = '/public',
    NotFound = '/not-found',
    Login = '/login',
    SignUp = '/signup',
    User = '/user',
    TechnicalIssue = '/oops',
    Auth = '/auth',
    RegisterVerificationCode = '/auth/emailverification',
    RegisterSuccess = '/auth/register-success',
    ForgotPass = '/auth/forgot-pass',
    ResetPassword = '/auth/reset-pass',
    PrivacyNotice = '/auth/privacy-notice',
    TermsConditions = '/auth/terms-conditions',
    
    
  }
  