export enum ContainerTypes {
    WILL_ADD_CONTAINER = 'container/WILL_ADD_CONTAINER',
    WILL_GET_CONTAINERS = 'container/WILL_GET_CONTAINERS',
    DID_GET_CONTAINERS = 'container/DID_GET_CONTAINERS',
    DID_GET_SYNC_SESSIONS = 'container/DID_GET_SYNC_SESSIONS',
    WILL_GET_EXPERIMENTS = 'container/WILL_GET_EXPERIMENTS',
    DID_GET_EXPERIMENTS = 'container/DID_GET_EXPERIMENTS',
    DID_ADD_ELEMENT = 'container/DID_ADD_ELEMENT',
    WILL_SAVE_EXPERIMENT = 'container/WILL_SAVE_EXPERIMENT',
    WILL_ADD_EXPERIMENT = 'container/WILL_ADD_EXPERIMENT',
    WILL_DELETE_EXPERIMENT = 'container/WILL_DELETE_EXPERIMENT',
    WILL_CLONE_CONTAINER = 'container/WILL_CLONE_CONTAINER',
    WILL_EDIT_CONTAINER = 'container/WILL_EDIT_CONTAINER',
    WILL_DELETE_CONTAINER = 'container/WILL_DELETE_CONTAINER',
    WILL_UPLOAD_FILE = 'container/WILL_UPLOAD_FILE',
    WILL_GET_PUBLIC_CONTAINER = 'container/WILL_GET_PUBLIC_CONTAINER',
    DID_GET_PUBLIC_CONTAINER = 'container/DID_GET_PUBLIC_CONTAINER',
    WILL_PUBLISH_CONTAINER = 'container/WILL_PUBLISH_CONTAINER',
    WILL_SEND_SYNC_COMMAND = 'container/WILL_SEND_SYNC_COMMAND',
    IWillGetPublicExperiments = "IWillGetPublicExperiments"
}

  export interface ContainerInitialState {
    containers:[],
    experiments:[],
    public_container:[],
    public_container_links:[],
    sync_sessions: []
  }
  
  export interface IWillAddContainer {
  readonly type: ContainerTypes.WILL_ADD_CONTAINER;
  readonly title: string,
  readonly description: string,
  readonly start_date: Date,
  readonly end_date: Date
}

export interface IWillGetContainers {
  readonly type: ContainerTypes.WILL_GET_CONTAINERS;
}

export interface IDidGetContainers {
  readonly type: ContainerTypes.DID_GET_CONTAINERS;
  readonly containers: any
}

export interface IDidGetSyncSessions {
  readonly type: ContainerTypes.DID_GET_SYNC_SESSIONS;
  readonly sessions: any
}

export interface IWillGetExperiments {
  readonly type: ContainerTypes.WILL_GET_EXPERIMENTS;
  readonly container: string
}

export interface IDidGetExperiments {
  readonly type: ContainerTypes.DID_GET_EXPERIMENTS;
  readonly experiments: any
}

export interface IDidAddElement {
  readonly type: ContainerTypes.DID_ADD_ELEMENT;
  readonly element: any
  readonly experimentIndex: number,
  readonly experiments: any,
  readonly event: string
}

export interface WillSaveExperiment {
  readonly type: ContainerTypes.WILL_SAVE_EXPERIMENT;
  readonly experimentIndex: number
}

export interface IWillAddExperiment {
  readonly type: ContainerTypes.WILL_ADD_EXPERIMENT;
  readonly title: string,
  readonly description: string,
  readonly container: string
}

export interface IWillDeleteExperiment {
  readonly type: ContainerTypes.WILL_DELETE_EXPERIMENT;
  readonly experiment: string,
  readonly container: string
}

export interface IWillCloneContainer {
  readonly type: ContainerTypes.WILL_CLONE_CONTAINER;
  readonly container: string,
  readonly owner: string
}
export interface IWillEditContainer {
  readonly type: ContainerTypes.WILL_EDIT_CONTAINER;
  readonly title: string,
  readonly description: string,
  readonly start_date: Date,
  readonly end_date: Date,
  readonly container: string,
  readonly visibility: string
}

export interface IWillDeleteContainer {
  readonly type: ContainerTypes.WILL_DELETE_CONTAINER;
  readonly container: string
}

export interface IWillUploadFile {
  readonly type: ContainerTypes.WILL_UPLOAD_FILE;
  readonly url: any
}

export interface IWillGetPublicExperiments {
  readonly type: ContainerTypes.WILL_GET_PUBLIC_CONTAINER;
  readonly container: string
}

export interface IWillPublishContainer {
  readonly type: ContainerTypes.WILL_PUBLISH_CONTAINER;
  readonly container: string
}

export interface IDidGetPublicContainers {
  readonly type: ContainerTypes.DID_GET_PUBLIC_CONTAINER;
  readonly containers: any
}

export interface IWillSendSyncCommand {
  readonly type: ContainerTypes.WILL_SEND_SYNC_COMMAND;
  readonly command: string
}


  export type ContainerActionTypes =
    | IWillAddContainer     
    | IWillGetContainers
    | IDidGetContainers
    | IDidGetExperiments
    | IDidGetSyncSessions
    | IWillGetExperiments
    | IDidAddElement
    | WillSaveExperiment
    | IWillAddExperiment
    | IWillDeleteExperiment
    | IWillCloneContainer
    | IWillEditContainer
    | IWillDeleteContainer
    | IWillUploadFile
    | IWillGetPublicExperiments
    | IWillPublishContainer
    | IDidGetPublicContainers
    | IWillSendSyncCommand
  ;