import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const chatbotSlice = createSlice({
    name: 'chatbot',
    initialState: {
      messages: [],
    }
    ,
    reducers: {
      willCreateChatbotFeedback: (state, action: PayloadAction<any>) => state,
      removeAllMessages:  (state, action: PayloadAction<any>) => {
        state.messages = [];
      },
      setMessages: (state, action: PayloadAction<any>) => {
        //console.log("current experiment set slice");
        state.messages = action.payload;
      },
    }
  });
  
  
  export const { actions, reducer }: any = chatbotSlice

  export const selectors = {
    getMessages:  (state: any) => {
      
      return state.chatbot.messages;
    }
}