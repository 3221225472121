export default `

mutation deleteExperiment(
    $experiment: ID!
){
    deleteExperiment(
        experiment: $experiment
    ){
        experiment
    }
}
`