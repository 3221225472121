
 export class RecordingPasscode {
    static RECORDING = 0
    static NO_RECORDING = 1

    static charset_NO_RECORDING = "abcdefghijklmnopqrstuvwxyz".toUpperCase()
    static charset_RECORDING = "0123456789abcdefghijklmnopqrstuvwxyz".toUpperCase()
    
    static getCharset = (recordingPassCode) => {if (recordingPassCode==RecordingPasscode.RECORDING)
        return  RecordingPasscode.charset_RECORDING ; 
        else return RecordingPasscode.charset_NO_RECORDING}
    }


 export const generatePasscode = (passcodeType=RecordingPasscode.NO_RECORDING) =>
{  
    //console.log("PasscodeType in generatePasscode", passcodeType)
    const charset=RecordingPasscode.getCharset(passcodeType)
    const passCodeLen = (passcodeType==RecordingPasscode.NO_RECORDING ? 8 : 10)
    ////console.log("Lunghezza alfabeto:", charset.length)
    ////console.log("charset (generatePasscode):", charset)
    let passCode = ""
    let passCodeSum = 0;
    for (let i=0;i<passCodeLen-1;i++)
    {
        const newCharIndex= Math.floor(Math.random()*charset.length)
        const newChar = charset.charAt(newCharIndex)
        passCode+= newChar
        passCodeSum+= newCharIndex
    }
    
    ////console.log(`PasscodeSum prima:${passCodeSum} Compensazione:${passCodeSum % charset.length}`)
    const controlBitCharIndex = passCodeSum % charset.length
    const controlBitChar = charset.charAt(controlBitCharIndex)
    passCode+=controlBitChar
    passCodeSum-=controlBitCharIndex
    ////console.log(`PasscodeSum dopo:${passCodeSum} Compensazione:${passCodeSum % charset.length}`)
    ////console.log(`Passcode is valid?`, isValid(passCode,passcodeType))
    if (isValid(passCode, passcodeType))
    return passCode;
    else throw("TEST OF GENERATED PASSCODE FAILED. PLEASE RETRY")
}


export const isValid = (passCodeOrig, passcodeType=RecordingPasscode.NO_RECORDING) => {
    ////console.log("PasscodeType in isValid:", passcodeType)
    const charset=RecordingPasscode.getCharset(passcodeType)
    const passCodeLen = (passcodeType==RecordingPasscode.NO_RECORDING ? 8 : 10)
    ////console.log("charset (isValid):", charset)
    try{
        const passCode = passCodeOrig.trim();
        if (passCode.trim().length!=passCodeLen) return false;
        let passCodeSum = 0;
        for (let i=0;i<passCode.length-1;i++)
        {
            ////console.log("Indice carattere:",charset.indexOf(passCode.charAt(i)))
            passCodeSum += charset.indexOf(passCode.charAt(i))
        }

        passCodeSum -= charset.indexOf(passCode.charAt(passCodeLen-1))
        ////console.log(`PasscodeSum check:${passCodeSum}`)
        return (passCodeSum % charset.length) == 0
    } catch(error) {
        return false;
    }

}

const testPasscodes_NO_RECORDING = () =>
{
    const validPassCodes = ["OWPJKCFZ","DYWKQABY"]
    const invalidPassCodes = ["OWPJKCFA","DYWKQHHJ"]
    for (let i=0;i<validPassCodes.length;i++)
    {
        if (isValid(validPassCodes[i])) 
        {
            //console.log("TEST PASSED")
        }
        else throw "TEST FAILED"
    }

    for (let i=0;i<invalidPassCodes.length;i++)
    {
        if (isValid(validPassCodes[i])) 
        {
            //console.log("TEST PASSED")
         }
        else throw "TEST FAILED"
    }
}

testPasscodes_NO_RECORDING()
////console.log("Passcode NO_RECORDING:", generatePasscode())
//console.log("Codice valido (NO_RECORDING): OWPJKCFZ ? " , isValid("OWPJKCFZ", RecordingPasscode.NO_RECORDING))
//console.log("Codice NON valido (RECORDING): OWPJKCFZ ? " , isValid("OWPJKCFZ", RecordingPasscode.RECORDING))

//console.log("Codice valido (NO_RECORDING): TPZ380TQNY ? " , isValid("TPZ380TQNY", RecordingPasscode.RECORDING))
//console.log("Codice NON valido (NO_RECORDING): TPZ380TQNY ? " , isValid("TPZ380TQNY", RecordingPasscode.NO_RECORDING))


//console.log("Passcode RECORDING:", generatePasscode(RecordingPasscode.RECORDING))
//console.log("Passcode RECORDING (AAAAAAAAAA) ? :", isValid("AAAAAAAABB", RecordingPasscode.RECORDING))