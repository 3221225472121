import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const currentSlice = createSlice({
  name: 'auth',
  initialState: {
    privacy: {currentVersion: "N.A", url:""},
    registrationProfile : {},
    newsletterSubscribe : true, // di default la sottoscrizione è impostata
    confirmed: false,
    logged: false,
    checked: false,
    registered: false,
    user: {} as {} | null,
    forgotPasswordRequested: false,
    error: undefined,
    registeredError: undefined
  },
  reducers: {
    setCurrentPrivacyVersion:  (state, action: PayloadAction<any>) => void (state.privacy = action.payload),
    setNewsletterSubscribe:  (state, action: PayloadAction<any>) => void (state.newsletterSubscribe = action.payload),
    willConfirmUser: (state, action: PayloadAction<any>) => state,
    didConfirmUserSuccess: (state, action: PayloadAction<any>) => void (state.confirmed = true as any),
    didConfirmUserFails: (state, action: PayloadAction<any>) => void (state.error = action.payload),

    willLoginUser: (state, action: PayloadAction<any>) => state,
    didLoginUserSuccess: (state, action: PayloadAction<any>) => void (state.checked = true, state.logged = true as any, state.user = action.payload.user),
    didLoginUserFails: (state, action: PayloadAction<any>) => void (state.checked = true, state.error  = action.payload),

    willRegisterUser: (state, action: PayloadAction<any>) => state,
    didRegisterUserSuccess: (state, action: PayloadAction<any>) => void 
    (state.registered = true,  (state.registeredError as any) =null, state.registrationProfile=action.payload),
    didRegisterUserFails: (state, action: PayloadAction<any>) => void (state.registered = false, 
      state.registeredError = action.payload.error,state.registrationProfile = action.payload.registrationProfile),
    
    willLogoutUser: (state, action: PayloadAction<any>) => state,
    didLogoutUser: (state, action: PayloadAction<any>) => void (state.checked = true, 
      state.registrationProfile = {},
      state.logged = false as any, state.user = null),

    willSignupUser: (state, action: PayloadAction<any>) => state,
    didSignupUserSuccess: (state, action: PayloadAction<any>) => state,
    didSignupUserFails: (state, action: PayloadAction<any>) => void (state.error = action.payload),

    willForgotPasswordRequest: (state, action: PayloadAction<any>) => state,
    didForgotPasswordRequestSuccess: (state, action: PayloadAction<any>) => void (state.forgotPasswordRequested = true),
    didForgotPasswordRequestFails: (state, action: PayloadAction<any>) => void (state.error = action.payload),

    willForgotPasswordConfirm: (state, action: PayloadAction<any>) => state,
    didForgotPasswordConfirmSuccess: (state, action: PayloadAction<any>) => void (state.forgotPasswordRequested = false),
    didForgotPasswordConfirmFails: (state, action: PayloadAction<any>) => void (state.error = action.payload),

    willResendSignup: (state, action: PayloadAction<any>) => state,
  }
})

export const { actions, reducer }: any = currentSlice
export const {
  getCurrentPrivacyVersion,
  willConfirmUser,
  didConfirmUserSuccess,
  didConfirmUserFails,
  willLoginUser,
  willRegisterUser,
  didLoginUserSuccess,
  didLoginUserFails,
  didRegisterUserSuccess,
  didLRegisterUserFails,
  willLogoutUser,
  didLogoutUser,
  willSignupUser,
  didSignupUserSuccess,
  didSignupUserFails,
  willForgotPasswordRequest,
  didForgotPasswordRequestSuccess,
  didForgotPasswordRequestFails,
  willForgotPasswordConfirm,
  didForgotPasswordConfirmSuccess,
  didForgotPasswordConfirmFails,
  setNewsletterSubscribe,
} = actions

export const selectors = {
  getCurrentPrivacyVersion: (state: any) => state.auth.privacy,
  isLogged: (state: any) => state.auth.logged,
  isChecked: (state: any) => state.auth.checked,
  isAuthenticated: (state: any) => state.auth.logged,
  isRegistered: (state: any) => state.auth.registered,
  getLoggedError: (state: any) => state.auth.error,
  getRegisteredError: (state: any) => state.auth.registeredError,
  requestedForgotPassword: (state: any) => state.auth.forgotPasswordRequested,
  getRegistrationProfile: (state: any) => state.auth.registrationProfile,
  isIdeaTeacher: (state: any) => state.auth.registrationProfile.ideaSchool==true,
  isTeacher:(state:any) => state.auth.registrationProfile.type=="teacher" || (state.auth.registrationProfile.type!="student" && state.auth.registrationProfile.school!="--"),
  isStudent:(state:any) => state.auth.registrationProfile.type=="student",  
  getNewsletterSubscribe: (state: any) => state.auth.newsletterSubscribe
}