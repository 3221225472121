import styled, { css } from "styled-components";
import { Button, Input, Label, NavLink } from 'reactstrap'
import { Field } from 'formik';
import Select from "react-select";


export const ClampedDiv = styled.div`

${(props) =>{
  ////console.log("ClampedDiv  props:", props);
  return (props.clamped ? css`
  -webkit-line-clamp: ${props.numLines};
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  margin-bottom: 5px;
  line-height: ${props.lineHeight}px;
` : css`
  margin-bottom: 5px;
  line-height: ${props.lineHeight}px;
  `
    )
}}                  
`

export const PageWrapper = styled.section`
  // &,
  // & * {
  //   box-sizing: border-box;
  //   display: block;
  // }

  hr {
    display: block;
    border: none;
    border-top: 1px solid lightgrey;

    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  font-family: system-ui;
  font-size: 1rem;
  line-height: 1.5rem;
  width: 65em;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1.5rem;
  margin-bottom: 6.5rem;

  padding: 1rem 1rem;
  padding-bottom: 3rem;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.53);
`;

export const CodeWrapper = styled.pre`
  font-family: monospace;
  font-size: 0.875rem;
  line-height: 1.25rem;
  background-color: hsl(210, 4%, 96%);
  overflow: auto;
  padding: 0.75rem;
  margin: 0;
  border-radius: 4px;

  & strong {
    margin-top: 1.5rem;

    &:first-child {
      margin-top: 0;
    }
  }
`;

export const Title = styled.h1`
  font-size: 2rem;
  line-height: 1.25rem;
  font-weight: bold;
  margin-top: 0;
  color: black;
  margin-bottom:30px;
`;

export const NavLinks = styled(NavLink)`
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: bold;
  margin-top: 0;
  cursor: pointer;
  background: #2cc2ca;
`;

export const SubTitle = styled.h4`

line-height: 2.25rem;
margin-top: 25px;
margin-bottom: 25px;
color: #2cc2ca;
`;

export const StyledLabel = styled(Label)`
  // margin-top: 1.25rem;
  // width: 100%;
  font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 600;
    // line-height: 40px;
    // font-weight: 400;
    color: #614e56;
    // min-width: 10px;
    // min-height: 10px;
    // font-style: normal;
    // cursor: pointer;
    // margin: 0;
    // box-sizing: border-box;
    text-align: left;
    // padding-left: 16px;
    // padding: 2px;
    // position: relative;
    // overflow: hidden;
    // display: inline-block;
    // width: 100%;
    // min-width: 10px;
`;

// export const StyledButton=styled(Button)`
// margin-top: 1.5rem;
// margin-left: 50%
// `
export const SelectInput = styled(Select)`
border-radius: 4px;
  box-sizing: border-box;
  color: #666666;
  // font-size: 18px;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  outline: 0;
  width: 100%;
  height: 100%;
  // padding: 10 10px;
  cursor: text;
  background: grey;
  font-style: normal;
  position: relative;
  display: inline-block;
  padding-left: 0%;
  ::placeholder {
    font-size: 10px;
    text-align: left;
    padding-bottom: 50%
  }
`



export const StyledInput = styled(Input)`

// border-radius: 8px;
  // box-sizing: border-box;
  // color: #fffff;
  // font-size: 18px;
  // border-top: 0;
  // border-right: 0;
  // border-left: 0;
  // border-bottom: 0;
  // outline: 0;
  // width: 80%;
  // // height: 32px;
  // padding: 10 10px;
  // cursor: text;
  // background: white;
  // font-style: normal;
  // // position: relative;
  // // display: inline-block;
  // padding-left: 3%;
  // ::placeholder {
  //   font-size: 18px;
  //   text-align: left;
  //   padding-bottom: 50%
  // }
  ${({ valid }) =>
    valid &&
    css`
      // border: 1px solid rgb(0, 156, 38);
      // &:focus,
      // &:active {
      //   border: 1px solid rgb(0, 156, 38);
      //   box-shadow: rgb(106, 237, 97) 0px 0px 2px 1px, rgb(177, 247, 160) 0px 0px
      //       0px 3px;
      //   outline: none;
      // }
      // /* Autocomplete styles in Chrome*/
      // &:-webkit-autofill,
      // &:-webkit-autofill:hover,
      // &:-webkit-autofill:focus {
      //   border: 1px solid rgb(0, 156, 38);
      // }
    `}
  ${({ error }) =>
    error &&
    css`
      // border: 1px solid rgb(191, 49, 12);
      // outline: none;
      // &:focus,
      // &:active {
      //   box-shadow: rgb(244, 129, 116) 0px 0px 2px 1px, rgb(251, 178, 174) 0px 0px
      //       0px 3px;
      //   border: 1px solid rgb(191, 49, 12);
      //   outline: none;
      // }
      // /* Autocomplete styles in Chrome*/
      // &:-webkit-autofill,
      // &:-webkit-autofill:hover,
      // &:-webkit-autofill:focus {
      //   border: 1px solid rgb(191, 49, 12);
      // }
    `}
`;

export const StyledPlainTextInput = styled(StyledInput)`
  border: 1px solid #ced4da;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: #fff;
`




// export const StyledInput = styled(Input)`

// border-radius: 15px;

//   box-sizing: border-box;
//   color: #fffff;
//   font-size: 30px;
//   border-top: 0;
//   border-right: 0;
//   border-left: 0;
//   border: 3px solid #00CCCC;

//   outline: 0;
//   width: 80%;
//   height: 100%;
//   padding: 10 10px;
//   cursor: text;
//   background: white;

//   font-style: normal;
//   position: relative;
//   display: inline-block;


//   ${({ valid }) =>
//     valid &&
//     css`
//       border: 1px solid rgb(0, 156, 38);

//       &:focus,
//       &:active {
//         border: 1px solid rgb(0, 156, 38);
//         box-shadow: rgb(106, 237, 97) 0px 0px 2px 1px, rgb(177, 247, 160) 0px 0px
//             0px 3px;
//         outline: none;
//       }

//       /* Autocomplete styles in Chrome*/
//       &:-webkit-autofill,
//       &:-webkit-autofill:hover,
//       &:-webkit-autofill:focus {
//         border: 1px solid rgb(0, 156, 38);
//       }
//     `}

//   ${({ error }) =>
//     error &&
//     css`
//       border: 1px solid rgb(191, 49, 12);
//       outline: none;

//       &:focus,
//       &:active {
//         box-shadow: rgb(244, 129, 116) 0px 0px 2px 1px, rgb(251, 178, 174) 0px 0px
//             0px 3px;
//         border: 1px solid rgb(191, 49, 12);
//         outline: none;
//       }

//       /* Autocomplete styles in Chrome*/
//       &:-webkit-autofill,
//       &:-webkit-autofill:hover,
//       &:-webkit-autofill:focus {
//         border: 1px solid rgb(191, 49, 12);
//       }
//     `}
// `;

export const StyledInlineErrorMessage = styled.div`
  background-color: transparent;
  color: red;
  display: block;

  white-space: pre-line;
`;

export const ImgFooter = styled.img`
      margin-left: 30%;
      margin-right: 30%;
      max-width: 40%;
      max-height: 50%
`;

export const StyledButton = styled(Button)`
    // margin-top: 30 px;

    // padding: 0.5rem 1rem;
    // margin: 0 auto;
    // background-color:rgb(0, 159, 253);
    // color: rgb(243, 242, 241);
    // font-weight: bold;
    // // border: 2;
    // border-radius: 15px;
    // font-size: 22px;
    // font-family: 'Julius Sans One', sans-serif;
    // cursor: pointer;
    // position: relative;
    // vertical-align: baseline;
    // outline: 0;
    // width: 83%;
    // height: 50px;
    
  &:active,
  &:focus,
  &:hover {
    cursor: pointer;
    background-color:rgb(0, 201, 252);
  }

  &:disabled {
    cursor: pointer;
    background-color: rgb(163, 168, 173);
    color: rgb(255, 255, 255) !important;

    &:hover,
    &:focus {
      cursor: not-allowed;
    }
  }
`;

export const CenteredText = styled.div`
  text-align: center;
`;

export const OutlinedButton = styled.div`
  text-decoration: underline;
  color: #614e56;
  cursor: pointer;
`
