import { useState, useEffect } from "react";
import { FaEye , FaEyeSlash } from "react-icons/fa";
import { Input } from "reactstrap";
import ReactTooltip from "react-tooltip";
import { useTranslation } from 'react-i18next';

const PasswordRevealer = (props) => {
  const [shown, setShown] = useState(false);
  const iconStyle= {cursor:"pointer", marginLeft:"5px", marginTop:"8px"}
  const { t } = useTranslation('frontend', { useSuspense: false });
  
  useEffect(()=>{
    ReactTooltip.rebuild();
  }, [shown])

  return (
      <div style={{ display:"flex" , justifyContent:"flex-start"}}>
        <Input {...props} type={shown ? "text" : "password"} />
        {
            (shown ?  
            <FaEye style={iconStyle} onClick={() => setShown(!shown)} data-for="passwordRevealer" 
            data-tip={t("nascondi password")} />:
            <FaEyeSlash style={iconStyle} onClick={() => setShown(!shown)} data-for="passwordRevealer" 
            data-tip={t("mostra password")} />)
        }
        <ReactTooltip id={"passwordRevealer"} />
      </div>
  );
};

export default PasswordRevealer;