import { useEffect, useRef, useState } from 'react';
import { HotTable } from '@handsontable/react';
import 'handsontable/dist/handsontable.full.min.css';
import Handsontable from 'handsontable';
import HyperFormula from 'hyperformula';
import * as XLSX from 'xlsx';
import { registerAllModules } from 'handsontable/registry';
import { useTranslation } from 'react-i18next';

registerAllModules();


const SheetEditor = (props) => {
  const [sheetData, setSheetData] = useState({ "data": [], cellStyles: {} })
  const [readOnlyCells, setReadOnlyCells] = useState([]);
  const [hiddenColumns, setHiddenColumns] = useState([]);
  const { t } = useTranslation('frontend', { useSuspense: false });

  const hotTableComponent = useRef(null);
  //console.log("IDEVICESESSION:", props.isessionDevice);
  const excelFileURL = props.isessionDevice["attachments"]["worksheet"]["url"]
  // Funzione per gestire gli stili delle celle
  const cellRenderer = (instance, td, row, col, prop, value, cellProperties) => {


    const cellKey = `${String.fromCharCode(col + 65)}${row + 1}`
    // A1 
    const cellStyle = sheetData.cellStyles[cellKey];
    //console.log("Richiamato cellRenderer su:",cellKey);
    if (cellStyle != null) {
      // Applica gli stili delle celle dalla XLSX alla HotTable
      for (const key in cellStyle) {

        const styleValue = cellStyle[key];
        if (styleValue != null) {
          if (key == "fgColor") // N;B il riempimento della cella è il foreground color
          {
            td.style["backgroundColor"] = `#${styleValue["rgb"]}`
            //console.log("Stile attuale background:", styleValue)
            //console.log("Stile completo della cella:", cellStyle)
          }
          /*
          else if (key=="bgColor")
          {
            console.log(`Stile proposto per il foreground su cella ${cellKey}:`, styleValue)
            //td.style["color"] = "rgb(255,0,0)" //`#${styleValue["rgb"]}`
            //td.style["color"] = `#FFFF00`
            console.log("Imposto il colore del foreground della cella al valore:", `#${styleValue["rgb"]}`);
            td.style["color"] =  "#1E6A39" //`#${styleValue["rgb"]}`
          }
          */
          else td.style[key] = styleValue;

        }
        //td.innerHTML = '<b>' + value + '</b>';
      }
    }
    //td.style["color"] = '#008080'    //"#1E6A39"
    //td.style.color = 'red';
    // Imposta il testo in grassetto
    //td.style.fontWeight = 'bold';
    // Imposta il valore della cella
    if (row == 0) {
      td.style.fontWeight = 'bold';
    }
    Handsontable.renderers.TextRenderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
    return td;
  };

  const isColumnHidden = (sheet, columnIndex) => {
    //const sheet = workbook.Sheets[workbook.SheetNames[0]]; // Assume che tu stia lavorando con il primo foglio
    const colWidth = sheet['!cols'] ? sheet['!cols'][columnIndex] : null;

    // Controlla se la larghezza della colonna è 0 o se la colonna è nascosta
    if (colWidth && colWidth.hidden === 1) {
      return colWidth;
    }

    // Altri criteri per determinare se la colonna è nascosta potrebbero essere aggiunti qui

    return null;
  };

  const convertToHotTableData = (worksheet) => {

    const hotTableData = [];
    const cellStyles = {}; // Oggetto per memorizzare gli stili delle celle

    // Itera sulle celle del foglio di calcolo
    for (const cell in worksheet) {
      if (cell.startsWith('!')) continue; // Ignora le chiavi speciali come "!ref"

      const [col, row] = cell.match(/[A-Z]+|\d+/g); // Estrae la colonna e la riga dalla chiave della cella
      const maxRows = props.maxRows || props.isessionDevice["attachments"]["worksheet"]["maxRows"]
      if (maxRows && !isNaN(maxRows) && row > maxRows) break; // in questo modo impongo un limite al numero di righe da leggere!

      const cellData = worksheet[cell];
      if (cellData.f != null) {
        cellData.f = cellData.f.replace(/\d+(?:\.\d+)?(?:[Ee][+\-]?\d+)?/g, match => match.toLowerCase());
        console.log("HTD; Trovata FORMULA MODIFICATA", cellData);
        //cellData = cellData.toExponential().toLowerCase();
      }


      let value;
      let style;
      const colHidden = isColumnHidden(worksheet, col.charCodeAt(0) - 65)
      if (colHidden != null)
        console.log(`CCC: Colonna ${(col.charCodeAt(0) - 65)} nascosta trovata:`, colHidden)

      // Se la cella contiene una formula, assegna la formula stessa
      if (cellData.f) {
        value = `=${cellData.f}`; // Ottieni la formula
      } else {
        value = cellData.v; // Altrimenti, ottieni il valore della cella
      }

      // Ottieni gli stili della cella se presenti
      if (cellData.s) {
        style = cellData.s; // Memorizza gli stili della cella
        cellStyles[`${col}${row}`] = style; // Memorizza gli stili utilizzando la chiave della cella come identificatore
      }

      // Se non esiste ancora un array per la riga, crealo
      if (!hotTableData[row - 1]) hotTableData[row - 1] = [];

      // Assegna il valore alla cella nella riga e nella colonna corrispondenti
      hotTableData[row - 1][col.charCodeAt(0) - 65] = value;

      // Aggiungi gli stili della cella alla matrice dei dati se presenti
      if (style) {
        hotTableData[row - 1][`${col}${row}_style`] = style;
      }
    }

    return { data: hotTableData, cellStyles };
  }

  useEffect(() => {
    if (hotTableComponent.current) {
      const hotInstance = hotTableComponent.current.hotInstance;
      if (hotInstance) {
        const numberOfRows = sheetData.data.length;
        const defaultRowHeight = 23;
        const additionalHeight = 30;
        const height = `${numberOfRows * defaultRowHeight + additionalHeight}px`;
        hotInstance.updateSettings({ height: height });
      }
    }

  }, [sheetData]);


  const convertExcelToIndexes = (coordinates) => {
    const regex = /^([A-Z]+)(\d+)$/; // Regex per estrarre lettere e numeri dalle coordinate Excel
    const indexes = [];
    for (const coord of coordinates) {
      const match = coord.match(regex);

      if (match) {
        const [, colLetters, rowNum] = match;
        const colNum = colLetters.split('').reduce((acc, letter, index) => {
          // Calcoliamo il numero di colonna basandoci sulle lettere dell'alfabeto
          return acc * 26 + (letter.charCodeAt(0) - 65 + 1);
        }, 0) - 1; // Sottraiamo 1 per convertire da base 1 a base 0
        const rowIdx = parseInt(rowNum, 10) - 1; // Sottraiamo 1 per convertire da base 1 a base 0
        indexes.push([rowIdx, colNum]);
      } else {
        console.error(`Coord invalida: ${coord}`);
      }
    }
    return indexes;
  }

  const coordinateMatch = (row, col, coordinateList) => {
    for (const coord of coordinateList) {
      const [coordRow, coordCol] = coord;
      if (row === coordRow && col === coordCol) {
        return true; // Trovato una corrispondenza
      }
    }
    return false; // Nessuna corrispondenza trovata
  }

  // Funzione per importare dati da un file Excel tramite URL
  const importDataFromExcelURL = (url, ignoreLocalStorage) => {
    console.log("Richiamato importDataFromExcelURL", ignoreLocalStorage);
    //ignoreLocalStorage = true; // forzato per debugging
    const sheetIndex = props.index || 0
    fetch(url)
      .then(response => response.arrayBuffer())
      .then(data => {
        const workbook = XLSX.read(new Uint8Array(data),
          { type: 'array', cellFormula: true, cellStyles: true });
        const sheet = workbook.Sheets[workbook.SheetNames[sheetIndex]];

        // PER EVITARE DI NON MOSTRARE COLONNE CHE HANNO DATI IN QUALCHE RIGA
        //const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        // Find the maximum number of columns in any row
        //const maxColumns = Math.max(...jsonData.map(row => row.length));
        //console.log("sheet", sheet)
        //https://docs.sheetjs.com/docs/solutions/output#api-1
        //const parsedData = XLSX.utils.sheet_to_json(sheet, { header: 1, cellFormula: true, cellStyles: true });

        setReadOnlyCells(convertExcelToIndexes(props.readOnly || []))
        setHiddenColumns(props.hiddenColumns || [])

        let loadFromLocalStorage = !(ignoreLocalStorage === true)
        //console.log("lloadFromLocalStorage:", loadFromLocalStorage);
        if (loadFromLocalStorage) {
          const REPORT_KEY = `REPORT_ISESSION_${props.isession["id"]}`
          const sessionLocalStorage = localStorage.getItem(REPORT_KEY, null)
          //console.log(`LOCALSTORAGE: ${REPORT_KEY}:`, sessionLocalStorage)
          const sessionLocalStorageContent = sessionLocalStorage && JSON.parse(sessionLocalStorage)
          //console.log(`LOCALSTORAGE CONTENT: ${REPORT_KEY}:`, sessionLocalStorageContent)
          if (sessionLocalStorageContent != null &&
            sessionLocalStorageContent["worksheet"] != null &&
            sessionLocalStorageContent["worksheet"][props.id] != null &&
            sessionLocalStorageContent["worksheet"][props.id]["data"]?.length > 0) {
            //console.log("LOCALSTORAGE trovato con sheetData:", sessionLocalStorageContent["worksheet"])
            setSheetData(sessionLocalStorageContent["worksheet"][props.id]);
            // SE I DATI LI STO LEGGENDO DA LOCALSTORAGE NON DEVO NOTIFICARE MODIFICHE AL PARENT
            //props.onChange(sessionLocalStorageContent["worksheet"][props.id], props.id)
          }
          else {
            //console.log(`LOCALSTORAGE ${REPORT_KEY} NON TROVATO su isession:`, sessionLocalStorage);
            loadFromLocalStorage = false
          }
        }

        if (loadFromLocalStorage == false) {
          const parsedData = convertToHotTableData(sheet);
          //console.log("parsed data->", parsedData.data);
          // console.log("parsed styles:::", parsedData.cellStyles);
          setSheetData(parsedData);
          props.onChange(parsedData, props.id);
        }
      })
      .catch(error => {
        console.error(`Errore durante il recupero del file Excel url:${url}:`, error);
      });
  };


  useEffect(() => {
    //console.log("RICHIAMATO COSTRUTTORE SHEET EDITOR:", props.id);
    // URL del file Excel
    // Importa dati dal file Excel tramite URL
    importDataFromExcelURL(excelFileURL, false);
    // Cleanup
    return () => {
    };
  }, []);


  const hotTableSettings = {
    data: sheetData.data,

    /*
    afterSelectionEnd : (row,column) => {
      if (hotTableComponent.current) {
        console.log("Modificato contenuto: (after selection end)")
        const hot = hotTableComponent.current.hotInstance;
        const editor = hot.getActiveEditor();
        if (editor !== null) {
          // L'editor è attivo, abilita la modifica della cella
          hot.getCell(row, column).readOnly = false;
        } else {
          // L'editor non è attivo, disabilita la modifica della cella
          hot.getCell(row, column).readOnly = true;
        }
      }
    },
    */
    afterChange: (changes, source) => {
      //console.log("AFTER_CHANGE: changes/source:", changes,source)

      if (changes != null && source == "edit") {
        props.onChange(sheetData, props.id);
        //console.log("AFTER_CHANGE:",changes, sheetData);
      }
    },
    contextMenu: {
      items: {
        undo: { name: t("undo") },
        redo: { name: t("redo") },
        copy: { name: t("copy") },
        cut: { name: t("cut") },
        paste: {
          name: t("paste"),
          async callback(key, selection, clickEvent) {
            const hotInstance = hotTableComponent.current?.hotInstance;
            
            if (hotInstance != null && selection.length > 0) {

              const contentToPaste = await navigator.clipboard.readText();
              
              let clipboardRows = contentToPaste.split('\n');
               
              let targetOffsetRow = selection[0]["start"]["row"] 
              let targetOffsetCol = selection[0]["start"]["col"] 
              let content = []
              /*
              const content = clipboardRows.map((rowItem, rowIndex) => 
              {
                const rowContent = rowItem.split("\t")
                return rowContent.map((colItem, colIndex)=>{
                  return [targetOffsetRow+rowIndex, targetOffsetCol+colIndex, colItem]
                })
            }).filter(Boolean);
             */

           
              clipboardRows.forEach((clipboardRow, rowIndex) => {
                let clipboardColumns = clipboardRow.split('\t');
                // Cicla attraverso le colonne del clipboard
                clipboardColumns.forEach((clipboardValue, colIndex) => {
                  // Calcola la posizione della cella target
                  let targetRow = targetOffsetRow + rowIndex;
                  let targetCol = targetOffsetCol + colIndex;

                  if (targetRow < hotInstance.countRows() && targetCol < hotInstance.countCols())
                   {
                    content.push([targetRow, targetCol, clipboardValue])
                    //hotInstance.setDataAtCell(targetRow, targetCol, clipboardValue);
                  }
                })
              })
              
              // aggiorno tutto alla fine
              console.log("content:", content);
              hotInstance.setDataAtCell(content) 
            }

          },
        },
        reset: {
          name: t("reset"),
          callback(key, selection, clickEvent) { importDataFromExcelURL(excelFileURL, true) }
        }
      }
    },
    colHeaders: true,
    rowHeaders: true,
    stretchH: 'all',
    hiddenColumns: { columns: hiddenColumns },
    autoColumnSize: true,
    formulas: {
      engine: HyperFormula,
    },
    cells: (row, col, prop) => (
      {
        renderer: cellRenderer,
        readOnly: (coordinateMatch(row, col, readOnlyCells))
      }

    ),
    licenseKey: "non-commercial-and-evaluation",

    autoWrapRow: true,
    autoWrapCol: true,
    height: "100%"

  };

  return (
    <div style={{ display: "flex", padding: "10px", flexDirection: "column", justifyContent: "flex-start" }}>
      <div style={{ overflow: 'auto', width: '100%' }}>
        <HotTable
          style={{ width: "100%" }}
          ref={hotTableComponent}
          settings={hotTableSettings}
        />
      </div>
    </div>
  );
}


export default SheetEditor;
