import { API } from 'aws-amplify';
import { loader } from 'graphql.macro';


export const createChatbotFeedback = async (
    value: any,
    chatbotVersion: String,
    userType: String,
    currentSection: String,
    messages: any,
    text : String,
    timestamp : any
  ) => {
    const query = loader("../graphql/createChatbotFeedback.gql");
    //console.log("generateVideoTranscription mutation: ", query);
    try {
      const result: any = await API.graphql({
        query: query,
        variables: {
          value,
          chatbotVersion,
          userType,
          currentSection,
          messages,
          text,
          timestamp
        }
      });
      return result;
    } catch (error) {
      console.log("Error in createChatbotFeedback Mutation", error);
      throw error;
    }
  };
